/* import __COLOCATED_TEMPLATE__ from './add-participant-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { not, and, or } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Component.extend({
  securityAppSettingsService: service(),
  appService: service(),
  intercomEventService: service(),
  notificationsService: service(),

  emailContentDisabled: and('conversation.isChannelMessenger', 'emailContentSettingsDisabled'),
  emailContentSettingsDisabled: not(
    'securityAppSettingsService.settings.notificationEmailsWithContentEnabled',
  ),

  init() {
    this._super(...arguments);
    this.securityAppSettingsService.ensureDataIsLoaded.perform();
  },

  inputError: false,
  addParticipantButtonDisabled: or(
    'conversation.main_participant.leadWithoutEmail',
    'conversation.main_participant.is_mobile_unidentified',
    'conversation.has50ParticipantsOrMore',
    'emailContentDisabled',
  ),
  tooltipForLeads: "To add more customers to this conversation, you need this lead's email address",
  tooltipForUnidentifiedUsers:
    "You can't add more customers to conversations with unidentified users",
  tooltipForCannotAddMoreThan50Customers: 'You can only add up to 50 customers in a conversation',
  tooltipForEmailContentDisabled:
    'When conversation content in email notifications is disabled, people cannot be added to Messenger conversations',
  disabledToolTipText: computed(
    'conversation.{main_participant.leadWithoutEmail,has50ParticipantsOrMore}',
    'tooltipForCannotAddMoreThan50Customers',
    'tooltipForLeads',
    'tooltipForUnidentifiedUsers',
    'tooltipForEmailContentDisabled',
    'emailContentDisabled',
    function () {
      if (this.get('conversation.has50ParticipantsOrMore')) {
        return this.tooltipForCannotAddMoreThan50Customers;
      } else if (this.get('conversation.main_participant.leadWithoutEmail')) {
        return this.tooltipForLeads;
      } else if (this.emailContentDisabled) {
        return this.tooltipForEmailContentDisabled;
      } else {
        return this.tooltipForUnidentifiedUsers;
      }
    },
  ),

  addParticipantTask: task(function* (email, hidePopover) {
    let conversation = this.conversation;
    let dataForAnalyticsEvent = {
      action: 'added',
      object: 'user',
      owner: 'respond',
      place: 'inbox',
      email,
      models: [conversation],
      success: true,
    };
    try {
      yield conversation.addParticipant(email);
      hidePopover();
      this.notificationsService.notifyConfirmation(`${email} has been added to the conversation`);
    } catch (error) {
      dataForAnalyticsEvent.success = false;
      if (error.jqXHR.status === 422) {
        this.notificationsService.notifyWarning(error.jqXHR.responseJSON.errors);
        this.set('inputError', true);
      } else if (error.jqXHR.status === 403) {
        this.notificationsService.notifyError('Failed to add a participant');
      } else {
        this.notificationsService.notifyWarning(error.jqXHR.responseJSON.errors);
      }
    } finally {
      this.intercomEventService.trackAnalyticsEvent(dataForAnalyticsEvent);
    }
  }).drop(),
});
