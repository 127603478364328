/* import __COLOCATED_TEMPLATE__ from './confirm-link-reports-to-tracker-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';

interface Args {}

interface Signature {
  Args: Args;
}

export default class ConfirmLinkReportsToTrackerModal extends Component<Signature> {
  @service declare inboxState: InboxState;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConfirmLinkReportsToTrackerModal': typeof ConfirmLinkReportsToTrackerModal;
    'inbox2/left-nav/confirm-link-reports-to-tracker-modal': typeof ConfirmLinkReportsToTrackerModal;
  }
}
