/* import __COLOCATED_TEMPLATE__ from './spam-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { type SpamMessage } from 'embercom/objects/inbox/spam';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { request } from 'embercom/lib/inbox/requests';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { type InboundEmailWireFormat, InboundEmail } from 'embercom/objects/inbox/spam';

export interface SpamPanelArgs {
  message: SpamMessage;
  hidePanel: () => void;
  markAsNotSpam: () => Promise<void>;
}

interface Signature {
  Args: SpamPanelArgs;
}

export default class SpamPanel extends Component<Signature> {
  @service declare session: Session;

  @tracked email: InboundEmail | undefined;
  @tracked hasError = false;

  @ref('panel') declare panel: HTMLDivElement;

  constructor(owner: unknown, args: SpamPanelArgs) {
    super(owner, args);
    taskFor(this.loadEmail).perform();
  }

  @action handleDocumentClick(ev: PointerEvent) {
    let ignoredElements = [
      this.panel,
      document.querySelector('.popover__content'),
    ].compact() as HTMLElement[];

    if (ignoredElements.any((element) => element.contains(ev.target as HTMLElement))) {
      return;
    }

    this.args.hidePanel();
  }

  @task
  *loadEmail() {
    try {
      this.hasError = false;
      this.email = yield this.fetchInboundEmail();
    } catch (e) {
      this.hasError = true;
    }
  }

  async fetchInboundEmail() {
    let response = await request(
      `/ember/inbound_emails/${this.args.message.id}?app_id=${this.session.workspace.id}`,
    );
    let json = (await response.json()) as InboundEmailWireFormat;
    return InboundEmail.deserialize(json);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SpamPanel': typeof SpamPanel;
    'inbox2/spam-panel': typeof SpamPanel;
  }
}
