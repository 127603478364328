/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
// @ts-ignore
import { ref } from 'ember-ref-bucket';

interface Args {
  value: string | null;
  onSubmit: (value: string) => void;
  isTitle?: boolean;
  isRequired?: boolean;
}

interface Signature {
  Args: Args;
}

export default class Input extends Component<Signature> {
  @service declare session: Session;

  @tracked scrollHeight = 72;
  @tracked editMode = false;
  @ref('textarea') textarea!: HTMLElement;

  @action
  resizeAndSubmit(value: string) {
    this.scrollHeight = this.textarea.scrollHeight + 5;
    this.submit(value);

    this.editMode = false;
  }

  @action
  submit(value: string) {
    this.editMode = false;
    this.args.onSubmit(value);

    this.editMode = false;
  }

  updateTextAreaSize = modifier((element: Element) => {
    this.scrollHeight = element.scrollHeight + 5;
  });

  get heightStyle() {
    return `height: ${this.scrollHeight}px;resize:none;`;
  }

  get inputPadding() {
    return this.isTitle ? '-mx-2 p-2' : 'py-px px-1';
  }

  get valueToString() {
    return this.args.value?.toString();
  }

  get isTitle() {
    return this.args.isTitle;
  }

  get placeholderText() {
    if (this.isTitle) {
      return 'inbox.conversation-attributes.add-title-placeholder';
    } else {
      return 'inbox.conversation-attributes.placeholder';
    }
  }

  get linkifiedValue() {
    return this.escapeHtml(this.args.value)?.replace(
      /(https?:\/\/)([^\s]+)/g,
      '<a target="_blank" href="$1$2" data-inline-link="true">$2</a>',
    );
  }
  escapeHtml(unsafe: string | null) {
    return unsafe
      ?.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  @action edit(event: Event) {
    if (event.target instanceof Element) {
      if (event.target?.getAttribute('data-inline-link') === 'true') {
        event.stopPropagation();
        return;
      }
    }

    this.editMode = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::Input': typeof Input;
    'inbox2/ticket-attribute/input': typeof Input;
  }
}
