/* import __COLOCATED_TEMPLATE__ from './swift.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  iosApiKey: string;
  appId: string;
}

interface Signature {
  Args: Args;
  Element: any;
}

const Swift = templateOnlyComponent<Signature>();
export default Swift;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Ios::CodeSnippets::Swift': typeof Swift;
    'installation-new/mobile/initial-setup/ios/code-snippets/swift': typeof Swift;
  }
}
