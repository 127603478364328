/* import __COLOCATED_TEMPLATE__ from './edit-menu.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type IntlService from 'embercom/services/intl';
import { type InboxFolder } from 'embercom/objects/inbox/inbox-folder';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { action } from '@ember/object';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import { type StrictModifiers } from '@popperjs/core';

interface Arguments {
  inboxFolder: InboxFolder;
  isHovering: boolean;
  addPin: (inbox: Inbox) => Promise<void>;
  removePin: (inbox: Inbox) => Promise<void>;
  expandFolder: () => void;
  onCreateView: (customFolderId?: number) => void;
  onEditView: (row: ViewSummary, hidePopover: Function | null) => void;
  togglePin: () => void;
  onDelete: () => void;
  onCreateCustomFolder: () => void;
  onEditCustomFolder: () => void;
}

interface Signature {
  Args: Arguments;
}

export default class EditMenu extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  noop() {}

  get popoverOffsetModifier(): StrictModifiers {
    return {
      name: 'offset',
      options: {
        offset: [1, -12],
      },
    };
  }

  get teamItem() {
    return {
      text: this.intl.t(`inbox.inbox-list.custom-folders-edit-menu.add-teams`),
      searchPlaceholder: 'inbox.inbox-list.search-teams-placeholder',
      entityType: SearchableType.Team,
      inboxCategory: InboxCategory.Team,
      pinnedInboxes: this.pinnedInboxesForType('team'),
      addPin: this.args.addPin,
      removePin: this.args.removePin,
      customFolderId: this.args.inboxFolder.id,
      expandFolder: this.args.expandFolder,
      onCreateView: this.args.onCreateView,
      onEditView: this.args.onEditView,
      component: 'inbox2/left-nav/folders/custom/edit-menu/item',
      componentShouldReplaceItem: true,
    };
  }

  get adminItem() {
    return {
      text: this.intl.t(`inbox.inbox-list.custom-folders-edit-menu.add-teammates`),
      searchPlaceholder: 'inbox.inbox-list.search-teammates-placeholder',
      entityType: SearchableType.Admin,
      inboxCategory: InboxCategory.Admin,
      pinnedInboxes: this.pinnedInboxesForType('admin'),
      addPin: this.args.addPin,
      removePin: this.args.removePin,
      customFolderId: this.args.inboxFolder.id,
      expandFolder: this.args.expandFolder,
      onCreateView: this.args.onCreateView,
      onEditView: this.args.onEditView,
      component: 'inbox2/left-nav/folders/custom/edit-menu/item',
      componentShouldReplaceItem: true,
    };
  }

  get viewItem() {
    return {
      text: this.intl.t(`inbox.inbox-list.custom-folders-edit-menu.add-views`),
      searchPlaceholder: 'inbox.inbox-list.search-views-placeholder',
      entityType: SearchableType.View,
      inboxCategory: InboxCategory.View,
      pinnedInboxes: this.pinnedInboxesForType('view'),
      addPin: this.args.addPin,
      removePin: this.args.removePin,
      customFolderId: this.args.inboxFolder.id,
      expandFolder: this.args.expandFolder,
      onCreateView: this.args.onCreateView,
      onEditView: this.args.onEditView,
      component: 'inbox2/left-nav/folders/custom/edit-menu/item',
      componentShouldReplaceItem: true,
    };
  }

  @action trackOpenPopover(inboxFolder: InboxFolder) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'inbox_folder_dropdown',
      inbox_folder_id: inboxFolder.id,
    });
  }

  private pinnedInboxesForType(type: string): Inbox[] {
    return this.args.inboxFolder.objects
      .filter((inboxFolderObject) => inboxFolderObject.objectType === type)
      .map((inboxFolderObject) => inboxFolderObject.inbox);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Custom::EditMenu': typeof EditMenu;
    'inbox2/left-nav/folders/custom/edit-menu': typeof EditMenu;
  }
}
