/* import __COLOCATED_TEMPLATE__ from './priority-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type BooleanComparison } from 'embercom/objects/inbox/search/predicate-group';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  selected?: BooleanComparison;
  onSelect: (priority?: BooleanComparison) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SearchFiltersPrioritySelector extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked selectedPriority?: BooleanComparison | 'none' = this.args.selected || 'none';

  get label() {
    this.intl.primaryLocale; // We must access the locale to make this getter aware that it is dependent on it.

    if (this.selectedPriority === 'none') {
      return this.intl.t('inbox.search.filters.priority.none');
    }

    return this.intl.t(`inbox.search.filters.priority.${this.selectedPriority}`);
  }

  @action onSelectPriority(value: BooleanComparison) {
    this.selectedPriority = value;
    this.applyFilter();
  }

  @action clear() {
    this.selectedPriority = 'none';
    this.applyFilter();
  }

  @action applyFilter() {
    if (this.selectedPriority === 'none') {
      this.args.onSelect();
    } else {
      this.args.onSelect(this.selectedPriority);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::PrioritySelector': typeof SearchFiltersPrioritySelector;
    'inbox2/search/priority-selector': typeof SearchFiltersPrioritySelector;
  }
}
