/* import __COLOCATED_TEMPLATE__ from './event-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import relativeTime from 'embercom/lib/relative-time';
import moment from 'moment-timezone';
import { equal } from '@ember/object/computed';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  expanded: false,
  isEvent: false,
  iconClass: ternary('expanded', 'small-thin-down-arrow', 'small-thin-right-arrow'),
  relativeDateTime: computed('timestamp', function () {
    let momentTime = moment(this.timestamp);

    return relativeTime(momentTime, 'en-abbreviated-relative-time', false);
  }),
  displayedName: computed('name', 'url', function () {
    return this.name || this.url || '-';
  }),
  isRecent: equal('relativeDateTime', 'Now'),
  formattedToolTipDate: computed('timestamp', function () {
    return moment(this.timestamp).format(dateAndTimeFormats.fullDateAndTimeWithoutTimezone);
  }),
  hasMetadata: computed('metadata', function () {
    return this.metadata && Object.keys(this.metadata).length > 0;
  }),
  actions: {
    toggleExpand() {
      this.toggleProperty('expanded');
    },
  },
});
