/* import __COLOCATED_TEMPLATE__ from './user-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type UserSummary from 'embercom/objects/inbox/user-summary';

interface Signature {
  Element: HTMLElement;
  Args: { user: UserSummary | undefined; onSelect: (user: UserSummary) => any };
}

const UserSelector = templateOnlyComponent<Signature>();
export default UserSelector;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::UserSelector': typeof UserSelector;
  }
}
