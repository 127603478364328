/* import __COLOCATED_TEMPLATE__ from './tags-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type SearchableData } from 'embercom/objects/inbox/searchable-document';
import type Tag from 'embercom/objects/inbox/tag';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import { trackedFunction } from 'ember-resources/util/function';

interface Signature {
  Element: HTMLElement;
  Args: { tags: SearchableData[]; onSelect: (tag: Tag) => any; onClear: () => any };
}

export default class TagsSelector extends Component<Signature> {
  @service declare inbox2TagsSearch: Inbox2TagsSearch;

  @tracked query = '';

  @action onQueryChanged(query: string) {
    this.query = query;
  }

  get results() {
    return this.tagsLoader.value || [];
  }

  tagsLoader = trackedFunction(this, async () => {
    let results = await this.inbox2TagsSearch.filterTags(this.query);
    return results.results.map((d) => d.data);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::TagsSelector': typeof TagsSelector;
  }
}
