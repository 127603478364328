/* import __COLOCATED_TEMPLATE__ from './recent-searches.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import storage from 'embercom/vendor/intercom/storage';
import { tracked } from '@glimmer/tracking';

interface Args {}

interface Signature {
  Args: Args;
}

export const LOCALSTORAGE_RECENT_SEARCHES_KEY = 'search-v2-recent-searches';
export default class SearchV2RecentSearches extends Component<Signature> {
  @service declare router: RouterService;
  @tracked recentSearches: string[] = storage.get(LOCALSTORAGE_RECENT_SEARCHES_KEY);

  get hasRecentSearches() {
    return this.recentSearches && this.recentSearches.length > 0;
  }

  @action onSelect(query: string) {
    this.router.transitionTo('inbox.workspace.inbox.search', {
      queryParams: { query },
    });
  }

  @action onClose(index: number) {
    let filteredSearches = this.recentSearches.filter(
      (_: string, searchIndex: number) => index !== searchIndex,
    );
    storage.set(LOCALSTORAGE_RECENT_SEARCHES_KEY, filteredSearches);
    this.recentSearches = filteredSearches;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::RecentSearches': typeof SearchV2RecentSearches;
    'inbox2/search/recent-searches': typeof SearchV2RecentSearches;
  }
}
