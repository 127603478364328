/* import __COLOCATED_TEMPLATE__ from './ticket-always-shared.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type InboxApi from 'embercom/services/inbox-api';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type TicketDescriptor, type TicketType } from 'embercom/objects/inbox/ticket';

interface Args {
  conversation: Conversation;
  ticketType?: TicketType;
}

interface Signature {
  Args: Args;
}

export default class TicketAlwaysShared extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;

  @tracked showVisibilityModal = false;
  @tracked showCategoryChangeVisibilityModal = false;

  get conversation() {
    return this.args.conversation;
  }

  get ticketType() {
    return this.args.ticketType;
  }

  get hasUserVisibleAttributes() {
    return this.userVisibleAttributes && this.userVisibleAttributes.length > 0;
  }

  get userVisibleAttributes() {
    return this.ticketType?.descriptors
      ?.filter((descriptor: TicketDescriptor) => !descriptor.archived && descriptor.visibleToUsers)
      .map((descriptor: TicketDescriptor) => ({
        ...descriptor,
        attributeIconName: this.attributeIconName(descriptor.dataType, descriptor.multiline),
      }));
  }

  get sharingTooltipContent() {
    return this.intl.t(`inbox.ticket-details-header.tooltip-content.customer-ticket-always-shared`);
  }

  get visibleAttributesTooltipHeader() {
    return this.intl.t('inbox.ticket-details-header.tooltip-content.visible-attributes');
  }

  attributeIconName(attribute: DataType, isMultiline: boolean | null) {
    if (attribute === 'integer' || attribute === 'decimal') {
      return 'numbers';
    } else if (attribute === 'datetime') {
      return 'calendar';
    } else if (attribute === 'list') {
      return 'list';
    } else if (attribute === 'boolean') {
      return 'boolean';
    } else if (attribute === 'files') {
      return 'attachment';
    } else if (attribute === 'string' && isMultiline) {
      return 'long-text';
    } else {
      return 'short-text';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAlwaysShared': typeof TicketAlwaysShared;
    'inbox2/ticket-always-shared': typeof TicketAlwaysShared;
  }
}
