/* import __COLOCATED_TEMPLATE__ from './ios-installation-snippet-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';

export default class IosInstallationSnippetSelectComponent extends Component {
  options = [
    { label: 'Swift', value: 'swift' },
    { label: 'Objective C', value: 'objectivec' },
    { label: 'React Native', value: 'reactnative' },
  ];
}
