/* import __COLOCATED_TEMPLATE__ from './gifs.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { INBOX2_ONBOARDING_GIFS } from 'embercom/lib/inbox2/onboarding/state';

export default class Gifs extends Component {
  readonly gifs = INBOX2_ONBOARDING_GIFS;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::CommandK::Gifs': typeof Gifs;
    'inbox2/onboarding/command-k/gifs': typeof Gifs;
  }
}
