/* import __COLOCATED_TEMPLATE__ from './responsiveness-heatmap.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { botInboxAndOfficeHoursField } from 'embercom/lib/reporting/office-hours-field';
import {
  MEDIAN,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  TEAMMATE_RESPONSE_TIME,
  COMMENT_CREATED_AT_DAY_OF_WEEK,
  CONVERSATION_PART_REPLIES_FILTERS,
} from 'embercom/lib/reporting/flexible/constants';

export default class ResponsivenessHeatmapComponent extends Component {
  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = { defaultOption: MEDIAN };
    viewConfig.groupings = { defaultOption: GROUPINGS[COMMENT_CREATED_AT_DAY_OF_WEEK] };
    viewConfig.formatUnit = { unit: units.minute, displayUnit: units.minute };

    return viewConfig;
  }

  get botInboxTimeExcluded() {
    return this.args.botInboxTime?.[0] === 'excluded';
  }

  get dataConfig() {
    return {
      yAxis: {
        type: 'temporal',
        data: {
          interval: 'hour_of_day',
          property: 'comment_created_at',
        },
      },
      xAxis: {
        name: 'comment_created_at_day',
        type: 'temporal',
        data: {
          interval: 'day_of_week',
          property: 'comment_created_at',
        },
      },
      series: [
        {
          name: 'responsiveness_median_heatmap',
          source: 'consolidated_conversation_part',
          filter: {
            type: 'and',
            filters: CONVERSATION_PART_REPLIES_FILTERS,
          },
          type: MEDIAN,
          data: {
            property: botInboxAndOfficeHoursField(
              TEAMMATE_RESPONSE_TIME,
              this.args.excludeOooHours,
              this.botInboxTimeExcluded,
              true,
            ),
            value: 50,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
