/* import __COLOCATED_TEMPLATE__ from './summary-part.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Session from 'embercom/services/session';
import type TwilioService from 'embercom/services/twilio-service';
import type CtiService from 'embercom/services/cti-service';
import type PhoneCallService from 'embercom/services/phone-call-service';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Arguments {
  conversation: ConversationSummary | Conversation | ConversationTableEntry;
  peopleTyping?: {
    typingUserId?: string;
    typingAdmin?: AdminSummary;
    typingNoteAdmin?: AdminSummary;
  };
  checkForTruncation?: boolean;
  isHighlighted?: boolean;
  isTableView?: boolean;
  userDisplay?: string;
  hideSenderLabel?: boolean;
  hideTitle?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

export default class SummaryPart extends Component<Signature> {
  @service declare session: Session;
  @service declare twilioService: TwilioService;
  @service declare ctiService: CtiService;
  @service declare phoneCallService: PhoneCallService;

  RenderableType = RenderableType;

  get workspaceID() {
    return this.session.workspace.id;
  }

  get hasActivePhoneCall() {
    return this.twilioCallActive || this.ctiCallActive;
  }

  get hadOutboundCall() {
    let lastOutboundState = this.args.conversation.attributes?.find((attribute) => {
      return attribute.descriptor.id === 'last_outbound_call_state';
    });

    return typeof lastOutboundState !== 'undefined';
  }

  get twilioCallActive() {
    return (
      this.twilioService.isActiveCall &&
      this.twilioService.activeCall?.conversation.id === this.args.conversation.id
    );
  }

  get ctiCallActive() {
    return (
      this.ctiService.ctiAvailable &&
      this.ctiService.activeCall &&
      this.phoneCallService.conversationId === this.args.conversation.id
    );
  }

  get summaryPart() {
    return (
      this.args.conversation.lastAdminMentionedPart ||
      this.args.conversation.lastRenderableSummaryPart
    );
  }

  get showTitle() {
    return !this.args.hideTitle;
  }

  get isSideConversationStartedPart() {
    let renderableType = this.args.conversation.lastRenderableSummaryPart?.renderableType;

    return renderableType === RenderableType.SideConversationStarted;
  }

  get isSideConversationReplyPart() {
    let renderableType = this.args.conversation.lastRenderableSummaryPart?.renderableType;

    return renderableType === RenderableType.SideConversationReply;
  }

  get isSideConversationEventPart() {
    return this.isSideConversationStartedPart || this.isSideConversationReplyPart;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationCard::SummaryPart': typeof SummaryPart;
    'inbox2/left-nav/conversation-card/summary-part': typeof SummaryPart;
  }
}
