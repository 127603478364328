/* import __COLOCATED_TEMPLATE__ from './conversation-out-of-context-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    conversationSummary: ConversationSummary;
    inboxMatches: boolean;
    stateMatches: boolean;
  };
}

const ConversationOutOfContextOverlay = templateOnlyComponent<Signature>();
export default ConversationOutOfContextOverlay;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationOutOfContextOverlay': typeof ConversationOutOfContextOverlay;
    'inbox2/left-nav/conversation-out-of-context-overlay': typeof ConversationOutOfContextOverlay;
  }
}
