/* import __COLOCATED_TEMPLATE__ from './new-next-breach-time.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import RelativeDatetime from 'embercom/components/common/relative-datetime';
import relativeTime from 'embercom/lib/relative-time';
import moment from 'moment-timezone';

export default RelativeDatetime.extend({
  classNameBindings: ['active:o__active'],

  datetime: computed('nextBreachTime', function () {
    return moment(this.nextBreachTime).toISOString();
  }),

  formatted: computed(
    'nextBreachTime',
    'hasActiveTimetarget',
    'showLongFormatTimeTarget',
    'showMediumFormatTimeTarget',
    function () {
      if (!this.hasActiveTimeTarget) {
        return 'SLA';
      }
      // We never show 'now' for the next breach time, always minimum 'Reply in 1m' or 'Overdue'
      let now = moment();
      let nextBreachTime = moment(this.nextBreachTime);
      if (nextBreachTime.isBefore(now)) {
        return 'Overdue';
      }

      let relativeTimestamp = this._relativeTimestamp(now, nextBreachTime);
      let formattedReply = this.showLongFormatTimeTarget
        ? `Reply in ${relativeTimestamp}`
        : `${relativeTimestamp}`;
      return formattedReply;
    },
  ),

  _relativeTimestamp(now, nextBreachTime) {
    let nextBreachTimeDiffNow = Math.abs(nextBreachTime.diff(now, 's'));

    if (nextBreachTimeDiffNow > 60) {
      return relativeTime(nextBreachTime, 'en-abbreviated-relative-time', false);
    }

    let seconds = Math.ceil(nextBreachTimeDiffNow / 10) * 10;
    return `${seconds}s`;
  },
});
