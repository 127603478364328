/* import __COLOCATED_TEMPLATE__ from './whatsapp-sender-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import type WhatsappIntegrationSender from 'embercom/objects/inbox/whatsapp-integration-sender';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';

interface Args {
  sender: WhatsappIntegrationSender;
  replyChannel: ReplyChannel;
  onSenderChange: (sender: any) => void;
}

interface Signature {
  Args: Args;
}

export default class WhatsappSenderSelector extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;

  @tracked senders: WhatsappIntegrationSender[] = [];
  @tracked isLoading = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadWhatsappSenders();
  }

  async loadWhatsappSenders() {
    this.isLoading = true;
    this.senders = await this.inboxApi.fetchWhatsappIntegrations({ no_external_updates: true });
    this.isLoading = false;
  }

  @action changeSender(newSender: any, popover: any) {
    this.args.onSenderChange(newSender);
    popover?.hide();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation::WhatsappSenderSelector': typeof WhatsappSenderSelector;
    'inbox2/new-conversation/whatsapp-sender-selector': typeof WhatsappSenderSelector;
  }
}
