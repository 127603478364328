/* import __COLOCATED_TEMPLATE__ from './team-inboxes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TEAM_FILTER_TYPE } from 'embercom/lib/settings/filters/constants';
import { inject as service } from '@ember/service';
import { partition } from 'underscore';

export default class TeamInboxes extends Component {
  @service intl;

  @tracked tempSelections = [];
  @tracked selections = [];

  // Selections on dropdown dont get updated when the dropdown is open and you hit clear filters
  // this has to do with how the didUpdateAttrs method works
  // https://github.com/intercom/embercom-core/blob/master/addon/components/ic-select.js#L118
  // i am adding a random number to distinguish the two groups of items before and after the attrs changed on ic-select
  @tracked forceUpdate = undefined;

  get filterLabel() {
    if (this.tempSelections.length > 0) {
      let firstInbox = this.args.teamInboxes.find((inbox) => {
        return this.tempSelections.includes(inbox.id);
      });

      return this.intl.t('settings.teammate-workload.filters.team-inboxes.label', {
        count: this.tempSelections.length - 1,
        name: firstInbox.name,
      });
    }
    return this.intl.t('settings.teammate-workload.filters.team-inboxes.any');
  }

  get truncatedLabel() {
    return this.intl.t('settings.teammate-workload.filters.team-inboxes.truncated-label', {
      label: this.filterLabel,
    });
  }

  get items() {
    let inboxItems = this.args.teamInboxes.map((inbox) => ({
      text: inbox.name,
      value: inbox.id,
      forceUpdate: this.forceUpdate,
      isSelected: this.selections.includes(inbox.id),
      inbox,
      component: 'inbox/teammate-workload/team-inboxes/team-select-item',
    }));

    let [selectedItems, unselectedItems] = partition(inboxItems, ({ isSelected }) => isSelected);

    return [
      {
        heading: this.intl.t('settings.teammate-workload.filters.team-inboxes.selected-teams'),
        items: selectedItems,
      },
      {
        heading: this.intl.t('settings.teammate-workload.filters.team-inboxes.all-teams'),
        items: unselectedItems,
      },
    ];
  }

  @action
  onSelectionChange(selectedTeamInboxes) {
    this.tempSelections = selectedTeamInboxes;

    let members = this.args.teamInboxes
      .filter((inbox) => {
        return this.tempSelections.includes(inbox.id);
      })
      .map((inbox) => {
        return inbox.member_ids;
      })
      .flat();

    this.args.updateFilter({
      type: TEAM_FILTER_TYPE,
      comparator: undefined,
      value: members,
    });
  }

  @action
  onClose() {
    this.selections = this.tempSelections;
  }

  @action
  reset(element, [filter]) {
    if (filter.value.length === 0) {
      this.selections = [];
      this.tempSelections = [];
      this.forceUpdate = Math.random();
    }
  }
}
