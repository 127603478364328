/* import __COLOCATED_TEMPLATE__ from './participant-input-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { isValidEmail } from 'embercom/lib/email';
import { action } from '@ember/object';

export default Component.extend({
  notificationsService: service(),
  inputError: false,
  email: '',
  keyDown(e) {
    switch (e.which) {
      case 13:
        this.send('addParticipant');
        break;
      default:
        this.clearErrors();
        break;
    }
  },
  clearErrors() {
    if (this.inputError) {
      this.set('inputError', false);
    }
  },
  addParticipant: action(function () {
    if (isValidEmail(this.email)) {
      this.addParticipantTask.perform(this.email, this.hidePopover);
    } else {
      this.set('inputError', true);
      this.notificationsService.notifyWarning('Please enter a valid email address');
    }
  }),
});
