/* import __COLOCATED_TEMPLATE__ from './tickets-assignment-setting.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import templateOnly from '@ember/component/template-only';

interface Args {
  ticketsAssignmentDisabled: boolean;
  ticketsAssignmentDisabledUpdate: () => void;
}

const TicketsAssignmentSetting = templateOnly<Args>();

export default TicketsAssignmentSetting;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::TicketsAssignmentSetting': typeof TicketsAssignmentSetting;
    'inbox/workload-management/tickets-assignment-setting': typeof TicketsAssignmentSetting;
  }
}
