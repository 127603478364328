/* import __COLOCATED_TEMPLATE__ from './more-filters.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type MoreFiltersGroup, type MoreFiltersItem } from '../search2';
import { inject as service } from '@ember/service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLElement;
  Args: {
    filters: MoreFiltersGroup[];
    query: string;
    onQueryChanged: (query: string) => void;
    onSelect: (item: MoreFiltersItem) => void;
    showPlaceholder?: boolean;
  };
}

export interface AttributeDropdownRow {
  id: number;
  groupHeading?: string;
  item: MoreFiltersItem;
  icon?: InterfaceIconName;
}

export default class MoreFilters extends Component<Signature> {
  @service declare intercomEventService: any;

  @action onPopoverShow() {
    this.intercomEventService.trackAnalyticsEvent({
      place: 'inbox',
      section: 'search',
      action: 'clicked',
      object: 'more_filters',
    });
  }

  get showNoResults() {
    return (
      this.args.filters.length === 0 ||
      this.args.filters.every((filter) => filter.items.length === 0)
    );
  }

  get items(): AttributeDropdownRow[] {
    let items: Array<AttributeDropdownRow> = [];
    let count = 0;
    // In order to navigate the filters using keyboard, we need to get all the filters
    // and assign them an id. This is because the keyboard navigation resource
    // expects an array of items with an id that is similar to how the items appear in UI.

    this.args.filters.map((filter) => {
      filter.items.map((item, index) => {
        let groupHeading;

        if (index === 0) {
          groupHeading = filter.heading;
        }
        items.push({
          icon: item.attribute.icon || 'transfer',
          id: count++,
          item,
          groupHeading,
        });
      });
    });

    return items;
  }

  @action onClick(item: MoreFiltersItem) {
    this.intercomEventService.trackAnalyticsEvent({
      place: 'inbox',
      section: 'search',
      action: 'clicked',
      object: 'more_filters_item',
      id: item.attribute.id,
    });
    this.args.onSelect(item);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::MoreFilters': typeof MoreFilters;
  }
}
