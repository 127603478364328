/* import __COLOCATED_TEMPLATE__ from './help-link.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const HelpLink = templateOnlyComponent<Signature>();
export default HelpLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ConsistentNav::HelpLink': typeof HelpLink;
    'inbox2/user-menu/consistent-nav/help-link': typeof HelpLink;
  }
}
