/* import __COLOCATED_TEMPLATE__ from './use-macro-pane.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  locale: string;
  onClose: () => unknown;
  onSelect: () => unknown;
  onNext: () => unknown;
}

interface Signature {
  Args: Args;
}

export default class Inbox2OnboardingUseMacroPane extends Component<Signature> {
  @service declare intl: IntlService;

  get blocks() {
    return [
      { type: 'paragraph', text: 'Hi 👋,', class: 'no-margin' },
      {
        type: 'paragraph',
        text: this.intl.t('onboarding.guide-library.inbox-tour.steps.use-macro.macro-preview'),
        class: 'no-margin',
      },
      {
        type: 'paragraph',
        text: this.intl.t('onboarding.guide-library.inbox-tour.steps.use-macro.macro-preview-2'),
        class: 'no-margin',
      },
      {
        type: 'paragraph',
        text: this.intl.t('onboarding.guide-library.inbox-tour.steps.use-macro.macro-preview-3'),
        class: 'no-margin',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::Inbox2OnboardingUseMacroPane': typeof Inbox2OnboardingUseMacroPane;
    'inbox2/onboarding/use-macro-pane': typeof Inbox2OnboardingUseMacroPane;
  }
}
