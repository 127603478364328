/* import __COLOCATED_TEMPLATE__ from './spam-menu-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';

interface Arguments {
  inbox: Inbox;
  translationKey: string;
}

interface Signature {
  Args: Arguments;
}

export default class SpamMenuItem extends Component<Signature> {
  @service declare inbox2Counters: Inbox2Counters;

  get count() {
    return this.inbox2Counters.countForInbox(this.args.inbox);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::SpamMenuItem': typeof SpamMenuItem;
    'inbox2/left-nav/spam-menu-item': typeof SpamMenuItem;
  }
}
