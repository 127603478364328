/* import __COLOCATED_TEMPLATE__ from './performance-metric.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  title: string;
  value: any;
  iconName: InterfaceIconName;
  tooltipContent: string;
  awayReasons?: string[];
  linkTo: string;
  qualifier?: string;
  bgClass?: string;
}

interface Signature {
  Args: Args;
  Element: HTMLAnchorElement;
}

export default class PerformanceMetric extends Component<Signature> {
  get value() {
    return this.args.value;
  }

  get qualifier() {
    return this.args.qualifier || '';
  }

  get hasValue() {
    return this.value !== null && this.value !== undefined && this.value !== '';
  }

  get text() {
    return this.hasValue ? `${this.value}${this.qualifier}` : '-';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::YourAchievements::PerformanceMetric': typeof PerformanceMetric;
    'inbox2/your-achievements/performance-metric': typeof PerformanceMetric;
  }
}
