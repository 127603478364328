/* import __COLOCATED_TEMPLATE__ from './away-mode-warning.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AwayModeWarning extends Component {
  @service modalService;

  @action
  closeModal() {
    this.modalService.closeModal();
  }
}
