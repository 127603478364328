/* import __COLOCATED_TEMPLATE__ from './keyboard-shortcut-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import { bool } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import platform from 'embercom/lib/browser-platform';

export default Component.extend({
  notificationsService: service(),
  shortcuts: service(),

  modifierKey: computed(function () {
    return platform.isMac ? '⌘' : 'ctrl';
  }),

  altKey: computed(function () {
    return platform.isMac ? 'Option' : 'Alt';
  }),

  adminAdvancedShortcutsEnabled: bool('admin.advanced_shortcuts_enabled'),
  adminHasEnabledCSComponent: bool('admin.hasEnabledCSComponent'),

  toggleAdvancedShortcuts: action(function () {
    this.toggleProperty('admin.advanced_shortcuts_enabled');
    this.shortcuts.setState('advancedEnabled', this.get('admin.advanced_shortcuts_enabled'));
    this.admin.save().catch(() => {
      this.notificationsService.notifyError(
        'There was a problem saving your preference for keyboard shortcuts, please try again later.',
      );
    });
  }),

  toggleCSComponent: action(function () {
    this.toggleProperty('admin.hasEnabledCSComponent');
    this.shortcuts.setState('csEnabled', this.get('admin.hasEnabledCSComponent'));
    this.admin.save().catch(() => {
      this.notificationsService.notifyError(
        'There was a problem saving your preference for keyboard shortcuts, please try again later.',
      );
    });
  }),
});
