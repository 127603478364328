/* import __COLOCATED_TEMPLATE__ from './linked-conversations-table.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from 'tracked-built-ins';
import { task } from 'ember-concurrency-decorators';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { type TicketType } from 'embercom/objects/inbox/ticket';
import type Session from 'embercom/services/session';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import {
  type ColumnDefinition,
  ColumnDefinitionsBuilder,
  columnId,
  ConversationsTableColumns,
} from 'embercom/objects/inbox/conversations-table-column';
import type InboxApi from 'embercom/services/inbox-api';
import type ConversationsTableSettings from 'embercom/services/conversations-table-settings';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { REQUIRED_CONVERSATIONS_TABLE_FIELDS } from 'embercom/objects/inbox/conversation-table-entry';
import { type SortDirection } from './conversations-table';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
// @ts-ignore
import { cached, trackedReset } from 'tracked-toolbox';
import { getOwner } from '@ember/application';
import type PredicateGroup from 'embercom/objects/inbox/search/predicate-group';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import type RouterService from '@ember/routing/router-service';
import { type NexusEvent, NexusEventName } from 'embercom/services/nexus';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import { type ConversationsSearchResource } from 'embercom/components/inbox2/search/conversations-search-resource';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type CommandKService from 'embercom/services/command-k';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';

interface Args {
  trackerTicket: Conversation;
  predicates: PredicateGroup;
  showBulkEditModal: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class LinkedConversationsTable extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare conversationsTableSettings: ConversationsTableSettings;
  @service declare inboxState: InboxState;
  @service declare router: RouterService;
  @service declare intercomEventService: any;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;
  @service declare commandK: CommandKService;
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;

  @trackedReset('settings') selectedSortOption = this.settings.selectedSortOption;
  @tracked descriptors: ConversationAttributeDescriptor[] = [];
  @tracked ticketTypes: TicketType[] = [];
  @tracked columnDefinitions?: ColumnDefinition[];
  @tracked showTrackerBulkEditModal = this.args.showBulkEditModal;
  @tracked conversations: (ConversationTableEntry | ConversationRecord)[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadDynamicColumns).perform();

    registerDestructor(this, () => {
      taskFor(this.loadDynamicColumns).cancelAll();
    });
  }

  get trackerTicketId() {
    return this.args.trackerTicket.id;
  }

  get settings() {
    return this.conversationsTableSettings.getCustomerReportsTableSettings(
      this.args.trackerTicket.ticketType!.id,
    );
  }

  @cached
  get tableColumns() {
    if (!taskFor(this.loadDynamicColumns).lastComplete) {
      return undefined;
    }
    return new ConversationsTableColumns(
      getOwner(this),
      this.settings.selectedColumns,
      this.columnDefinitions!,
      this.ticketTypes,
    );
  }

  get hasNoSelectedCustomerReports() {
    return !this.inboxState.hasSelectedConversations;
  }

  get realtimeUpdateEventName() {
    return NexusEventName.TrackerTicketLinkedConversationsUpdated;
  }

  get realtimeUpdateTopic() {
    return [`conversation_id/${this.trackerTicketId}`];
  }

  get fields() {
    if (!this.tableColumns) {
      return [];
    }

    return [
      ...REQUIRED_CONVERSATIONS_TABLE_FIELDS,
      ...this.tableColumns.columns.flatMap((column) => column.fields),
    ].uniq();
  }

  get hiddenColumns() {
    return this.tableColumns?.hiddenColumns ?? [];
  }

  get columns() {
    return this.tableColumns?.columns ?? [];
  }

  get customerReportIds() {
    return this.args.trackerTicket.linkedCustomerReportIds;
  }

  get firstSelectedTicketState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.conversations[0]?.ticketCustomStateId,
    );
  }

  @action changeColumns(columns: ConversationsTableColumn[]) {
    this.tableColumns!.selectedColumns = columns.map((col) => ({
      id: columnId(col),
      width: col.width,
    }));

    this.saveSettings();
  }

  @action onSortColumn(column: ConversationsTableColumn, direction: SortDirection) {
    if (!column.sortField) {
      return;
    }

    this.inboxState.selectedConversations.clear();

    this.selectedSortOption = { sortField: column.sortField, direction };
  }

  @task({ drop: true }) *loadDynamicColumns(): TaskGenerator<void> {
    let [descriptors, ticketTypes] = yield Promise.all([
      this.session.workspace.fetchConversationAttributeDescriptors(),
      this.inboxApi.listTicketTypes(),
    ]);

    this.descriptors = descriptors;
    this.ticketTypes = ticketTypes;

    this.columnDefinitions = new ColumnDefinitionsBuilder(
      getOwner(this),
      this.ticketTypes,
      this.descriptors,
      'linked-tickets',
    ).columnDefinitions;
  }

  @action async handleThreadIngestedEvent(reloadFn: () => unknown, event: NexusEvent) {
    if (event.eventData.conversation_id === this.trackerTicketId) {
      reloadFn();
      this.onClosePreview();
    }
  }

  @action onPreviewConversation(conversation: ConversationTableEntry) {
    this.router.transitionTo(
      'inbox.workspace.inbox.linked-conversations.conversation',
      conversation,
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversation',
      section: 'conversation_table',
      conversation_id: conversation.id,
    });
  }

  @action onClosePreview() {
    this.router.transitionTo('inbox.workspace.inbox.linked-conversations', {
      queryParams: { skipRedirection: true },
    });
  }

  @action onBulkEdit() {
    this.showTrackerBulkEditModal = true;
  }

  @action async onReOpenAll() {
    let customerReportIds = this.customerReportIds;
    let updates = this.conversationUpdates.addUpdates(customerReportIds, 'state-change', {
      state: ConversationState.Open,
    });

    try {
      let response = await this.inboxApi.openConversations(customerReportIds, {
        id: this.trackerTicketId,
        linkedCustomerReports: 'all',
      });

      if (
        'error' in response &&
        response.error === 'tracker_ticket_linked_customer_reports_out_of_date'
      ) {
        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.tracker-ticket-linked-customer-reports-out-of-date'),
        );

        throw new Error('Tracker ticket linked customer reports out of date');
      }
    } catch (err) {
      customerReportIds.forEach((customerReportId) => {
        this.conversationUpdates.rollbackUpdates(customerReportId, [updates[customerReportId]]);
      });
    }
  }

  @action onCloseAll(resource: ConversationsSearchResource) {
    this.inboxState.selectedConversations.toggleAll(resource.conversations);
    this.conversationResolveAndCloseService.startResolveAndCloseProcess(
      resource.conversations,
      undefined,
      {
        id: this.trackerTicketId,
        linkedCustomerReports: 'all',
      },
    );
  }

  @action async onBulkUpdateTicketStates(
    resource: ConversationsSearchResource,
    state: TicketCustomState['id'],
  ) {
    let visibleCustomerReports = resource.conversations;

    let customState = this.ticketStateService.getTicketCustomStateById(state);
    // Optimistically update the UI
    let updates = Object.fromEntries(
      visibleCustomerReports
        .filter((conversation) => conversation.isTicket)
        .map((conversation) => [
          conversation.id,
          this.conversationUpdates.addUpdate(conversation.id, 'ticket-state-change', {
            ticketState: customState.systemState,
            visibleToUser: conversation.visibleToUser,
            conversationId: conversation.id,
            ticketTypeId: conversation.ticketType?.id,
            ticketCustomStateId: state,
          }),
        ]),
    );

    try {
      let response = await this.inboxApi.bulkChangeTicketState(this.customerReportIds, state, {
        id: this.trackerTicketId,
        linkedCustomerReports: 'all',
      });

      if ('invalidConversationIds' in response && response.invalidConversationIds.length > 0) {
        this.snackbar.notifyError(
          this.intl.t('inbox.conversations-table.errors.update-ticket-state-all', {
            conversationsCount: response.invalidConversationIds.length,
          }),
        );
      } else if (
        'error' in response &&
        response.error === 'tracker_ticket_linked_customer_reports_out_of_date'
      ) {
        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.tracker-ticket-linked-customer-reports-out-of-date'),
        );

        throw new Error('Tracker ticket linked customer reports out of date');
      }
    } catch (err) {
      Object.keys(updates).forEach((id: string) => {
        this.conversationUpdates.rollbackUpdates(Number(id), [updates[id]]);
      });
    }
  }

  @task *bulkEditCustomerReports(actions: MacroAction[]): TaskGenerator<void> {
    this.showTrackerBulkEditModal = false;

    try {
      let response = yield this.inboxState.applyBulkMacroActions(this.customerReportIds, actions, {
        id: this.trackerTicketId,
        linkedCustomerReports: 'all',
      });

      if (
        'error' in response &&
        response.error === 'tracker_ticket_linked_customer_reports_out_of_date'
      ) {
        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.tracker-ticket-linked-customer-reports-out-of-date'),
        );
      }
    } catch {
      this.snackbar.notifyError(this.intl.t('inbox.bulk-edit.errors.edit'));
    }
  }

  @action async onBulkAssign(
    resource: ConversationsSearchResource,
    assignee: AdminSummary | TeamSummary,
  ) {
    assignee instanceof AdminSummary
      ? await this.assignReportsToAdmin(assignee, resource)
      : await this.assignReportsToTeam(assignee, resource);
  }

  private async assignReportsToTeam(assignee: TeamSummary, resource: ConversationsSearchResource) {
    let visibleCustomerReports = resource.conversations;
    let updates = visibleCustomerReports.map((customerReport: ConversationTableEntry) =>
      this.conversationUpdates.addUpdate(customerReport.id, 'assign', {
        type: 'team',
        team: assignee,
        currentAdmin: customerReport.adminAssignee,
        currentTeam: customerReport.teamAssignee,
      }),
    );

    try {
      let response = await this.inboxApi.bulkAssignConversationsToTeam(
        this.customerReportIds,
        assignee,
        {
          id: this.trackerTicketId,
          linkedCustomerReports: 'all',
        },
      );

      if (
        'error' in response &&
        response.error === 'tracker_ticket_linked_customer_reports_out_of_date'
      ) {
        let visibleCustomerReportIds = visibleCustomerReports.map(
          (customerReport) => customerReport.id,
        );

        updates.forEach((update, index) => {
          this.conversationUpdates.rollbackUpdates(visibleCustomerReportIds[index], [update]);
        });

        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.tracker-ticket-linked-customer-reports-out-of-date'),
        );
      }
    } catch (err) {
      let visibleCustomerReportIds = visibleCustomerReports.map(
        (customerReport) => customerReport.id,
      );
      updates.forEach((update, index) => {
        this.conversationUpdates.rollbackUpdates(visibleCustomerReportIds[index], [update]);
      });
      this.snackbar.notifyError(this.intl.t('inbox.bulk-edit.errors.assign-to'));
    }
  }

  private async assignReportsToAdmin(
    assignee: AdminSummary,
    resource: ConversationsSearchResource,
  ) {
    let visibleCustomerReports = resource.conversations;
    let updates = visibleCustomerReports.map((customerReport: ConversationTableEntry) =>
      this.conversationUpdates.addUpdate(customerReport.id, 'assign', {
        type: 'admin',
        admin: assignee,
        currentAdmin: customerReport.adminAssignee,
      }),
    );

    try {
      let response = await this.inboxApi.bulkAssignConversationsToAdmin(
        this.customerReportIds,
        assignee,
        {
          id: this.trackerTicketId,
          linkedCustomerReports: 'all',
        },
      );

      if (
        'error' in response &&
        response.error === 'tracker_ticket_linked_customer_reports_out_of_date'
      ) {
        let visibleCustomerReportIds = visibleCustomerReports.map(
          (customerReport) => customerReport.id,
        );

        updates.forEach((update, index) => {
          this.conversationUpdates.rollbackUpdates(visibleCustomerReportIds[index], [update]);
        });

        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.tracker-ticket-linked-customer-reports-out-of-date'),
        );
      }
    } catch (err) {
      let visibleCustomerReportIds = visibleCustomerReports.map(
        (customerReport) => customerReport.id,
      );
      updates.forEach((update, index) => {
        this.conversationUpdates.rollbackUpdates(visibleCustomerReportIds[index], [update]);
      });
      this.snackbar.notifyError(this.intl.t('inbox.bulk-edit.errors.assign-to'));
    }
  }

  @action
  async onTogglePriority(resource: ConversationsSearchResource) {
    let visibleCustomerReports = resource.conversations;
    let newPriority = !visibleCustomerReports.every((c) => c.priority);

    let updates = this.conversationUpdates.addUpdates(this.customerReportIds, 'priority-change', {
      priority: newPriority,
    });

    let rollbackUpdates = (ids: number[]) => {
      ids.forEach((id: number) => {
        this.conversationUpdates.rollbackUpdates(id, [updates[id]]);
      });
    };

    try {
      let response = await this.inboxApi.changeConversationsPriority(
        this.customerReportIds,
        newPriority,
        {
          id: this.trackerTicketId,
          linkedCustomerReports: 'all',
        },
      );

      if (
        'error' in response &&
        response.error === 'tracker_ticket_linked_customer_reports_out_of_date'
      ) {
        rollbackUpdates(this.customerReportIds);

        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.tracker-ticket-linked-customer-reports-out-of-date'),
        );
      }
    } catch (err) {
      rollbackUpdates(this.customerReportIds);
      this.snackbar.notifyError(this.intl.t('inbox.bulk-edit.errors.priority'));
      throw err;
    }
  }

  private saveSettings() {
    let settings = {
      ...this.settings,
      selectedColumns: this.tableColumns!.selectedColumns,
      selectedSortOption: this.selectedSortOption,
    };

    this.conversationsTableSettings.saveCustomerReportsTableSettings(
      this.args.trackerTicket.ticketType!.id,
      settings,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LinkedConversationsTable': typeof LinkedConversationsTable;
  }
}
