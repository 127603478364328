/* import __COLOCATED_TEMPLATE__ from './side-conversation-resource.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import { type ParticipantDataType } from 'embercom/services/inbox-api';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { type BlockList } from '@intercom/interblocks.ts';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import { ComposerPaneType, type ReplyChannelType } from 'embercom/objects/inbox/composer-pane';
import type Nexus from 'embercom/services/nexus';
import { NexusFallbackPoller } from 'embercom/services/nexus';
import type Session from 'embercom/services/session';
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { restartableTask, task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator, timeout } from 'ember-concurrency';

import { registerDestructor } from '@ember/destroyable';
import type ApplicationInstance from '@ember/application/instance';
import { type UpdateMessage } from 'embercom/services/conversation-updates';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import type TracingService from 'embercom/services/tracing';
import Component from '@glimmer/component';
// @ts-ignore
import { dedupeTracked } from 'tracked-toolbox';
import { requestWithRetries } from 'embercom/lib/inbox/requests';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';

const BACKGROUND_RELOAD_BACKOFF_INCREMENT = 1000;
const BACKGROUND_RELOAD_MAX_BACKOFF = 5000;
const BACKGROUND_RELOAD_TIMEOUT = 1000;

type ResourceArgs = { sideConversationId: number; parentConversationId: number };

export class SideConversationResource extends Resource<Named<ResourceArgs>> {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare nexus: Nexus;
  @service declare session: Session;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare tracing: TracingService;

  #sideConversationId?: number;
  #parentConversationId?: number;
  #poller = new NexusFallbackPoller(this);

  @tracked declare sideConversation: Conversation;
  @dedupeTracked failedToLoad = false;

  constructor(owner: ApplicationInstance) {
    super(owner);

    registerDestructor(this, () => {
      taskFor(this.fetchSideConversation).cancelAll();
      taskFor(this.reloadConversation).cancelAll();
      this.#poller.stop();

      if (this.#sideConversationId) {
        this.conversationUpdates.unsubscribe(this.onConversationUpdate);
      }
    });
  }

  modify(_: unknown[], args: ResourceArgs) {
    let id = args.sideConversationId;
    if (id === this.#sideConversationId) {
      return;
    }

    this.startTraceForConversation(id);
    // Stop any pending work for the previous conversation
    taskFor(this.reloadConversation).cancelAll();
    if (this.#sideConversationId) {
      this.conversationUpdates.unsubscribe(this.onConversationUpdate);
    }

    // Start work for the new conversation
    this.#sideConversationId = id;
    this.#parentConversationId = args.parentConversationId;
    this.failedToLoad = false;
    this.#poller.start(() => taskFor(this.reloadConversation).perform());

    this.conversationUpdates.subscribe(this.onConversationUpdate);

    if ('sideConversationId' in args) {
      taskFor(this.fetchSideConversation).perform();
      return;
    }
  }

  @action startTraceForConversation(id: Conversation['id'], createdAt?: Conversation['createdAt']) {
    this.tracing.startRootSpan({
      name: 'customMeasurement',
      resource: 'inbox2:conversation_stream',
      attributes: {
        conversation_id: id,
        vertical_collection_used: true,
        conversation_created_at: createdAt?.getTime(),
        conversation_resource_type: 'side',
      },
    });
  }

  get hasError() {
    return this.failedToLoad;
  }

  @action
  reload() {
    taskFor(this.reloadConversation).perform();
  }

  @action
  manualReload() {
    taskFor(this.reloadConversation).perform();
  }

  @action
  private onConversationUpdate(updates: UpdateMessage[]) {
    updates.forEach((update) => {
      if (update.conversationId !== this.#sideConversationId) {
        return;
      }

      if (update.type === 'added') {
        update.entries.forEach((update) => update.apply(this.sideConversation));
      } else if (update.type === 'removed') {
        update.entries.forEach((update) => update.rollback(this.sideConversation));
      }
    });
  }

  @action async sendReply(
    type: ComposerPaneType,
    blocks: BlockList,
    _: Array<MacroAction>, // macro actions
    _channel?: ReplyChannelType,
    replyData?: any,
    participantData?: ParticipantDataType,
    _1 = false, // crosspost
    emailHistoryMetadataId?: number,
  ) {
    let { sideConversation } = this;

    if (blocks.length > 0) {
      if (type === ComposerPaneType.Reply) {
        await this.inboxState.replyToConversation(
          sideConversation,
          blocks,
          replyData,
          participantData,
          emailHistoryMetadataId,
        );
      }
    }
  }

  @restartableTask
  private *fetchSideConversation(): TaskGenerator<void> {
    if (typeof this.#sideConversationId === 'undefined') {
      throw new Error('getSideConversation cannot be called without a side conversation id');
    }

    let inboxApi = this.inboxApi;
    let remoteConversation = yield requestWithRetries(
      () => inboxApi.getSideConversation(this.#sideConversationId!, this.#parentConversationId!),
      {
        retries: Infinity,
        backoffDelay: BACKGROUND_RELOAD_BACKOFF_INCREMENT,
        maxBackoffDelay: BACKGROUND_RELOAD_MAX_BACKOFF,
        onFailedRequest: () => {
          this.failedToLoad = true;
        },
      },
    );

    this.failedToLoad = false;

    this.conversationUpdates.dropCommittedUpdates(remoteConversation);
    this.conversationUpdates
      .updatesFor(remoteConversation.id)
      .forEach((update) => update.apply(remoteConversation));
    this.tracing.tagRootSpan({
      conversation_id: this.#sideConversationId,
      conversation_renderable_part_count: remoteConversation.renderableParts.length,
      vertical_collection_used: true,
      conversation_has_email_parts: remoteConversation.renderableParts.some(
        (renderablePart: RenderablePart) =>
          renderablePart.renderableType === RenderableType.UserEmailComment,
      ),
    });

    this.sideConversation = remoteConversation;
  }

  @task({ keepLatest: true })
  private *reloadConversation(): TaskGenerator<void> {
    yield taskFor(this.fetchSideConversation).perform();
    yield timeout(BACKGROUND_RELOAD_TIMEOUT);
  }
}

export default class SideConversationResourceComponent extends Component<{
  Args: ResourceArgs;
  Blocks: {
    default: [SideConversationResource];
  };
}> {
  sideConversationResource = SideConversationResource.from(this, () => this.args);
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SideConversationResource': typeof SideConversationResourceComponent;
    'inbox2/side-conversation-resource': typeof SideConversationResourceComponent;
  }
}
