/* import __COLOCATED_TEMPLATE__ from './team-inbox-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';

interface Team {
  name: string;
  id: string;
  reload: Function;
  member_ids: number[];
  member_ids_with_priority: {
    [priority: number]: number[];
  };
  primaryMembers: number[];
  secondaryMembers: number[];
}

interface Args {
  inboxes: Team[];
  selectedTeammateIds: string[];
  bulkUpdateLimit: number;
  inboxPriority: string;
}

export default class PrimaryInbox extends Component<Args> {
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare store: Store;
  @service permissionsService: any;

  @tracked deselectedInboxIds: string[] = [];
  @tracked addedInboxIds: string[] = [];
  @tracked showConfirmation = false;
  openerApi?: { hide: () => void };
  @tracked partiallySelected: string[] = [];
  @tracked partialToFull: string[] = [];

  @tracked filterText = '';

  inboxIncludesAllSelectedTeammates(inbox: Team) {
    return this.countSelectedTeammatesInInbox(inbox) === this.allTeammateIdsInSelection.length;
  }

  countSelectedTeammatesInInbox(inbox: Team) {
    return this.memberIdsForLevel(inbox).filter((member_id) =>
      this.allTeammateIdsInSelection.includes(member_id),
    ).length;
  }

  memberIdsForLevel(inbox: Team) {
    switch (this.args.inboxPriority) {
      case 'team':
        return inbox.member_ids;
      case 'primary':
        return inbox.primaryMembers;
      case 'secondary':
        return inbox.secondaryMembers;
    }
    return inbox.member_ids;
  }

  resetState() {
    this.partialToFull = [];
  }

  getTeamSettingsById(teamId: number) {
    let teamSetting = this.store
      .peekAll('team-setting')
      .filter((teamSetting: any) => teamSetting.teamId === teamId);

    if (teamSetting.length) {
      return teamSetting[0];
    }

    return undefined;
  }

  inboxItemConfig(inbox: any) {
    return {
      text: inbox.name,
      value: inbox.id,
      isSelected: this.isSelected(inbox),
      partiallySelected: this.partiallySelected.includes(inbox.id),
      inbox,
      component: 'inbox/teammate-workload/team-inboxes/team-select-item',
      componentAttrs: {
        onSelectItem: this.toggleItem,
      },
      teamSettings: this.getTeamSettingsById(parseInt(inbox.id, 10)),
    };
  }

  isSelected(inbox: Team) {
    return (
      (this.fullySelectedInboxIds.includes(inbox.id) || this.addedInboxIds.includes(inbox.id)) &&
      !this.deselectedInboxIds.includes(inbox.id)
    );
  }

  get lacksPermissionToEditInboxes() {
    return !this.permissionsService.currentAdminCan('can_manage_teams');
  }

  get fullySelectedInboxIds() {
    return this.args.inboxes
      .filter((inbox) => this.inboxIncludesAllSelectedTeammates(inbox))
      .map((inbox) => inbox.id);
  }

  get partiallySelectedInboxIds() {
    return this.args.inboxes
      .filter((inbox) => {
        let count = this.countSelectedTeammatesInInbox(inbox);
        return count < this.allTeammateIdsInSelection.length && count > 0;
      })
      .map((inbox) => inbox.id);
  }

  get allTeammateIdsInSelection(): number[] {
    return this.args.selectedTeammateIds.map((id) => parseInt(id, 10));
  }

  get inboxesInTeammateSelection() {
    return this.args.inboxes.filter((inbox) => this.countSelectedTeammatesInInbox(inbox) > 0);
  }

  get inboxesNotInTeammateSelection() {
    return this.args.inboxes.filter((inbox) => this.countSelectedTeammatesInInbox(inbox) === 0);
  }

  get filterPlaceholder() {
    return this.intl.t(`settings.teammate-workload.${this.inboxType}.search-placeholder`);
  }

  get buttonLabel() {
    return this.intl.t(`settings.teammate-workload.${this.inboxType}.edit-label`);
  }

  get confirmationModalTitle() {
    return this.intl.t(`settings.teammate-workload.${this.inboxType}.confirmation-modal.title`);
  }

  get confirmationModalLabel() {
    return this.intl.t(
      `settings.teammate-workload.${this.inboxType}.confirmation-modal.confirm-label`,
    );
  }

  get confirmationModalAddedInboxesTitle() {
    return this.intl.t(
      `settings.teammate-workload.${this.inboxType}.confirmation-modal.added-title`,
    );
  }

  get confirmationModalRemovedInboxesTitle() {
    return this.intl.t(
      `settings.teammate-workload.${this.inboxType}.confirmation-modal.removed-title`,
    );
  }

  get confirmationModalText() {
    return `settings.teammate-workload.${this.inboxType}.confirmation-modal.paragraph`;
  }

  get notEditableLabel() {
    return `settings.teammate-workload.${this.inboxType}.not-editable-label`;
  }

  get inboxType() {
    switch (this.args.inboxPriority) {
      case 'team':
        return 'team-inboxes';
      case 'primary':
        return 'primary-inboxes';
      case 'secondary':
        return 'secondary-inboxes';
    }
    return 'team-inboxes';
  }

  get inboxPriorityLevel() {
    switch (this.args.inboxPriority) {
      case 'team':
      case 'primary':
        return 1;
      case 'secondary':
        return 2;
    }
    return 1;
  }

  get bulkUpdateAllowed() {
    return this.args.selectedTeammateIds.length <= this.args.bulkUpdateLimit;
  }

  get groupList() {
    return {
      hasItemsMarkableAsSelected: true,
      isMain: true,
      isFilterable: true,
      items: this.items,
    };
  }

  get items() {
    let selectedGroup = this.inboxesInTeammateSelection.map((inbox) => this.inboxItemConfig(inbox));

    let nonSelectedGroup = this.inboxesNotInTeammateSelection.map((inbox) =>
      this.inboxItemConfig(inbox),
    );

    let filterForSearch = (item: any) =>
      item.text.toLowerCase().includes(this.filterText.toLowerCase());

    selectedGroup = selectedGroup.filter(filterForSearch);

    nonSelectedGroup = nonSelectedGroup.filter(filterForSearch);

    return [
      {
        heading: this.intl.t(`settings.teammate-workload.${this.inboxType}.added-team-inboxes`),
        items: selectedGroup,
      },
      {
        heading: this.intl.t(`settings.teammate-workload.${this.inboxType}.all-team-inboxes`),
        items: nonSelectedGroup,
      },
    ];
  }

  get updateIsDisabled() {
    return this.deselectedInboxIds.length === 0 && this.addedInboxIds.length === 0;
  }

  get confirmationData() {
    return {
      added: this.args.inboxes.filter((inbox) => this.addedInboxIds.includes(inbox.id)),
      removed: this.args.inboxes.filter((inbox) => this.deselectedInboxIds.includes(inbox.id)),
    };
  }

  @action onOpen() {
    this.addedInboxIds = [];
    this.deselectedInboxIds = [];
    this.partiallySelected = this.partiallySelectedInboxIds.filter((id) => {
      return !this.addedInboxIds.includes(id) && !this.deselectedInboxIds.includes(id);
    });
  }

  @action toggleItem(selectedInbox: any) {
    // addedInboxIds and deselectedInboxIds are update with the changes to team memberships
    // partially and fully selected inbox ids track the initial state
    // addedInboxIds, deselectedInboxIds, and partiallSelected all control the UI state (which symbol to show in the dropdown menu)
    let selectedInboxId: string = selectedInbox.value;

    if (this.addedInboxIds.includes(selectedInboxId)) {
      this.addedInboxIds = this.addedInboxIds.filter((inboxId) => inboxId !== selectedInboxId);

      if (this.partiallySelectedInboxIds.includes(selectedInboxId)) {
        this.deselectedInboxIds = this.deselectedInboxIds.concat([selectedInboxId]);
      }
    } else if (this.deselectedInboxIds.includes(selectedInboxId)) {
      this.deselectedInboxIds = this.deselectedInboxIds.filter(
        (inboxId) => inboxId !== selectedInboxId,
      );

      if (!this.fullySelectedInboxIds.includes(selectedInboxId)) {
        this.addedInboxIds = this.addedInboxIds.concat([selectedInboxId]);
      }
    } else if (this.partiallySelected.includes(selectedInboxId)) {
      this.addedInboxIds = this.addedInboxIds.concat([selectedInboxId]);
      this.partiallySelected = this.partiallySelected.filter(
        (inboxId) => inboxId !== selectedInboxId,
      );
    } else if (this.fullySelectedInboxIds.includes(selectedInboxId)) {
      this.deselectedInboxIds = this.deselectedInboxIds.concat([selectedInboxId]);
    } else {
      this.addedInboxIds = this.addedInboxIds.concat([selectedInboxId]);
    }
  }

  @action showConfirmationModal() {
    this.showConfirmation = true;
  }

  @action async confirmUpdates() {
    await this.editInboxes();
    this.showConfirmation = false;
  }

  @action async editInboxes() {
    let params = {
      app_id: this.appService.app.id,
      admin_ids: this.args.selectedTeammateIds,
      team_ids: {
        added: this.addedInboxIds.map((id) => {
          return { id: parseInt(id, 10), wlm_inbox_priority_level: this.inboxPriorityLevel };
        }),
        removed: this.deselectedInboxIds.map((id) => {
          let object: any = {
            id: parseInt(id, 10),
          };
          // send inbox level if there is any
          if (this.args.inboxPriority !== 'team') {
            object['wlm_inbox_priority_level'] = this.inboxPriorityLevel;
          }
          return object;
        }),
      },
    };

    try {
      let updatedTeams = await post('/ember/admins/update_team_memberships', params);
      this.store.pushPayload({ teams: updatedTeams });
      this.notificationsService.notifyConfirmation(
        this.intl.t(`settings.teammate-workload.${this.inboxType}.success`, {
          count: this.allTeammateIdsInSelection.length,
        }),
      );
    } catch (error) {
      if (error?.jqXHR?.responseJSON?.errors) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
      } else {
        throw error;
      }
    } finally {
      this.resetState();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: `${this.args.inboxPriority}_inboxes`,
        place: 'teammate_workload',
        teammate_ids: this.args.selectedTeammateIds,
        selected_teammate_count: this.args.selectedTeammateIds.length,
        added_team_inbox_ids: this.addedInboxIds.map((id) => parseInt(id, 10)),
        removed_team_inbox_ids: this.deselectedInboxIds.map((id) => parseInt(id, 10)),
        wlm_inbox_priority_level: this.inboxPriorityLevel,
      });
      this.deselectedInboxIds = [];
      this.addedInboxIds = [];
      this.openerApi?.hide();
    }
  }

  @action triggerPermissionsModal() {
    if (this.lacksPermissionToEditInboxes) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teams');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::TeammateWorkload::BulkActions::TeamInboxDropdown': typeof PrimaryInbox;
    'inbox/teammate-workload/bulk-actions/team-inbox-dropdown': typeof PrimaryInbox;
  }
}
