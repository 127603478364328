/* import __COLOCATED_TEMPLATE__ from './customer-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import UserSummary from 'embercom/objects/inbox/user-summary';

interface Args {
  selectItem: (user: UserSummary) => void;
  user?: UserSummary;
  email?: string;
  isNewCustomer?: boolean;
}

interface Signature {
  Args: Args;
}

export default class CustomerDropdownItem extends Component<Signature> {
  @action
  selectCustomer() {
    if (!this.args.user) {
      return;
    }
    this.args.selectItem(this.args.user);
  }

  @action
  addNewCustomer() {
    let user = new UserSummary('', this.args.email);
    this.args.selectItem(user);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::CustomerDropdownItem': typeof CustomerDropdownItem;
    'inbox2/ticket-attribute/customer-dropdown-item': typeof CustomerDropdownItem;
  }
}
