/* import __COLOCATED_TEMPLATE__ from './notification-nexus-error.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type Notification } from 'embercom/services/snackbar';
import { action } from '@ember/object';

interface Args {
  notification: Notification;
}

interface Signature {
  Args: Args;
}

export default class NotificationNexusError extends Component<Signature> {
  @action reload(e: Event) {
    e.preventDefault();
    window.location.reload();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::NotificationNexusError': typeof NotificationNexusError;
    'inbox2/left-nav/notification-nexus-error': typeof NotificationNexusError;
  }
}
