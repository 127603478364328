/* import __COLOCATED_TEMPLATE__ from './section-expand-button.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { SIDEBAR_EXPANDED_CONTENT_VISIBILITY } from 'embercom/lib/inbox/constants';

export default class InboxConversationSiderbarSectionBodyExpandButton extends Component {
  @service intercomEventService;

  get showExpandedContent() {
    return this.args.expandedContentVisibility === SIDEBAR_EXPANDED_CONTENT_VISIBILITY.expanded;
  }
  get hideExpandedContent() {
    return this.args.expandedContentVisibility === SIDEBAR_EXPANDED_CONTENT_VISIBILITY.collapsed;
  }
  get showExpandedContentWithoutLabel() {
    return (
      this.args.expandedContentVisibility ===
      SIDEBAR_EXPANDED_CONTENT_VISIBILITY.expandedWithHiddenLabel
    );
  }
  get shouldShowExpandButton() {
    return this.args.hiddenCount > 0 && !this.showExpandedContentWithoutLabel;
  }

  @action
  toggleExpandedContentVisibility() {
    if (this.args.expandedContentVisibility === SIDEBAR_EXPANDED_CONTENT_VISIBILITY.collapsed) {
      this.args.updateExpandedContentVisibility(SIDEBAR_EXPANDED_CONTENT_VISIBILITY.expanded);

      if (this.args.analyticsObject) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'expanded',
          object: this.args.analyticsObject,
          place: 'inbox',
        });
      }
    } else {
      this.args.updateExpandedContentVisibility(SIDEBAR_EXPANDED_CONTENT_VISIBILITY.collapsed);

      if (this.args.analyticsObject) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'collapsed',
          object: this.args.analyticsObject,
          place: 'inbox',
        });
      }
    }
  }
}
