/* import __COLOCATED_TEMPLATE__ from './ticket-visibility-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Conversation from 'embercom/objects/inbox/conversation';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';

interface Args {
  conversation: Conversation;
  onUserVisibilityChanged?: (visibleToUser: boolean) => boolean;
  labelRowSize?: string;
  viewMode?: '2-column' | 'stacked';
}

interface Signature {
  Args: Args;
}

export default class TicketVisibilityToggle extends Component<Signature> {
  @service declare session: Session;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare inboxApi: InboxApi;
  @tracked visibleToUser = !!this.args?.conversation?.visibleToUser;
  @tracked showVisibilityModal = false;

  @action
  toggleVisibleToUser() {
    this.visibleToUser = !this.visibleToUser;
    if (this.args.onUserVisibilityChanged) {
      this.args.onUserVisibilityChanged(this.visibleToUser);
    }
  }

  @task({ drop: true })
  *makeTicketVisible() {
    try {
      yield this.inboxApi.shareTicketWithUser(this.args.conversation.id);
      this.visibleToUser = !this.visibleToUser;
      this.showVisibilityModal = !this.showVisibilityModal;
      this.args.conversation.visibleToUser = true;
    } catch {
      this.snackbar.notifyError(
        this.intl.t('inbox.conversation-header.ticket-attributes-tab.make-ticket-visible-error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketVisibilityToggle': typeof TicketVisibilityToggle;
    'inbox2/ticket-visibility-toggle': typeof TicketVisibilityToggle;
  }
}
