/* import __COLOCATED_TEMPLATE__ from './redacted-conversation-warning.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const RedactedConversationWarning = templateOnlyComponent<Signature>();
export default RedactedConversationWarning;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::RedactedConversationWarning': typeof RedactedConversationWarning;
    'inbox2/redacted-conversation-warning': typeof RedactedConversationWarning;
  }
}
