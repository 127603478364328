/* import __COLOCATED_TEMPLATE__ from './recent-page-views.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { gt } from '@ember/object/computed';
import { greaterThanProperty } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  recentPageViews: computed('user.recent_page_views.[]', function () {
    return this.get('user.recent_page_views') || [];
  }),
  shownPageViews: computed('recentPageViews.[]', 'numberToDisplay', function () {
    return this.recentPageViews.slice(0, this.numberToDisplay);
  }),
  numberToDisplay: 5,
  shouldShowLoadMore: greaterThanProperty('recentPageViews.length', 'numberToDisplay'),
  shouldShowLess: gt('numberToDisplay', 5),
  actions: {
    showLess() {
      this.set('numberToDisplay', 5);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'show_less_clicked',
        object: 'recent_page_views',
        context: 'the_user_loaded_more_page_views',
      });
    },
    showMore() {
      this.set('numberToDisplay', this.numberToDisplay + 5);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'load_more_clicked',
        object: 'recent_page_views',
        context: 'the_user_loaded_more_page_views',
      });
    },
  },
});
