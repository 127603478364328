/* import __COLOCATED_TEMPLATE__ from './ticket-fields.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { type TicketTypeResource } from '../ticket-type-resource';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';
import type Router from '@ember/routing/router-service';
import { action } from '@ember/object';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {
  newConversation: NewConversation;
  createTicket: any;
  isTicketRecipientMissing: boolean;
  ticketTypeResource: TicketTypeResource;
  isRecipientRequired: boolean;
  selectedUserCompanyId: string | undefined;
}

interface Signature {
  Args: Args;
}

export default class TicketFields extends Component<Signature> {
  @service declare session: Session;
  @service declare router: Router;

  get showTicketRecipientMissingError() {
    return this.args.createTicket.performCount > 0 && this.args.isTicketRecipientMissing;
  }

  get ticketAttributesToDisplay() {
    let { ticketAttributes } = this.args.ticketTypeResource;

    return ticketAttributes.filter(
      (attribute) =>
        attribute.descriptor.name !== DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE &&
        attribute.descriptor.name !== DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION,
    );
  }

  get userCompanies(): { text: string | undefined; value: string }[] {
    if (!this.args.newConversation?.userSummary?.companies) {
      return [];
    } else {
      return this.args.newConversation.userSummary.companies.map((company) => ({
        text: company.name,
        value: company.id,
      }));
    }
  }

  get areAttributesVisibleToUser() {
    let category = this.args.ticketTypeResource.selectedTicketType?.category;
    return category && category === TicketCategory.Request;
  }

  get showUserCompanyDropdown() {
    return (
      this.args.ticketTypeResource.selectedTicketType &&
      [TicketCategory.Request, TicketCategory.Task].includes(
        this.args.ticketTypeResource.selectedTicketType.category,
      ) &&
      this.args.newConversation.userSummary &&
      this.userCompanies.length > 0
    );
  }

  @action
  onCancel() {
    this.router.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.Shared, InboxType.All);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation::TicketFields': typeof TicketFields;
    'inbox2/new-conversation/ticket-fields': typeof TicketFields;
  }
}
