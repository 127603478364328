/* import __COLOCATED_TEMPLATE__ from './user-profile-customer-support-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { getOwner } from '@ember/application';
import $ from 'jquery';
import { computed, action } from '@ember/object';
import { lt, reads } from '@ember/object/computed';
import { findByProperty, ternary } from '@intercom/pulse/lib/computed-properties';
import Component from '@ember/component';
import ClipboardJS from 'clipboard';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { task, timeout } from 'ember-concurrency';
import DidChangeAttrs from 'ember-did-change-attrs';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';

const URL_REGEXES = {
  answerBot: /resolution-bot\/.+\d+.*?(?=\/|\/*|)/gi,
  customBot: /custom-bots\/.*\d{3}.+?(?=\/*)/gi,
  edit: /edit.*/gi,
};

const ADMIN_URLS = {
  baseURL: 'https://intercomrades.intercom.com/admin',
  apps: '/apps/',
  answerBot: '/answers/',
  articles: '/articles/',
  companies: '/companies?app_company_url=',
  conversations: '/conversations?conversation_id=',
  messages: '/messages/',
  users: '/users?app_user_url=',
};

const BILLING_URLS = {
  baseURL: 'https://intercomrades.intercom.com/billing',
  customers: '/customers/',
};

const URL_IDENTIFIERS = {
  answerBot: '/resolution-bot/',
  articles: '/articles/',
  companies: '/companies/',
  conversations: '/conversations?/',
  customBot: '/custom-bots/',
  messages: '/messages/',
  users: '/users/',
};

const DOM_SELECTORS = {
  clipboardContent: 'ghIssueButton',
};

const CS_TAGS_FOR_ALERTS = [
  'Graduation from Early Stage - September 2018',
  'Graduation from Early Stage - November 2018',
  'Graduation from Early Stage',
];

export default Component.extend(DidChangeAttrs, {
  isInbox: computed(function () {
    let currentPath = getOwner(this).lookup('controller:application').currentPath;
    if (currentPath === undefined) {
      return false;
    }
    return currentPath.includes('.inbox');
  }),

  listOfCompanies: computed('user.{companies,computedURL,user_id}', function () {
    let _list = this.get('user.companies').map((company) => {
      return {
        id: company.get('id'),
        appId: company.get('remote_company_id'),
        appIdCode: company.get('custom_data.id_code'),
        appPage: company.get('custom_data.admin_link') || null,
        billingPage: company.get('custom_data.pricing_link') || null,
        name: company.get('name'),
        tags: company.get('tags'),
      };
    });
    return _list;
  }),

  listOfCompaniesForDropdown: computed('listOfCompanies', function () {
    let _list = this.listOfCompanies.map((company) => {
      return {
        value: company.id,
        text: company.name,
        icon: 'company',
        tooltip: {
          text: `App ID Code: ${company.appIdCode}`,
        },
        isSelected: company.id === this.selectedCompanyId,
      };
    });
    return _list;
  }),

  isInAnyCompany: ternary('user.mostRecentCompany', true, false),
  onlyInOneCompany: lt('listOfCompanies.length', 2),
  selectedCompanyId: reads('user.mostRecentCompany.id'),
  selectedCompany: findByProperty('listOfCompanies', 'id', 'selectedCompanyId'),
  conversationPlaceholder: `${TEAMMATE_APP_URL}/xxx/conversations/123`,

  csTagsToDisplay: computed('selectedCompany.tags', function () {
    return this.get('selectedCompany.tags').filter((tag) => {
      return CS_TAGS_FOR_ALERTS.includes(tag.get('name'));
    });
  }),

  passTemplates: {
    perfectPass:
      '@CS =============\nSIGNAL:\nTRIGGER:\nQUOTE:\nADDITIONAL NOTES FOR SALES:\nTAG MANAGER FOR VISIBILITY:\n\n@SALES TEAMMATE =============\nThis is being passed to you because CS has identified there might be a sales opportunity per the above information.  If you agree there’s a sales opportunity here, please take over this conversation.  If you disagree, please pass back to me ASAP so the customer isn’t caught in limbo.  Thanks so much! __#OneTeamOneDream__\n\nNOTE TO SALES:  If you do not respond back directly to the customer in THIS specific conversation, please DO NOT CLOSE the conversation - instead, pass the convo back to me as this affects my KPI’s!  Much appreciated!',
    salesToCS:
      '@Sales =============\nWhat is the email address of the customer?\n\nWhat is the customer asking for?\n\nDo you want this passed back after CS is done with the technical part?\n\nNOTE =============\nMake sure you have set expectations with the customer that this is a pass to another department and that they might experience longer response times.',
  },

  githubIssueText: computed('user.{mostRecentCompany,computedURL,user_id}', function () {
    let currentUser = this.user;
    let recentCompany = currentUser.get('mostRecentCompany');
    let textToReturn;

    if (recentCompany !== undefined) {
      // Copy issue for User
      textToReturn = `**APP ID**: ${recentCompany.get('remote_company_id')} / ${recentCompany.get(
        'custom_data.id_code',
      )}
                      **Admin ID (who reported issue)**: ${currentUser.get('user_id')}
                      **Can Impersonate?**: ${currentUser.get('consents_to_impersonation')}
                      **Conversation with Customer**: ${document.location.href}`.replace(
        /^ +/gm,
        '',
      );
    } else {
      // Copy issue for Lead
      textToReturn = `**APP ID**: REPLACEME
                      **Lead (Who reported the issue)**: ${
                        document.location.origin
                      }/${currentUser.get('computedUrl')}
                      **Can Impersonate?**: No
                      **Conversation with Customer**: ${document.location.href}`.replace(
        /^ +/gm,
        '',
      );
    }

    return textToReturn;
  }),

  init() {
    this._super();
    this.set('additionalOptions', [
      {
        items: [
          {
            text: 'Copy GitHub Template',
            icon: 'github',
            tooltip: {
              text: 'Copy all conversation details into a template for a GitHub issue.',
            },
            onSelectItem: () => this._triggerCopyToClipboard(),
          },
          {
            text: 'Copy Perfect Pass Template',
            icon: 'arrow',
            tooltip: {
              text: 'Copy #PerfectPass template for easy pasting into your passing note!',
            },
            onSelectItem: () => this._triggerCopyToClipboard(`passTemplates.perfectPass`),
          },
          {
            text: 'Copy Pass-to-CS Template',
            icon: 'respond',
            tooltip: {
              text: 'Copy this Sales-To-CS template for easy pasting into your passing note!',
            },
            onSelectItem: () => this._triggerCopyToClipboard(`passTemplates.salesToCS`),
          },
        ],
      },
    ]);
  },

  didChangeAttrsConfig: {
    attrs: ['user.companies', 'user.computedUrl', 'user.user_id', 'selectedCompanyId'],
  },

  didChangeAttrs(changes) {
    this._super(...arguments);

    if (changes['user.companies'] || changes['user.computedUrl'] || changes['user.user_id']) {
      this._updateClipboardForGithubButton();
    }
  },

  didInsertElement() {
    this._super(...arguments);
    if (this.isInbox) {
      this._updateClipboardForGithubButton();
      this.clipboard = new ClipboardJS(`#${DOM_SELECTORS.clipboardContent}`);

      this.clipboard.on('success', () => {
        this.displayStatusMessage.perform('Copied to clipboard.', 'help');
      });
      // For browsers that don't support the clipboard API ( < Safari 10 )
      this.clipboard.on('error', () => {
        this.displayStatusMessage.perform('Unable to copy to clipboard.', 'error');
      });
    }
  },

  willDestroyElement() {
    if (this.isInbox) {
      this.clipboard.destroy();
    }
    this._super(...arguments);
  },

  displayStatusMessage: task(function* (message, type) {
    this.set('statusMessage', message);
    this.set('statusType', type);
    yield timeout(2000);
    this.set('statusMessage', null);
  }).restartable(),

  _updateClipboardForGithubButton() {
    let $ghIssueButtonObj = $(`#${DOM_SELECTORS.clipboardContent}`, this.element);
    $ghIssueButtonObj.attr('data-clipboard-text', this.githubIssueText);
  },

  _triggerCopyToClipboard(contentToCopy) {
    let $ghIssueButtonObj = $(`#${DOM_SELECTORS.clipboardContent}`, this.element);
    if (contentToCopy) {
      $ghIssueButtonObj.attr('data-clipboard-text', this.get(`${contentToCopy}`));
    } else {
      this._updateClipboardForGithubButton();
    }
    $ghIssueButtonObj.click();
  },

  _returnNumericValue(str) {
    return str.replace(/\D/gi, ''); // Remove all non-numerical characters from string
  },

  _removeVersionNumber(str) {
    // Remove version numbers in the url path "/vX/" => "/" where X is a number
    return str.replace(/\/v\d+\//gi, '/');
  },

  _removeAppID(str) {
    let editIndex = str.indexOf('apps');
    if (editIndex > 0) {
      return str.replace(/apps\/.*?\//gi, '');
    }
  },

  _answerBotRegexCheck(str) {
    let output = this._removeAppID(str);
    output = output.match(URL_REGEXES.answerBot); // Strip URL to after "/resolution-bot" if it exists.

    if (output === null) {
      return 'Error';
    }

    output = output[0]; // Quickly just grab the first element since we know it will always be this one.

    let editIndex = output.indexOf('edit');
    if (editIndex >= 0) {
      output = output.replace(URL_REGEXES.edit, ''); // Remove "/edit?editing_admin_id=xxx" at the end of a URL.
    }

    output = this._removeVersionNumber(output);
    output = this._returnNumericValue(output);
    return output;
  },

  _messagesRegexCheck(str) {
    let output = this._removeAppID(str);
    output = output.match(/messages\/.*\d{3}.+?(?=\/*)/gi); // Strip URL to after "/messages" if it exists.

    if (output === null) {
      output = 'Error';
      return output;
    }

    output = output[0]; // Quickly just grab the first element since we know it will always be this one.

    let editIndex = output.indexOf('edit');
    if (editIndex > 0) {
      output = output.replace(/edit.*/gi, ''); // Remove "/edit?editing_admin_id=xxx" at the end of a URL.
    }

    output = this._removeVersionNumber(output);
    output = this._returnNumericValue(output);
    return output;
  },

  _customBotRegexCheck(str) {
    let output = this._removeAppID(str);
    output = output.match(URL_REGEXES.customBot); // Strip URL to after "/custom-bots" if it exists.

    if (output === null) {
      return 'Error';
    }

    output = output[0]; // Quickly just grab the first element since we know it will always be this one.

    let editIndex = output.indexOf('edit');
    if (editIndex >= 0) {
      output = output.replace(URL_REGEXES.edit, ''); // Remove "/edit?editing_admin_id=xxx" at the end of a URL.
    }

    output = this._removeVersionNumber(output);
    output = this._returnNumericValue(output);
    return output;
  },

  _conversationsRegexCheck(str) {
    let output = this._removeAppID(str);

    let editIndex = output.indexOf('all-conversations');
    if (editIndex > 0) {
      output = 'Error';
      return output;
    }

    output = output.match(/(?:conversation|conversations)\/(\d+?)(?=\#part\_id|\/|\b)/gi); //matches for conversation/45897239457#part_id=comment-239845-138904 and other variations of our links
    if (output === null) {
      output = 'Error';
      return output;
    }

    output = output[0]; //1: Grab the first element to pull
    output = this._removeVersionNumber(output); //Maintain backwards compatibility of URL schemes - version numbers were contained in conversation URL before.
    output = this._returnNumericValue(output); //Strip non-numeric parts of item 0 in array - item string should be something like => "conversation/389837538"
    return output;
  },

  _articlesRegexCheck(str) {
    let output = this._removeAppID(str);

    let editIndex = output.indexOf('edit');
    if (editIndex > 0) {
      output = output.replace(/edit.*/gi, ''); // Remove "/edit?editing_admin_id=xxx" at the end of a URL.
    }

    output = output.match(/articles?\/(.*)/gi); // Strip URL to after "/messages" if it exists.

    if (output === null) {
      output = 'Error';
      return output;
    }

    output = output[0]; // Quickly just grab the first element since we know it will always be this one.
    output = this._removeVersionNumber(output);
    output = this._returnNumericValue(output);
    return output;
  },

  lookupCSLink() {
    let lookupLink = this.lookupValue;
    let outputID;
    let outputURL;

    if (lookupLink === undefined) {
      this.displayStatusMessage.perform('Hmm, no text was entered into the input box.', 'error');
      return;
    }

    if (lookupLink.match('/intercomrades.*/admin/')) {
      this._openNewWindowToAdminPage(lookupLink);
      return;
    } else if (lookupLink.match(URL_IDENTIFIERS.answerBot)) {
      outputID = this._answerBotRegexCheck(lookupLink);
      outputURL = ADMIN_URLS.answerBot;
    } else if (lookupLink.match(URL_IDENTIFIERS.messages)) {
      outputID = this._messagesRegexCheck(lookupLink);
      outputURL = ADMIN_URLS.messages;
    } else if (lookupLink.match(URL_IDENTIFIERS.customBot)) {
      outputID = this._customBotRegexCheck(lookupLink);
      outputURL = ADMIN_URLS.messages;
    } else if (lookupLink.match(URL_IDENTIFIERS.companies)) {
      outputID = lookupLink;
      outputURL = ADMIN_URLS.companies;
    } else if (lookupLink.match(URL_IDENTIFIERS.articles)) {
      outputID = this._articlesRegexCheck(lookupLink);
      outputURL = ADMIN_URLS.articles;
    } else if (lookupLink.match(URL_IDENTIFIERS.users)) {
      outputID = lookupLink;
      outputURL = ADMIN_URLS.users;
    } else if (lookupLink.match(URL_IDENTIFIERS.conversations)) {
      outputID = this._conversationsRegexCheck(lookupLink);
      outputURL = ADMIN_URLS.conversations;
    } else {
      this.displayStatusMessage.perform("I don't understand that URL.", 'error');
      return;
    }

    if (outputID !== 'Error') {
      this._openNewWindowToAdminPage(`${ADMIN_URLS.baseURL}${outputURL}${outputID}`);
    } else {
      this.displayStatusMessage.perform("That URL doesn't compute.", 'error');
      return;
    }
  },

  _openNewWindowToAdminPage(url = null) {
    if (url === null) {
      return;
    }
    safeWindowOpen(url);
  },

  _changeSelectedCompany(companyId) {
    this.set('selectedCompanyId', companyId);
  },

  triggerLookupCSLink: action(function () {
    this.lookupCSLink();
  }),

  openWorkspaceBillingPage: action(function () {
    let _url = this.get('selectedCompany.billingPage');
    if (_url === null) {
      _url = `${BILLING_URLS.baseURL}${BILLING_URLS.customers}${this.selectedCompanyId}`;
    }
    this._openNewWindowToAdminPage(_url);
  }),

  actions: {
    changeSelectedCompany(companyId) {
      this._changeSelectedCompany(companyId);
    },

    openWorkspaceAdminPage() {
      let _url = this.get('selectedCompany.appPage');
      if (_url === null) {
        _url = `${ADMIN_URLS.baseURL}${ADMIN_URLS.apps}${this.selectedCompanyId}`;
      }
      this._openNewWindowToAdminPage(_url);
    },
  },
});
