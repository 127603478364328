/* import __COLOCATED_TEMPLATE__ from './pull-conversation-setting.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  capacity: any;
  pullConversationEnabledUpdate(pullConversationEnabled: boolean, isChanged: boolean): void;
}

export default class PullConversationSetting extends Component<Args> {
  @tracked pullConversationEnabled: boolean = this.args.capacity.pullConversationEnabled;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  @action
  async togglePullConversation() {
    this.pullConversationEnabled = !this.pullConversationEnabled;
    this.args.pullConversationEnabledUpdate(
      this.pullConversationEnabled,
      this.pullConversationEnabled !== this.args.capacity.pullConversationEnabled,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::PullConversationSetting': typeof PullConversationSetting;
    'inbox/workload-management/pull-conversation-setting': typeof PullConversationSetting;
  }
}
