/* import __COLOCATED_TEMPLATE__ from './animated-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';

interface Args<T> {
  itemHeight?: number;
  items: T[];
}

interface Signature<T> {
  Element: HTMLDivElement;
  Args: Args<T>;
  Blocks: {
    default: [T, number];
  };
}

export default class AnimatedList<T extends Conversation | ConversationSummary> extends Component<
  Signature<T>
> {
  get containerHeight() {
    if (this.args.itemHeight) {
      return this.args.items.length * this.args.itemHeight;
    }
    return this.args.items.reduce((acc, item) => acc + this.itemHeight(item), 0);
  }

  get itemHeights() {
    if (this.args.itemHeight) {
      return this.args.items.map(() => this.args.itemHeight!);
    }
    return this.args.items.map(this.itemHeight);
  }

  showTicketType(conversation: Conversation | ConversationSummary) {
    return conversation.ticketTitle !== conversation.ticketType?.name;
  }

  @action itemHeight(conversation: Conversation | ConversationSummary) {
    if (conversation.redacted) {
      return 65;
    } else if (!conversation.isTicket && conversation.title) {
      return 85;
    } else if (!conversation.isTicket) {
      return 65;
    } else if (conversation.isTrackerTicket) {
      return 85;
    } else if (conversation.isTicket) {
      return 85;
    } else {
      return 65;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::AnimatedList': typeof AnimatedList;
    'inbox2/left-nav/animated-list': typeof AnimatedList;
  }
}
