/* import __COLOCATED_TEMPLATE__ from './conversation-preview-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLElement;
  Args: any;
}

const ConversationPreviewPanel = templateOnlyComponent<Signature>();
export default ConversationPreviewPanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationPreviewPanel': typeof ConversationPreviewPanel;
    'inbox2/onboarding/conversation-preview-panel': typeof ConversationPreviewPanel;
  }
}
