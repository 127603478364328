/* import __COLOCATED_TEMPLATE__ from './external-profiles.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { match, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import RouteRegexes from 'embercom/lib/route-regexes';

export default Component.extend({
  store: service(),
  router: service(),

  isOnUserRoute: match('router.currentRouteName', RouteRegexes.userRoute),
  profiles: computed('app.crm_profiles', function () {
    return this.app.crm_profiles.filter((profile) => {
      return profile !== 'marketo_enrichment';
    });
  }),
  admin: readOnly('app.currentAdmin'),

  hasSalesforceInboxApp: false,
  hasHubSpotInboxApp: false,

  loadHasCrmInboxApps: task(function* () {
    if (this.isOnUserRoute) {
      return;
    }
    let sidebarSectionsList = this.store.peekRecord(
      'inbox/admin-app-sidebar-section-list',
      `${this.get('app.id')}-${this.get('admin.id')}`,
    );

    if (sidebarSectionsList === null) {
      sidebarSectionsList = yield this.store.queryRecord('inbox/admin-app-sidebar-section-list', {
        filter: {
          appId: this.get('app.id'),
          adminId: this.get('admin.id'),
        },
      });
    }

    this.setProperties({
      hasSalesforceInboxApp: sidebarSectionsList.sections.any(
        (section) => section.name === 'Salesforce',
      ),
      hasHubSpotInboxApp: sidebarSectionsList.sections.any((section) => section.name === 'HubSpot'),
    });
  }).drop(),

  activeProfiles: ternaryToProperty('isOnUserRoute', 'profiles', 'filteredProfiles'),

  filteredProfiles: computed(
    'profiles',
    'hasSalesforceInboxApp',
    'hasHubSpotInboxApp',
    function () {
      return this.profiles.filter((profile) => {
        if (this.hasSalesforceInboxApp && profile === 'salesforce') {
          return false;
        }
        if (this.hasHubSpotInboxApp && profile === 'hubspot') {
          return false;
        }
        return true;
      });
    },
  ),

  didReceiveAttrs() {
    this._super(...arguments);
    this.loadHasCrmInboxApps.perform();
  },

  didUpdateAttrs() {
    this._super(...arguments);
    this.loadHasCrmInboxApps.perform();
  },
});
