/* import __COLOCATED_TEMPLATE__ from './conversation-type-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { InboxConversationTypeOption } from 'embercom/models/data/inbox/inbox-filters';

interface Args {
  selectedType?: InboxConversationTypeOption;
  onSelect: (options: { id: InboxConversationTypeOption }) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SearchFiltersConversationTypeSelector extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  get label() {
    this.intl.primaryLocale; // We must access the locale to make this getter aware that it is dependent on it.
    return this.intl.t(`inbox.search.filters.conversation-type.${this.args.selectedType}`);
  }

  get items() {
    this.intl.primaryLocale; // We must access the locale to make this getter aware that it is dependent on it.
    return Object.values(InboxConversationTypeOption).map((option) => {
      return {
        text: this.intl.t(`inbox.search.filters.conversation-type.${option}`),
        id: option,
        isSelected: option === this.args.selectedType,
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::ConversationTypeSelector': typeof SearchFiltersConversationTypeSelector;
    'inbox2/search/conversation-type-selector': typeof SearchFiltersConversationTypeSelector;
  }
}
