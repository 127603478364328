/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type InboxApi from 'embercom/services/inbox-api';
import { tracked } from '@glimmer/tracking';
import {
  TicketCategory,
  type TicketDescriptor,
  type TicketType,
} from 'embercom/objects/inbox/ticket';
import TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import { taskFor } from 'ember-concurrency-ts';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import { task } from 'ember-concurrency-decorators';
import { type Named, Resource } from 'ember-resources';
import { action } from '@ember/object';
import type ApplicationInstance from '@ember/application/instance';

interface TicketTypeResourceArgs {
  ticketTypeId?: number;
  conversation?: Conversation;
  allowedTicketCategories?: TicketCategory[];
}

export class TicketTypeResource extends Resource<Named<TicketTypeResourceArgs>> {
  @service session!: Session;
  @service inboxApi!: InboxApi;

  @tracked ticketTypes: Array<TicketType> = [];
  @tracked selectedTicketType: TicketType | undefined;
  @tracked ticketAttributes: TicketAttributeSummary[] = this.ticketAttributeSummaries;
  @tracked visibleToUser = true;
  @tracked conversation: Conversation | undefined;

  constructor(
    owner: ApplicationInstance,
    args: Named<TicketTypeResourceArgs>,
    previous?: TicketTypeResource,
  ) {
    super(owner, args);

    let ticketTypeId = args.named.ticketTypeId;
    this.conversation = args.named.conversation;

    if (!previous) {
      taskFor(this.loadTicketTypes).perform();
    } else {
      this.ticketTypes = previous.ticketTypes;
    }

    if (ticketTypeId && ticketTypeId !== previous?.selectedTicketType?.id) {
      this.selectedTicketType = this.ticketTypes.filter(
        (ticketType: TicketType) => ticketType.id === ticketTypeId,
      )[0];
      this.ticketAttributes = this.ticketAttributeSummaries;
    } else if (ticketTypeId) {
      this.selectedTicketType = previous?.selectedTicketType;
      this.ticketAttributes = previous ? previous.ticketAttributes : this.ticketAttributeSummaries;
    }
  }

  get isLoading() {
    return taskFor(this.loadTicketTypes).isRunning;
  }

  get isCreatingNewTicket() {
    return this.conversation === undefined || this.conversation.ticketType === undefined;
  }

  get ticketAttributeSummaries() {
    if (!this.selectedTicketType) {
      return [];
    }

    if (this.isCreatingNewTicket) {
      let descriptors = this.selectedTicketType.descriptors;

      return (
        descriptors
          ?.filter(
            (descriptor: TicketDescriptor) => !descriptor.archived && descriptor.visibleOnCreate,
          )
          .map((descriptor: TicketDescriptor) => {
            return new TicketAttributeSummary(undefined, descriptor);
          })
          .sortBy('descriptor.order') || []
      );
    } else {
      return (
        this.selectedTicketType.descriptors
          ?.filter(
            (descriptor: TicketDescriptor) =>
              !descriptor.archived || this.conversationAttributesById[descriptor.id]?.value,
          )
          ?.map((descriptor: TicketDescriptor) => {
            return new TicketAttributeSummary(
              this.conversationAttributesById[descriptor.id]?.value,
              descriptor,
            );
          })
          .sortBy('descriptor.order') || []
      );
    }
  }

  get ticketHasAttributes() {
    if (!this.selectedTicketType?.descriptors) {
      return false;
    }

    return this.ticketAttributes.length > 0;
  }

  get hasInvalidTicketAttributes() {
    return this.ticketAttributes.any((attr) => attr.showErrorOnCreate);
  }

  get conversationAttributesById() {
    if (!this.conversation) {
      return {};
    }

    return this.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  get allowedTicketCategories() {
    if (this.args.named.allowedTicketCategories === undefined) {
      return [TicketCategory.Task, TicketCategory.Request];
    }

    return this.args.named.allowedTicketCategories;
  }

  get activeTicketTypes() {
    return this.ticketTypes
      .filter(({ archived }) => !archived)
      .filter(({ category }) => this.allowedTicketCategories.includes(category));
  }

  get activeShareableTicketTypes() {
    return this.activeTicketTypes.reject(({ internal }) => internal);
  }

  @action refreshAttributes() {
    this.ticketAttributes = this.ticketAttributeSummaries;
  }

  @task({ restartable: true })
  *loadTicketTypes() {
    this.ticketTypes = yield this.inboxApi.listTicketTypes();
  }
}
