/* import __COLOCATED_TEMPLATE__ from './view-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: any;
}

const ViewSwitcher = templateOnlyComponent<Signature>();
export default ViewSwitcher;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ViewSwitcher': typeof ViewSwitcher;
    'inbox2/onboarding/view-switcher': typeof ViewSwitcher;
  }
}
