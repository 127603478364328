/* import __COLOCATED_TEMPLATE__ from './react-native.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import {
  INSTALLATION_TYPE,
  INSTALLATION_PLATFORMS,
  type MobilePlatform,
} from 'embercom/components/installation-new/constants';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type SdkAppService from 'embercom/services/sdk-app-service';

interface Args {}

type PackageManager = 'yarn' | 'npm' | 'expo';

export default class ReactNative extends Component<Args> {
  @service declare appService: any;
  @service declare sdkAppService: SdkAppService;

  @tracked selectedPackageManager: PackageManager = 'yarn';
  @tracked selectedInstallationPlatform: MobilePlatform = INSTALLATION_PLATFORMS.IOS;

  installationType = INSTALLATION_TYPE.LOGGED_IN_USERS;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.appService.app.currentAdmin;
  }

  get addIntercomCodeSnippet() {
    switch (this.selectedPackageManager) {
      case 'yarn':
        return `yarn add @intercom/intercom-react-native`;
      case 'npm':
        return `npm install @intercom/intercom-react-native`;
      case 'expo':
        return `npx expo install @intercom/intercom-react-native`;
      default:
        return '';
    }
  }

  get expoConfigSnippet() {
    return `
    {
      "expo": {
        "plugins": [
          [
            "@intercom/intercom-react-native",
            {
              "appId": "${this.app.id}",
              "androidApiKey": "${this.androidSdkAppApiKey}",
              "iosApiKey": "${this.iosSdkAppApiKey}"
            }
          ]
        ]
      }
    }
    `;
  }

  @action
  onSelectPackageManager(packageManager: PackageManager) {
    this.selectedPackageManager = packageManager;
  }

  get packageManagers() {
    return [
      {
        value: 'yarn',
        // Not translated as it's a package manager name
        // eslint-disable-next-line
        label: 'Yarn',
      },
      {
        value: 'npm',
        // Not translated as it's a package manager name
        // eslint-disable-next-line
        label: 'NPM',
      },
      {
        value: 'expo',
        // Not translated as it's a package manager name
        // eslint-disable-next-line
        label: 'Expo',
      },
    ];
  }

  @action
  onSelectInstallationPlatform(installationPlatform: MobilePlatform) {
    this.selectedInstallationPlatform = installationPlatform;
  }

  get installationPlatforms() {
    return [
      {
        value: INSTALLATION_PLATFORMS.IOS,
        label: 'iOS',
      },
      {
        value: INSTALLATION_PLATFORMS.ANDROID,
        label: 'Android',
      },
    ];
  }

  get iosCodeSnippet() {
    return `
      @import UIKit;
      #import <IntercomModule.h>

      @implementation AppDelegate

      - (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
          [IntercomModule initialize:@"${this.iosSdkAppApiKey}" withAppId:@"${this.app.id}"];
          return YES;
      }`;
  }

  get androidCodeSnippet() {
    return `
      import com.intercom.reactnative.IntercomModule;

      @Override
      public void onCreate() {
        super.onCreate();
        SoLoader.init(this, /* native exopackage */ false);
        IntercomModule.initialize(this, "${this.androidSdkAppApiKey}", "${this.app.id}");
      }`;
  }

  get jsInitCodeSnippet() {
    return `
      useEffect(() => {
        Intercom.loginUnidentifiedUser();
        Intercom.setLauncherVisibility(Visibility.VISIBLE);
      }, []);
    `;
  }

  get selectedPlatformApiKey() {
    switch (this.selectedInstallationPlatform) {
      case INSTALLATION_PLATFORMS.ANDROID:
        return this.androidSdkAppApiKey;
      default:
        return this.iosSdkAppApiKey;
    }
  }

  private get iosSdkAppApiKey() {
    return this.admin.hasProductAccessForCurrentApp
      ? this.sdkAppService.iosApiKey
      : 'ios_sdk-*********';
  }

  private get androidSdkAppApiKey() {
    return this.admin.hasProductAccessForCurrentApp
      ? this.sdkAppService.androidApiKey
      : 'android_sdk-*********';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::ReactNative': typeof ReactNative;
    'installation-new/mobile/initial-setup/react-native': typeof ReactNative;
  }
}
