/* import __COLOCATED_TEMPLATE__ from './example-code-snippets.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import {
  INSTALLATION_METHOD,
  PROGRAMMING_LANGUAGES,
} from 'embercom/components/installation-new/constants';

interface Args {
  selectedAppType: string;
  selectedUserIdentifier: string;
  selectedProgrammingLanguage: string;
  selectedInstallationMethod: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class ExampleCodeSnippets extends Component<Signature> {
  @service declare appService: any;
  @tracked placeholderUserHash = false;

  get app() {
    return this.appService.app;
  }

  get isNpmInstallationMethod() {
    switch (this.args.selectedInstallationMethod) {
      case String(INSTALLATION_METHOD.ANGULAR):
      case String(INSTALLATION_METHOD.VUE):
      case String(INSTALLATION_METHOD.REACT):
      case String(INSTALLATION_METHOD.EMBER):
        return true;
      default:
        return false;
    }
  }

  unknownHmacMethodPlaceholderText = '"INSERT_HMAC_VALUE_HERE"';

  get examples() {
    return {
      rails: {
        userID: '"<%= current_user.id %>"',
        name: '"<%= current_user.name %>"',
        email: '"<%= current_user.email %>"',
        createdAt: '"<%= current_user.created_at.to_i %>"',
        userHash: this.placeholderUserHash
          ? `'<%= ${this.unknownHmacMethodPlaceholderText} %>'`
          : `"<%=
      OpenSSL::HMAC.hexdigest(
        'sha256',
        '${this.app.api_secret}',
        current_user.${this.args.selectedUserIdentifier}
      )
    %>"`,
      },
      django: {
        userID: '"{{ request.user.id|escapejs }}"',
        name: '"{{ request.user.name|escapejs }}"',
        email: '"{{ request.user.email|escapejs }}"',
        createdAt: '"{{ request.user.date_joined|date:"U" }}"',
        userHash: this.placeholderUserHash
          ? `'{{ ${this.unknownHmacMethodPlaceholderText} }}'`
          : `"{{
      hmac.new(
        b'${this.app.api_secret}',
        bytes(request.user.${this.args.selectedUserIdentifier}, encoding='utf-8'),
        digestmod=hashlib.sha256
      ).hexdigest()
    }}"`,
      },
      php: {
        userID: '<?php echo json_encode($current_user->id) ?>',
        name: '<?php echo json_encode($current_user->name) ?>',
        email: '<?php echo json_encode($current_user->email) ?>',
        createdAt: '"<?php echo strtotime($current_user->created_at) ?>"',
        userHash: this.placeholderUserHash
          ? `<?php echo ${this.unknownHmacMethodPlaceholderText} ?>`
          : `"<?php
      echo hash_hmac(
        'sha256',
        $user->${this.args.selectedUserIdentifier},
        '${this.app.api_secret}'
      );
    ?>"`,
      },
      node: {
        userID: 'user.id',
        name: 'user.name',
        email: 'user.email',
        createdAt: 'user.createdAt',
        userHash: this.unknownHmacMethodPlaceholderText,
      },
      javascript: {
        userID: 'user.id',
        name: 'user.name',
        email: 'user.email',
        createdAt: 'user.createdAt',
        userHash: this.unknownHmacMethodPlaceholderText,
      },
    };
  }

  get example() {
    // default value (for when language arg does not map to any key inside the `examples` object)
    let example = {
      userID: '"123"',
      /* eslint-disable @intercom/intercom/no-bare-strings */
      name: '"Jane Doe"',
      /* eslint-enable @intercom/intercom/no-bare-strings */
      email: '"customer@example.com"',
      createdAt: '"1312182000"',
      userHash: this.unknownHmacMethodPlaceholderText,
    };
    if (Object.keys(this.examples).includes(this.args.selectedProgrammingLanguage)) {
      let key = this.args.selectedProgrammingLanguage as keyof typeof this.examples;
      example = this.examples[key];
    }

    return example;
  }

  get userID() {
    return this.example.userID;
  }

  get name() {
    return this.example.name;
  }

  get email() {
    return this.example.email;
  }

  get createdAt() {
    return this.example.createdAt;
  }

  get userHash() {
    return this.example.userHash;
  }

  get noPlaceholderUserHash() {
    return !this.placeholderUserHash;
  }

  get highlightLanguage() {
    if (this.args.selectedProgrammingLanguage === PROGRAMMING_LANGUAGES.RAILS) {
      return 'ruby';
    } else if (this.args.selectedProgrammingLanguage === PROGRAMMING_LANGUAGES.DJANGO) {
      return 'python';
    } else if (this.args.selectedProgrammingLanguage === PROGRAMMING_LANGUAGES.NODE) {
      return 'javascript';
    } else {
      return this.args.selectedProgrammingLanguage;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::CodeSnippets::UpdateSite::ExampleCodeSnippets': typeof ExampleCodeSnippets;
    'installation-new/web/idv-setup/code-snippets/update-site/example-code-snippets': typeof ExampleCodeSnippets;
  }
}
