/* import __COLOCATED_TEMPLATE__ from './accordion-content.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { INSTALLATION_TYPE } from 'embercom/components/installation-new/constants';
import type {
  INSTALLATION_METHOD,
  InstallationMethodSelection,
} from 'embercom/components/installation-new/constants';

interface Args {
  installationMethods: InstallationMethodSelection[];
  selectedInstallationMethod: INSTALLATION_METHOD;
  onSelectInstallationMethod: (method: string) => void;
  selectedInstallationType: INSTALLATION_TYPE;
  setSelectedInstallationType: (type: INSTALLATION_TYPE) => void;
  profilesForLoggedInUserEnabled: boolean;
}

interface Signature {
  Args: Args;
}

export default class AccordionContent extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  get selectedInstallationMethodTitle() {
    return this.args.installationMethods.find(
      (installationMethod) => installationMethod.id === this.args.selectedInstallationMethod,
    )?.instructionsTitle;
  }

  get selectedInstallationMethodSubTitle() {
    return this.args.installationMethods.find(
      (installationMethod) => installationMethod.id === this.args.selectedInstallationMethod,
    )?.instructionsSubTitle;
  }

  @action
  setInstallationMethod(method: string): void {
    // Always reset selectedInstallationType to default value of 'LOGGED_IN_USERS' when installation method is changed in initial setup step.
    // Only if 'code-snippet' or 'shopify' are chosen can they actually then change the installation type back to 'VISITORS'.
    this.args.setSelectedInstallationType(INSTALLATION_TYPE.LOGGED_IN_USERS);

    this.args.onSelectInstallationMethod(method);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'messenger_installation_method',
      metadata: {
        item: method.replaceAll('-', '_'),
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::AccordionContent': typeof AccordionContent;
    'installation-new/web/initial-setup/accordion-content': typeof AccordionContent;
  }
}
