/* import __COLOCATED_TEMPLATE__ from './conversations-search-resource.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { type SortState } from '../conversations-table';
import { registerDestructor } from '@ember/destroyable';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { intersection, isEmpty, isEqual, pluck } from 'underscore';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';
import PredicateGroup, {
  type SimplePredicate,
} from 'embercom/objects/inbox/search/predicate-group';
import generateUUID from 'embercom/lib/uuid-generator';
import type InboxRedirectionService from 'embercom/services/inbox-redirection-service';
import type ApplicationInstance from '@ember/application/instance';
import storage from 'embercom/vendor/intercom/storage';
import { LOCALSTORAGE_RECENT_SEARCHES_KEY } from 'embercom/components/inbox2/search/recent-searches';
import { qualifyRecentSearchToSave } from 'embercom/lib/inbox2/qualify-recent-search-to-save';
import type RouterService from '@ember/routing/router-service';
import { type UpdateMessage } from 'embercom/services/conversation-updates';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import Component from '@glimmer/component';
import { type ConversationsTableData } from 'embercom/components/inbox2/conversations-table';
import { noCache } from 'embercom/lib/cached-decorator';
import { type NexusEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import type Tracing from 'embercom/services/tracing';
export const CONVERSATIONS_PER_PAGE = 30;

const BACKGROUND_RELOAD_TIMEOUT = 1000;

interface ApiResponse {
  total: number;
  conversations: ConversationTableEntry[];
  validFor: number;
}

interface Args {
  query?: string;
  selectedSortOptionForSearch: SortState;
  selectedConversations: number;
  fields: string[];
  predicateGroup?: PredicateGroup;
  initialCount?: number;
  onUpdate?: (attributes: {
    fetch_count: number;
    in_background: boolean;
    from_search?: boolean;
  }) => void;
  realtimeUpdateEventName?: string;
  realtimeUpdateTopic?: string[];
  realtimeEventHandler?: (event: NexusEvent) => void;
}

export class ConversationsSearchResource
  extends Resource<Named<Args>>
  implements ConversationsTableData
{
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare router: RouterService;
  @service declare session: Session;
  @service declare tracing: Tracing;
  @service declare inboxRedirectionService: InboxRedirectionService;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare intercomEventService: any;
  @service declare nexus: Nexus;

  @tracked private localConversations: ConversationTableEntry[] = [];

  @tracked totalConversationsCount = 0;
  @tracked isInitialLoad = true;

  // used in search analytics events to group actions against search results
  searchUUID?: string;
  private args!: Args;
  private predicateGroup?: PredicateGroup;
  private count: number = CONVERSATIONS_PER_PAGE;
  private realtimeUpdateEventName?: string;
  private realtimeUpdateTopic?: string[];

  constructor(owner: ApplicationInstance) {
    super(owner);

    registerDestructor(this, () => {
      this.teardownSubscriptions();

      if (this.shouldListenForRealtimeEvents) {
        this.nexus.unsubscribeTopics(this.realtimeUpdateTopic!);
        this.nexus.removeListener(this.realtimeUpdateEventName!, this.notifyUpdates);
      }
    });
  }

  modify(_: unknown[], args: Args) {
    let predicatesHaveChanged = this.predicatesHaveChanged(args);
    let simpleArgsHaveChanged = this.simpleArgsHaveChanged(args, this.args);

    if (isEmpty(args.fields) || (!simpleArgsHaveChanged && !predicatesHaveChanged)) {
      return;
    }
    this.teardownSubscriptions();

    if (predicatesHaveChanged && args.predicateGroup) {
      this.predicateGroup = PredicateGroup.from(args.predicateGroup.toB64());
    }

    this.args = args;
    this.localConversations = [];
    this.totalConversationsCount = 0;
    this.count = this.args.initialCount ?? CONVERSATIONS_PER_PAGE;
    this.searchUUID = undefined;
    this.isInitialLoad = true;
    this.realtimeUpdateEventName = args.realtimeUpdateEventName;
    this.realtimeUpdateTopic = args.realtimeUpdateTopic;

    taskFor(this.loadConversations).perform();
    this.conversationUpdates.subscribe(this.applyConversationUpdates);

    if (this.shouldListenForRealtimeEvents) {
      this.nexus.subscribeTopics(this.realtimeUpdateTopic!);
      this.nexus.addListener(this.realtimeUpdateEventName!, this.notifyUpdates);
    }
  }

  get predicates() {
    return this.args.predicateGroup?.predicates || [];
  }

  get inboxConversationsCount() {
    return this.totalConversationsCount;
  }

  get hasConversations() {
    return this.inboxConversationsCount > 0;
  }

  get conversations() {
    return this.localConversations;
  }

  get conversationIds(): number[] {
    return pluck(this.conversations, 'id');
  }

  get hasSearchParams(): boolean {
    return (
      !!this.args.query ||
      this.predicates.length > 1 ||
      (this.predicates.length === 1 &&
        !this.predicateGroup?.findPredicate<SimplePredicate>('conversation-type'))
    );
  }

  get selectedConversations() {
    return this.conversations.filter((c: ConversationTableEntry) =>
      this.inboxState.selectedConversations.ids.includes(c.id),
    );
  }

  get shouldListenForRealtimeEvents(): boolean {
    return !(this.realtimeUpdateEventName === undefined || this.realtimeUpdateTopic === undefined);
  }

  private simpleArgsHaveChanged(current: Args, previous: Args) {
    return (
      !previous ||
      current.fields.length !== intersection(current.fields, previous.fields).length ||
      current.query !== previous.query ||
      !isEqual(current.selectedSortOptionForSearch, previous.selectedSortOptionForSearch)
    );
  }

  private predicatesHaveChanged(current: Args) {
    return current.predicateGroup?.toB64() !== this.predicateGroup?.toB64();
  }

  get canLoadMore() {
    return this.nextCount > this.count;
  }

  @action loadMore(metadata: Record<string, any>) {
    if (!this.canLoadMore) {
      return;
    }

    this.count = this.nextCount;
    taskFor(this.loadConversations).perform();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'scrolled',
      object: 'conversations',
      section: 'search',
      ...metadata,
    });
  }

  @action reload(
    options: { skipCache: boolean; inBackground: boolean } = {
      skipCache: false,
      inBackground: false,
    },
  ) {
    taskFor(this.loadConversations).perform(options);
  }

  /**
   * Api calls
   */

  @task({ keepLatest: true }) private *loadConversations(
    options: { skipCache: boolean; inBackground: boolean } = {
      skipCache: false,
      inBackground: false,
    },
  ): TaskGenerator<void> {
    this.args.onUpdate?.({
      fetch_count: this.count,
      in_background: options.inBackground,
      from_search: this.hasSearchParams,
    });

    yield taskFor(this.searchConversations).linked().perform(options);
    yield timeout(BACKGROUND_RELOAD_TIMEOUT);
  }

  private teardownSubscriptions() {
    taskFor(this.searchConversations).cancelAll();
    this.conversationUpdates.unsubscribe(this.applyConversationUpdates);
  }

  @task *searchConversations(
    options: { skipCache: boolean; inBackground: boolean } = {
      skipCache: false,
      inBackground: false,
    },
  ) {
    if (!this.hasSearchParams) {
      return;
    }

    let conversations: ConversationTableEntry[] = [];
    let total = 0;

    let inboxApi = options.skipCache ? noCache(this.inboxApi) : this.inboxApi;

    ({ conversations, total } = (yield inboxApi.searchForConversationsTableV2({
      query: this.args.query || '',
      sortParams: {
        sort_field: this.args.selectedSortOptionForSearch.sortField,
        sort_direction: this.args.selectedSortOptionForSearch.direction,
      },
      count: this.count,
      predicates: { predicates: this.predicates },
      fields: this.args.fields,
    })) as ApiResponse);

    this.searchUUID = generateUUID();
    this.localConversations = conversations.map((conversation) => {
      this.conversationUpdates
        .updatesAfter(conversation.id, conversation.lastUpdated)
        .forEach((update) => update.apply(conversation));
      return conversation;
    });

    this.totalConversationsCount = total;
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
    }

    if (this.args.query && total > 0) {
      this.saveRecentSearch(this.args.query);
    }
  }

  /**
   * State
   */

  get isLoading() {
    return taskFor(this.searchConversations).isRunning;
  }

  get isLoadingInForeground() {
    return this.isLoading;
  }

  get hasError(): boolean {
    return (!this.isLoading && taskFor(this.searchConversations).last?.isError) ?? false;
  }

  get nextCount() {
    return Math.min(
      this.localConversations.length + CONVERSATIONS_PER_PAGE,
      this.totalConversationsCount,
    );
  }

  get countAdditionalConversationsBeingFetched() {
    if (!this.isLoading) {
      return 0;
    }

    return this.count - this.localConversations.length;
  }

  /**
   * Navigation
   */

  get active() {
    return this.conversations.find(({ id }) => this.inboxState.activeConversationId === id);
  }

  getRelativeConversation(position: number): ConversationTableEntry | null {
    let index = this.findActiveIndex() + position;
    if (index >= 0 && index < this.conversations.length) {
      return this.conversations[index];
    }

    return null;
  }

  private findActiveIndex() {
    return this.conversations.findIndex(({ id }) => id === this.inboxState.activeConversationId);
  }

  /**
   * Recent searches
   */

  private saveRecentSearch(query: string) {
    let lastSearchTimestampKey = 'search-v2-last-search-timestamp';
    let lastSearchTimestamp = storage.get(lastSearchTimestampKey);
    let timePeriod = Date.now() - lastSearchTimestamp;
    let recentSearches = storage.get(LOCALSTORAGE_RECENT_SEARCHES_KEY) || [];
    let qualifiedSearches = qualifyRecentSearchToSave(query, recentSearches, timePeriod, 10 * 1000);
    if (qualifiedSearches) {
      storage.set(LOCALSTORAGE_RECENT_SEARCHES_KEY, qualifiedSearches.slice(-7));
      storage.set(lastSearchTimestampKey, Date.now());
    }
  }

  /**
   * Local updates
   */

  @action
  private async applyConversationUpdates(updates: UpdateMessage[]) {
    updates.forEach((update) => {
      let conversation = this.localConversations.find(
        (conversation) => conversation.id === update.conversationId,
      );

      if (!conversation) {
        return;
      }
      if (update.type === 'added') {
        update.entries.forEach((entry) => {
          entry.apply(conversation!);
        });
      } else if (update.type === 'removed') {
        update.entries.forEach((entry) => entry.rollback(conversation!));
      }
    });
  }

  /**
   * Realtime updates
   */

  @action private notifyUpdates() {
    taskFor(this.loadConversations).perform({ skipCache: true, inBackground: true });
  }
}

export default class ConversationsSearchResourceComponent extends Component<{
  Args: Args;
  Blocks: {
    default: [ConversationsSearchResource];
  };
}> {
  resource = ConversationsSearchResource.from(this, () => {
    return { ...this.args };
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::ConversationsSearchResource': typeof ConversationsSearchResourceComponent;
    'inbox2/search/conversations-search-resource': typeof ConversationsSearchResourceComponent;
  }
}
