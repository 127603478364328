/* import __COLOCATED_TEMPLATE__ from './conversation-rating-feature-pointer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { computed, action } from '@ember/object';
import { and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ConversationRatingSettings from 'embercom/models/conversation-ratings-settings';
import LegacyOverlayBaseComponent from 'embercom/components/legacy-overlay-base-component';
import ENV from 'embercom/config/environment';
import storage from 'embercom/vendor/intercom/storage';

export default LegacyOverlayBaseComponent.extend({
  appService: service(),
  store: service(),
  onboardingHomeExternalStepService: service(),
  extraClassNames: ['conversation-rating-feature-pointer'],
  pageviewKey: 'respond-conversation-rating-feature-pointer',

  notDismissed: computed('pageviewKey', function () {
    return !storage.get(this.pageviewKey);
  }),

  shouldShow: and(
    'notDismissed',
    'conversationRatingSettings.canCreateConversationRatings',
    'onboardingHomeExternalStepService.externalStepModeDisabled',
  ),

  didInsertElement() {
    this._super(...arguments);
    this.delayedOpen.perform();
  },

  willDestroyElement() {
    this._dismissFeaturePointer();
    this._super(...arguments);
  },

  //disable the default event listeners
  setUpEvents() {},

  tearDownEvents() {},

  _openPointerIfNecessary() {
    let open = this.shouldShow;
    this.set('isOpen', open);
    if (open) {
      if (this.opened) {
        this.opened();
      }
    }
  },

  delayedOpen: task(function* () {
    let conversationRatingSettings;
    try {
      conversationRatingSettings = yield ConversationRatingSettings.fetch(
        this.get('appService.app.id'),
      );
    } catch (e) {
      return;
    }
    this.set('conversationRatingSettings', conversationRatingSettings);
    yield timeout(ENV.APP._5000MS);
    this._openPointerIfNecessary();
  }).drop(),

  _dismissFeaturePointer() {
    if (!this.isOpen) {
      return;
    }
    storage.set(this.pageviewKey, new Date());
    this.store
      .createRecord('admin-pageview', {
        pageKey: this.pageviewKey,
      })
      .save();
    this.set('notDismissed', false);
    this._openPointerIfNecessary();
  },

  closeAndDismiss: action(function () {
    this._dismissFeaturePointer();
  }),
});
