/* import __COLOCATED_TEMPLATE__ from './whatsapp-restricted-replies-banner.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onButtonClick: () => void;
    heading: string;
    body: string;
  };
}

const WhatsappRestrictedRepliesBanner = templateOnlyComponent<Signature>();
export default WhatsappRestrictedRepliesBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::WhatsappRestrictedRepliesBanner': typeof WhatsappRestrictedRepliesBanner;
    'inbox2/whatsapp-restricted-replies-banner': typeof WhatsappRestrictedRepliesBanner;
  }
}
