/* import __COLOCATED_TEMPLATE__ from './card-header-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { not, readOnly, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { greaterThanProperty, ternary } from '@intercom/pulse/lib/computed-properties';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { CONVERSATION_PRIORITIES, SNOOZED_UNTIL_MAP } from 'embercom/lib/inbox/constants';
import FormForDownload from 'embercom/lib/form-for-download';
import {
  canExportConversationTranscriptsInbox1,
  exportConversationTranscriptsPermission,
} from 'embercom/lib/settings/permissions/inline-permission-enforcement';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

export default Component.extend({
  tagName: '',
  intercomEventService: service(),
  permissionsService: service(),
  notificationsService: service(),
  conversationsService: service(),
  paywallService: service(),
  appService: service(),
  intercomConfirmService: service(),
  app: readOnly('appService.app'),
  width: 960,
  defaultTeammateAvatar: DefaultAvatars.admins[128],
  isInbox: false,
  notReadOnly: not('readOnly'),
  isNotInbox: not('isInbox'),
  mediumWidth: 539,
  wideWidth: 1700,
  isMediumHeader: greaterThanProperty('width', 'mediumWidth'),
  isWideHeader: greaterThanProperty('width', 'wideWidth'),
  shouldShowSnoozeAction: readOnly('conversation.isNotClosed'),
  hasInboxProductAndAccess: and('app.inboxIsActive', 'app.currentAdminHasInboxAccess'),
  showAssignment: readOnly('hasInboxProductAndAccess'),
  showTags: readOnly('app.canUseConversationLevelTagging'),
  showPriorityAction: and('hasInboxProductAndAccess', 'app.isNotOnFree'),
  canShowSla: and('app.canUseInboxSlas', 'conversation.hasSla'),
  showSla: and('isInbox', 'canShowSla'),
  place: ternary('isInbox', 'inbox', 'platform'),
  shouldCheckRequiredAttributesBeforeClosing: computed('paywallService', function () {
    return !this.paywallService.isPaywallActive({ featureName: 'conversation_attributes' });
  }),

  onWindowResize: action(function ({ width }) {
    this.set('width', width);
  }),

  maybeCollapse: action(function (event) {
    if (event.metaKey !== true && event.ctrlKey !== true) {
      event.preventDefault();
    }
    let $target = $(event.target);
    if (
      this.isNotInbox &&
      ($target.hasClass('inbox__card__header__title') || $target.hasClass('inbox__card__header'))
    ) {
      this.collapse?.();
    }
  }),

  downloadTranscript: action(function () {
    if (!canExportConversationTranscriptsInbox1(this.appService)) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        exportConversationTranscriptsPermission,
      );
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'downloaded',
      object: 'transcript',
      conversation_id: this.get('conversation.id'),
    });
    let url = `ember/conversations/download_transcript?v=${Math.floor(Math.random() * 1e8)}`;
    FormForDownload.postNewTab(url, {
      app_id: this.get('app.id'),
      conversation_id: this.get('conversation.id'),
    });
  }),

  removeSlaTask: task(function* () {
    let isConfirmed = yield this.intercomConfirmService.confirm({
      title: 'Remove SLA from this conversation?',
      confirmButtonText: 'Remove',
      cancelButtonText: 'Cancel',
      body: 'Are you sure you want to remove the SLA from this conversation? This is permanent.',
      primaryButtonType: 'primary-destructive',
    });
    if (!isConfirmed) {
      return;
    }

    // Warning: Removing an SLA is asynchronous. A 204 does not mean the SLA was removed, only queued to be removed.
    return yield ajax({
      url: `/ember/conversations/${this.get('conversation.id')}/sla`,
      type: 'DELETE',
      data: JSON.stringify({
        app_id: this.get('app.id'),
      }),
    }).catch((err) => {
      console.error('Unable to remove sla', err);
    });
  }).drop(),

  removeParticipantFromConversation: task(function* (participant) {
    let conversation = this.conversation;
    try {
      yield conversation.removeParticipant(participant);
      this.notificationsService.notifyConfirmation(
        `${participant.display_as} has been removed from the conversation`,
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'removed_from_conversation',
        object: participant,
        models: [conversation],
      });
    } catch (e) {
      this.notificationsService.notifyError('Failed to remove user from conversation');
    }
  }).drop(),

  closeConversation: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: this.conversation,
      place: this.place,
      context: 'from_conversation_header',
    });
    this.conversationsService.closeConversation(this.conversation);
    if (this.onConversationClosed) {
      this.onConversationClosed();
    }
  }),

  reopenConversation: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: this.conversation,
      place: this.place,
      context: 'from_conversation_header',
    });
    this.conversationsService.reopenConversation(this.conversation);
    if (this.onConversationReopened) {
      this.onConversationReopened();
    }
  }),

  snoozeConversation: action(function (until) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      object: this.conversation,
      context: 'from_conversation',
      snoozed_until: SNOOZED_UNTIL_MAP[until].key,
    });

    let customSnoozedUntil = SNOOZED_UNTIL_MAP[until].absoluteUnsnoozeTime;
    this.conversationsService.addEventPartAndSave(this.conversation, 'snoozed', {
      snoozedUntil: until,
      customSnoozedUntil,
    });

    if (this.onConversationSnoozed) {
      this.onConversationSnoozed();
    }
  }),

  hoursSnoozeConversation: action(function (hours) {
    let snoozeTime = moment().add(hours, 'hours').toISOString();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      object: this.conversation,
      context: 'from_conversation',
      snoozed_until: 'hours',
      hours,
    });

    this.conversationsService.addEventPartAndSave(this.conversation, 'snoozed', {
      snoozedUntil: 'custom_timer',
      customSnoozedUntil: snoozeTime,
    });

    if (this.onConversationSnoozed) {
      this.onConversationSnoozed();
    }
  }),

  customSnoozeConversation: action(function (customSnoozedUntil) {
    let snoozeTime = moment(customSnoozedUntil);
    let timeOfDay = snoozeTime.format('HH:mm');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      object: this.conversation,
      context: 'from_conversation',
      snoozed_until: 'custom_timer',
      days_snoozed: snoozeTime.startOf('day').diff(moment().startOf('day'), 'days'),
      time_of_day: timeOfDay,
    });

    this.conversationsService.addEventPartAndSave(this.conversation, 'snoozed', {
      snoozedUntil: 'custom_timer',
      customSnoozedUntil,
    });

    if (this.onConversationSnoozed) {
      this.onConversationSnoozed();
    }
  }),

  unsnoozeConversation: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: this.conversation,
      context: 'from_conversation',
    });
    this.conversationsService.addEventPartAndSave(this.conversation, 'unsnoozed');
    if (this.onConversationReopened) {
      this.onConversationReopened();
    }
  }),

  toggleConversationPriority: action(function () {
    $('.js__change-priority-button').trigger('blur');

    let priority =
      this.get('conversation.priority') === CONVERSATION_PRIORITIES.priority
        ? CONVERSATION_PRIORITIES.not_priority
        : CONVERSATION_PRIORITIES.priority;

    this.conversationsService
      .addEventPartAndSave(this.conversation, 'priority_changed', { priority })
      .catch(() =>
        this.notificationsService.notifyError(
          'An error occurred while trying to change priority. Please try again.',
        ),
      );

    this.streamAndControls.send('scrollStreamToBottom');

    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed_priority',
      object: this.conversation,
      context: 'from_conversation',
      owner: 'respond',
      place: this.place,
      priority,
    });
  }),

  removeParticipant: action(function (participant) {
    this.removeParticipantFromConversation.perform(participant);
  }),
});
