/* import __COLOCATED_TEMPLATE__ from './filters-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const FiltersItem = templateOnlyComponent<Signature>();
export default FiltersItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationList::FiltersItem': typeof FiltersItem;
    'inbox2/left-nav/conversation-list/filters-item': typeof FiltersItem;
  }
}
