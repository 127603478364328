/* import __COLOCATED_TEMPLATE__ from './cvda-boolean-filter.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { type BooleanComparison } from 'embercom/objects/inbox/search/predicate-group';

interface Signature {
  Element: HTMLElement;
  Args: {
    selected?: string;
    onSelect: (value: BooleanComparison) => void;
  };
}

export default class CvdaBooleanFilter extends Component<Signature> {
  @service declare intl: IntlService;

  get radioButtons() {
    return [
      {
        value: 'true',
        label: this.intl.t('inbox.search.filters.more-filters.is-true'),
      },
      {
        value: 'false',
        label: this.intl.t('inbox.search.filters.more-filters.is-false'),
      },
    ];
  }

  @action onSelect(value: BooleanComparison) {
    this.args.onSelect(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::CvdaBooleanFilter': typeof CvdaBooleanFilter;
  }
}
