/* import __COLOCATED_TEMPLATE__ from './events.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { readOnly, sort } from '@ember/object/computed';

export default Component.extend({
  store: service(),
  attributeService: service(),
  tagName: '',
  editVisibilityMode: false,
  eventSummariesSortKey: ['name'],
  allEventSummaries: readOnly('user.event_summaries'),
  sortedEventSummaries: sort('allEventSummaries', 'eventSummariesSortKey'),
  visibleEventSummaries: computed(
    'sortedEventSummaries.[]',
    'attributeService.visibleEventNames.[]',
    function () {
      return this._findOrCreateEventSummaries(this.get('attributeService.visibleEventNames'));
    },
  ),
  hiddenEventSummaries: computed(
    'sortedEventSummaries.[]',
    'attributeService.hiddenEventNames.[]',
    function () {
      return this._findOrCreateEventSummaries(this.get('attributeService.hiddenEventNames'));
    },
  ),
  hasAdminVisibleEventSummaries: readOnly('attributeService.hasEvents'),
  _findOrCreateEventSummaries(eventSet) {
    let eventSummaries = [];
    eventSet.forEach((eventName) => {
      let eventSummary = this.sortedEventSummaries.findBy('identifier', eventName);
      if (eventSummary) {
        eventSummaries.push(eventSummary);
      } else {
        eventSummaries.push(this._createDefaultSummary(eventName));
      }
    });
    return eventSummaries;
  },
  _createDefaultSummary(eventName) {
    let replacedName = eventName.replace(/user_event_summaries./, '');
    return this.store.createFragment('event-summary', {
      name: replacedName,
      first: null,
      last: null,
      count: 0,
      description: '',
    });
  },
  updateEditVisibilityMode: action(function (newEditVisibilityModeState) {
    this.set('editVisibilityMode', newEditVisibilityModeState);
  }),
});
