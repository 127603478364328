/* import __COLOCATED_TEMPLATE__ from './company-segments.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

export default class CompanySegments extends Component {
  constructor() {
    super(...arguments);
    this.args.userCompanies.forEach((company) => {
      company.loadSegments.perform();
    });
  }
}
