/* import __COLOCATED_TEMPLATE__ from './custom-time-sla-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import Delays from 'embercom/lib/workflows/helpers/delays';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';
import type ConversationSla from 'embercom/models/inbox/conversation-sla';
import type { TaskGenerator } from 'ember-concurrency';
interface Args {
  officeHoursSchedules: OfficeHoursSchedule[];
  conversationSlas: ConversationSla[];
  onClose: () => void;
  isOpen: boolean;
  onSlaCreated: (sla: ConversationSla) => void;
}
export default class CustomTimeSlaSideDrawer extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked showSideDrawer = false;
  @tracked slaName: string | null = '';
  @tracked firstReplyTimeInSeconds = null;
  @tracked nextReplyTimeInSeconds = null;
  @tracked resolutionTimeInSeconds = null;
  @tracked timeToCloseInSeconds = null;
  @tracked firstReplyDelayUnit = 'second';
  @tracked firstReplyDelayValue = 0;
  @tracked nextReplyDelayUnit = 'second';
  @tracked nextReplyDelayValue = 0;
  @tracked resolutionDelayUnit = 'second';
  @tracked resolutionDelayValue = 0;
  @tracked timeToCloseDelayUnit = 'second';
  @tracked timeToCloseDelayValue = 0;
  @tracked selectedScheduleId = this.defaultScheduleId;
  @tracked pauseOnWaitingOnCustomer = false;
  @tracked pauseOnSnooze = false;

  get delays() {
    return new Delays();
  }

  get officeHoursSchedules() {
    return this.args.officeHoursSchedules;
  }

  get conversationSlas() {
    return this.args.conversationSlas;
  }

  onSlaCreated(sla: ConversationSla) {
    this.args.onSlaCreated(sla);
  }

  get trimmedSlaName() {
    let slaName = this.slaName || '';
    return slaName.trim();
  }

  get duplicatedName() {
    return this.conversationSlas
      .filter((sla) => !sla.isNew)
      .any((sla) => sla.name === this.trimmedSlaName);
  }

  get firstTimeValueNull() {
    return this.firstReplyTimeInSeconds === null;
  }

  get nextTimeValueNull() {
    return this.nextReplyTimeInSeconds === null;
  }

  get resolutionTimeValueNull() {
    return this.resolutionTimeInSeconds === null;
  }

  get timeToCloseValueNull() {
    return this.timeToCloseInSeconds === null;
  }

  get firstReplyTimeValid() {
    // we want this to be between 30 seconds and 100 days
    return (
      this.firstTimeValueNull ||
      (!this.firstTimeValueNull &&
        Number(this.firstReplyTimeInSeconds) >= 30 &&
        Number(this.firstReplyTimeInSeconds) <= 8640000)
    );
  }

  get nextReplyTimeValid() {
    // we want this to be between 30 seconds and 100 days
    return (
      this.nextTimeValueNull ||
      (!this.nextTimeValueNull &&
        Number(this.nextReplyTimeInSeconds) >= 30 &&
        Number(this.nextReplyTimeInSeconds) <= 8640000)
    );
  }

  get resolutionTimeValid() {
    // we want this to be between 30 seconds and 100 days
    return (
      this.resolutionTimeValueNull ||
      (!this.resolutionTimeValueNull &&
        Number(this.resolutionTimeInSeconds) >= 30 &&
        Number(this.resolutionTimeInSeconds) <= 8640000)
    );
  }

  get timeToCloseValid() {
    // we want this to be between 30 seconds and 100 days

    return (
      this.timeToCloseValueNull ||
      (!this.timeToCloseValueNull &&
        Number(this.timeToCloseInSeconds) >= 30 &&
        Number(this.timeToCloseInSeconds) <= 8640000 &&
        Number(this.timeToCloseInSeconds) >= Number(this.resolutionTimeInSeconds))
    );
  }

  get timeValidationError() {
    if (!this.firstReplyTimeValid || !this.nextReplyTimeValid) {
      return this.intl.t('inbox.sla.custom-time-sla-drawer.invalid-response-times');
    }

    if (!this.resolutionTimeValid || !this.timeToCloseValid) {
      return this.intl.t('inbox.sla.custom-time-sla-drawer.invalid-completion-times');
    }
    return null;
  }

  get noValuesSelectedError() {
    return (
      this.firstTimeValueNull &&
      this.nextTimeValueNull &&
      this.resolutionTimeValueNull &&
      this.timeToCloseValueNull
    );
  }

  get saveButtonDisabled() {
    let invalidName = this.trimmedSlaName.length === 0 || this.duplicatedName;
    return invalidName || !!this.timeValidationError || this.noValuesSelectedError;
  }

  get defaultScheduleId(): string | undefined {
    let schedule = this.officeHoursSchedules.findBy('isDefault', true);
    if (schedule) {
      return schedule.id;
    } else if (this.officeHoursSchedules.length > 0) {
      return this.officeHoursSchedules.firstObject?.id;
    } else {
      return undefined;
    }
  }

  get appHasMoreThanOneOfficeHours() {
    return this.officeHoursSchedules.length > 1;
  }

  get dropdownSchedules() {
    return this.officeHoursSchedules.map((schedule) => {
      return {
        value: schedule.id,
        text: schedule.name,
        description: schedule.labelWithTimezone,
      };
    });
  }

  @task({ drop: true })
  *createNewSlaAndCloseDrawer(): TaskGenerator<void> {
    let sla = yield this.store
      .createRecord('inbox/conversation-sla', {
        name: this.trimmedSlaName,
        firstReplyTimeInSeconds: this.firstReplyTimeInSeconds,
        nextReplyTimeInSeconds: this.nextReplyTimeInSeconds,
        resolutionTimeInSeconds: this.resolutionTimeInSeconds,
        timeToCloseInSeconds: this.timeToCloseInSeconds,
        officeHoursScheduleId: this.selectedScheduleId,
        pauseOnWaitingOnCustomer: this.pauseOnWaitingOnCustomer,
        pauseOnSnooze: this.pauseOnSnooze,
      })
      .save();
    this.clearForm();
    this.closeCustomTimeSlaDrawer();
    this.onSlaCreated(sla);
  }

  @action
  clearForm() {
    this.slaName = '';
    this.firstReplyTimeInSeconds = null;
    this.nextReplyTimeInSeconds = null;
    this.resolutionTimeInSeconds = null;
    this.timeToCloseInSeconds = null;
    this.firstReplyDelayValue = 0;
    this.nextReplyDelayValue = 0;
    this.resolutionDelayValue = 0;
    this.timeToCloseDelayValue = 0;
    this.firstReplyDelayUnit = 'second';
    this.nextReplyDelayUnit = 'second';
    this.timeToCloseDelayUnit = 'second';
    this.resolutionDelayUnit = 'second';
    this.selectedScheduleId = this.defaultScheduleId;
    this.pauseOnWaitingOnCustomer = false;
    this.pauseOnSnooze = false;
  }

  @action
  closeCustomTimeSlaDrawer() {
    this.slaName = null;
    this.firstReplyTimeInSeconds = null;
    this.nextReplyTimeInSeconds = null;
    this.args.onClose();
  }

  @action
  setFirstReplyDelayUnit(unit: string) {
    this.firstReplyDelayUnit = unit;
    if (Number(this.firstReplyDelayValue) === 0) {
      this.firstReplyTimeInSeconds = null;
    } else {
      this.firstReplyTimeInSeconds = this.delays.getDelayInSeconds(this.firstReplyDelayValue, unit);
    }
  }

  @action
  setFirstReplyDelayValue() {
    if (Number(this.firstReplyDelayValue) === 0) {
      this.firstReplyTimeInSeconds = null;
    } else {
      this.firstReplyTimeInSeconds = this.delays.getDelayInSeconds(
        this.firstReplyDelayValue,
        this.firstReplyDelayUnit,
      );
    }
  }

  @action
  setNextReplyDelayUnit(unit: string) {
    this.nextReplyDelayUnit = unit;
    if (Number(this.nextReplyDelayValue) === 0) {
      this.nextReplyTimeInSeconds = null;
    } else {
      this.nextReplyTimeInSeconds = this.delays.getDelayInSeconds(this.nextReplyDelayValue, unit);
    }
  }

  @action
  setNextReplyDelayValue() {
    if (Number(this.nextReplyDelayValue) === 0) {
      this.nextReplyTimeInSeconds = null;
    } else {
      this.nextReplyTimeInSeconds = this.delays.getDelayInSeconds(
        this.nextReplyDelayValue,
        this.nextReplyDelayUnit,
      );
    }
  }

  @action
  setResolutionDelayUnit(unit: string) {
    this.resolutionDelayUnit = unit;
    if (Number(this.resolutionDelayValue) === 0) {
      this.resolutionTimeInSeconds = null;
    } else {
      this.resolutionTimeInSeconds = this.delays.getDelayInSeconds(this.resolutionDelayValue, unit);
    }
  }

  @action
  setResolutionDelayValue() {
    if (Number(this.resolutionDelayValue) === 0) {
      this.resolutionTimeInSeconds = null;
    } else {
      this.resolutionTimeInSeconds = this.delays.getDelayInSeconds(
        this.resolutionDelayValue,
        this.resolutionDelayUnit,
      );
    }
  }

  @action
  setTimeToCloseDelayUnit(unit: string) {
    this.timeToCloseDelayUnit = unit;
    if (Number(this.timeToCloseDelayValue) === 0) {
      this.timeToCloseInSeconds = null;
    } else {
      this.timeToCloseInSeconds = this.delays.getDelayInSeconds(this.timeToCloseDelayValue, unit);
    }
  }

  @action
  setTimeToCloseDelayValue() {
    if (Number(this.timeToCloseDelayValue) === 0) {
      this.timeToCloseInSeconds = null;
    } else {
      this.timeToCloseInSeconds = this.delays.getDelayInSeconds(
        this.timeToCloseDelayValue,
        this.timeToCloseDelayUnit,
      );
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::Slas::CustomTimeSlaSideDrawer': typeof CustomTimeSlaSideDrawer;
    'inbox/slas/custom-time-sla-side-drawer': typeof CustomTimeSlaSideDrawer;
  }
}
