/* import __COLOCATED_TEMPLATE__ from './inbox-list-menu-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type InboxState from 'embercom/services/inbox-state';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';

interface Arguments {
  route: string;
}

interface Signature {
  Args: Arguments;
}

export default class InboxListMenuItem extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare router: Router;
  @service declare session: Session;

  get isActive(): boolean {
    return this.router.currentRouteName?.startsWith(this.args.route);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::InboxListMenuItem': typeof InboxListMenuItem;
    'inbox2/left-nav/inbox-list-menu-item': typeof InboxListMenuItem;
  }
}
