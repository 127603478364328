/* import __COLOCATED_TEMPLATE__ from './accordion-content.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import {
  PROGRAMMING_LANGUAGES,
  type INSTALLATION_TYPE,
  INSTALLATION_METHOD,
  type InstallationMethodSelection,
} from 'embercom/components/installation-new/constants';
import {
  HMAC_APP_TYPES,
  USER_IDENTIFIER_VALUES,
} from 'embercom/components/installation-new/hmac-generation';

export interface AccordionContentArgs {
  installationMethods: InstallationMethodSelection[];
  openUserHashChecker: () => void;
  selectedInstallationMethod: INSTALLATION_METHOD;
  onSelectInstallationMethod: (method: INSTALLATION_METHOD) => void;
  profilesForLoggedInUserEnabled: boolean;
  selectedInstallationType: INSTALLATION_TYPE;
  idvState: 'secured' | 'recommended' | 'not-required' | 'not-configured';
}

interface Signature {
  Args: AccordionContentArgs;
}

export const PROGRAMMING_LANGUAGE_LIST = [
  /* eslint-disable @intercom/intercom/no-bare-strings */
  { text: 'Node.js', value: PROGRAMMING_LANGUAGES.NODE },
  { text: 'Rails (Ruby)', value: PROGRAMMING_LANGUAGES.RAILS },
  { text: 'Django (Python 3)', value: PROGRAMMING_LANGUAGES.DJANGO },
  { text: 'PHP', value: PROGRAMMING_LANGUAGES.PHP },
  { text: 'Go', value: PROGRAMMING_LANGUAGES.GO },
  { text: 'Java', value: PROGRAMMING_LANGUAGES.JAVA },
  /* eslint-enable @intercom/intercom/no-bare-strings */
];

export default class AccordionContent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;

  @tracked selectedAppType = HMAC_APP_TYPES.BASIC_JAVASCRIPT;
  @tracked selectedUserIdentifier = USER_IDENTIFIER_VALUES.ID;
  @tracked selectedProgrammingLanguage = PROGRAMMING_LANGUAGES.NODE;

  get isNpmInstallation() {
    switch (this.args.selectedInstallationMethod) {
      case INSTALLATION_METHOD.REACT:
      case INSTALLATION_METHOD.ANGULAR:
      case INSTALLATION_METHOD.VUE:
      case INSTALLATION_METHOD.EMBER:
        return true;
      default:
        return false;
    }
  }

  get hideAppTypes() {
    return (
      this.args.selectedInstallationMethod === 'google-tag-manager' ||
      this.args.selectedInstallationMethod === 'segment'
    );
  }

  get hideProgrammingLanguages() {
    return this.selectedAppType === 'rails-gem';
  }

  get hideSecondCodeBlock() {
    let isRailsgem =
      this.selectedAppType === 'rails-gem' &&
      this.args.selectedInstallationMethod === String(INSTALLATION_METHOD.CODE_SNIPPET);
    return (
      isRailsgem || this.args.selectedInstallationMethod === 'segment' || this.shopifyOrWordpress
    );
  }

  get shopifyOrWordpress() {
    return (
      this.args.selectedInstallationMethod === 'wordpress' ||
      this.args.selectedInstallationMethod === 'shopify'
    );
  }

  get displayProgrammingLanguage() {
    if (this.isNpmInstallation) {
      return 'javascript';
    }
    return this.selectedProgrammingLanguage;
  }

  get highlightLanguage() {
    if (this.selectedProgrammingLanguage === 'rails') {
      return 'ruby';
    } else if (this.selectedProgrammingLanguage === 'django') {
      return 'python';
    } else if (this.selectedProgrammingLanguage === 'node') {
      return 'javascript';
    } else {
      return this.selectedProgrammingLanguage;
    }
  }

  get checkInstallationStepNumber() {
    if (this.shopifyOrWordpress) {
      return 2;
    }

    return this.hideSecondCodeBlock ? 3 : 4;
  }

  get switchOnIdvStepNumber() {
    return this.checkInstallationStepNumber + 1;
  }

  get app() {
    return this.appService.app;
  }

  @action
  setInstallationMethod(method: INSTALLATION_METHOD): void {
    this.args.onSelectInstallationMethod(method);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'idv_installation_method',
      metadata: {
        item: method.replaceAll('-', '_'),
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  onSelectAppType(appType: HMAC_APP_TYPES) {
    this.selectedAppType = appType;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'idv_app_type',
      metadata: {
        item: appType.replaceAll('-', '_'),
        platform: 'web',
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  onSelectUserIdentifier(userIdentifier: USER_IDENTIFIER_VALUES) {
    this.selectedUserIdentifier = userIdentifier;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'idv_identification_method',
      metadata: {
        item: userIdentifier,
        platform: 'web',
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  onSelectProgrammingLanguage(language: PROGRAMMING_LANGUAGES) {
    this.selectedProgrammingLanguage = language;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'code_snippet_language',
      metadata: {
        item: language,
        context: 'idv',
        platform: 'web',
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::AccordionContent': typeof AccordionContent;
    'installation-new/web/idv-setup/accordion-content': typeof AccordionContent;
  }
}
