/* import __COLOCATED_TEMPLATE__ from './deals-influenced-table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import ParseMultiSignal from 'embercom/lib/reporting/multi-signal-parsing';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import moment from 'moment-timezone';
import ResolveAllPromises from 'embercom/lib/resolve-all-promises';
import { gt } from '@ember/object/computed';

const TOP_PERFORMERS_COUNT = 5;

export default Component.extend({
  store: service(),
  intl: service(),
  appService: service(),
  normalized: [],
  selected: [],
  get allColumns() {
    return [
      {
        label: this.intl.t('components.inbox.reporting.deals-influenced-table.deal'),
        propertyName: 'opportunity_name',
        canExpandToFit: true,
        valueComponent: 'inbox/reporting/performance-table-fields/business',
      },
      {
        label: this.intl.t('components.inbox.reporting.deals-influenced-table.conversations'),
        propertyName: 'conversations_created',
      },
      {
        label: this.intl.t('components.inbox.reporting.deals-influenced-table.response-time'),
        propertyName: 'median_first_response_time',
        valueComponent: 'inbox/reporting/performance-table-fields/minutes',
        description: this.intl.t(
          'components.inbox.reporting.deals-influenced-table.response-time-tooltip',
        ),
        maxWidth: 100,
      },
      {
        label: this.intl.t('components.inbox.reporting.deals-influenced-table.time-to-close'),
        propertyName: 'time_diff',
        valueComponent: 'inbox/reporting/performance-table-fields/minutes',
        maxWidth: 100,
      },
      {
        label: this.intl.t('components.inbox.reporting.deals-influenced-table.date-closed'),
        propertyName: 'opportunity_closed_at',
        valueComponent: 'inbox/reporting/performance-table-fields/date',
      },
      {
        label: this.intl.t('components.inbox.reporting.deals-influenced-table.deal-worth'),
        propertyName: 'closed_won',
        valueComponent: 'inbox/reporting/performance-table-fields/currency',
      },
    ];
  },

  columns: computed('intl.locale', 'filteredByAdmin', function () {
    let adminColumn = {
      label: this.intl.t('components.inbox.reporting.deals-influenced-table.owner'),
      propertyName: 'person',
      valueComponent: 'inbox/reporting/performance-table-fields/admin-firstname',
    };
    let cols = this.allColumns.slice();
    if (!this.filteredByAdmin) {
      cols.splice(1, 0, adminColumn);
    }
    return cols;
  }),

  async formatRow(row) {
    let person = {};
    if (!this.filteredByAdmin) {
      person = await this.store.findRecord('admin', row.owner_id);
    }
    let time_diff = (moment(row.opportunity_closed_at) - moment(row.opportunity_created_at)) / 1000;
    return {
      ...row,
      time_diff,
      person,
    };
  },

  didReceiveAttrs() {
    this._super(...arguments);
    this.setUpRows.perform();
  },

  setUpRows: task(function* () {
    let current = this.get('signal.current.context');
    let previous = this.get('signal.previous.context');
    let merged = ParseMultiSignal(current, previous);
    let formattedRows = merged.map((row) => this.formatRow(row));
    Promise.all(ResolveAllPromises(formattedRows)).then((resolved) => {
      this.set(
        'normalized',
        resolved.filter((r) => r.resolved).map((r) => r.value),
      );
    });
    yield;
  }).restartable(),

  topPages: computed('normalized', function () {
    return this.normalized.slice(0, TOP_PERFORMERS_COUNT);
  }),

  hasAdditionalSelections: gt('additionalSelectionOptions.firstObject.items.length', 0),

  additionalSelectionOptions: computed('topPages', 'normalized', function () {
    let top = this.topPages;
    let fields = this.normalized.filter((row) => !top.includes(row));
    return [
      {
        items: fields.map((row) => {
          return {
            text: row.opportunity_name,
            value: row,
          };
        }),
      },
    ];
  }),

  rows: computed('topPages', 'selected', function () {
    let top = this.topPages;
    let additionalRows = this.selected;
    return top.concat(additionalRows);
  }),

  customOfficeHoursTooltipText: computed(
    'intl.locale',
    'appService',
    'intl',
    'settings',
    function () {
      let defaultTooltip = this.intl.t('apps.app.reports.lead-generation.sales.deals-tooltip');
      let officeHoursTooltip = this.intl.t(
        'components.reporting.flexible.chart-header-custom-tooltip.office-hours-tooltip.tooltip-description',
        { includeOfficeHours: this.settings.reportingExcludeOooHours },
      );
      return this.intl.t(
        'components.reporting.reports.effectiveness.office-hours.tooltip-with-office-hours',
        { defaultTooltip, officeHoursTooltip, htmlSafe: true },
      );
    },
  ),
});
