/* import __COLOCATED_TEMPLATE__ from './read-only-value.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    value: any;
  };
}

const ReadOnlyValue = templateOnlyComponent<Signature>();
export default ReadOnlyValue;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ReadOnlyValue': typeof ReadOnlyValue;
    'inbox2/read-only-value': typeof ReadOnlyValue;
  }
}
