/* import __COLOCATED_TEMPLATE__ from './article-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import type CommandKService from 'embercom/services/command-k';
import type InboxState from 'embercom/services/inbox-state';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { type Block, type Heading } from '@intercom/interblocks.ts';
import { tracked } from '@glimmer/tracking';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import type ArticlesApi from 'embercom/services/articles-api';
import { type AugmentedHelpCenterData } from 'embercom/services/articles-api';
import type CustomIntlService from 'embercom/services/intl';

export interface Args {
  content: KnowledgeBaseContent;
  setActiveTranslation: (content: KnowledgeBaseContent) => void;
}

interface Signature {
  Args: Args;
}

type HeadingLink = {
  id: string | null;
  name: string;
};

type HelpCenterOption = {
  id: string | null;
  name: string;
};

type LocaleOption = {
  id: string;
  name: string;
};

function isHeadingBlock(block: Block): block is Heading {
  return (
    block.type === 'heading' ||
    block.type === 'subheading' ||
    block.type === 'subheading3' ||
    block.type === 'subheading4'
  );
}

export default class ArticleInserter extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare inboxState: InboxState;
  @service declare articlesApi: ArticlesApi;
  @service declare intl: CustomIntlService;
  @service declare intercomEventService: any;

  defaultHeading: HeadingLink | null = null;

  @tracked heading: HeadingLink | null = this.defaultHeading;
  @tracked helpCenterId: string | null = null;
  @tracked locale = this.args.content?.locale || 'en';
  @tracked activeContent = this.args.content;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.defaultHeading = {
      id: 'default',
      name: this.intl.t('inbox.knowledge-base.article-inserter.default-heading'),
    };
  }

  get localeOptions(): LocaleOption[] {
    return this.allTranslations.map((t) => {
      return {
        id: t.locale,
        name: t.locale.toUpperCase(),
      };
    });
  }

  get localeText() {
    return this.locale.toUpperCase();
  }

  @use helpCentersLoader = AsyncData<AugmentedHelpCenterData[]>(async () => {
    let helpCenters = await this.articlesApi.helpCenters(this.args.content.articleId, true);
    this.helpCenterId = helpCenters[0]?.id;
    return helpCenters;
  });

  @action insertArticle() {
    if (this.args.content && this.args.content.articleId) {
      // Please do not copy this pattern, reach out to #team-help-desk-experience if you need to do something similar.
      let action = new ChildAction({
        id: this.args.content.articleId,
        // @ts-ignore
        parent: {
          id: 'insert-article',
        },
        trackingData: {
          help_center_id: this.helpCenterId,
        },
      });

      let metadata = {
        heading: {
          id: this.heading?.id,
          title: this.heading?.name,
        },
        locale: this.locale,
      };
      this.intercomEventService.trackAnalyticsEvent({
        action: 'insert_article',
        object: 'inbox_knowledge_base',
        article_id: this.args.content.articleId,
        help_center_id: this.helpCenterId,
        conversation_id: this.inboxState.activeConversationId,
      });

      this.commandK.triggerAction(action, metadata);
    }
  }

  get allTranslations(): KnowledgeBaseContent[] {
    return [this.args.content].concat(this.args.content.siblings);
  }

  @action
  onSelectLocale(locale: LocaleOption) {
    this.locale = locale.id;

    let newTranslation =
      this.allTranslations.find((c) => c.locale === locale.id) || this.args.content;
    this.args.setActiveTranslation(newTranslation);
    this.activeContent = newTranslation;
    this.heading = this.defaultHeading;
  }

  @action
  onSelectHeading(heading: HeadingLink) {
    document.querySelector(`#${heading.id}`)?.scrollIntoView({ behavior: 'smooth' });

    this.heading = heading;
  }

  @action
  onSelectHelpCenter(hc: HelpCenterOption) {
    this.helpCenterId = hc.id;
  }

  get selectedHelpCenter() {
    return this.helpCenterId || this.inboxState.activeConversationHelpCenterId;
  }

  get helpCenterOptions(): HelpCenterOption[] {
    return (
      this.helpCentersLoader.value?.map((hc) => ({
        id: hc.id,
        name: hc.name,
      })) || []
    );
  }

  get selectedHelpCenterName() {
    return this.helpCenterOptions.find((hc) => hc.id === this.selectedHelpCenter)?.name;
  }

  get contentHeadings() {
    return this.activeContent.blocks.filter(isHeadingBlock);
  }

  get headingOptions(): HeadingLink[] {
    let options =
      this.contentHeadings?.map((b) => ({
        id: b.idAttribute || null,
        name: htmlToTextContent(b.text),
      })) || [];

    return this.defaultHeading ? [this.defaultHeading, ...options] : options;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::ArticleInserter': typeof ArticleInserter;
    'inbox2/knowledge-base/article-inserter': typeof ArticleInserter;
  }
}
