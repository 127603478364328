/* import __COLOCATED_TEMPLATE__ from './conversations-table.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';

class Column {
  readonly id: string;
  readonly label: string;
  readonly type: string;
  readonly minWidth?: number;
  readonly width?: number;

  constructor({
    id,
    label,
    type,
    width,
    minWidth,
  }: {
    id: string;
    label: string;
    type: string;
    width?: number;
    minWidth?: number;
  }) {
    this.id = id;
    this.label = label;
    this.type = type;
    this.width = width;
    this.minWidth = minWidth;
  }

  get style() {
    if (!this.width && !this.minWidth) {
      return;
    }

    let style = '';
    if (this.width) {
      style += `width: ${this.width}px;`;
    }

    if (this.minWidth) {
      style += `min-width: ${this.minWidth}px;`;
    }

    return style;
  }
}

interface Args {
  additionalClass?: string;
}

interface Signature {
  Args: Args;
}

export default class ConversationsTable extends Component<Signature> {
  readonly columns = [
    new Column({ id: 'userSummary', label: 'User', type: 'user', width: 160, minWidth: 128 }),
    new Column({ id: 'company', label: 'Company', type: 'company', width: 192, minWidth: 128 }),
    new Column({ id: 'title', label: 'Subject', type: 'subject', width: 256, minWidth: 128 }),
    new Column({ id: 'priority', label: 'Priority', type: 'priority', width: 96 }),
    new Column({ id: 'started', label: 'Started', type: 'date' }),
    new Column({ id: 'waiting_since', label: 'Waiting since', type: 'date' }),
    new Column({ id: 'lastRenderableSummaryPart.createdAt', label: 'Last updated', type: 'date' }),
    new Column({ id: 'sla', label: 'SLA', type: 'sla', width: 96 }),
    new Column({ id: 'state', label: 'Status', type: 'state', width: 96 }),
  ];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationsTable': typeof ConversationsTable;
    'inbox2/onboarding/conversations-table': typeof ConversationsTable;
  }
}
