/* import __COLOCATED_TEMPLATE__ from './team-assignee-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';

interface Args {
  teamsAssigned?: TeamSummary[] | undefined;
  onSelect: (team: TeamSummary | null) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SearchFiltersTeamAssigneeSelector extends Component<Signature> {
  @service declare session: Session;

  readonly quickSearchEntityType = SearchableType.Team;

  get defaultTeamAssignees() {
    return [TeamSummary.unassigned];
  }

  get teamIds() {
    return this.args.teamsAssigned?.map((team) => team.id) ?? [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::TeamAssigneeSelector': typeof SearchFiltersTeamAssigneeSelector;
  }
}
