/* import __COLOCATED_TEMPLATE__ from './close-button-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export const CONVERSATION_CLOSE_SERVICE_CHECK_ID = 'CloseButtonComponent::EmptyRequiredAttributes';

export default class CloseButtonComponent extends Component {
  @service appService;
  @service store;
  @service notificationsService;
  @service conversationsService;
  @service conversationCloseService;
  @service intercomEventService;
  @tracked shouldShowPopover = false;
  @tracked requiredAttributesWithEmptyValue = [];

  get app() {
    return this.appService.app;
  }

  get conversation() {
    return this.args.conversation;
  }

  get canCloseConversation() {
    return !this.args.conversation.hasRequiredAttributesWithEmptyValue;
  }

  get showPopover() {
    return this.shouldShowPopover;
  }

  closeConversation() {
    this.conversationsService.closeConversation(this.conversation);
  }

  @action register(popover) {
    this.conversationCloseService.registerCheck(CONVERSATION_CLOSE_SERVICE_CHECK_ID, {
      doCheck: (convo) =>
        !(
          convo.id === this.conversation.id && this.conversation.hasRequiredAttributesWithEmptyValue
        ),
      onError: () => popover.show(),
    });
  }

  @action showPopoverOrCloseConversation() {
    if (this.canCloseConversation) {
      this.closeConversation();
    } else {
      this.requiredAttributesWithEmptyValue = [];
      this.requiredAttributesWithEmptyValue = this.conversation.requiredAttributesWithEmptyValue;
      this.shouldShowPopover = true;
    }
  }

  @action save() {
    this.requiredAttributesWithEmptyValue.forEach(this.saveValue);
    this.shouldShowPopover = false;
  }

  @action saveAndClose() {
    this.save();
    this.closeConversation();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'conversation',
      place: 'inbox',
      context: 'from_required_attributes_popover',
      required_attributes: true,
    });
  }

  saveValue = async ({ descriptor, value }) => {
    try {
      if (value.hasDirtyAttributes && !value.isEmpty(descriptor)) {
        value.normalizeValue(descriptor);
        await value.save();
      }
    } catch (e) {
      this.notificationsService.notifyError(
        `An error occurred while saving the attribute ${descriptor.name}: Closing conversation.`,
      );
      value.value = '';
    }
  };

  willDestroy() {
    super.willDestroy();
    this.conversationCloseService.deleteCheck(CONVERSATION_CLOSE_SERVICE_CHECK_ID);
  }
}
