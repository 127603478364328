/* import __COLOCATED_TEMPLATE__ from './datetime.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  value: string | null;
  onUpdateValue: (value: unknown) => unknown;
  onFinishEditing: () => unknown;
}

interface Signature {
  Args: Args;
}

export default class Datetime extends Component<Signature> {
  // Unsure why / whether we need appService for this
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare appService: any;

  @tracked _date: any = this.valueAsMoment;
  @tracked _hour: number = this.valueAsMoment.hour();
  @tracked _minute: number = this.valueAsMoment.minute();

  get timezone() {
    return this.appService.app.timezone;
  }

  get selectedValue() {
    let { value } = this.args;
    if (value === '' || value === null) {
      return undefined;
    }

    return value;
  }

  get valueAsMoment() {
    if (this.selectedValue) {
      return moment(this.selectedValue);
    } else {
      return moment();
    }
  }

  set minute(minute: string) {
    this._minute = Number(minute);
  }

  get minute() {
    return String(this._minute).padStart(2, '0');
  }

  set hour(hour: string) {
    this._hour = Number(hour);
  }

  get hour() {
    return String(this._hour).padStart(2, '0');
  }

  set selectedDate(date: any) {
    this._date = date.moment;
  }

  get selectedDate() {
    return this._date;
  }

  get displayDateTime() {
    let currentlySelectedDate = this.selectedDate;
    currentlySelectedDate.set({
      hour: this._hour,
      minute: this._minute,
    });
    return currentlySelectedDate;
  }

  get hourSelectItems() {
    return [...Array(24).keys()].map((idx) => ({
      text: idx.toString().padStart(2, '0'),
      value: idx.toString().padStart(2, '0'),
    }));
  }

  get minuteSelectItems() {
    return [...Array(60).keys()].map((idx) => ({
      text: idx.toString().padStart(2, '0'),
      value: idx.toString().padStart(2, '0'),
    }));
  }

  @action
  save(hide: () => void) {
    let changeAtCopy = this._date;
    changeAtCopy.set({
      hour: this._hour,
      minute: this._minute,
    });
    this.updateAttribute(changeAtCopy.toDate());
    hide();
  }

  @action
  clear(hide: () => void) {
    this.args.onUpdateValue(null);
    hide();
  }

  @action
  reset() {
    let resetValue = this.args.value;
    let resetMoment = resetValue ? moment(resetValue) : moment();

    this._date = resetMoment;
    this._hour = resetMoment.hour();
    this._minute = resetMoment.minute();
  }

  @action
  updateAttribute(date: Date) {
    this.args.onUpdateValue(date.toISOString());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::Datetime': typeof Datetime;
    'inbox2/ticket-attribute/datetime': typeof Datetime;
  }
}
