/* import __COLOCATED_TEMPLATE__ from './input-attribute-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { bind } from '@ember/runloop';
import $ from 'jquery';
import { observer, action } from '@ember/object';
import { filterBy, filter } from '@ember/object/computed';
import { A } from '@ember/array';
import EmberObject, { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  appAttributes: computed(function () {
    return A([
      EmberObject.create({
        name: 'Author name',
        identifier: 'message.author.first_name',
        templatable_identifier: 'message.author.first_name',
        noFallback: true,
      }),
      EmberObject.create({
        name: 'Author full name',
        identifier: 'message.author.name',
        templatable_identifier: 'message.author.name',
        noFallback: true,
      }),
      EmberObject.create({
        name: 'App name',
        identifier: 'app.name',
        templatable_identifier: 'app.name',
        noFallback: true,
      }),
    ]);
  }),

  classNames: ['input-attribute-selector__selector', 'js__input-attribute-selector'],
  classNameBindings: ['isHovered:o__hover'],
  attributesAreVisible: false,

  templatableAttributes: filterBy('attributes', 'templatable', true),
  userAttributes: filter('templatableAttributes', function (attribute) {
    return attribute.get('isUser') && !attribute.get('isUserEvent');
  }),
  companyAttributes: filterBy('templatableAttributes', 'isCompany'),
  eventAttributes: computed('templatableAttributes', function () {
    let processedEventAttributes = [];
    this.templatableAttributes.filterBy('isUserEvent').forEach(function (item) {
      let itemData = item.toJSON();
      processedEventAttributes.pushObject(this._duplicateEventAttribute(itemData, 'count'));
      processedEventAttributes.pushObject(this._duplicateEventAttribute(itemData, 'first'));
      processedEventAttributes.pushObject(this._duplicateEventAttribute(itemData, 'last'));
    }, this);

    return processedEventAttributes;
  }),

  selectedEventMetadataIdentifiers: computed('selectedEvents.[]', function () {
    if (!this.selectedEvents) {
      return [];
    }

    return this.selectedEvents.reduce((eventIdentifiers, selectedEvent) => {
      let selectedEventMetadata = selectedEvent.metadata || [];
      return eventIdentifiers.concat(selectedEventMetadata.map((metadata) => metadata.identifier));
    }, []);
  }),

  selectedEventMetadataAttributes: computed(
    'selectedEventMetadataIdentifiers.[]',
    'attributes.[]',
    function () {
      let metadata = [];

      if (!this.selectedEventMetadataIdentifiers) {
        return metadata;
      }

      this.attributes.forEach((attribute) => {
        if (this.selectedEventMetadataIdentifiers.includes(attribute.identifier)) {
          metadata.pushObject(attribute);
        }
      });

      return metadata;
    },
  ),

  shownStateChanged: observer({
    dependentKeys: ['attributesAreVisible'],

    fn() {
      if (this.attributesAreVisible) {
        $('body').on(`mousedown.${this.elementId}`, bind(this, this.bodyClicked));
      } else {
        $('body').off(`mousedown.${this.elementId}`);
      }
    },

    sync: true,
  }),

  _duplicateEventAttribute(itemData, type) {
    let itemToDuplicate = EmberObject.create(itemData);
    itemToDuplicate.set(
      'templatable_identifier',
      `${itemToDuplicate.get('templatable_identifier')}.${type}`,
    );
    itemToDuplicate.set('name', `${itemToDuplicate.get('name').replace(/_|\-/g, ' ')} ${type}`);
    return itemToDuplicate;
  },

  bodyClicked(e) {
    if (!$(this.element).parent().find(e.target).length) {
      this.set('attributesAreVisible', false);
    }
  },

  willDestroyElement() {
    $('body').off(`mousedown.${this.elementId}`);
    this._super(...arguments);
  },

  mouseEnter() {
    this.set('isHovered', true);
  },

  mouseLeave() {
    this.set('isHovered', false);
  },

  addAttribute: action(function (attribute) {
    this.onAddAttribute(attribute);
    this.set('attributesAreVisible', false);
  }),

  actions: {
    showAttributeSelector() {
      this.set('attributesAreVisible', true);
    },
  },
});
