/* import __COLOCATED_TEMPLATE__ from './edit-assignment-limit.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class EditAssignmentLimit extends Component {
  @service appService;
  @service notificationsService;
  @service intl;
  @service permissionsService;
  @service intercomEventService;
  @service intercomConfirmService;
  @service modelDataCacheService;

  @tracked assignmentLimit = '';
  @tracked selectedTeammates = [];
  @tracked valueIsModified = false;
  @tracked showUpdateNumber = false;
  @tracked popoverOpen = false;

  get cantChangeAssignmentLimit() {
    return !this.permissionsService.currentAdminCan('can_manage_workload_management');
  }

  @action popoverClosed() {
    this.showUpdateNumber = false;
    this.popoverOpen = false;
  }

  @action
  prepareUpdate() {
    this.resetState();

    this.selectedTeammates = this.args.teammates.filter((teammate) => {
      return teammate.isSelected;
    });

    this.assignmentLimit = '';
  }

  get assignmentLimitInvalid() {
    return (
      !this.assignmentLimit ||
      parseInt(this.assignmentLimit, 10) > 1_000_000 ||
      parseInt(this.assignmentLimit, 10) < 0
    );
  }

  get bulkUpdateAllowed() {
    let selectedTeammatesCount = this.args.teammates.filter((teammate) => {
      return teammate.isSelected;
    }).length;
    return selectedTeammatesCount <= this.args.bulkUpdateLimit;
  }

  @action
  async pushUpdates() {
    if (!(await this.askConfirmation())) {
      return;
    }

    try {
      let teammates = this.selectedTeammates.map((teammate) => teammate.id);
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammate-workload.assignment-limit.updating', {
          count: teammates.length,
        }),
        ENV.APP._1M * 60,
      ); // 1 hour

      let assignmentLimit =
        this.assignmentLimit !== `${this.args.workspaceCapacityLimit}`
          ? this.assignmentLimit
          : null;
      await post(`/ember/admins/change_assignment_limit`, {
        assignment_limit: assignmentLimit,
        admin_ids: teammates,
        id: this.appService.app.currentAdmin.id,
        app_id: this.appService.app.id,
      });

      this.selectedTeammates.forEach((teammate) => {
        teammate.workload_management_settings = {
          assignment_limit: assignmentLimit ? parseInt(assignmentLimit, 10) : null,
        };
      });

      this.notificationsService.clear();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammate-workload.assignment-limit.updated', {
          count: teammates.length,
        }),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'assignment_limit',
        place: 'teammate_workload',
        teammate_ids: teammates,
        selected_teammate_count: teammates.length,
        assignment_limit: assignmentLimit,
      });

      this.resetState();
    } catch (error) {
      this.notificationsService.clear();
      if (error.jqXHR) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
      }
    } finally {
      this.appService.app.updateLocalCache(this.modelDataCacheService);
    }
  }

  async askConfirmation() {
    let backToWorkspaceLimit =
      this.assignmentLimit === null ||
      parseInt(this.assignmentLimit, 10) === this.args.workspaceCapacityLimit;
    return await this.intercomConfirmService.confirm({
      title: this.intl.t('settings.teammate-workload.assignment-limit.confirmation.title'),
      body: sanitizeHtml(
        backToWorkspaceLimit
          ? this.intl.t('settings.teammate-workload.assignment-limit.confirmation.body-revert', {
              workspaceLimit: this.args.workspaceCapacityLimit,
              teammateCount: this.selectedTeammates.length,
            })
          : this.intl.t('settings.teammate-workload.assignment-limit.confirmation.body', {
              assignmentLimit: this.assignmentLimit,
              teammateCount: this.selectedTeammates.length,
            }),
      ),
      cancelButtonText: this.intl.t(
        'settings.teammate-workload.assignment-limit.confirmation.cancel',
      ),
      confirmButtonText: this.intl.t(
        'settings.teammate-workload.assignment-limit.confirmation.confirm',
      ),
    });
  }

  resetState() {
    this.assignmentLimit = '';
    this.valueIsModified = false;
  }
}
