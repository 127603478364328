/* import __COLOCATED_TEMPLATE__ from './ticket-header-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';

interface Args {
  conversation: Conversation;
  highlights?: TableEntryHighlights;
}

interface Signature {
  Args: Args;
}

export default class Inbox2TicketHeaderContentComponent extends Component<Signature> {
  get ticketTitleHighlights() {
    return this.args.highlights?.ticketTitle;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketHeaderContent': typeof Inbox2TicketHeaderContentComponent;
    'inbox2/ticket-header-content': typeof Inbox2TicketHeaderContentComponent;
  }
}
