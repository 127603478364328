/* import __COLOCATED_TEMPLATE__ from './inbox-order-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {
  teams: any[];
  settings: any[];
  onClose: () => void;
}

export default class InboxOrderSideDrawer extends Component<Args> {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;

  @tracked initialOrder: any = {};
  @tracked currentOrder: any = {};

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setInitialOrder();
    this.currentOrder = { ...this.initialOrder };
  }

  get teamSettings() {
    let allTeamSettings = this.args.teams.map((team) => {
      let setting = this.args.settings.find((s) => s.teamId === team.id);

      if (!setting || !team || setting.distributionMethod !== 'load_balanced') {
        return null;
      }

      return {
        team,
        setting,
      };
    });

    return allTeamSettings.filter((teamSetting) => teamSetting !== null);
  }

  get sortedInboxes() {
    let currentOrder = this.currentOrder;
    return this.teamSettings.sort(function (a: any, b: any) {
      if (currentOrder[a.team.id] < currentOrder[b.team.id]) {
        return -1;
      }

      return 1;
    });
  }

  get isOrderUpdated() {
    let updated = false;
    this.teamSettings.forEach((teamSetting: any) => {
      if (this.initialOrder[teamSetting.team.id] !== this.currentOrder[teamSetting.team.id]) {
        updated = true;
      }
    });
    return updated;
  }

  private setInitialOrder() {
    let order: any = {};
    order = this.teamSettings.sort(function (a: any, b: any) {
      if (a.setting.order < b.setting.order) {
        return -1;
      }

      return 1;
    });
    order.forEach((teamSetting: any, index: number) => {
      this.initialOrder[teamSetting.team.id] = index;
    });
  }

  @action
  async onClose() {
    if (this.isOrderUpdated) {
      let options = {
        title: this.intl.t('settings.workload-management.unsaved-changes'),
        body: this.intl.t('settings.workload-management.unsaved-changes-discard'),
        confirmButtonText: this.intl.t('settings.workload-management.discard'),
      };

      let confirmed = await this.intercomConfirmService.confirm(options);
      if (!confirmed) {
        return;
      }
    }
    this.args.onClose();
  }

  @action
  reorderInboxes(inboxes: any) {
    this.currentOrder = {};
    inboxes.forEach((inbox: any, index: number) => {
      this.currentOrder[inbox.team.id] = index;
    });
  }

  @action
  async save() {
    try {
      this.teamSettings.forEach((teamSetting: any) => {
        this.store.pushPayload({
          'team-setting': {
            ...teamSetting.setting,
            id: teamSetting.setting.id,
            order: this.currentOrder[teamSetting.team.id],
          },
        });
      });

      await ajax({
        url: `/ember/inbox/workload_management/team_settings/reorder`,
        type: 'PUT',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          order: this.sortedInboxes.map((x: any) => x.team.id),
        }),
      });

      this.initialOrder = this.currentOrder;

      this.intercomEventService.trackAnalyticsEvent({
        action: 'order-changed',
        object: 'inbox-priority-order',
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.workload-management.inbox-order-drawer.success'),
      );
    } catch (error) {
      if (error.jqXHR.status === 403) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.workload-management.inbox-order-drawer.error'),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::InboxOrderSideDrawer': typeof InboxOrderSideDrawer;
    'inbox/workload-management/inbox-order-side-drawer': typeof InboxOrderSideDrawer;
  }
}
