/* import __COLOCATED_TEMPLATE__ from './theme-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import Theme from 'embercom/objects/inbox/types/theme';

interface Args {
  item: {
    componentAttrs: {};
  };
}

interface Signature {
  Args: Args;
}

export default class ThemeSwitcherPopover extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  get modes() {
    return [
      {
        name: this.intl.t('inbox.user-menu.light-theme'),
        value: Theme.Light,
      },
      {
        name: this.intl.t('inbox.user-menu.dark-theme'),
        value: Theme.Dark,
      },
      {
        name: this.intl.t('inbox.user-menu.system-theme'),
        value: Theme.System,
      },
    ];
  }

  get modesLabel() {
    switch (this.session.theme) {
      case Theme.Light:
        return this.intl.t('inbox.user-menu.light-theme');
      case Theme.Dark:
        return this.intl.t('inbox.user-menu.dark-theme');
      case Theme.System:
      default:
        return this.intl.t('inbox.user-menu.system-theme');
    }
  }

  // This function is a Popper.js modifier (https://popper.js.org/docs/v2/modifiers/)
  // that keeps the dimensions of the app switcher popover the same as those of
  // the primary nav menu popover. This behavior is a design requirement from
  // the "early 2018" brand refresh.
  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }

  @action selectTheme(theme: string) {
    this.session.setTheme(theme as Theme);
    this.trackChangeTheme();
  }

  private trackChangeTheme() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed',
      object: 'theme',
      section: 'admin_details',
      selected: this.session.darkModeEnabled,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ThemeSwitcher': typeof ThemeSwitcherPopover;
    'inbox2/user-menu/theme-switcher': typeof ThemeSwitcherPopover;
  }
}
