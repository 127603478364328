/* import __COLOCATED_TEMPLATE__ from './custom.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type InboxFolder, InboxFolderObject } from 'embercom/objects/inbox/inbox-folder';
import storage from 'embercom/vendor/intercom/storage';
import { action } from '@ember/object';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import View from 'embercom/objects/inbox/inboxes/view';
import Admin from 'embercom/objects/inbox/inboxes/admin';

interface Args {
  inboxFolder: InboxFolder;
  isHoverable: boolean;
  updateCustomFolder: (inboxFolder: InboxFolder) => Promise<void>;
  onCreateView: (footer: any) => void;
  onEditView: (row: ViewSummary, hidePopover: Function | null) => void;
  customFolders?: InboxFolder[];
  togglePin: () => void;
  onDelete: () => void;
  onCreateCustomFolder: () => void;
  onEditCustomFolder: (inboxFolder: InboxFolder) => void;
}

interface Signature {
  Args: Args;
}

export default class Custom extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare intercomEventService: any;

  @tracked openSectionId: string | undefined;
  @tracked isHovering = false;

  constructor(owner: any, args: Args) {
    super(owner, args);

    let label = `inbox.inbox-list.custom-folder-accordion-${args.inboxFolder.id}`;

    if (storage.get(label) === null || storage.get(label) === true) {
      this.openSectionId = 'section_a';
    } else {
      this.openSectionId = undefined;
    }
  }

  get inboxFolderObjects(): InboxFolderObject[] {
    // Filter out removed admins from the list
    return this.args.inboxFolder.objects.filter((object) => {
      if (object.inbox instanceof Admin) {
        return object.inbox.adminSummary.removed !== true;
      }
      return true;
    });
  }

  get isProductIAEnabled() {
    return this.session.hasOptedInForProductIa;
  }

  @action onOpenSectionChange(sectionId: string) {
    if (sectionId === 'section_a') {
      this.openSectionId = sectionId;
      storage.set(`inbox.inbox-list.custom-folder-accordion-${this.args.inboxFolder.id}`, true);
    } else {
      this.openSectionId = undefined;
      storage.set(`inbox.inbox-list.custom-folder-accordion-${this.args.inboxFolder.id}`, false);
    }
  }

  @action async addPin(inbox: Inbox): Promise<void> {
    let newInboxFolderObject = new InboxFolderObject(
      inbox.type,
      parseInt(inbox.id, 10),
      this.args.inboxFolder.objects.length,
      inbox,
    );

    let inboxFolderObjects = [...this.args.inboxFolder.objects];
    inboxFolderObjects.push(newInboxFolderObject);

    let updatedInboxFolder = Object.assign({}, this.args.inboxFolder, {
      objects: inboxFolderObjects,
    });

    await this.args.updateCustomFolder(updatedInboxFolder);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'pinned',
      object: 'inbox',
      selected_inbox: inbox.type,
      selected_inbox_id: inbox.id,
      view_type: inbox instanceof View ? inbox.viewSummary.systemType : undefined,
      inbox_folder_id: this.args.inboxFolder.id,
    });
  }

  @action async removePin(inbox: Inbox): Promise<void> {
    let inboxFolderObjects = this.args.inboxFolder.objects
      .reject((inboxFolderObject) => inboxFolderObject.objectId.toString() === inbox.id)
      .map((object, index) => {
        return Object.assign({}, object, { position: index });
      });

    let updatedInboxFolder = Object.assign({}, this.args.inboxFolder, {
      objects: inboxFolderObjects,
    });

    await this.args.updateCustomFolder(updatedInboxFolder);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'unpinned',
      object: 'inbox',
      selected_inbox: inbox.type,
      selected_inbox_id: inbox.id,
      view_type: inbox instanceof View ? inbox.viewSummary.systemType : undefined,
      inbox_folder_id: this.args.inboxFolder.id,
    });
  }

  @action async reorderInboxes(
    objects: InboxFolderObject[],
    draggedObject: InboxFolderObject,
  ): Promise<void> {
    let objectsWithUpdatedPosition = objects.map((object, index) => {
      return Object.assign({}, object, { position: index });
    });

    let updatedInboxFolder = Object.assign({}, this.args.inboxFolder, {
      objects: objectsWithUpdatedPosition,
    });

    await this.args.updateCustomFolder(updatedInboxFolder);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'reordered',
      object: 'inboxes',
      inbox_type: draggedObject.inbox.type,
      inbox_folder_id: this.args.inboxFolder.id,
    });
  }

  @action trackInboxHover(object: InboxFolderObject) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hover',
      object: 'inbox',
      inbox_type: object.inbox.type,
      inbox_folder_id: this.args.inboxFolder.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Custom': typeof Custom;
  }
}
