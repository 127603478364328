/* import __COLOCATED_TEMPLATE__ from './redact-part.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class RedactPartModal extends Component {
  @service intercomEventService;
  @service conversationsService;
  @service appService;
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  @service modalService;

  @action
  closeModal() {
    this.modalService.closeModal();
  }

  @action
  redactPart() {
    let part = this.args.model.part;
    this.conversationsService.redactPart(part);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: part,
      isNote: part.isNote,
    });
    this.closeModal();
  }

  @action
  cancel() {
    this.closeModal();
  }
}
