/* import __COLOCATED_TEMPLATE__ from './manual.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

const Manual = templateOnlyComponent<Signature>();
export default Manual;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Ios::Steps::Manual': typeof Manual;
    'installation-new/mobile/initial-setup/ios/steps/manual': typeof Manual;
  }
}
