/* import __COLOCATED_TEMPLATE__ from './mentions-filters.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { InboxMentionsStatus } from 'embercom/models/data/inbox/inbox-filters';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

interface Arguments {
  selectedMentionsStatus?: InboxMentionsStatus;
  onMentionsStatusChanged?: (status: InboxMentionsStatus) => void;
  selectedLabelPrefix?: number;
}

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

export default class Inbox2LeftNavConversationListMentionsFilters extends Component<Signature> {
  @service declare intl: IntlService;

  get items() {
    this.intl.primaryLocale;

    return Object.values(InboxMentionsStatus).map((option) => {
      return {
        text: this.intl.t(`inbox.conversation-filters.mentions-status.${option}`),
        value: option,
        isSelected: option === this.args.selectedMentionsStatus,
      };
    });
  }

  @action select(value: InboxMentionsStatus) {
    this.args.onMentionsStatusChanged?.(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationList::MentionsFilters': typeof Inbox2LeftNavConversationListMentionsFilters;
    'inbox2/left-nav/conversation-list/mentions-filters': typeof Inbox2LeftNavConversationListMentionsFilters;
  }
}
