/* import __COLOCATED_TEMPLATE__ from './conversation-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type UserAttributeWithIconLinkParams } from '../../conversation-details-sidebar/sidebar-sections/user-details';

interface Signature {
  Element: HTMLDivElement;
  Args: SidebarSectionComponentArgs<{
    conversation: Conversation;
    conversationDetailsAttributes: UserAttributeWithIconLinkParams[];
  }>;
}

const ConversationDetails = templateOnlyComponent<Signature>();
export default ConversationDetails;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationDetailsSidebar::ConversationDetails': typeof ConversationDetails;
    'inbox2/onboarding/conversation-details-sidebar/conversation-details': typeof ConversationDetails;
  }
}
