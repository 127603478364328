/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import FormatSelectedValueAsText from 'embercom/helpers/format-selected-value-as-text';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    selectedValue: any;
    items: any[];
    isReadOnly: boolean;
    hasError: boolean;
    onSelectItem: (value: any) => void;
    autofocus: boolean;
  };
}

export default class Select extends Component<Signature> {
  @service declare inboxHotkeys: any;

  // @ts-ignore
  formatSelectedValueAsTextHelper = FormatSelectedValueAsText.create(this);

  get readOnlySelectValue(): string {
    return (
      this.formatSelectedValueAsTextHelper.compute([this.args.selectedValue, this.args.items]) ||
      '-'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::Select': typeof Select;
    'inbox2/ticket-attribute/select': typeof Select;
  }
}
