/* import __COLOCATED_TEMPLATE__ from './onboarding.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import OnboardingState from 'embercom/lib/inbox2/onboarding/state';
import {
  DefaultSteps,
  ShortSteps,
  OnboardingType,
  StepId,
} from 'embercom/lib/inbox2/onboarding/steps';

// @ts-ignore
import { cached } from 'tracked-toolbox';
import { later } from '@ember/runloop';
import { getOwner, setOwner } from '@ember/application';
import ENV from 'embercom/config/environment';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Session from 'embercom/services/session';
import platform from 'embercom/lib/browser-platform';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import type ApplicationInstance from '@ember/application/instance';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { type EmberKeyboardEvent } from 'embercom/lib/inbox2/types';

interface Args {
  type: `${OnboardingType}`;
  activeStepId?: StepId;
  onExit?: () => unknown;
  onFinish: () => unknown;
  onStepChange?: (id: StepId) => unknown;
  showExit?: boolean;
}

interface Signature {
  Args: Args;
}

export default class Onboarding extends Component<Signature> {
  @service declare session: Session;
  @service intercomEventService: any;
  @service declare inboxHotkeys: InboxHotkeys;

  @ref('step-panel') stepPanel?: HTMLDivElement;

  @tracked activeStepIndex = 0;

  // We keep all the state contained within a single object so that it's easy
  // to copy and restore a previous object.
  @tracked state: OnboardingState;

  // History keeps a stack of the state object. Every time you go to the next
  // step, we push to this, and every time you go to the previous step, we pop
  // from it. This ensures we can restore state from a previous step.
  @tracked history: OnboardingState[] = [];

  @tracked didPressRightKeys = false;
  @tracked didPressWrongKeys = false;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    this.state = new OnboardingState(getOwner(this) as any, this.args.type);
    this.history.pushObject(this.state.clone());

    let index = 0;
    if (this.args.activeStepId && Object.values(StepId).includes(this.args.activeStepId)) {
      for (let Step of this.steps) {
        let step = new Step(owner);
        step.enter(this.state);

        if (step.id === this.args.activeStepId) {
          break;
        }

        // Manually trigger all key handlers on that step so that they can update
        // the state correctly
        index += 1;
        step.handleAllKeys(this.state);
        this.history.pushObject(this.state.clone());
      }
    }

    this.activeStepIndex = index;
  }

  get steps() {
    if (this.args.type === OnboardingType.Short) {
      return ShortSteps;
    } else {
      return DefaultSteps;
    }
  }

  @cached
  get activeStep() {
    let Class = this.steps[this.activeStepIndex];
    let instance = new Class(getOwner(this) as ApplicationInstance);
    setOwner(instance, getOwner(this));

    return instance;
  }

  get isFirstStep() {
    return this.activeStepIndex === 0;
  }

  get isLastStep() {
    return this.activeStepIndex === this.steps.length - 1;
  }

  get progress() {
    return (this.activeStepIndex / (this.steps.length - 1)) * 100;
  }

  @action nextStep({
    handleAllKeys,
    usedButton = false,
  }: { handleAllKeys?: boolean; usedButton?: boolean } = {}) {
    let step = this.activeStep;

    if (usedButton) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'next_button',
        place: 'onboarding',
        section: 'onboarding',
        step_index: this.activeStepIndex,
        onboarding_type: this.args.type,
      });
    }

    // If a step has a transition state, we complete it manually, then wait for
    // a second before transitioning to the next step.
    if (step.hasTransitionState) {
      step.complete();
      later(this, this.moveToNextStep, handleAllKeys, ENV.APP._2000MS);
    } else {
      this.moveToNextStep(handleAllKeys);
    }
  }

  @action previousStep({ usedButton = false } = {}) {
    if (usedButton) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'previous_button',
        place: 'onboarding',
        section: 'onboarding',
        step_index: this.activeStepIndex,
        onboarding_type: this.args.type,
      });
    }
    // Last object in history is the current state. So we remove that.
    this.history.popObject();

    // Now, the last state is the previous step's state. That becomes our
    // current state.
    this.state = this.history[this.history.length - 1].clone();
    this.activeStepIndex -= 1;
    this.activeStep.enter(this.state);
    this.args.onStepChange?.(this.activeStep.id);
  }

  @action
  handleKeys(event: KeyboardEvent, kev: EmberKeyboardEvent) {
    // When you press cmd or shift, it sends three events. We can ignore the
    // first two which are just cmd or shift, because we do not have anything
    // that responds to just those keys.

    // If we don't ignore these, we'll set didPressWrongKeys to true which isn't
    // quite accurate in this case!
    if (event.key === 'Meta' || event.key === 'Shift' || event.key === 'Control') {
      return;
    }

    if (!this.activeStep.activeKey) {
      return;
    }

    event.preventDefault();
    kev.stopPropagation();
    kev.stopImmediatePropagation();

    let code = this.activeStep.activeKey.code;
    if (!this.isValidCode(code, event)) {
      taskFor(this.onWrongKeys).perform();
      return;
    }

    this.didPressWrongKeys = false;
    this.didPressRightKeys = true;
  }

  @action handleKeyUp() {
    if (this.didPressRightKeys) {
      this.didPressRightKeys = false;
      this.activeStep.handleKeys(this.state, this.nextStep);
    }
  }

  @action
  onClickInsideContent(event: PointerEvent) {
    // If clicked inside the step panel, that's okay.
    if (
      event.target &&
      event.target instanceof Node &&
      (this.stepPanel?.isSameNode(event.target) || this.stepPanel?.contains(event.target))
    ) {
      return;
    }

    // Clicking inside the onboarding content (i.e. the Inbox) results in the
    // same behavior as pressing the wrong keys.
    taskFor(this.onWrongKeys).perform();
  }

  private moveToNextStep(handleAllKeys?: boolean) {
    if (this.isLastStep) {
      this.args.onFinish();
    } else {
      // If moving to next step manually, i.e. by clicking a button,
      // automatically handle all keys in this step, otherwise the next step's
      // state won't be correct.
      if (handleAllKeys) {
        this.activeStep.handleAllKeys(this.state);
      }

      this.activeStepIndex += 1;
      this.history.pushObject(this.state.clone());
      this.activeStep.enter(this.state);
      this.args.onStepChange?.(this.activeStep.id);
    }
  }

  private isValidCode(code: string, event: KeyboardEvent) {
    let keys = code.split('+');
    let isValid = true;

    keys.forEach((key) => {
      if (key === 'cmd') {
        isValid = this.cmdKey === 'meta' ? !!event.metaKey : !!event.ctrlKey;
      } else if (key === 'shift') {
        isValid = !!event.shiftKey;
      } else {
        // If isValid is already false, we shouldn't set it to true again.
        isValid = isValid && (key.toLowerCase() === event.key.toLowerCase() || key === event.code);
      }
    });

    return isValid;
  }

  private get cmdKey() {
    return platform.isMac ? 'meta' : 'ctrl';
  }

  @task
  private *onWrongKeys() {
    this.didPressWrongKeys = true;

    yield timeout(ENV.APP._500MS);
    this.didPressWrongKeys = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding': typeof Onboarding;
    'inbox2/onboarding': typeof Onboarding;
  }
}
