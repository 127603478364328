/* import __COLOCATED_TEMPLATE__ from './script-tag.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';

interface Args {
  inline: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class ScriptTag extends Component<Signature> {
  constructor(owner: never, args: Args) {
    super(owner, args);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::ScriptTag': typeof ScriptTag;
    'installation-new/web/script-tag': typeof ScriptTag;
  }
}
