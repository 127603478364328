/* import __COLOCATED_TEMPLATE__ from './realtime-subscription.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type NexusEvent, type NexusEventName } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';

/**
 * Subscribe to a topic
 *
 * Does all set-up and teardown for you.
 * Optionally: Listen for an event and handle it,
 * (assuming you've previously subscribed to a topic)
 */

type Args =
  | {
      topic: string;
    }
  | {
      topic?: string;
      events: `${NexusEventName}`[];
      handler: (e: NexusEvent) => void;
    };

interface Signature {
  Args: Args;
  Element: HTMLSpanElement;
}

export default class RealtimeSubscription extends Component<Signature> {
  @service declare nexus: Nexus;
  private currentTopic?: string;
  private handler: (e: NexusEvent) => void;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.handler = this.handle.bind(this);

    if (this.handler && 'events' in this.args) {
      this.args.events.forEach((event) => this.nexus.addListener(event, this.handler));
    }

    registerDestructor(this, () => {
      if (this.currentTopic) {
        this.nexus.unsubscribeTopics([this.currentTopic]);
      }
      if (this.handler && 'events' in this.args) {
        this.args.events.forEach((event) => this.nexus.removeListener(event, this.handler));
      }
    });

    this.updateSubscription();
  }

  @action
  updateSubscription() {
    if (this.currentTopic && this.currentTopic !== this.args.topic) {
      this.nexus.unsubscribeTopics([this.currentTopic]);
      this.currentTopic = undefined;
    }

    if (this.currentTopic !== this.args.topic) {
      this.currentTopic = this.args.topic;
      if (this.args.topic) {
        this.nexus.subscribeTopics([this.args.topic]);
      }
    }
  }

  private handle(event: NexusEvent) {
    if ('handler' in this.args) {
      this.args.handler(event);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::RealtimeSubscription': typeof RealtimeSubscription;
  }
}
