/* import __COLOCATED_TEMPLATE__ from './install-options.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { IOS_INSTALLATION_OPTIONS } from 'embercom/components/installation-new/constants';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

export default class InstallOptions extends Component<Signature> {
  @tracked selectedInstallOption: IOS_INSTALLATION_OPTIONS =
    IOS_INSTALLATION_OPTIONS.SWIFT_PACKAGE_MANAGER;

  @action onSelectInstallOption(installOption: IOS_INSTALLATION_OPTIONS) {
    this.selectedInstallOption = installOption;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Ios::InstallOptions': typeof InstallOptions;
    'installation-new/mobile/initial-setup/ios/install-options': typeof InstallOptions;
  }
}
