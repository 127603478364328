/* import __COLOCATED_TEMPLATE__ from './wordpress.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import templateOnlyComponent from '@ember/component/template-only';

const Wordpress = templateOnlyComponent();
export default Wordpress;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::Wordpress': typeof Wordpress;
  }
}
