/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { type SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { type InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type ViewSummary from 'embercom/objects/inbox/view-summary';

interface Arguments {
  text: string;
  searchPlaceholder: string;
  entityType: SearchableType;
  inboxCategory: InboxCategory;
  pinnedInboxes: Inbox[];
  addPin: (inbox: Inbox) => void;
  removePin: (inbox: Inbox) => void;
  customFolderId: number;
  hideMenu: () => void;
  expandFolder: () => void;
  onCreateView: (footer: any) => void;
  onEditView: (row: ViewSummary, hidePopover: Function | null) => void;
}

interface Signature {
  Args: Arguments;
}

const Item = templateOnlyComponent<Signature>();
export default Item;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Custom::EditMenu::Item': typeof Item;
    'inbox2/left-nav/folders/custom/edit-menu/item': typeof Item;
  }
}
