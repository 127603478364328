/* import __COLOCATED_TEMPLATE__ from './focusable-section.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    section: string;
    focusedSections: string[];
  };
  Blocks: {
    default: [string];
  };
}

const FocusableSection = templateOnlyComponent<Signature>();
export default FocusableSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::FocusableSection': typeof FocusableSection;
    'inbox2/onboarding/focusable-section': typeof FocusableSection;
  }
}
