/* import __COLOCATED_TEMPLATE__ from './ticket-state-dot-icon.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* eslint-disable @intercom/intercom/no-bare-strings */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface Signature {
  Args: {
    size?: 'small' | 'large';
    ticketState: TicketSystemState;
    class?: string;
  };
}

export default class TicketStateDotIcon extends Component<Signature> {
  @service declare ticketStateService: TicketStateService;

  get newStateIcon() {
    return this.ticketStateService.getIconForSystemState(this.args.ticketState);
  }

  get className(): string {
    return `${this.args.class || 'flex items-center'} ${this.sizeCssClassName}`;
  }

  get sizeCssClassName(): string {
    switch (this.args.size) {
      case 'small':
        return 'w-3 h-3';
      case 'large':
      default:
        return 'w-4 h-4';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketStateDotIcon': typeof TicketStateDotIcon;
    'inbox2/ticket-state-dot-icon': typeof TicketStateDotIcon;
  }
}
