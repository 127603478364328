/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

const TeammateStatus = {
  Active: 'active',
  Away: 'away',
  Reassign: 'away_and_reassigning',
};

export default class Status extends Component {
  @service intercomEventService;
  @service intl;

  get status() {
    if (this.args.admin.away_mode_enabled) {
      return this.args.admin.reassign_conversations ? TeammateStatus.Reassign : TeammateStatus.Away;
    }
    return TeammateStatus.Active;
  }

  get statusReason() {
    return this.args.admin.away_status_reason;
  }

  get statusText() {
    return this.statusOptions.findBy('value', this.status)?.text;
  }

  get statusOptions() {
    return [
      {
        text: this.intl.t('settings.teammate-workload.admin-status.active'),
        value: TeammateStatus.Active,
      },
      {
        text: this.intl.t('settings.teammate-workload.admin-status.away'),
        value: TeammateStatus.Away,
      },
      {
        text: this.intl.t('settings.teammate-workload.admin-status.reassigning'),
        value: TeammateStatus.Reassign,
      },
    ];
  }
}
