/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { type KnowledgeBaseSearchResult } from 'embercom/objects/inbox/knowledge-base/search-result';
import { isPresent } from '@ember/utils';
import type InboxApi from 'embercom/services/inbox-api';
import { EntityType } from 'embercom/models/data/entity-types';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import type CustomIntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { type KnowledgeBaseFolder } from 'embercom/objects/inbox/knowledge-base/folder';
import { State } from 'embercom/models/data/matching-system/matching-constants';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import { localCopy } from 'tracked-toolbox';
import type Conversation from 'embercom/objects/inbox/conversation';

export interface Args {
  isCollapsed: boolean;
  closeSidebar?: () => void; // TODO: This is unused - remove it
  goBack: () => void;
  conversationId: Conversation['id'];
  activeParticipantId: string;
  selectedContentId?: KnowledgeBaseContent['entityId'] | undefined;
  selectedContentType?: EntityType | undefined;
}

interface Signature {
  Args: Args;
}

export default class InboxKnowledgeBaseSidebar extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;
  @service declare intl: CustomIntlService;
  @service declare session: Session;
  @service declare router: any;

  @tracked searchQuery = '';
  @tracked searchResults: KnowledgeBaseSearchResult[] = [];
  @tracked searchError = false;
  @localCopy('args.selectedContentId') selectedContentId:
    | KnowledgeBaseContent['entityId']
    | undefined;
  @localCopy('args.selectedContentType') selectedContentType: EntityType | undefined;
  @tracked selectedFolder: KnowledgeBaseFolder | undefined;

  @tracked activeTranslation?: KnowledgeBaseContent;
  @tracked conversationId: Args['conversationId'];
  @tracked activeParticipantId: string;

  @tracked linkedContentStack: KnowledgeBaseContent[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    // These seem to get nulled out and reset to the same value when inboxes do a background refresh.
    // This causes us to trigger the active content loaders again, which causes a network request + re-render.
    // We can instead just set these once when the sidebar opens since you need to close it to switch conversations.
    this.conversationId = this.args.conversationId;
    this.activeParticipantId = this.args.activeParticipantId;
  }

  get showLoadingSkeleton() {
    return taskFor(this.searchKnowledgeBase).isRunning;
  }

  get showLoadingSkeletonForFolders() {
    return this.foldersLoader.isLoading;
  }

  get showErrorStateForFolders() {
    return !!this.foldersLoader.error;
  }

  get activeContent(): KnowledgeBaseContent | undefined {
    return this.activeContentLoader.value ?? undefined;
  }

  get linkedContent(): KnowledgeBaseContent | undefined {
    return this.linkedContentStack.lastObject;
  }

  get linkedOrActiveContent(): KnowledgeBaseContent {
    return (this.linkedContent || this.activeTranslation) as KnowledgeBaseContent;
  }

  get hasActiveContent() {
    return !!this.activeContent;
  }

  get folders(): KnowledgeBaseFolder[] {
    return this.foldersLoader.value ?? [];
  }

  get hasSearchQuery() {
    return isPresent(this.searchQuery);
  }

  get shouldShowArticleInserter() {
    return this.activeContent && this.activeContent.entityType === EntityType.ArticleContent;
  }

  get isViewingHome() {
    return !this.selectedFolder && !this.searchQuery && !this.selectedContentId;
  }

  @use activeContentLoader = AsyncData<KnowledgeBaseContent | undefined>(async () => {
    if (this.selectedContentId && this.selectedContentType) {
      let content = await this.inboxApi.fetchKnowledgeBaseContent(
        this.selectedContentId,
        this.selectedContentType,
        this.activeParticipantId,
      );
      this.activeTranslation = content;
      return content;
    }
    return undefined;
  });

  @use foldersLoader = AsyncData<KnowledgeBaseFolder[]>(async () => {
    return await this.inboxApi.fetchKnowledgeBaseFolders();
  });

  @restartableTask *searchKnowledgeBase() {
    this.searchError = false;
    if (!this.hasSearchQuery) {
      this.searchResults = [];
      return;
    }

    yield timeout(ENV.APP._350MS);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'search_initiated',
      object: 'inbox_knowledge_base',
      query_keyword: this.searchQuery,
      section: 'search',
      conversation_id: this.conversationId,
    });

    try {
      this.searchResults = yield this.inboxApi.searchKnowledgeBase({
        searchTerm: this.searchQuery,
        entityTypes: [EntityType.InternalArticle, EntityType.ArticleContent],
        states: [State.Live],
        copilotState: this.session.workspace.isFeatureEnabled(
          'psg-inbox-knowledge-base-search-copilot-state',
        )
          ? AiContentState.USED_BY_FIN
          : undefined,
      });
    } catch (error) {
      this.searchError = true;
      console.error(error);
    }
  }

  @action retryLoadingFolders() {
    this.foldersLoader.reload();
  }

  @action retryLoadingArticle() {
    this.activeContentLoader.reload();
  }

  @action retrySearch() {
    taskFor(this.searchKnowledgeBase).perform();
  }

  @action goToKnowledgeHub() {
    this.router.transitionTo('apps.app.knowledge-hub.all-content', this.session.workspace.id);
  }

  @action onSearchQueryChange(query: string) {
    this.selectedFolder = undefined;
    this.searchQuery = query;
    taskFor(this.searchKnowledgeBase).perform();
  }

  @action onSelectSearchResult(
    entityId: KnowledgeBaseSearchResult['entityId'],
    entityType: EntityType,
    isSearchResult: boolean,
  ) {
    let actionName = isSearchResult ? 'search_result_selected' : 'browse_result_selected';
    this.intercomEventService.trackAnalyticsEvent({
      action: actionName,
      object: 'inbox_knowledge_base',
      query_keyword: this.searchQuery,
      section: 'search',
      result_id: entityId,
      result_type: entityType,
      conversation_id: this.conversationId,
    });
    this.selectedContentId = entityId;
    this.selectedContentType = entityType;
  }

  @action setActiveTranslation(content: KnowledgeBaseContent) {
    this.activeTranslation = content;
  }

  @action returnToSearch() {
    this.selectedContentId = undefined;
    this.selectedContentType = undefined;
    this.activeTranslation = undefined;
    this.selectedFolder = undefined;
    this.searchError = false;
  }

  @action returnToHome() {
    this.selectedContentId = undefined;
    this.selectedContentType = undefined;
    this.activeTranslation = undefined;
    this.selectedFolder = undefined;
    this.clearSearchQuery();
    this.clearLinkedContentStack();
  }

  @action onClickBackButton() {
    if (this.linkedContentStack.length > 0) {
      this.linkedContentStack = this.linkedContentStack.slice(0, -1);
    } else if (this.hasSearchQuery) {
      this.returnToSearch();
    } else if (this.selectedContentId) {
      this.selectedContentId = undefined;
      this.selectedContentType = undefined;
      this.activeTranslation = undefined;
    } else {
      this.selectedFolder = this.folders.find(
        (folder) => folder.id === this.selectedFolder?.parentFolderId,
      );
      this.selectedContentId = undefined;
      this.selectedContentType = undefined;
      this.activeTranslation = undefined;
    }
  }

  @action clearSearchQuery() {
    this.searchQuery = '';
    this.searchResults = [];
    this.searchError = false;
  }

  @action onSelectFolder(folder: KnowledgeBaseFolder | undefined) {
    this.selectedFolder = folder;
    this.selectedContentId = undefined;
    this.selectedContentType = undefined;
    this.activeTranslation = undefined;
    this.clearSearchQuery();
    this.clearLinkedContentStack();

    this.intercomEventService.trackAnalyticsEvent({
      object: 'inbox_knowledge_base',
      action: 'folder_selected',
      section: 'browse',
      conversation_id: this.conversationId,
    });
  }

  @action onManualCopy() {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'inbox_knowledge_base',
        action: 'manually_copied',
        section: 'content_viewer',
        text: selection,
        conversation_id: this.conversationId,
      });
    }
  }

  @action onOpenCrossLinkedContent(content: KnowledgeBaseContent) {
    this.linkedContentStack = [...this.linkedContentStack, content];
  }

  clearLinkedContentStack() {
    this.linkedContentStack = [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::Sidebar': typeof InboxKnowledgeBaseSidebar;
    'inbox2/knowledge-base/sidebar': typeof InboxKnowledgeBaseSidebar;
  }
}
