/* import __COLOCATED_TEMPLATE__ from './manage-menu.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  hideMenu: () => void;
  togglePin: () => void;
  onDelete: () => void;
  onCreateCustomFolder: () => void;
  onEditCustomFolder: () => void;
}

interface Signature {
  Args: Arguments;
}

const ManageMenu = templateOnlyComponent<Signature>();
export default ManageMenu;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Custom::EditMenu::ManageMenu': typeof ManageMenu;
    'inbox2/left-nav/folders/custom/edit-menu/manage-menu': typeof ManageMenu;
  }
}
