/* import __COLOCATED_TEMPLATE__ from './topics-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationTopic from 'embercom/objects/inbox/conversation-topic';

interface Signature {
  Element: HTMLElement;
  Args: {
    topics: ConversationTopic[];
    onSelect: (topic: ConversationTopic) => any;
    onClear: () => any;
  };
}

const TopicsSelector = templateOnlyComponent<Signature>();
export default TopicsSelector;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::TopicsSelector': typeof TopicsSelector;
  }
}
