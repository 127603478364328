/* import __COLOCATED_TEMPLATE__ from './resolve-and-close-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { type ModalWidth } from '@intercom/pulse/template-registry';
interface Args {
  conversation: ConversationRecord;
  onModalClose: () => void;
  onResolveAndClose: () => void;
  onClose: () => void;
  withReply?: boolean;
  width?: ModalWidth;
}

interface Signature {
  Args: Args;
}

export default class ResolveAndCloseModal extends Component<Signature> {
  @service declare ticketStateService: TicketStateService;
  @service declare intl: IntlService;

  get selectedTicketStateText(): string {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversation.ticketCustomStateId,
    ).adminLabel;
  }

  get resolveAndCloseText() {
    if (this.args.withReply) {
      return this.intl.t('inbox.multi-state-button.modal.resolve-and-close-with-reply');
    }

    return this.intl.t('inbox.multi-state-button.modal.resolve-and-close');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ResolveAndCloseModal': typeof ResolveAndCloseModal;
    'inbox2/resolve-and-close-modal': typeof ResolveAndCloseModal;
  }
}
