/* import __COLOCATED_TEMPLATE__ from './phone-number-option.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  componentAttrs: {
    name: string;
    phoneNumber: string;
  };
}

interface Signature {
  Args: Args;
}

const PhoneNumberOption = templateOnlyComponent<Signature>();
export default PhoneNumberOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneNumberOption': typeof PhoneNumberOption;
    'inbox2/phone-number-option': typeof PhoneNumberOption;
  }
}
