/* import __COLOCATED_TEMPLATE__ from './accordion-content.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import {
  type InstallationMethodSelection,
  type InstallationMethod,
  INSTALLATION_METHOD,
  PROGRAMMING_LANGUAGES,
  INSTALLATION_PLATFORMS,
} from 'embercom/components/installation-new/constants';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  HMAC_APP_TYPES,
  USER_IDENTIFIER_VALUES,
} from 'embercom/components/installation-new/hmac-generation';
import type SdkAppService from 'embercom/services/sdk-app-service';
import { type InstallationPlatform } from 'embercom/components/installation-new/constants';

interface Args {
  installationMethods: InstallationMethodSelection[];
  selectedInstallationMethod: InstallationMethod;
  onSelectInstallationMethod: (installationMethod: InstallationMethod) => void;
  openUserHashChecker: () => void;
  selectedReactNativeInstallationPlatform: InstallationPlatform;
  setSelectedReactNativeInstallationPlatform: (platform: InstallationPlatform) => void;
}
interface Signature {
  Args: Args;
  Element: any;
}

export default class AccordionContent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare sdkAppService: SdkAppService;

  @tracked selectedAppType = HMAC_APP_TYPES.BASIC_JAVASCRIPT;
  @tracked selectedUserIdentifier = USER_IDENTIFIER_VALUES.ID;
  @tracked selectedProgrammingLanguage = PROGRAMMING_LANGUAGES.NODE;

  get mobileSdkApiSecret() {
    if (this.args.selectedInstallationMethod === INSTALLATION_METHOD.REACT_NATIVE) {
      switch (this.args.selectedReactNativeInstallationPlatform) {
        case INSTALLATION_PLATFORMS.IOS:
          return this.sdkAppService.iosApiSecret;
        case INSTALLATION_PLATFORMS.ANDROID:
          return this.sdkAppService.androidApiSecret;
      }
    }

    return this.args.selectedInstallationMethod === INSTALLATION_METHOD.IOS
      ? this.sdkAppService.iosApiSecret
      : this.sdkAppService.androidApiSecret;
  }

  get mobileSdkApp() {
    if (this.args.selectedInstallationMethod === INSTALLATION_METHOD.REACT_NATIVE) {
      switch (this.args.selectedReactNativeInstallationPlatform) {
        case INSTALLATION_PLATFORMS.IOS:
          return this.sdkAppService.iosSdkApp;
        case INSTALLATION_PLATFORMS.ANDROID:
          return this.sdkAppService.androidSdkApp;
      }
    }

    return this.args.selectedInstallationMethod === INSTALLATION_METHOD.IOS
      ? this.sdkAppService.iosSdkApp
      : this.sdkAppService.androidSdkApp;
  }

  get userHashTitle() {
    switch (this.args.selectedInstallationMethod) {
      case INSTALLATION_METHOD.IOS:
        return this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.ios.setup-idv.set-user-hash',
        );
      case INSTALLATION_METHOD.ANDROID:
        return this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.android.setup-idv.set-user-hash',
        );
      default:
        return this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.react-native.setup-idv.set-user-hash',
        );
    }
  }

  get selectedInstallationPlatform() {
    switch (this.args.selectedInstallationMethod) {
      case INSTALLATION_METHOD.REACT_NATIVE:
        return this.args.selectedReactNativeInstallationPlatform;
      case INSTALLATION_METHOD.ANDROID:
        return INSTALLATION_PLATFORMS.ANDROID;
      default:
        return INSTALLATION_PLATFORMS.IOS;
    }
  }

  get reactNativeInstallationPlatforms() {
    return [
      {
        value: INSTALLATION_PLATFORMS.IOS,
        label: 'iOS',
      },
      {
        value: INSTALLATION_PLATFORMS.ANDROID,
        label: 'Android',
      },
    ];
  }

  @action
  onSelectReactNativePlatform(platform: INSTALLATION_PLATFORMS) {
    this.args.setSelectedReactNativeInstallationPlatform(platform);
  }

  @action
  onSelectAppType(appType: HMAC_APP_TYPES) {
    this.selectedAppType = appType;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'idv_app_type',
      metadata: {
        item: appType.replaceAll('-', '_'),
        platform: 'mobile',
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  onSelectUserIdentifier(userIdentifier: USER_IDENTIFIER_VALUES) {
    this.selectedUserIdentifier = userIdentifier;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'idv_identification_method',
      metadata: {
        item: userIdentifier,
        platform: 'mobile',
      },
      place: 'installation-new',
      section: 'settings',
    });
  }

  @action
  onSelectProgrammingLanguage(language: PROGRAMMING_LANGUAGES) {
    this.selectedProgrammingLanguage = language;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'code_snippet_language',
      metadata: {
        item: language,
        context: 'idv',
        platform: 'mobile',
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::IdvSetup::AccordionContent': typeof AccordionContent;
    'installation-new/mobile/idv-setup/accordion-content': typeof AccordionContent;
  }
}
