/* import __COLOCATED_TEMPLATE__ from './command-k.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';

export default class OnboardingCommandK extends Component {
  @service declare intl: IntlService;
  @service declare inboxHotkeys: InboxHotkeys;

  get items() {
    return [
      {
        icon: 'note',
        name: this.intl.t('inbox.command-k.actions.compose-note'),
        hotkeys: this.inboxHotkeys.hotkeysMap.ComposeNote,
      },
      {
        icon: 'saved-reply',
        name: this.intl.t('inbox.command-k.actions.use-macro'),
        hotkeys: {
          keys: '#',
          display: ['#'],
        },
      },
      {
        icon: 'gif',
        name: this.intl.t('inbox.command-k.actions.gifs'),
        hotkeys: this.inboxHotkeys.hotkeysMap.InsertGif,
      },
      {
        icon: 'check',
        name: this.intl.t('inbox.command-k.actions.close'),
        hotkeys: this.inboxHotkeys.hotkeysMap.Close,
      },
      {
        icon: 'snooze',
        name: this.intl.t('inbox.command-k.actions.snooze'),
        hotkeys: this.inboxHotkeys.hotkeysMap.SnoozeAction,
      },
      {
        icon: 'ticket',
        name: this.intl.t('inbox.command-k.actions.convert-to-ticket'),
        hotkeys: this.inboxHotkeys.hotkeysMap.ConvertToTicket,
      },
      {
        icon: 'lwr-happy',
        name: this.intl.t('inbox.command-k.actions.emoji'),
        hotkeys: this.inboxHotkeys.hotkeysMap.Emoji,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::CommandK': typeof OnboardingCommandK;
    'inbox2/onboarding/command-k': typeof OnboardingCommandK;
  }
}
