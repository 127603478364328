/* import __COLOCATED_TEMPLATE__ from './subscription-types.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { consentType } from 'embercom/models/outbound-subscriptions/subscription-type';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class SubscriptionTypes extends Component {
  @service intercomEventService;
  @service store;

  @tracked subscriptionTypes = [];
  @tracked showConfirmModal = false;
  @tracked selectedSubscriptionType;

  constructor() {
    super(...arguments);
    this.loadSubscriptionTypes.perform();
  }

  get user() {
    return this.args.user;
  }

  get userOptedOutSubscriptionTypeIds() {
    if (this.user.opted_out_subscription_type_ids) {
      return this.user.opted_out_subscription_type_ids.map((id) => id.toString());
    }
    return [];
  }

  get userOptedInSubscriptionTypeIds() {
    if (this.user.opted_in_subscription_type_ids) {
      return this.user.opted_in_subscription_type_ids.map((id) => id.toString());
    }
    return [];
  }

  get subscriptionTypePreferences() {
    return this.subscriptionTypes
      .filter((subscriptionType) => subscriptionType.isLive)
      .map((subscriptionType) => ({
        id: subscriptionType.id,
        name: subscriptionType.translationForTeammate?.name,
        isActive:
          (subscriptionType.consentType === consentType.optIn &&
            this.userOptedInSubscriptionTypeIds.includes(subscriptionType.id)) ||
          (subscriptionType.consentType === consentType.optOut &&
            !this.userOptedOutSubscriptionTypeIds.includes(subscriptionType.id)),
        consentType: subscriptionType.consentType,
        permittedContentTypes: subscriptionType.permittedContentTypes,
      }));
  }

  get emailSubscriptionTypePreferences() {
    return this.subscriptionTypePreferences.filter((subscriptionTypePreference) =>
      subscriptionTypePreference.permittedContentTypes.includes(objectTypes.email),
    );
  }

  get smsSubscriptionTypePreferences() {
    return this.subscriptionTypePreferences.filter((subscriptionTypePreference) =>
      subscriptionTypePreference.permittedContentTypes.includes(objectTypes.sms),
    );
  }

  @action
  handleSubscriptionTypePreferenceUpdate(subscriptionTypePreference) {
    if (
      subscriptionTypePreference.consentType === consentType.optIn &&
      !subscriptionTypePreference.isActive
    ) {
      this.selectedSubscriptionType = subscriptionTypePreference;
      this.showConfirmModal = true;
    } else {
      this.toggleSubscriptionTypePreference.perform(subscriptionTypePreference);
    }
  }

  @task({ restartable: true })
  *loadSubscriptionTypes() {
    this.subscriptionTypes = yield this.store.findAll('outbound-subscriptions/subscription-type');
  }

  @task({ drop: true })
  *toggleSubscriptionTypePreference(subscriptionTypePreference) {
    let subscriptionTypeList =
      subscriptionTypePreference.consentType === consentType.optIn
        ? this.user.opted_in_subscription_type_ids
        : this.user.opted_out_subscription_type_ids;
    let id = parseInt(subscriptionTypePreference.id, 10);
    let eventName =
      subscriptionTypePreference.consentType === consentType.optIn
        ? 'opted_in_subscription_type_id'
        : 'opted_out_subscription_type_id';

    if (subscriptionTypeList.includes(id)) {
      subscriptionTypeList.removeObject(id);
      eventName += '_removed';
    } else {
      subscriptionTypeList.pushObject(id);
      eventName += '_added';
    }

    this.intercomEventService.trackAnalyticsEvent({
      object: 'user',
      action: 'updated',
      [eventName]: subscriptionTypePreference.id,
      user_id: this.user.id,
    });

    yield this.user.updateSubscriptionTypeIds(this.user.opted_out_subscription_type_ids);
  }

  @task({ drop: true })
  *toggleGlobalUnsubscribeFromEmails() {
    this.user.unsubscribed_from_emails = !this.user.unsubscribed_from_emails;
    this.intercomEventService.trackAnalyticsEvent({
      object: 'user',
      action: 'updated',
      unsubscribed_from_emails: this.user.unsubscribed_from_emails,
      user_id: this.user.id,
    });

    yield this.user.saveUnsubscribedFromEmails();
  }

  @task({ drop: true })
  *toggleGlobalSmsConsent() {
    this.user.sms_consent = !this.user.sms_consent;
    this.intercomEventService.trackAnalyticsEvent({
      object: 'user',
      action: 'updated',
      sms_consent: this.user.sms_consent,
      user_id: this.user.id,
    });

    yield this.user.saveSmsConsent();
  }

  @task({ drop: true })
  *toggleGlobalUnsubscribeFromSms() {
    this.user.unsubscribed_from_sms = !this.user.unsubscribed_from_sms;
    this.intercomEventService.trackAnalyticsEvent({
      object: 'user',
      action: 'updated',
      unsubscribed_from_sms: this.user.unsubscribed_from_sms,
      user_id: this.user.id,
    });

    yield this.user.saveUnsubscribedFromSms();
  }
}
