/* import __COLOCATED_TEMPLATE__ from './admin-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import { inject as service } from '@ember/service';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  teammateSelected: AdminWithPermissions | AdminSummary | undefined;
  onSelect: (admin: AdminWithPermissions | AdminSummary | undefined) => void;
  searchPlaceholder: string;
  buttonText: string;
  hideUnassignedOption?: boolean;
  showRemoveButton?: boolean;
  initiallyOpen?: boolean;
  onRemove?: () => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class SearchFiltersAdminSelector extends Component<Signature> {
  @service declare session: Session;
  @tracked isHovered = false;

  readonly quickSearchEntityType = SearchableType.Admin;

  get defaultAdmins() {
    return this.args.hideUnassignedOption
      ? [this.session.teammate]
      : [AdminSummary.unassigned, this.session.teammate];
  }

  @action onRemove() {
    this.args.onRemove?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::AdminSelector': typeof SearchFiltersAdminSelector;
  }
}
