/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Avatar = templateOnlyComponent<Signature>();
export default Avatar;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::Avatar': typeof Avatar;
    'inbox2/user-menu/avatar': typeof Avatar;
  }
}
