/* import __COLOCATED_TEMPLATE__ from './code-snippets.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { IOS_PROGRAMMING_LANGUAGES } from 'embercom/components/installation-new/constants';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

export default class CodeSnippets extends Component<Signature> {
  @tracked selectedProgrammingLanguage: IOS_PROGRAMMING_LANGUAGES = IOS_PROGRAMMING_LANGUAGES.SWIFT;

  @action onSelectProgrammingLanguage(language: IOS_PROGRAMMING_LANGUAGES) {
    this.selectedProgrammingLanguage = language;
  }

  get programmingLanguages() {
    return [
      { text: 'Swift', value: IOS_PROGRAMMING_LANGUAGES.SWIFT },
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      { text: 'Swift UI', value: IOS_PROGRAMMING_LANGUAGES.SWIFT_UI },
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      { text: 'Objective-C', value: IOS_PROGRAMMING_LANGUAGES.OBJECTIVE_C },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::IdvSetup::Ios::CodeSnippets': typeof CodeSnippets;
    'installation-new/mobile/idv-setup/ios/code-snippets': typeof CodeSnippets;
  }
}
