/* import __COLOCATED_TEMPLATE__ from './user-details-title-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InboxConversationSidebarUserDetailsTitleDropdown extends Component {
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  @service modalService;
  @service router;
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get userUnsubscribedFromEmails() {
    return this.args.user?.unsubscribed_from_emails;
  }

  get groupList() {
    let groupList = [
      {
        text: this.intl.t(
          'inbox.conversation-details-sidebar.submenu-header.new-conversation-title',
        ),
        icon: 'compose',
        value: 'new-conversation',
        description: this.intl.t(
          'inbox.conversation-details-sidebar.submenu-header.new-conversation-title',
          { username: this.args.user.displayAs },
        ),
        onSelectItem: this.routeToOutboundSupportComposer,
      },
      {
        text: this.intl.t('inbox.conversation-details-sidebar.submenu-header.block-title'),
        icon: 'error',
        value: 'block',
        description: this.intl.t(
          'inbox.conversation-details-sidebar.submenu-header.block-description',
        ),
        onSelectItem: this.blockUser,
      },
    ];

    if (this.userUnsubscribedFromEmails) {
      groupList.push({
        text: this.intl.t('inbox.conversation-details-sidebar.submenu-header.resubscribe-title'),
        icon: 'message',
        value: 'resubscribe',
        description: this.intl.t(
          'inbox.conversation-details-sidebar.submenu-header.resubscribe-description',
        ),
        onSelectItem: this.resubscribeUser,
      });
    } else {
      groupList.push({
        text: this.intl.t('inbox.conversation-details-sidebar.submenu-header.unsubscribe-title'),
        icon: 'unsubscribed',
        value: 'unsubscribe',
        description: this.intl.t(
          'inbox.conversation-details-sidebar.submenu-header.unsubscribe-description',
        ),
        onSelectItem: this.unsubscribeUser,
      });
    }

    return [
      {
        items: groupList,
      },
    ];
  }

  _manageSubscription() {
    let component = 'users/modals/subscription';
    this.modalService.openModal(component, this.args.user);
  }

  @action
  blockUser() {
    let component = 'users/modals/block-user';
    this.modalService.openModal(component, this.args.user, {
      conversation: this.args.conversation,
    });
  }

  @action
  unsubscribeUser() {
    this._manageSubscription();
  }

  @action
  resubscribeUser() {
    this._manageSubscription();
  }

  @action
  routeToOutboundSupportComposer() {
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', this.app.id, {
      queryParams: { recipient: this.args.user.id, overwrite: null },
    });
  }
}
