/* import __COLOCATED_TEMPLATE__ from './conversation-card-placeholder.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const ConversationCardPlaceholder = templateOnlyComponent<Signature>();
export default ConversationCardPlaceholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationCardPlaceholder': typeof ConversationCardPlaceholder;
    'inbox2/left-nav/conversation-card-placeholder': typeof ConversationCardPlaceholder;
  }
}
