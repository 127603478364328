/* import __COLOCATED_TEMPLATE__ from './macro-execution-error-notification.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type NexusEvent, NexusEventName } from 'embercom/services/nexus';
import type Session from 'embercom/services/session';
import type Snackbar from 'embercom/services/snackbar';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';

export default class MacroExecutionErrorNotification extends Component {
  @service declare session: Session;
  @service declare snackbar: Snackbar;
  @service declare router: Router;
  @service declare intl: IntlService;

  eventNames: NexusEventName[] = [NexusEventName.MacroExecutionError];

  @action
  handleMacroExecutionError(event: NexusEvent) {
    this.snackbar.notifyError(
      this.intl.t('inbox.notifications.macro-execution-error.description', {
        conversationId: event.eventData.conversation_id,
      }),
      {
        onButtonClick: () => {
          this.router.transitionTo(
            'inbox.workspace.inbox.conversation.conversation',
            event.eventData.conversation_id,
          );
        },
        buttonLabel: this.intl.t('inbox.notifications.macro-execution-error.button-label'),
        contentComponent: 'inbox2/macro-execution-error-notification-content',
        persistent: true,
        clearable: true,
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::MacroExecutionErrorNotification': typeof MacroExecutionErrorNotification;
    'inbox2/macro-execution-error-notification': typeof MacroExecutionErrorNotification;
  }
}
