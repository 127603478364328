/* import __COLOCATED_TEMPLATE__ from './section-body-expanded-content.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { SIDEBAR_EXPANDED_CONTENT_VISIBILITY } from 'embercom/lib/inbox/constants';

export default class InboxConversationSiderbarSectionBodyExpandedContent extends Component {
  get showExpandedContent() {
    return (
      this.args.expandedContentVisibility === SIDEBAR_EXPANDED_CONTENT_VISIBILITY.expanded ||
      this.args.expandedContentVisibility ===
        SIDEBAR_EXPANDED_CONTENT_VISIBILITY.expandedWithHiddenLabel
    );
  }
}
