/* import __COLOCATED_TEMPLATE__ from './delete-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

interface Args {
  view: ViewSummary;
  onClose: (args: { success: boolean; view?: ViewSummary }) => void;
}

interface Signature {
  Args: Args;
}

export default class DeleteModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service notificationsService: any;
  @service intercomEventService: any;

  @action cancel() {
    this.args.onClose({ success: false });
  }

  @task({ drop: true })
  *deleteView() {
    try {
      yield ajax({
        url: `/ember/inbox_views/${this.args.view.id}?app_id=${this.session.workspace.id}`,
        type: 'DELETE',
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('inbox.inbox-views.modals.delete.notifications.success', {
          name: this.args.view.name,
        }),
      );

      this.trackAnalyticsEvent(this.args.view);
      this.args.onClose({ success: true, view: this.args.view });
    } catch (_error) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.inbox-views.modals.delete.notifications.generic-error'),
      );
    }
  }

  trackAnalyticsEvent(view: ViewSummary) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'inbox_view',
      id: view.id,
      has_dynamic_admin_assignee_predicates: view.hasDynamicAdminAssigneePredicates,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Views::DeleteModal': typeof DeleteModal;
    'inbox2/views/delete-modal': typeof DeleteModal;
  }
}
