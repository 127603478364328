/* import __COLOCATED_TEMPLATE__ from './manage-participants.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isValidEmail } from 'embercom/lib/email';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { getParticipantChanges } from 'embercom/objects/inbox/user-summary';
import type InboxState from 'embercom/services/inbox-state';

interface Args {
  conversation: Conversation;
  onClose: () => unknown;
}

interface Signature {
  Args: Args;
}

export default class ManageParticipants extends Component<Signature> {
  MAX_PARTICIPANTS = 10;
  @service declare inboxState: InboxState;
  @tracked currentParticipants: UserSummary[] = this.args.conversation.participantSummaries;

  readonly getParticipantChanges = getParticipantChanges;

  @action addParticipant(participant: UserSummary) {
    this.currentParticipants = [...this.currentParticipants, participant];
  }

  @action removeParticipant(participant: UserSummary) {
    this.currentParticipants = this.currentParticipants.without(participant);
  }

  get hasErrors() {
    return (
      this.currentParticipants.length < 1 ||
      this.participantsWithStatus.any(({ isInvalid }) => isInvalid)
    );
  }

  get participantsWithStatus() {
    let participantStatuses: {
      participant: UserSummary;
      isInvalid: boolean;
    }[] = [];

    this.currentParticipants.forEach((participant) => {
      participantStatuses.push({
        participant,
        isInvalid: this.isInvalid(participant),
      });
    });

    return participantStatuses;
  }

  private isInvalid(userSummary: UserSummary): boolean {
    return userSummary && !userSummary.id && !isValidEmail(userSummary.email);
  }

  get participantsData() {
    let existingParticipants = this.args.conversation.participantSummaries;
    let { addedParticipants, removedParticipants } = this.getParticipantChanges(
      existingParticipants,
      this.currentParticipants,
    );

    let removedParticipantIds = removedParticipants.map((participant) => participant.id);

    let newParticipantEmails: string[] = [];
    let newParticipantIds: string[] = [];
    addedParticipants?.forEach((participant) =>
      participant.isNewUser
        ? newParticipantEmails.push(participant.email!)
        : newParticipantIds.push(participant.id),
    );

    return { removedParticipantIds, newParticipantEmails, newParticipantIds };
  }

  @action updateParticipants() {
    this.inboxState.updateConversationParticipants(this.args.conversation, this.participantsData);
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ManageParticipants': typeof ManageParticipants;
    'inbox2/manage-participants': typeof ManageParticipants;
  }
}
