/* import __COLOCATED_TEMPLATE__ from './phone-details-audio-player.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { trackedRef } from 'ember-ref-bucket';
import { type Optionable, type GroupedOptionable } from './common/dropdown-button';
import PhoneDetailsAudioElement from 'embercom/objects/inbox/phone-details-audio-element';

interface Args {
  recordingUrl: string;
}

interface SpeedOption extends Optionable {
  id: number;
  value: string;
}

export default class PhoneDetailsAudioPlayer extends Component<Args> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked showVolumeSlider = false;
  @tracked tempDuration: string | undefined;

  @trackedRef('audioPlayer', (audioElement: HTMLAudioElement) => {
    let instance = new PhoneDetailsAudioElement(audioElement);
    return instance;
  })
  private _audioElement?: PhoneDetailsAudioElement;

  showVolumeSliderTimeout: number | undefined;
  sliderMouseUpTimeout: number | undefined;

  get audioElement() {
    return this._audioElement;
  }

  get duration() {
    return this.audioElement?.duration;
  }

  get volume() {
    return this.audioElement?.volume;
  }
  get muted() {
    return this.audioElement?.muted;
  }

  get playbackRate() {
    return this.audioElement?.playbackRate;
  }

  get isPlaying() {
    return this.audioElement?.isPlaying;
  }

  get currentDuration() {
    return this.audioElement?.currentTime;
  }

  get isDisabled() {
    return !this.args.recordingUrl || !this.duration;
  }

  get rangeStylesDuration() {
    let duration = this.tempDuration ?? this.currentDuration;
    return sanitizeHtml(`--min: 0; --max:${this.duration}; --val:${duration}`);
  }

  get rangeStylesVolume() {
    return sanitizeHtml(`--min: 0; --max:1; --val:${this.muted ? 0 : this.volume}`);
  }

  get formattedTotalDuration() {
    return this.formatDuration(this.duration);
  }

  get formattedCurrentDuration() {
    return this.formatDuration(this.currentDuration);
  }

  get volumeIcon() {
    if (!this.volume || this.volume <= 0 || this.muted) {
      return 'volume-off';
    } else if (this.volume <= 0.5) {
      return 'volume-low';
    } else {
      return 'volume';
    }
  }

  get playbackRateOptions(): GroupedOptionable<SpeedOption>[] {
    return [
      {
        id: 'speed',
        heading: this.intl.t('inbox.phone-details-sidebar.audio-player.playback-rate'),
        selected: this.playbackRate,
        items: [
          {
            id: 0.25,
            value: '0.25',
          },
          {
            id: 0.5,
            value: '0.5',
          },
          {
            id: 0.75,
            value: '0.75',
          },
          {
            id: 1,
            value: this.intl.t('inbox.phone-details-sidebar.audio-player.normal'),
          },
          {
            id: 1.25,
            value: '1.25',
          },
          {
            id: 1.5,
            value: '1.5',
          },
          {
            id: 1.75,
            value: '1.75',
          },
          {
            id: 2,
            value: '2',
          },
        ],
      },
    ];
  }

  formatDuration(duration = 0) {
    let momentDuration = moment.duration(duration, 'seconds');

    let totalDays = Math.floor(momentDuration.asDays());
    let hours = momentDuration.hours();
    let min = momentDuration.minutes();
    let seconds = momentDuration.seconds();

    let result = '';

    if (totalDays) {
      result += `${totalDays} `;
    }

    if (hours) {
      result += `${hours}:`;
    }

    result += `${min}:`;
    result += seconds < 10 ? `0${seconds}` : seconds;

    return result;
  }

  playbackTriggeredAnalyticsEvent = () => {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'playback_triggered',
      object: 'recording',
      place: 'phone_sidebar',
    });
  };

  @action
  togglePlaying() {
    this.audioElement?.togglePlaying();
  }

  @action
  toggleMute() {
    this.audioElement?.toggleMute();
  }

  updateDuration(duration: string) {
    this.audioElement?.stopSeeking(duration);
    this.tempDuration = undefined;
  }

  @action
  onManualDurationChange(event: Event & { target: HTMLInputElement }) {
    this.updateDuration(event.target.value);
  }

  @action
  onDurationInput(event: Event & { target: HTMLInputElement }) {
    this.cleanUpSliderMouseUpTimeout();
    this.tempDuration = event.target.value;
  }

  @action
  onDurationMouseDown() {
    this.audioElement?.startSeeking();
  }

  @action
  onDurationMouseUp() {
    // If the track is playing and the user just clicks on the range without dragging the slider, the change event wont fire,
    // so lets track the mouse up and update the value if that is the case.
    this.sliderMouseUpTimeout = window.setTimeout(() => {
      if (this.tempDuration === undefined || !this.audioElement?.isSeeking) {
        return;
      }

      this.updateDuration(this.tempDuration);
    }, 50);
  }

  @action
  onHoverVolume() {
    this.cleanUpShowVolumeSliderTimeout();
    this.showVolumeSlider = true;
  }

  @action
  onLeaveVolume() {
    this.showVolumeSliderTimeout = window.setTimeout(() => (this.showVolumeSlider = false), 250);
  }

  @action
  onVolumeChange(event: Event & { target: HTMLInputElement }) {
    this.audioElement?.onVolumeChange(parseFloat(event.target.value));
  }

  @action
  onPlaybackRateSelected(option: SpeedOption) {
    this.audioElement?.onPlaybackRateSelected(option.id);
  }

  cleanUpShowVolumeSliderTimeout() {
    if (this.showVolumeSliderTimeout) {
      clearTimeout(this.showVolumeSliderTimeout);
    }
  }

  cleanUpSliderMouseUpTimeout() {
    if (this.sliderMouseUpTimeout) {
      clearTimeout(this.sliderMouseUpTimeout);
    }
  }

  willDestroy() {
    super.willDestroy();
    this.audioElement?.removeEventListeners();
    this.cleanUpShowVolumeSliderTimeout();
    this.cleanUpSliderMouseUpTimeout();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneDetailsAudioPlayer': typeof PhoneDetailsAudioPlayer;
    'inbox2/phone-details-audio-player': typeof PhoneDetailsAudioPlayer;
  }
}
