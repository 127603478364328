/* import __COLOCATED_TEMPLATE__ from './sidebar-manager.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SidebarManager from 'embercom/services/sidebar-manager';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

interface Args {}

export default class SidebarManagerComponent extends Component<Args> {
  @service declare sidebarManager: SidebarManager;
  @service declare inboxSidebarService: InboxSidebarService;

  get currentSheet() {
    return this.sidebarManager.currentSheet;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SidebarManager': typeof SidebarManagerComponent;
    'inbox2/sidebar-manager': typeof SidebarManagerComponent;
  }
}
