/* import __COLOCATED_TEMPLATE__ from './create-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { AVAILABLE_EMOJI } from 'embercom/lib/available-emoji';
import type IntlService from 'embercom/services/intl';
import ViewSummary from 'embercom/objects/inbox/view-summary';
import type Session from 'embercom/services/session';
import { post } from 'embercom/lib/ajax';

// @ts-ignore
import intermoji from '@intercom/intermoji';
import shuffle from '@intercom/pulse/lib/shuffle';
import type Store from '@ember-data/store';

interface Args {
  onClose: (args: { success: boolean; view?: ViewSummary }) => void;
  customFolderId?: number;
}

interface Signature {
  Args: Args;
}

export default class CreateModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  @service notificationsService: any;
  @service intercomEventService: any;

  // These imports are not supported in Inbox 2, but we need them in this
  // component.

  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare store: Store;
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service inboxService: any;
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare appService: any;

  @tracked name = '';
  @tracked predicateGroup;
  @tracked avatarEmoji = this._randomEmoji();

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.predicateGroup = this.store.createFragment('predicates/predicate-group');
  }

  get isValid() {
    let group = this.predicateGroup;
    return this.name.trim() !== '' && group.isValid && group.hasEditablePredicates;
  }

  @action predicatesChanged(predicates: any) {
    this.predicateGroup.set('predicates', predicates.copy());
  }

  @action cancel() {
    this.args.onClose({ success: false });
  }

  @action selectAvatarEmoji(emojiIdentifier: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.avatarEmoji = twemojiCompatibleEmoji;
  }

  @task({ restartable: true })
  *saveView(): TaskGenerator<void> {
    try {
      let result = yield post(`/ember/inbox_views/?app_id=${this.session.workspace.id}`, {
        name: this.name,
        avatar_emoji: this.avatarEmoji,
        predicate_group: this.predicateGroup.copy().serialize(),
        custom_folder_id: this.args.customFolderId,
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('inbox.inbox-views.modals.create.notifications.success', {
          name: this.name,
        }),
      );

      let view = new ViewSummary(
        result.id,
        result.name,
        result.avatar_emoji,
        undefined,
        result.predicate_group,
        result.system_type,
        result.has_dynamic_admin_assignee_predicates,
      );
      this.trackAnalyticsEvent(view);
      this.args.onClose({ success: true, view });
    } catch (error) {
      if (error.jqXHR.responseJSON.errors[0].message === 'name has already been taken') {
        this.notificationsService.notifyError(
          this.intl.t('inbox.inbox-views.modals.create.notifications.duplicate-error'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('inbox.inbox-views.modals.create.notifications.generic-error'),
        );
      }
    }
  }

  trackAnalyticsEvent(view: ViewSummary) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: 'inbox_view',
      id: view.id,
      has_dynamic_admin_assignee_predicates: view.hasDynamicAdminAssigneePredicates,
    });
  }

  _randomEmoji() {
    return shuffle(AVAILABLE_EMOJI)[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Views::CreateModal': typeof CreateModal;
    'inbox2/views/create-modal': typeof CreateModal;
  }
}
