/* import __COLOCATED_TEMPLATE__ from './steps.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  PROGRAMMING_LANGUAGES,
  type AndroidInstallationScript,
} from 'embercom/components/installation-new/constants';

const GROOVY_INSTALLATION_SCRIPT = `
dependencies {
    implementation 'io.intercom.android:intercom-sdk:latest.release'
    // If you want to enable push notification, add the following dependency
    implementation 'com.google.firebase:firebase-messaging:23.1.+'
}
`;

const KOTLIN_INSTALLATION_SCRIPT = `
dependencies {
    implementation("io.intercom.android:intercom-sdk:latest.release")
    // If you want to enable push notification, add the following dependency
    implementation("com.google.firebase:firebase-messaging:23.1.+")
}
`;

const INSTALLATION_SCRIPTS: Record<AndroidInstallationScript, string> = {
  [PROGRAMMING_LANGUAGES.GROOVY]: GROOVY_INSTALLATION_SCRIPT,
  [PROGRAMMING_LANGUAGES.KOTLIN]: KOTLIN_INSTALLATION_SCRIPT,
};

const GRADLE_CONFIG_CODE_SNIPPET = `
allprojects {
    repositories {
        mavenCentral()
    }
}
`;

export default class InitialSetupAndroidSteps extends Component {
  gradleConfigCodeSnippet = GRADLE_CONFIG_CODE_SNIPPET;

  get installationScript() {
    return INSTALLATION_SCRIPTS[this.selectedInstallationScript];
  }

  @tracked selectedInstallationScript: AndroidInstallationScript = PROGRAMMING_LANGUAGES.GROOVY;

  @action onSelectInstallationScript(option: AndroidInstallationScript) {
    this.selectedInstallationScript = option;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Android::Steps': typeof InitialSetupAndroidSteps;
    'installation-new/mobile/initial-setup/android/steps': typeof InitialSetupAndroidSteps;
  }
}
