/* import __COLOCATED_TEMPLATE__ from './preview-conversation-options.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Args {
  conversation: Conversation;
  conversationToAction: ConversationTableEntry | Conversation;
  snooze: () => void;
  unsnooze: () => void;
}

interface Signature {
  Args: Args;
}

enum DropdownOption {
  snooze = 'snooze',
  unsnooze = 'unsnooze',
  openInNewTab = 'open-in-new-tab',
}

type OptionItem = {
  icon: string;
  id: DropdownOption;
};

export default class PreviewConversationOptions extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare session: Session;

  readonly defaultInbox = { id: 'all', category: 'shared' };

  get dropdownOptions(): OptionItem[] {
    let defaultOptions = [
      {
        id: DropdownOption.openInNewTab,
        icon: 'new-window',
      },
    ];

    if (this.args.conversation.isMerged || this.session.showLightInbox) {
      return defaultOptions;
    } else if (this.args.conversation.isOpen) {
      return [
        {
          id: DropdownOption.snooze,
          icon: 'snooze',
        },
        ...defaultOptions,
      ];
    } else if (this.args.conversation.isSnoozed) {
      return [
        {
          id: DropdownOption.unsnooze,
          icon: 'snooze',
        },
        ...defaultOptions,
      ];
    }

    return defaultOptions;
  }

  @action onOptionSelection(item: OptionItem) {
    switch (item.id) {
      case DropdownOption.snooze:
        this.args.snooze();
        break;
      case DropdownOption.unsnooze:
        this.args.unsnooze();
        break;
      default:
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PreviewConversationOptions': typeof PreviewConversationOptions;
    'inbox2/preview-conversation-options': typeof PreviewConversationOptions;
  }
}
