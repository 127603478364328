/* import __COLOCATED_TEMPLATE__ from './conversation-composer.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { Config } from 'embercom/objects/inbox/composer-config';
import type Session from 'embercom/services/session';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import platform from 'embercom/lib/browser-platform';
import type IntlService from 'embercom/services/intl';
import ReplyPane from 'embercom/objects/inbox/reply-pane';
import {
  type BlocksDocument,
  type ComposerPublicAPI,
} from '@intercom/embercom-prosemirror-composer';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { getOwner } from '@ember/application';

interface Args {
  blocksDoc: BlocksDocument;
}

interface Signature {
  Args: Args;
}

export default class ConversationComposer extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  readonly config: Config;
  readonly replyPane = new ReplyPane();

  @tracked api?: ComposerPublicAPI;

  constructor(owner: ConversationComposer, args: never) {
    super(owner, args);

    this.config = new Config(getOwner(this), this.session.workspace.id, {
      allowedImageFileTypes: [],
      allowedAttachmentTypes: [],
      uploader: EmbercomFileUploader,
      attrs: { policyUrl: `/apps/${this.session.workspace.id}/uploads` },
    });

    this.config.placeholder = this.intl.t('inbox.composer.reply.placeholder', {
      modifierKey: this.modifierKey,
    });
  }

  get isEmpty() {
    return isEmpty(this.args.blocksDoc.blocks);
  }

  @action onChange() {}

  @action onReady(api: ComposerPublicAPI) {
    this.api = api;
  }

  @action didChangeBlocks() {
    next(() => {
      this.api?.composer.commands.insertNewlineAndSelect();
    });
  }

  private get modifierKey() {
    return platform.isMac ? '⌘' : 'Ctrl';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationComposer': typeof ConversationComposer;
    'inbox2/onboarding/conversation-composer': typeof ConversationComposer;
  }
}
