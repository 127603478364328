/* import __COLOCATED_TEMPLATE__ from './stamp.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
export interface StampArgs {
  text: string;
  tooltip?: string;
  color?: string;
}

interface Signature {
  Element: HTMLElement;
  Args: StampArgs;
}

export default class Stamp extends Component<Signature> {
  get color() {
    if (this.args.color === 'green') {
      return 'bg-green-light text-green-dark border-green-light';
    }

    if (this.args.color === 'yellow') {
      return 'bg-yellow text-yellow-dark border-yellow';
    }

    return 'bg-gray-light text-gray border-gray-light';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Stamp': typeof Stamp;
    'installation-new/stamp': typeof Stamp;
  }
}
