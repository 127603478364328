/* import __COLOCATED_TEMPLATE__ from './merged-secondary-replies-banner.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onButtonClick: () => void;
  };
}

const MergedSecondaryRepliesBanner = templateOnlyComponent<Signature>();
export default MergedSecondaryRepliesBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::MergedSecondaryRepliesBanner': typeof MergedSecondaryRepliesBanner;
    'inbox2/merged-secondary-replies-banner': typeof MergedSecondaryRepliesBanner;
  }
}
