/* import __COLOCATED_TEMPLATE__ from './controls.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type OnboardingStep from 'embercom/lib/inbox2/onboarding/step';
interface Args {
  isFirstStep: boolean;
  isLastStep: boolean;
  step: OnboardingStep;
  nextStep: () => unknown;
  didPressWrongKeys: boolean;
  previousStep: () => unknown;
  onFinish: () => unknown;
  onExit: () => unknown;
  showExit: boolean;
  onSkipAll: () => unknown;
  showSkipAll: boolean;
}

interface Signature {
  Args: Args;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class Inbox2OnboardingControls extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::Controls': typeof Inbox2OnboardingControls;
    'inbox2/onboarding/controls': typeof Inbox2OnboardingControls;
  }
}
