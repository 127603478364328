/* import __COLOCATED_TEMPLATE__ from './next-sla.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type ConversationSlaState from 'embercom/objects/inbox/conversation-sla-state';
import { SlaStatus } from 'embercom/objects/inbox/conversation-sla-state';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Arguments {
  conversation: ConversationSummary | Conversation | ConversationTableEntry;
  isInteractive?: boolean;
}

interface Signature {
  Args: Arguments;
  Element: HTMLElement;
}

export default class NextSla extends Component<Signature> {
  private get conversation() {
    return this.args.conversation;
  }

  get hasSla() {
    return this.nextBreachAt !== undefined || this.nextPausedSlaState !== undefined;
  }

  get nextBreachAt() {
    return this.conversation.nextBreachTime;
  }

  get nextPausedSlaState() {
    let slaStates = this.conversation?.conversationSlaStates || [];
    let pausedSlaStates = slaStates.filter((state) => state.status === SlaStatus.Paused);

    if (pausedSlaStates.length === 0) {
      return undefined;
    }

    let sortSlaStatesDescByDuration = (
      slaStateA: ConversationSlaState,
      slaStateB: ConversationSlaState,
    ) => {
      if (!slaStateA.remainingSeconds) {
        return 1; // Move items with null to the end
      }
      if (!slaStateB.remainingSeconds) {
        return -1; // Move items with null to the end
      }
      return slaStateA.remainingSeconds - slaStateB.remainingSeconds;
    };

    return pausedSlaStates.sort(sortSlaStatesDescByDuration)[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Sla::NextSla': typeof NextSla;
    'inbox2/sla/next-sla': typeof NextSla;
  }
}
