/* import __COLOCATED_TEMPLATE__ from './pull-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import type PullConversationService from 'embercom/services/pull-conversation-service';
import { taskFor } from 'ember-concurrency-ts';
import type TracingService from 'embercom/services/tracing';

export default class PullConversation extends Component {
  @service declare session: Session;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare pullConversationService: PullConversationService;
  @service declare intl: IntlService;
  @service declare tracing: TracingService;

  get isPulling() {
    return taskFor(this.pullConversation).isRunning;
  }

  @dropTask({ maxConcurrency: 1 })
  *pullConversation() {
    if (!((yield this.pullConversationService.doesTeammateHaveLbaInbox()) as boolean)) {
      this.tracing.tagRootSpan({ teammate_has_no_lba_inbox: 'true' });
      this.notificationsService.notifyError(
        this.intl.t('inbox.pull-conversation.teammate-has-no-lba-inbox'),
      );
      return;
    }
    this.recordAnalyticsEvent();
    try {
      let response = (yield post('/ember/conversations/pull_conversation', {
        app_id: this.session.workspace.id,
      })) as boolean;
      let message =
        response === true
          ? this.intl.t('inbox.pull-conversation.new-conversation-assigned')
          : this.intl.t('inbox.pull-conversation.none-available');
      this.tracing.tagRootSpan({ pulled_new_conversations: response === true });
      this.notificationsService.notifyConfirmation(message);
    } catch (error) {
      this.tracing.onError(error, 'pull_conversation');
      this.notificationsService.notifyError(this.intl.t('inbox.pull-conversation.error'));
    }
  }

  recordAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'pull_conversation_button',
      action: 'clicked',
      place: 'inbox2',
      section: 'me_inbox',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::PullConversation': typeof PullConversation;
    'inbox2/left-nav/pull-conversation': typeof PullConversation;
  }
}
