/* import __COLOCATED_TEMPLATE__ from './snooze-button-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { later } from '@ember/runloop';
import { action } from '@ember/object';
import $ from 'jquery';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';
import SnoozeOptions from 'embercom/components/mixins/snooze-options';
import { SNOOZED_UNTIL_MAP, DIGITS_ONLY_REGEX } from 'embercom/lib/inbox/constants';
import ENV from 'embercom/config/environment';
import { BROWSER_SUPPORTS_TOUCH } from 'embercom/lib/screen-awareness';
import { task, timeout } from 'ember-concurrency';
import moment from 'moment-timezone';

export default Component.extend(ComponentDropdownToggler, SnoozeOptions, {
  options: SNOOZED_UNTIL_MAP,
  tagName: 'span',
  appService: service(),
  app: readOnly('appService.app'),
  preventDropdownToggleFromWithin: true,
  classNames: ['u__relative', 'u__right', 'js__inbox-snooze-button-selector-opener'],

  _preventClosing(e) {
    if (this.isDestroyed) {
      return false;
    }
    let $target = $(this.element).find(e.target);
    return $target.parents('.overlay').length > 0;
  },

  init() {
    this._super(...arguments);
    this.initSnoozeProperties();
    this.set('today', moment());
  },

  openSubcontent() {
    this.set('showingSubcontent', true);
    this.closeSubcontentAfter1sec.cancelAll();
  },

  closeSubcontentImmediately() {
    this.set('showingSubcontent', false);
  },

  closeSubcontent() {
    this.closeSubcontentAfter1sec.perform();
  },

  closeSubcontentAfter1sec: task(function* () {
    yield timeout(ENV.APP._1000MS);
    this.set('showingSubcontent', false);
  }).drop(),

  setSubcontentPosition(element) {
    if (element.getBoundingClientRect().right > document.documentElement.clientWidth) {
      element.classList.add('inbox__custom-snooze-until__subcontent__left');
    }
  },

  closeSubcontentOnMouseLeave: action(function () {
    if (!BROWSER_SUPPORTS_TOUCH) {
      this.closeSubcontent();
    }
  }),

  actions: {
    openSubcontentOnMouseEnter() {
      if (!BROWSER_SUPPORTS_TOUCH) {
        this.openSubcontent();
      }
    },

    openOrCloseSubcontentImmediatelyOnClick() {
      if (BROWSER_SUPPORTS_TOUCH) {
        if (this.showingSubcontent) {
          this.closeSubcontentImmediately();
        } else {
          this.openSubcontent();
        }
      }
    },

    snoozeConversationAndCloseDropdown(index) {
      this.snoozeConversation(index);
      later(() => this.send('closeDropdown'));
    },

    hoursSnoozeConversationAndCloseDropdown() {
      if (!DIGITS_ONLY_REGEX.test(this.selectedHours) || this.selectedHours <= 0) {
        this.set('selectedHours', '');
        return;
      }
      this.hoursSnoozeConversation(this.selectedHours);
      later(() => this.send('closeDropdown'));
    },

    customSnoozeConversationAndCloseDropdown() {
      if (this.disableSnoozeButton) {
        return;
      }

      let selectedDayMoment = this.selectedDayMoment;
      let selectedTimeInSecondsSinceMidnight = this.selectedTimeInSecondsSinceMidnight;

      let rawSnoozedUntilTime = selectedDayMoment
        .startOf('day')
        .add(selectedTimeInSecondsSinceMidnight, 'seconds');
      let utcOffset = rawSnoozedUntilTime.utcOffset();
      let correctedSecondsSinceMidnight = selectedTimeInSecondsSinceMidnight;

      // If the timezone has changed between midnight and the time to snooze until, the snooze timer will be off by the difference
      // We need to manipulate the snoozed until time to get the correct value
      if (selectedDayMoment.startOf('day').utcOffset() !== utcOffset) {
        let offsetDiff = selectedDayMoment.startOf('day').utcOffset() - utcOffset;
        let offsetValueInSeconds = Math.abs(offsetDiff) * 60;

        // Positive: we moved backward and adding the original selectedTimeInSecondsSinceMidnight will stop short of the desired snooze time
        // Negative: we moved forward and adding the original selectedTimeInSecondsSinceMidnight sets the timer later than the desired snooze time
        if (offsetDiff > 0) {
          correctedSecondsSinceMidnight += offsetValueInSeconds;
        } else {
          correctedSecondsSinceMidnight -= offsetValueInSeconds;
        }
      }

      let timezoneCorrectedSnoozedUntilTime = selectedDayMoment
        .startOf('day')
        .add(correctedSecondsSinceMidnight, 'seconds')
        .toISOString();

      this.customSnoozeConversation(timezoneCorrectedSnoozedUntilTime);
      this.closeSubcontentImmediately();
      later(() => this.send('closeDropdown'));
    },

    unsnoozeConversation() {
      this.unsnoozeConversation();
    },
  },
});
