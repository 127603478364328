/* import __COLOCATED_TEMPLATE__ from './ticket-attributes-tab-body-stacked.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const TicketAttributesTabBodyStacked = templateOnlyComponent<Signature>();
export default TicketAttributesTabBodyStacked;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttributesTabBodyStacked': typeof TicketAttributesTabBodyStacked;
    'inbox2/ticket-attributes-tab-body-stacked': typeof TicketAttributesTabBodyStacked;
  }
}
