/* import __COLOCATED_TEMPLATE__ from './recent-events.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import ActivityFeedItem from 'embercom/models/activity-feed-item';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { readOnly, notEmpty, gt, or, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DidChangeAttrs from 'ember-did-change-attrs';

const DEFAULT_VISIBLE_EVENT_COUNT = 5;
const EVENT_PAGE_SIZE = 10;

export default Component.extend(DidChangeAttrs, {
  store: service(),
  intercomEventService: service(),
  didChangeAttrsConfig: {
    attrs: ['user'],
  },
  firstLoad: true,
  canLoadMore: true,
  init() {
    this._super(...arguments);
    this.set('eventItems', []);
    this.fetchEventStream.perform();
  },

  didChangeAttrs(changes) {
    this._super(...arguments);
    if (changes.user) {
      this.set('eventItems', []);
      this.set('firstLoad', true);
      this.set('canLoadMore', true);
      this.fetchEventStream.perform();
    }
  },
  showInitSpinner: and('isFetchingActivities', 'firstLoad'),
  isFetchingActivities: readOnly('fetchEventStream.isRunning'),
  hasActivities: notEmpty('eventItems'),
  numberToDisplay: DEFAULT_VISIBLE_EVENT_COUNT,
  visibleEvents: computed('eventItems.[]', 'numberToDisplay', function () {
    return this.eventItems.slice(0, this.numberToDisplay);
  }),
  canShowMore: computed('eventItems.length', 'numberToDisplay', function () {
    return this.eventItems.length - this.numberToDisplay > 0;
  }),
  isLoadMoreVisible: or('canShowMore', 'canLoadMore'),
  isShowLessVisible: gt('visibleEvents.length', DEFAULT_VISIBLE_EVENT_COUNT),
  options: computed('app', 'user', function () {
    let options = {
      app_id: this.get('app.id'),
      timeline_type: 'event',
      followed_user_events: false,
      aggregate: false,
      profileType: 'user',
      intercom_user_id: this.get('user.id'),
    };
    return options;
  }),

  fetchEventStream: task(function* (beforeDate, timestampEventId) {
    let queryOptions = this.options;
    if (beforeDate) {
      queryOptions.before = beforeDate;
    }
    if (timestampEventId) {
      queryOptions.timestamp_event_id = timestampEventId;
    }
    let result = yield ActivityFeedItem.getFeedItems(queryOptions);
    if (result) {
      this.eventItems.pushObjects(result.items);
      this.set('firstLoad', false);
      this.set('canLoadMore', result.totalCount === result.pageSize);
    }
  }).restartable(),

  actions: {
    loadMore() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'load_more_clicked',
        object: 'events_feed',
        context: 'the_user_loaded_more_events',
      });
      this.set('numberToDisplay', this.numberToDisplay + EVENT_PAGE_SIZE);

      if (!this.canShowMore) {
        let beforeDate = this.eventItems.lastObject.created_at;
        let timestampEventId = this.eventItems.lastObject.id;
        this.fetchEventStream.perform(beforeDate, timestampEventId);
      }
    },
    loadLess() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'show_less_clicked',
        object: 'events_feed',
        context: 'the_user_loaded_more_events',
      });
      this.set('numberToDisplay', DEFAULT_VISIBLE_EVENT_COUNT);
    },
  },
});
