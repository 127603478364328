/* import __COLOCATED_TEMPLATE__ from './edit-menu-default.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type IntlService from 'embercom/services/intl';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type Session from 'embercom/services/session';
import ViewSummary from 'embercom/objects/inbox/view-summary';
import { helper } from '@ember/component/helper';
import { type SearchableData } from 'embercom/objects/inbox/searchable-document';
import TeamSummary from 'embercom/objects/inbox/team-summary';

interface Arguments {
  text: string;
  isHovering: boolean;
  searchPlaceholder: string;
  disablePinningTooltip?: string;
  entityType: SearchableType;
  inboxCategory: InboxCategory;
  pinnedInboxes: Inbox[];
  addPin: (inbox: Inbox) => void;
  removePin: (inbox: Inbox) => void;
  onCreateCustomFolder: () => void;
  togglePin: () => void;
  onCreateView: () => void;
  onEditView: (row: ViewSummary, hidePopover: () => void | null) => void;
  expandFolder: () => void;
  hideCurrentAdminFromResults?: boolean;
}

interface Signature {
  Args: Arguments;
}

export default class EditMenuDefault extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  isTeamAndIsCurrentAdminMember = helper(function ([entity]: [SearchableData]) {
    return entity instanceof TeamSummary && entity.isMember;
  });

  isAiChatbotView = helper(function ([entity]: [SearchableData]) {
    return entity instanceof ViewSummary && entity.isAiChatbotView;
  });

  get yourInboxId(): number {
    return this.session.teammate.id;
  }

  get isAdminType(): boolean {
    return this.args.entityType === SearchableType.Admin;
  }

  get isDefaultFolderType(): boolean {
    return [InboxCategory.Admin, InboxCategory.Team, InboxCategory.View].includes(
      this.args.inboxCategory,
    );
  }

  @action trackOpenPopover() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'inbox_list_dropdown',
      inbox_type: this.args.inboxCategory,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Default::EditMenuDefault': typeof EditMenuDefault;
    'inbox2/left-nav/folders/default/edit-menu-default': typeof EditMenuDefault;
  }
}
