/* import __COLOCATED_TEMPLATE__ from './sms-restricted-replies-banner.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    gotoLatestConversation: () => void;
    allowNewSmsConversation: () => void;
  };
}

const SmsRestrictedRepliesBanner = templateOnlyComponent<Signature>();
export default SmsRestrictedRepliesBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SmsRestrictedRepliesBanner': typeof SmsRestrictedRepliesBanner;
    'inbox2/sms-restricted-replies-banner': typeof SmsRestrictedRepliesBanner;
  }
}
