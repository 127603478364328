/* import __COLOCATED_TEMPLATE__ from './basic-javascript.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  forLoggedInUsers: boolean;
  selectedProgrammingLanguage: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class BasicJavascript extends Component<Signature> {
  @service appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  constructor(owner: never, args: Args) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  @action
  onCopyCode(installationType: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'copy_code_snippet',
      metadata: {
        installation_type: installationType,
        app_type: 'basic_javascript',
        programming_language: this.args.selectedProgrammingLanguage,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::CodeSnippets::BasicJavascript': typeof BasicJavascript;
    'installation-new/web/initial-setup/code-snippets/basic-javascript': typeof BasicJavascript;
  }
}
