/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Tags extends Component {
  @tracked taggingModalOpened = false;
  @service intercomEventService;

  @action
  openTaggingModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'tagging_modal',
    });

    this.taggingModalOpened = true;
  }
}
