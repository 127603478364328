/* import __COLOCATED_TEMPLATE__ from './sidebar-sections.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    openSectionId?: string;
    sidebarSectionId?: string;
    name: string;
    onOpenSectionChange: () => void;
    bodyClassNames?: string;
  };
  Blocks: {
    default: [];
  };
}

const SidebarSections = templateOnlyComponent<Signature>();
export default SidebarSections;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationDetailsSidebar::SidebarSections': typeof SidebarSections;
    'inbox2/onboarding/conversation-details-sidebar/sidebar-sections': typeof SidebarSections;
  }
}
