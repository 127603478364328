/* import __COLOCATED_TEMPLATE__ from './web-installation-snippet-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class WebInstallationSnippetSelectComponent extends Component {
  @service intercomEventService;

  @tracked leftAlign = false;
  @tracked isStartScreen = false;
  @tracked selectedValue = undefined;

  constructor() {
    super(...arguments);
    if (this.args.leftAlign) {
      this.leftAlign = this.args.leftAlign;
    }
    if (this.args.isStartScreen) {
      this.isStartScreen = this.args.isStartScreen;
    }
    if (this.args.selectedValue) {
      this.selectedValue = this.args.selectedValue;
    }
  }

  @action
  onSelectItem(value) {
    this.selectedValue = value;
    if (this.args.onSelect) {
      this.args.onSelect(value);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'select',
      object: 'server_side_language',
      place: 'identity_verification_settings',
      context: value,
    });
  }

  get options() {
    if (this.args.options) {
      return this.args.options;
    }
    return [
      { text: 'Rails (Ruby)', value: 'rails' },
      { text: 'Django (Python 3)', value: 'django' },
      { text: 'PHP', value: 'php' },
    ];
  }
}
