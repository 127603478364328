/* import __COLOCATED_TEMPLATE__ from './user-details-title.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// This is required to pass isEditing to the editable-user-name
// and have the two way binding so we can hide the company name in edit mode.
export default class UserDetailsTitle extends Component {
  @tracked isEditingUserName = false;
}
