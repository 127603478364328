/* import __COLOCATED_TEMPLATE__ from './inbox-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type InboxState from 'embercom/services/inbox-state';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { isSameInbox } from 'embercom/objects/inbox/inboxes/inbox';
// @ts-ignore
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Team from 'embercom/objects/inbox/inboxes/team';
import View from 'embercom/objects/inbox/inboxes/view';
import Admin from 'embercom/objects/inbox/inboxes/admin';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';
import { transformAvatarUrl } from 'embercom/objects/inbox/inboxes/avatars';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import type Router from '@ember/routing/router-service';
import { type InboxFolder } from 'embercom/objects/inbox/inbox-folder';
import { captureException } from 'embercom/lib/sentry';

interface Arguments {
  inbox: Inbox;
  isHoverable: boolean;
  isDraggable: boolean;
  forceActive?: boolean;
  onPopoverShow?: () => void;
  onPopoverHide?: () => void;
  removePin?: (inbox: Inbox) => void;
  translationKey?: string;
  onEditView?: (row: ViewSummary, hidePopover: Function | null) => void;
  // If the inbox is being rendered as part of a custom folder this argument will have the
  // id of the custom folder
  customFolderId?: number;
  customFolders?: InboxFolder[];
}

interface Signature {
  Args: Arguments;
}

export default class InboxListItem extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;
  @service declare inbox2Counters: Inbox2Counters;
  @service declare router: Router;

  @tracked isHovering = false;

  get isTeam() {
    return this.args.inbox instanceof Team;
  }

  get isView() {
    return this.args.inbox instanceof View;
  }

  get isMentions() {
    return this.args.inbox instanceof Mentions;
  }

  get shouldShowCount() {
    if (this.args.inbox instanceof View && this.args.inbox.viewSummary.isAiChatbotView) {
      return false;
    }
    return true;
  }

  get count() {
    return this.inbox2Counters.countForInbox(this.args.inbox);
  }

  get avatar() {
    let url: string = DefaultAvatars.admins[128];

    if (this.args.inbox instanceof Admin) {
      url = this.args.inbox.avatar;
    }

    return transformAvatarUrl(url, this.session.darkModeEnabled);
  }

  get isActive() {
    if (this.args.forceActive) {
      return true;
    }

    let customFolderIdQueryParam = this.router.currentRoute?.queryParams.custom_folder_id;

    let customFolderIdQueryParamValid =
      (this.args.customFolders ?? []).find(
        (folder) => folder.id.toString() === customFolderIdQueryParam,
      ) !== undefined;

    if (customFolderIdQueryParamValid && this.args.customFolderId === undefined) {
      // If there is a query param and this item is being rendered as part of a default folder
      return false;
    } else if (customFolderIdQueryParamValid && this.args.customFolderId !== undefined) {
      // If there is a query param and this item is being rendered as part of a custom folder
      return (
        isSameInbox(this.args.inbox, this.inboxState.activeInbox) &&
        customFolderIdQueryParam === this.args.customFolderId?.toString()
      );
    } else if (!customFolderIdQueryParamValid && this.args.customFolderId === undefined) {
      // If there isn't a query param or it's invalid and this item is being rendered as part of a default folder
      return isSameInbox(this.args.inbox, this.inboxState.activeInbox);
    } else if (!customFolderIdQueryParamValid && this.args.customFolderId !== undefined) {
      // If there isn't a query param or it's invalid and this item is being rendered as part of a custom folder
      return false;
    }

    // We should never get here
    captureException(new Error('Unexpected isActive state'), {
      extra: {
        customFolderIdQueryParam,
        customFolderIds: this.args.customFolders?.map((folder) => folder.id),
        customFolderIdQueryParamValid,
        customFolderId: this.args.customFolderId,
      },
    });

    return false;
  }

  get isProductIAEnabled() {
    return this.session.hasOptedInForProductIa;
  }

  get activeClasses() {
    let classes = [];
    if (this.isActive) {
      if (this.isProductIAEnabled) {
        classes.push('bg-gray-light');
        if (this.session.darkModeEnabled) {
          classes.push('dark:bg-dm-gray-dark');
        }
      } else {
        classes.push('bg-white');
        if (this.session.darkModeEnabled) {
          classes.push('dark:bg-dm-black');
        }
      }
    }
    return classes.join(' ');
  }

  get hoverClasses() {
    let classes = [];
    if (this.args.isHoverable) {
      if (this.isProductIAEnabled) {
        classes.push('hover:bg-white dark:hover:bg-dm-gray-darker');
      } else {
        classes.push('hover:bg-gray-light dark:hover:bg-dm-gray-dark dark:hover:text-dm-white');
      }
    }
    return classes.join(' ');
  }

  @action trackHover() {
    this.isHovering = true;
  }

  @action trackInboxNavigation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'inbox',
      place: 'inbox',
      section: 'inbox_list_sidebar',
      selected_inbox: this.args.inbox?.type,
      selected_inbox_id: this.args.inbox?.id,
      view_type:
        this.args.inbox instanceof View ? this.args.inbox.viewSummary.systemType : undefined,
      has_dynamic_admin_assignee_predicates:
        this.args.inbox instanceof View
          ? this.args.inbox.viewSummary.hasDynamicAdminAssigneePredicates
          : undefined,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::InboxListItem': typeof InboxListItem;
    'inbox2/left-nav/inbox-list-item': typeof InboxListItem;
  }
}
