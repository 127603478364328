/* import __COLOCATED_TEMPLATE__ from './editable-attribute-row.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';

interface Args {
  attribute: TicketAttributeSummary;
  formSubmitted: boolean;
}

interface Signature {
  Args: Args;
}

export default class EditableAttributeRow extends Component<Signature> {
  get showError(): boolean {
    return this.args.formSubmitted && this.args.attribute.showErrorOnCreate;
  }

  get descriptor() {
    return this.args.attribute.descriptor;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::EditableAttributeRow': typeof EditableAttributeRow;
    'inbox2/ticket-attribute/editable-attribute-row': typeof EditableAttributeRow;
  }
}
