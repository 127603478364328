/* import __COLOCATED_TEMPLATE__ from './progress-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

interface Args {
  refreshRateMs?: number;
  onClick: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

export default class UnnamedComponent extends Component<Signature> {
  @service intercomEventService: any;

  @tracked isHovered = false;
  @tracked isFocused = false;

  progressButtonAnimationOffset = -100;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.animateProgressButton();
  }

  get colorClasses() {
    if (this.isHovered || this.isFocused) {
      return 'bg-sky-dark text-blue dark:bg-dm-blue-dark dark:text-dm-blue';
    } else {
      return 'bg-gray-light dark:bg-dm-gray-dark';
    }
  }

  @action onClick({ buttonClicked = false } = {}) {
    this.args.onClick();
    this.progressButtonAnimationOffset = -100;
    if (buttonClicked) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'show_more',
        context: 'your_achievements',
      });
    }
  }

  @action animateProgressButton() {
    if (!this.args.refreshRateMs) {
      return;
    }
    let root = document.querySelector(':root');
    if (this.progressButtonAnimationOffset < 0) {
      requestAnimationFrame(() => {
        let fps = 1 / 60;
        let refreshRateSeconds = this.args.refreshRateMs! / 1000;
        let increment = 100 / (refreshRateSeconds / fps);

        this.progressButtonAnimationOffset = Math.min(
          0,
          this.progressButtonAnimationOffset + increment,
        );

        if (root instanceof HTMLElement) {
          root.style.setProperty(
            '--achievements__progress-button-offset',
            `${this.progressButtonAnimationOffset}%`,
          );
        }

        this.animateProgressButton();
      });
    } else {
      this.onClick();
      this.animateProgressButton();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::YourAchievements::ProgressButton': typeof UnnamedComponent;
    'inbox2/your-achievements/progress-button': typeof UnnamedComponent;
  }
}
