/* import __COLOCATED_TEMPLATE__ from './bot-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type CustomBot from 'embercom/objects/inbox/renderable/custom-bot';
import type BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import type ChannelAndReplyTimeExpectation from 'embercom/objects/inbox/renderable/channel-and-reply-time-expectation';
import { tracked } from '@glimmer/tracking';

interface BotContentRenderablePart extends RenderablePart {
  renderableData: CustomBot | BotComment | ChannelAndReplyTimeExpectation;
}

interface BotContentPartGroup extends PartGroup {
  parts: Array<BotContentRenderablePart>;
}

interface Args {
  partGroup: BotContentPartGroup;
}

interface Signature {
  Args: Args;
}

export default class TicketStreamTeammateContentComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @tracked isHovered = false;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get avatarObject() {
    if (this.customBotSummary) {
      return {
        id: this.customBotSummary.id,
        name: this.intl.t('inbox.custom-bot', { name: this.customBotSummary.title }),
        imageURL: this.renderableData.entity.imageURL,
      };
    } else {
      return this.renderableData.creatingEntity.object;
    }
  }

  get avatarRoute() {
    if (this.customBotSummary) {
      return 'apps.app.operator.custom-bots.custom-bot.show';
    } else {
      return 'apps.app.admins.admin';
    }
  }

  get customBotSummary() {
    return 'customBotSummary' in this.renderableData && this.renderableData.customBotSummary;
  }

  get firstConversationPartBackgroundStyles() {
    let renderablePart = this.args.partGroup.parts[0].renderableData;
    let hoverStyles = renderablePart.ticketStyles?.backgroundColorHover;

    if (this.isHovered && hoverStyles) {
      return hoverStyles;
    }

    return renderablePart.ticketStyles?.backgroundColor;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketStream::PartGroups::BotContent': typeof TicketStreamTeammateContentComponent;
    'inbox2/ticket-stream/part-groups/bot-content': typeof TicketStreamTeammateContentComponent;
  }
}
