/* import __COLOCATED_TEMPLATE__ from './hosted-messenger-opener.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import openCenteredWindow from 'embercom/lib/open-centered-window';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';

interface Args {
  onHostedMessengerOpen: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLButtonElement;
  Blocks: {
    default: [];
  };
}

export default class Inbox2HostedMessengerOpener extends Component<Signature> {
  @tracked hostedMessengerWindowOpen = false;
  @service declare router: Router;
  @service declare session: Session;

  waitForChildWindow(childWindow: Window) {
    if (childWindow.closed) {
      this.hostedMessengerWindowOpen = false;
    } else {
      later(this.waitForChildWindow.bind(this, childWindow), 300);
    }
  }

  @action openHostedMessengerWindow() {
    let url = this.router.urlFor(`apps.app.test-conversation`, this.session.workspace, {
      queryParams: { onboardingHome: false },
    });
    let window = openCenteredWindow(url, 800, 1280, 'Hosted Messenger') as Window;

    if (window) {
      this.waitForChildWindow(window);
      window.focus();
      this.hostedMessengerWindowOpen = true;
      this.args.onHostedMessengerOpen();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::HostedMessengerOpener': typeof Inbox2HostedMessengerOpener;
    'inbox2/onboarding/hosted-messenger-opener': typeof Inbox2HostedMessengerOpener;
  }
}
