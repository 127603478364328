/* import __COLOCATED_TEMPLATE__ from './notification-undo-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type Notification } from 'embercom/services/snackbar';
import type Snackbar from 'embercom/services/snackbar';
import { type Hotkey } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { action } from '@ember/object';

interface Args {
  notification: Notification;
}

interface Signature {
  Args: Args;
}

export default class NotificationUndoContent extends Component<Signature> {
  @service declare snackbar: Snackbar;
  @service declare inboxHotkeys: InboxHotkeys;

  readonly undoKey: Hotkey;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.undoKey = this.inboxHotkeys.hotkeysMap[HotkeyID.Undo];
  }

  @action handleUndoKey() {
    if (this.snackbar.notifications?.length) {
      let topMostNotification = this.snackbar.notifications.pop();
      topMostNotification?.onButtonClick();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::NotificationUndoContent': typeof NotificationUndoContent;
    'inbox2/left-nav/notification-undo-content': typeof NotificationUndoContent;
  }
}
