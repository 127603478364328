/* import __COLOCATED_TEMPLATE__ from './sla.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type ConversationSlaState from 'embercom/objects/inbox/conversation-sla-state';
import { SlaStatus } from 'embercom/objects/inbox/conversation-sla-state';

interface Arguments {
  nextBreachAt?: Date;
  slaState?: ConversationSlaState;
  isInteractive?: boolean;
}

interface Signature {
  Args: Arguments;
  Element: HTMLElement;
}

export default class Sla extends Component<Signature> {
  get nextBreachAt() {
    return this.args.nextBreachAt ?? this.args.slaState?.nextBreachAt;
  }

  get isPaused() {
    return this.args.slaState?.status === SlaStatus.Paused;
  }

  get remainingSeconds() {
    return this.args.slaState?.remainingSeconds;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Sla::Sla': typeof Sla;
    'inbox2/sla/sla': typeof Sla;
  }
}
