/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import {
  INSTALLATION_METHOD,
  INSTALLATION_PLATFORMS,
  type InstallationMethod,
  type InstallationPlatform,
} from 'embercom/components/installation-new/constants';
import { inject as service } from '@ember/service';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type SdkAppService from 'embercom/services/sdk-app-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  selectedAccordion: 'messenger-setup' | 'idv-setup' | 'push-notifications' | '';
  onAccordionChange: (accordion: string) => void;
  selectedTab: InstallationPlatform;
  onSelectTab: (tab: InstallationPlatform) => void;
}

interface Signature {
  Args: Args;
  Element: any;
}

export default class Setup extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare sdkAppService: SdkAppService;
  @service declare appService: any;

  @tracked selectedInstallationMethod: InstallationMethod = INSTALLATION_METHOD.IOS;
  @tracked showUserHashCheckerModal = false;
  @tracked selectedReactNativeInstallationPlatform = INSTALLATION_PLATFORMS.IOS;

  get mobileSdkApiSecret() {
    if (this.selectedInstallationMethod === INSTALLATION_METHOD.REACT_NATIVE) {
      switch (this.selectedReactNativeInstallationPlatform) {
        case INSTALLATION_PLATFORMS.IOS:
          return this.sdkAppService.iosApiSecret;
        case INSTALLATION_PLATFORMS.ANDROID:
          return this.sdkAppService.androidApiSecret;
      }
    }

    return this.selectedInstallationMethod === INSTALLATION_METHOD.IOS
      ? this.sdkAppService.iosApiSecret
      : this.sdkAppService.androidApiSecret;
  }

  @action
  setSelectedReactNativeInstallationPlatform(platform: INSTALLATION_PLATFORMS) {
    this.selectedReactNativeInstallationPlatform = platform;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    switch (this.args.selectedTab) {
      case INSTALLATION_PLATFORMS.ANDROID:
        this.selectedInstallationMethod = INSTALLATION_METHOD.ANDROID;
        break;
      case INSTALLATION_PLATFORMS.IOS:
        this.selectedInstallationMethod = INSTALLATION_METHOD.IOS;
        break;
      default:
        this.selectedInstallationMethod = INSTALLATION_METHOD.REACT_NATIVE;
    }
  }

  get installationMethods() {
    let shouldShowReactNative = this.args.selectedAccordion !== 'push-notifications';

    return [
      {
        id: INSTALLATION_METHOD.IOS,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.ios-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/apple.svg'),
        instructionsTitle: this.intl.t('apps.app.settings.installation.installation.new.ios.title'),
      },
      {
        id: INSTALLATION_METHOD.ANDROID,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.android-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/android.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.android.title',
        ),
      },
      ...(shouldShowReactNative
        ? [
            {
              id: INSTALLATION_METHOD.REACT_NATIVE,
              label: this.intl.t(
                'apps.app.settings.installation.installation.new.methods.react-native-label',
              ),
              iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/react.svg'),
              instructionsTitle: this.intl.t(
                'apps.app.settings.installation.installation.new.react-native.title',
              ),
            },
          ]
        : []),
    ];
  }

  get anyInitialMobileInstall() {
    return (
      this.sdkAppService.iosSdkApp.is_activated || this.sdkAppService.androidSdkApp.is_activated
    );
  }

  get anySecureMobileInstall() {
    return (
      this.sdkAppService.iosSdkApp.identityVerified ||
      this.sdkAppService.androidSdkApp.identityVerified
    );
  }

  get pushNotificationsEnabled() {
    return (
      this.sdkAppService.iosSdkApp.pushCredentials.length > 0 ||
      this.sdkAppService.androidSdkApp.pushCredentials.length > 0
    );
  }

  @action openUserHashChecker() {
    this.showUserHashCheckerModal = true;
  }

  @action closeUserHashChecker() {
    this.showUserHashCheckerModal = false;
  }

  @action
  onSelectInstallationMethod(installationMethod: InstallationMethod) {
    switch (installationMethod) {
      case INSTALLATION_METHOD.ANDROID:
        this.selectedInstallationMethod = INSTALLATION_METHOD.ANDROID;
        this.args.onSelectTab(INSTALLATION_PLATFORMS.ANDROID);
        break;
      case INSTALLATION_METHOD.IOS:
        this.selectedInstallationMethod = INSTALLATION_METHOD.IOS;
        this.args.onSelectTab(INSTALLATION_PLATFORMS.IOS);
        break;
      default:
        this.selectedInstallationMethod = INSTALLATION_METHOD.REACT_NATIVE;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::Setup': typeof Setup;
    'installation-new/mobile/setup': typeof Setup;
  }
}
