/* import __COLOCATED_TEMPLATE__ from './recipient-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { later } from '@ember/runloop';
import { taskFor } from 'ember-concurrency-ts';
import { schedule } from '@ember/runloop';
import { isValidEmail } from 'embercom/lib/email';
import UserSummary, { getParticipantChanges } from 'embercom/objects/inbox/user-summary';

import {
  DropdownNoMatchForType,
  DropdownSuggestion,
  DropdownUnselectableSuggestion,
} from 'embercom/objects/inbox/search/dropdown-items';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import {
  type ReplyChannel,
  replyChannelIsChat,
  replyChannelIsEmail,
  replyChannelIsSms,
  replyChannelIsWhatsapp,
  replyChannelIsMultiParticipant,
} from 'embercom/objects/inbox/composer-pane';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';

interface Args {
  preventInput?: boolean;
  onRecipientChange: (recipients?: UserSummary[]) => void;
  recipients: UserSummary[];
  originalRecipients?: UserSummary[];
  conversationId?: number;
  setRecipientErrors: Function;
  isCreatingConversation?: boolean;
  isCreatingTicket?: boolean;
  replyChannel: ReplyChannel;
  selectNextInput?: Function;
  showRecipientSelector?: Function;
  onBlur?: Function;
  replyToData?: Array<UserSummary>;
  autofocus?: boolean;
  isSideConversation?: boolean;
}

interface Signature {
  Args: Args;
}

type SearchListItemType =
  | DropdownSuggestion
  | DropdownNoMatchForType
  | DropdownUnselectableSuggestion;

const KEYCODE_ENTER = 13;
const MAX_PARTICIPANTS = 10; // backend max 50, assuming higher than required for usage

export default class RecipientSelector extends Component<Signature> {
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare session: Session;
  @service declare intl: IntlService;

  @tracked searchList: SearchListItemType[] = [];
  @tracked inputText = '';
  @tracked searchHasNoResults = false;
  @tracked selectedRecipientIndex?: number;
  @tracked isRemovingRecipient = false;
  @tracked isSelectingRecipient = false;
  @tracked isFocused = false;
  @tracked canAccessContacts = false;
  @trackedReset('args.conversationId') hasFocused = false;

  @ref('recipient-input') declare recipientInput: HTMLElement;

  popover?: { hide: () => void; show: () => void };

  readonly keycodesThatCanPropagate = [KEYCODE_ENTER];

  readonly replyChannelIsChat = replyChannelIsChat;
  readonly replyChannelIsEmail = replyChannelIsEmail;
  readonly replyChannelIsSms = replyChannelIsSms;
  readonly replyChannelIsWhatsapp = replyChannelIsWhatsapp;
  readonly replyChannelIsMultiParticipant = replyChannelIsMultiParticipant;
  readonly getParticipantChanges = getParticipantChanges;

  readonly list = useResource(this, NavigableSelection, () => ({
    items: this.searchList,
    equalityComparatorFn: this.equalityComparatorFn,
  }));

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.canAccessContacts = this.session.teammate.permissions.canAccessContacts;
  }

  get inputLooksLikeEmail() {
    return this.inputText?.match(/.+\@.+\..+/);
  }

  get autofocus() {
    return (
      this.args.isCreatingConversation || this.args.isCreatingTicket || this.args.autofocus || false
    );
  }

  private recipientError(userSummary: UserSummary, index = 0): string | void {
    if (this.args.isSideConversation && this.isAdminEmail(userSummary.email)) {
      return this.intl.t('inbox.new-conversation.errors.recipient-is-admin');
    }

    if (userSummary && !userSummary.id && !isValidEmail(userSummary.email)) {
      return this.intl.t('inbox.new-conversation.errors.invalid-contact');
    }

    if (
      this.args.isCreatingConversation &&
      this.replyChannelIsChat(this.args.replyChannel) &&
      userSummary?.isNewUser
    ) {
      return this.intl.t('inbox.new-conversation.errors.recipient-has-email-only');
    }

    if (
      this.args.isCreatingConversation &&
      (this.replyChannelIsChat(this.args.replyChannel) ||
        this.replyChannelIsSms(this.args.replyChannel)) &&
      index > 0
    ) {
      return this.intl.t('inbox.new-conversation.errors.group-conversation-email-only');
    }

    if (
      this.args.isCreatingConversation &&
      this.replyChannelIsEmail(this.args.replyChannel) &&
      (!userSummary?.email || !isValidEmail(userSummary.email))
    ) {
      return this.intl.t('inbox.new-conversation.errors.recipient-has-chat-only');
    }

    if (
      this.args.isCreatingConversation &&
      (this.replyChannelIsSms(this.args.replyChannel) ||
        this.replyChannelIsWhatsapp(this.args.replyChannel)) &&
      !userSummary?.phone
    ) {
      return this.replyChannelIsSms(this.args.replyChannel)
        ? this.intl.t('inbox.new-conversation.errors.recipient-missing-phone-number')
        : this.intl.t('inbox.new-conversation.errors.recipient-missing-whatsapp-phone-number');
    }

    return undefined;
  }

  get preventInput(): boolean {
    if (this.args.isCreatingConversation || this.args.isCreatingTicket) {
      if (
        (this.replyChannelIsChat(this.args.replyChannel) ||
          this.replyChannelIsSms(this.args.replyChannel) ||
          this.replyChannelIsWhatsapp(this.args.replyChannel) ||
          this.args.isCreatingTicket) &&
        this.args.recipients.length > 0
      ) {
        // chat, sms, tickets, whatsapp only allow conversation creation with single recipient
        return true;
      } else if (
        this.replyChannelIsEmail(this.args.replyChannel) &&
        this.args.recipients.length >= MAX_PARTICIPANTS
      ) {
        return true;
      }

      return false;
    }

    return this.replyChannelIsMultiParticipant(this.args.replyChannel)
      ? false
      : this.args.recipients.length > 0;
  }

  get recipientsWithStatus() {
    let recipientStatuses: {
      recipient: UserSummary;
      recipientError: string | void;
      recipientStatus: string | void;
      replyTo: UserSummary | undefined;
      isNewParticipant: boolean;
      isRemovedParticipant: boolean;
    }[] = [];

    let addedParticipantsIds: string[] = [];
    let removedParticipantsList: UserSummary[] = [];
    if (!this.hasFocused) {
      let { addedParticipants, removedParticipants } = this.getParticipantChanges(
        this.args.originalRecipients || [],
        this.args.recipients,
      );
      addedParticipantsIds = addedParticipants?.map((participant) => participant.id);
      removedParticipantsList = removedParticipants;
    }

    this.args.recipients?.forEach((recipient, index) => {
      let replyTo = this.replyToForUser(recipient);
      let isNewParticipant = addedParticipantsIds.includes(recipient.id);
      recipientStatuses.push({
        recipient,
        recipientError: this.recipientError(recipient, index),
        recipientStatus: isNewParticipant
          ? this.intl.t('inbox.new-conversation.new-recipient-tooltip')
          : undefined,
        replyTo,
        isNewParticipant,
        isRemovedParticipant: false,
      });
    });
    if (this.args.setRecipientErrors) {
      this.args.setRecipientErrors(recipientStatuses);
    }

    removedParticipantsList.forEach((removedRecipient) => {
      recipientStatuses.push({
        recipient: removedRecipient,
        recipientError: undefined,
        recipientStatus: this.intl.t('inbox.new-conversation.removed-recipient-tooltip'),
        replyTo: undefined,
        isNewParticipant: false,
        isRemovedParticipant: true,
      });
    });

    return recipientStatuses;
  }

  replyToForUser(user: UserSummary) {
    return this.args.replyToData?.find((replyTo) => replyTo.id === user.id);
  }

  findByReplyTo(user: UserSummary) {
    return this.recipientsWithStatus.find((recipientData) => recipientData.replyTo === user)
      ?.recipient;
  }

  isAdminEmail(email: string | undefined) {
    if (!email) {
      return false;
    }

    return this.inbox2AssigneeSearch.findAdminByEmail(email) !== undefined;
  }

  @action setPopover(popover: { hide: () => void; show: () => void }) {
    this.popover = popover;
  }

  @action
  addNewUser(email: string) {
    let user = new UserSummary('', email);
    this.addRecipient(user);
  }

  @action
  addRecipient(recipient: UserSummary) {
    this.inputText = '';
    this.args.onRecipientChange([...this.args.recipients, recipient]);
    this.popover?.hide();
    this.searchList = [];
    if (this.preventInput && this.args.selectNextInput) {
      this.args.selectNextInput();
    }
  }

  @action
  removeRecipient(recipient: UserSummary) {
    this.isRemovingRecipient = true;
    if (this.args.recipients.includes(recipient)) {
      this.args.onRecipientChange(this.args.recipients.without(recipient));
    } else if (this.findByReplyTo(recipient)) {
      let user = this.findByReplyTo(recipient) as UserSummary;
      this.args.onRecipientChange(this.args.recipients.without(user));
    } else {
      this.args.onRecipientChange(this.args.recipients);
    }
    this.focusInput();
    this.popover?.hide();
  }

  @action
  onInput(e: { target: { value: string } }) {
    let input = e.target.value;
    if (input) {
      this.deselectRecipient();
      this.popover?.show();
    }

    let previousInputText = this.inputText;
    this.inputText = input;
    let mightHaveMoreResults = !this.searchHasNoResults || !input.startsWith(previousInputText);
    // If the user cannot access contacts, we must still try to match an exact email
    if (mightHaveMoreResults || !this.canAccessContacts) {
      taskFor(this.searchUsers).perform(input);
    }
  }

  @task({ restartable: true })
  *searchUsers(query: string) {
    this.searchList = [];
    let selectedUsers = this.args.recipients;
    let users: UserSummary[] = [];

    users = yield this.inboxSearchSuggestionsService.getUserSuggestions(
      query,
      8,
      selectedUsers,
      'contains',
      this.replyChannelIsEmail(this.args.replyChannel) ? 'email' : 'name',
    );

    // We have added this check since we might have some users with invalid emails containing the search param
    // Trying to send emails to those users fail in the backend so we want to prevent the selection in the frontend
    if (this.replyChannelIsEmail(this.args.replyChannel)) {
      users = users.filter((user) => {
        return !user.email || isValidEmail(user.email);
      });
    }

    let searchList: SearchListItemType[];

    if (users.length > 0) {
      this.searchHasNoResults = false;

      let selectedUsersIds = selectedUsers.map((user) => user.id);
      let unselectedUsers = users.filter((user) => !selectedUsersIds.includes(user.id));
      if (this.replyChannelIsEmail(this.args.replyChannel)) {
        unselectedUsers = unselectedUsers.sortBy('name');
      }

      searchList = unselectedUsers.map((user) => {
        if (this.replyChannelIsEmail(this.args.replyChannel) && !user.email) {
          return new DropdownUnselectableSuggestion(user);
        }
        return new DropdownSuggestion(user, () => this.addRecipient(user));
      });
    } else {
      this.searchHasNoResults = true;
      searchList = [new DropdownNoMatchForType(() => this.addNewUser(this.inputText))];
    }

    this.searchList = searchList;
  }

  @action async onPopoverHide() {
    // Make sure we wait for any searches that are running to finish.
    await taskFor(this.searchUsers).last;

    // If we have an input at this point, try to create a recipient with it
    let input = this.inputText?.trim() ?? '';
    let itemMatchingInput = this.searchList.find(
      (item: DropdownSuggestion) => item.suggestion?.email === input.toLocaleLowerCase(),
    );

    if (itemMatchingInput) {
      this.addRecipient((itemMatchingInput as DropdownSuggestion).suggestion);
    } else if (input) {
      this.addNewUser(this.inputText);
    }
  }

  @action equalityComparatorFn(a: any, b: any) {
    return a.suggestion?.id === b.suggestion?.id;
  }

  @action handleBackspace() {
    if (this.inputText) {
      return;
    }
    if (this.selectedRecipientIndex === undefined) {
      this.selectedRecipientIndex = this.args.recipients.length - 1;
    } else {
      this.removeRecipient(this.args.recipients[this.selectedRecipientIndex]);
      this.selectedRecipientIndex = undefined;
    }
  }

  @action previousRecipient() {
    if (this.selectedRecipientIndex !== undefined && this.selectedRecipientIndex > 0) {
      this.selectedRecipientIndex--;
    }
  }

  @action nextRecipient() {
    if (
      this.selectedRecipientIndex !== undefined &&
      this.selectedRecipientIndex < this.args.recipients.length
    ) {
      this.selectedRecipientIndex === this.args.recipients.length - 1
        ? this.deselectRecipient()
        : this.selectedRecipientIndex++;
    }
  }

  @action deselectRecipient() {
    this.selectedRecipientIndex = undefined;
  }

  @action focusInput() {
    this.args.showRecipientSelector && this.args.showRecipientSelector();
    schedule('afterRender', this, () => {
      this.recipientInput?.focus();
      this.isFocused = true;
    });
  }

  @action onFocus() {
    this.hasFocused = true;
    this.isFocused = true;
  }

  @action onBlur() {
    this.isFocused = false;

    if (this.args.onBlur) {
      if (this.isRemovingRecipient || this.isSelectingRecipient) {
        this.focusInput();
      } else {
        this.args.onBlur && this.args.onBlur();
      }
    }
    this.isSelectingRecipient = false;
    this.isRemovingRecipient = false;

    this.onPopoverHide();
  }

  @action onSuggestionClick(item: SearchListItemType) {
    this.isSelectingRecipient = true;
    item.onClickAction();
    later(this, this.focusInput, 20);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation::RecipientSelector': typeof RecipientSelector;
    'inbox2/new-conversation/recipient-selector': typeof RecipientSelector;
  }
}
