/* import __COLOCATED_TEMPLATE__ from './rich-text.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import type IntlService from 'embercom/services/intl';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { INLINE_CONTROL_ALIGNMENT } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import type Session from 'embercom/services/session';

interface Args {
  attribute: TicketAttributeSummary;
  onSubmit: (value: string) => void;
}

interface Signature {
  Args: Args;
}

const FORMATTING_POPOVER_SELECTOR = '.embercom-prosemirror-popover';

export default class EditableTicketDescriptionAttribute extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  @tracked editMode = false;
  @tracked value = new BlocksDocument();

  @ref('composer-element') composerElement!: HTMLElement;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (args.attribute.value) {
      this.value = new BlocksDocument(JSON.parse(args.attribute.value.toString()));
    }
  }

  get composerConfig() {
    return {
      ...new BaseConfig(),
      allowedBlocks: ['paragraph', 'orderedList', 'unorderedList', 'codeBlock'],
      allowedInline: ['bold', 'italic', 'code', 'anchor'],
      autoFocus: false,
      placeholder: this.intl.t('inbox.conversation-attributes.add-description-placeholder'),
      renderOverlaysInPlace: true,
      tools: [{ name: 'text-formatter' }, { name: 'anchor-editor' }],
      allowImplicitMarginParagraphs: true,
      hideFromTextFormatter: [INLINE_CONTROL_ALIGNMENT],
    };
  }

  get hasValue() {
    return (
      this.value.blocks.filter((block: any) => {
        if (block.type === 'paragraph') {
          return block.text.length > 0;
        }
        if (['unorderedList', 'orderedList'].includes(block.type)) {
          return block.items.length > 0;
        }
        return true;
      }).length > 0
    );
  }

  @action
  onFocus(_event: FocusEvent) {
    this.editMode = true;
  }

  @action
  onChange(blocksDoc: BlocksDocument) {
    this.value = blocksDoc;
  }

  @action
  onBlur(_event: FocusEvent) {
    let isFormatting = this.composerElement.contains(
      document.querySelector(FORMATTING_POPOVER_SELECTOR),
    );

    if (!isFormatting) {
      if (this.hasValue) {
        this.args.attribute.value = JSON.stringify(this.value.blocks);
        this.args.onSubmit(this.args.attribute.value);
      }
      this.editMode = false;
    }
  }

  @action
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::RichText': typeof EditableTicketDescriptionAttribute;
    'inbox2/ticket-attribute/rich-text': typeof EditableTicketDescriptionAttribute;
  }
}
