/* import __COLOCATED_TEMPLATE__ from './teammate-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import { PartGroupCategory } from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type AdminNote from 'embercom/objects/inbox/renderable/admin-note';
import type Conversation from 'embercom/objects/inbox/conversation';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type NexusEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName } from 'embercom/services/nexus';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import type AdminAwayService from 'embercom/services/admin-away-service';
import { registerDestructor } from '@ember/destroyable';

export interface TeammateRenderablePart extends RenderablePart {
  renderableData: AdminNote;
}

interface TeammatePartGroup extends PartGroup {
  parts: Array<TeammateRenderablePart>;
}

interface Args {
  partGroup: TeammatePartGroup;
  conversation?: Conversation;
  isTicketStream?: boolean;
}

interface Signature {
  Args: Args;
}

export default class TicketStreamTeammateContentComponent extends Component<Signature> {
  categories = PartGroupCategory;
  renderableType = RenderableType;
  @service declare session: Session;
  @service declare nexus: Nexus;
  @service declare adminAwayService: AdminAwayService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @tracked isHovered = false;
  @tracked isAway =
    this.args.partGroup.parts[0].renderableData.adminSummary.adminStatus?.awayModeEnabled || false;

  private nexusListener: (e: NexusEvent) => void;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.nexusListener = this.handleAdminStatusChange.bind(this);
    this.nexus.addListener(NexusEventName.AdminAwayReassignModeChangeEvent, this.nexusListener);

    registerDestructor(this, () => {
      this.nexus.removeListener(
        NexusEventName.AdminAwayReassignModeChangeEvent,
        this.nexusListener,
      );
    });
  }

  get teammate(): AdminSummary {
    return this.args.partGroup.parts[0].renderableData.adminSummary;
  }

  get lastPartSeenByUser() {
    let { conversation } = this.args;

    return (
      conversation?.lastSeenByUserAt &&
      conversation?.lastSeenByUserAt >= this.lastPartInGroup.createdAt
    );
  }

  get firstConversationPartBackgroundStyles() {
    let renderablePart = this.args.partGroup.parts[0].renderableData;
    let hoverStyles = renderablePart.ticketStyles?.backgroundColorHover;

    if (this.isHovered && hoverStyles) {
      return hoverStyles;
    }

    return renderablePart.ticketStyles?.backgroundColor;
  }

  get lastPartInGroup() {
    let { parts } = this.args.partGroup;
    return parts[parts.length - 1];
  }

  get isOperator() {
    let lastPart = this.lastPartInGroup;
    return lastPart.renderableType === RenderableType.TicketStateUpdated;
  }

  get isActive() {
    return !this.isAway;
  }

  handleAdminStatusChange(e: NexusEvent) {
    if (e.eventData.admin_id === this.teammate.id) {
      this.isAway = e.eventData.away_mode_enabled;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketStream::PartGroups::TeammateContent': typeof TicketStreamTeammateContentComponent;
    'inbox2/ticket-stream/part-groups/teammate-content': typeof TicketStreamTeammateContentComponent;
  }
}
