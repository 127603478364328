/* import __COLOCATED_TEMPLATE__ from './browse-folders-responsive-styles.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';

const KnowledgeBaseBrowseFoldersResponsiveStyles = templateOnlyComponent();
export default KnowledgeBaseBrowseFoldersResponsiveStyles;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::Browse::BrowseFoldersResponsiveStyles': typeof KnowledgeBaseBrowseFoldersResponsiveStyles;
    'inbox2/knowledge-base/browse/browse-folders-responsive-styles': typeof KnowledgeBaseBrowseFoldersResponsiveStyles;
  }
}
