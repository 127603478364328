/* import __COLOCATED_TEMPLATE__ from './teammate-performance-table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ParseMultiSignal from 'embercom/lib/reporting/multi-signal-parsing';
import ResolveAllPromises from 'embercom/lib/resolve-all-promises';

const TOP_PERFORMERS_COUNT = 100;

export default Component.extend({
  store: service(),
  intl: service(),
  appService: service(),
  normalized: [],

  get columnDefinitions() {
    return [
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.teammate'),
        propertyName: 'person',
        canExpandToFit: true,
        valueComponent: 'inbox/reporting/performance-table-fields/admin-fullname',
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.conversations'),
        propertyName: 'conversations_created',
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.response-time'),
        propertyName: 'median_first_response_time',
        valueComponent: 'inbox/reporting/performance-table-fields/minutes',
        description: this.intl.t(
          'components.inbox.reporting.performance-tables.response-time-tooltip',
        ),
        maxWidth: 100,
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.opps-influenced'),
        propertyName: 'opps_influenced',
        maxWidth: 100,
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.pipeline'),
        propertyName: 'pipeline',
        valueComponent: 'inbox/reporting/performance-table-fields/currency',
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.closed-won'),
        propertyName: 'closed_won',
        valueComponent: 'inbox/reporting/performance-table-fields/closed-won',
      },
      {
        label: ' ',
        propertyName: 'report',
        valueComponent: 'inbox/reporting/performance-table-fields/report-link',
      },
    ];
  },

  columns: readOnly('columnDefinitions'),

  init() {
    this._super(...arguments);
  },

  didReceiveAttrs() {
    this._super(...arguments);
    this.loadStats.perform();
  },

  async setAdminProperties(entry) {
    let person = await this.store.findRecord('admin', entry.key);
    entry.person = person;
    return entry;
  },

  loadStats: task(function* () {
    let current = this.get('signal.current.context');
    let previous = this.get('signal.previous.context');
    let merged = ParseMultiSignal(current, previous);
    let teammateIds = this.teammateIds;
    let filtered = merged;
    if (teammateIds && teammateIds.length > 0) {
      filtered = merged.filter((entry) => teammateIds.includes(parseInt(entry.key, 10)));
    }
    let identified = filtered.map((entry) => this.setAdminProperties(entry));
    Promise.all(ResolveAllPromises(identified))
      .then((resolved) => {
        this.set(
          'normalized',
          resolved.filter((r) => r.resolved).map((r) => r.value),
        );
      })
      .catch(() => this.set('error', true));
    yield;
  }).restartable(),

  rows: computed('normalized', function () {
    return this.normalized.slice(0, TOP_PERFORMERS_COUNT);
  }),

  customOfficeHoursTooltipText: computed(
    'appService',
    'intl.locale',
    'settings.reportingExcludeOooHours',
    function () {
      let defaultTooltip = this.intl.t('apps.app.reports.lead-generation.sales.teammates-tooltip');
      let officeHoursTooltip = this.intl.t(
        'components.reporting.flexible.chart-header-custom-tooltip.office-hours-tooltip.tooltip-description',
        { includeOfficeHours: this.settings.reportingExcludeOooHours },
      );
      return this.intl.t(
        'components.reporting.reports.effectiveness.office-hours.tooltip-with-office-hours',
        { defaultTooltip, officeHoursTooltip, htmlSafe: true },
      );
    },
  ),
});
