/* import __COLOCATED_TEMPLATE__ from './shopify2.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isValidEmail } from 'embercom/lib/email';
import { isValidOrderName } from 'embercom/lib/inbox/shopify/order';

export default class Shopify extends Component {
  @service appService;
  @service store;

  @tracked shopifyCustomer;
  @tracked shopifyCustomerOrdersUrl;
  @tracked orders;

  @tracked shopifyError;
  @tracked shouldRequestMoreScopes;

  @tracked shopifyEmailOrOrderName = this.args.user.email;

  @tracked selectedTab = 'customer';
  @tracked showCreateOrderModal = false;

  constructor() {
    super(...arguments);

    this.fetchShopifyDataTask.perform();
    this.checkAccessScope.perform();
  }

  get emailIsValid() {
    return isValidEmail(this.shopifyEmailOrOrderName);
  }

  @task({ restartable: true })
  *fetchShopifyDataTask() {
    this.shopifyCustomer = null;
    this.shopifyError = null;

    if (
      !isValidEmail(this.shopifyEmailOrOrderName) &&
      !isValidOrderName(this.shopifyEmailOrOrderName)
    ) {
      this.shopifyError = { key: 'invalid_email_or_order_name' };
      return;
    }

    let data = {
      app_id: this.appService.app.id,
    };

    if (this.emailIsValid) {
      data['user_email'] = this.shopifyEmailOrOrderName;
    } else {
      data['order_name'] = this.shopifyEmailOrOrderName;
    }

    try {
      let shopifyResponse = yield ajax({
        url: '/ember/inbox/shopify/shopify_customer_data',
        type: 'GET',
        data,
      });

      this.shopifyCustomer = shopifyResponse.customer;
      this.orders = shopifyResponse.customer_orders;
      this.shopifyCustomerOrdersUrl = shopifyResponse.all_customer_orders_url;
    } catch (error) {
      console.error(error.jqXHR.responseJSON.error);
      this.shopifyError = error.jqXHR.responseJSON.error;
    }
  }

  @task({ restartable: true })
  *checkAccessScope() {
    try {
      let accessScopeResponse = yield ajax({
        url: '/ember/inbox/shopify/access_scope_check',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });

      this.shouldRequestMoreScopes = accessScopeResponse.should_request_more_scopes;
    } catch (error) {
      console.error(error);
    }
  }

  @action
  intercomUserChanged() {
    this.shopifyEmailOrOrderName = this.args.user.email;
    this.fetchShopifyDataTask.perform();
  }

  @action
  focusOnEmailSearchInput() {
    let emailOrOrderSearchInput = document.querySelector(
      '.js__shopify-email-or-order-search-input',
    );
    emailOrOrderSearchInput.focus();
  }
}
