/* import __COLOCATED_TEMPLATE__ from './sidebar-drag-handle.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import {
  ABSOLUTE_MINIMUM_STREAM_WIDTH,
  LOCALSTORAGE_SIDEBAR_WIDTH_KEY,
} from 'embercom/services/inbox-section-sizes';
import { LAYOUT_BREAKPOINT } from 'embercom/services/inbox-section-sizes';
// @ts-ignore
import { globalRef, ref } from 'ember-ref-bucket';
import storage from 'embercom/vendor/intercom/storage';

const ABSOLUTE_MINIMUM_SIDEBAR_WIDTH = 280;

export default class SidebarDragHandle extends Component {
  @service declare inboxState: InboxState;

  @tracked isDragging = false;

  drag: () => void;
  mouseUp: () => void;
  windowResize: () => void;

  @ref('drag-handle') declare dragHandle: HTMLElement;
  @globalRef('conversation-stream-element') declare conversationStreamElement: HTMLElement;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.drag = this.onDrag.bind(this);
    this.mouseUp = this.onMouseUp.bind(this);
    this.originalWindowWidth = window.innerWidth;
    this.windowResize = this.onWindowResize.bind(this);

    window.addEventListener('resize', this.windowResize);
  }

  willDestroy() {
    super.willDestroy();

    window.removeEventListener('resize', this.windowResize);
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.mouseUp);
  }

  originalSidebarWidth!: number;
  originalStreamWidth!: number;
  originalWindowWidth!: number;

  maximumStreamWidth!: number;
  maximumSidebarWidth!: number;

  minimumDraggablePosition!: number;
  maximumDraggablePosition!: number;

  @tracked windowResizeTimeout: any;

  get isNarrowWindow() {
    return this.originalWindowWidth < LAYOUT_BREAKPOINT;
  }

  // The width of the parent is the overall window width minus the distance from the lefthand of the screen to the conversation stream element.
  get streamAndSidebarContainerWidth() {
    return window.innerWidth - this.conversationStreamElement.getBoundingClientRect().x;
  }

  get windowMinimumStreamWidth() {
    if (this.isNarrowWindow) {
      // The minimum width of the stream is 30% of the parent container to the stream and sidebar.
      return 0.3 * this.streamAndSidebarContainerWidth;
    } else {
      return ABSOLUTE_MINIMUM_STREAM_WIDTH;
    }
  }

  get windowMinimumSidebarWidth() {
    if (this.isNarrowWindow) {
      // The minimum width of the sidebar is 30% of the parent container to the stream and sidebar.
      return 0.3 * this.streamAndSidebarContainerWidth;
    } else {
      return ABSOLUTE_MINIMUM_SIDEBAR_WIDTH;
    }
  }

  get windowMaximumSidebarWidth() {
    return this.streamAndSidebarContainerWidth - this.windowMinimumStreamWidth;
  }

  @action startDrag(event: MouseEvent) {
    // Prevent bubbling up and highlighting text etc. outside of the element we're dragging
    event.preventDefault();

    this.originalSidebarWidth = window.innerWidth - this.dragHandle.getBoundingClientRect().x;

    let distanceLeftOfConversationStream = this.conversationStreamElement.getBoundingClientRect().x;
    this.originalStreamWidth =
      window.innerWidth - this.originalSidebarWidth - distanceLeftOfConversationStream;

    this.maximumStreamWidth =
      this.originalStreamWidth - (ABSOLUTE_MINIMUM_SIDEBAR_WIDTH - this.originalSidebarWidth);
    this.maximumSidebarWidth =
      this.originalStreamWidth - (ABSOLUTE_MINIMUM_STREAM_WIDTH - this.originalSidebarWidth);

    // The area we can drag left to shouldn't be any further than the edge of the conversation stream + its minimum width
    this.minimumDraggablePosition =
      this.conversationStreamElement.getBoundingClientRect().x + this.windowMinimumStreamWidth;

    // And the area to the right shouldn't go outside the current window
    this.maximumDraggablePosition = window.innerWidth;

    this.isDragging = true;

    document.addEventListener('mousemove', this.drag);
    document.addEventListener('mouseup', this.mouseUp);
  }

  @action resetWidth() {
    this.inboxState.resetStreamAndSidebarLayout();
    storage.set(LOCALSTORAGE_SIDEBAR_WIDTH_KEY, this.inboxState.sidebarWidth);
  }

  onDrag(event: MouseEvent) {
    if (
      event.clientX < this.minimumDraggablePosition ||
      event.clientX > this.maximumDraggablePosition
    ) {
      return;
    }

    let newSidebarWidth = window.innerWidth - event.clientX;
    this.calculateStreamAndSidebarWidths(newSidebarWidth);
  }

  onMouseUp() {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.mouseUp);
    this.isDragging = false;
  }

  onWindowResize() {
    clearTimeout(this.windowResizeTimeout);

    this.windowResizeTimeout = setTimeout(() => {
      // reset the sidebar width only if the window width has changed
      if (this.originalWindowWidth !== window.innerWidth) {
        this.resetWidth();
        this.originalWindowWidth = window.innerWidth;
      }
    }, 250);
  }

  private calculateStreamAndSidebarWidths(desiredSidebarWidth: number) {
    // If the sidebar is becoming wider, this will be positive and the excess must be removed from the stream
    // If the sidebar is becoming narrower, this will be negative and must be added to the stream
    let diff = desiredSidebarWidth - this.originalSidebarWidth;
    let expectedStreamWidth = this.originalStreamWidth - diff;

    // if stream width is below the minimum limit, set the sidebar to its maximum value
    if (expectedStreamWidth < this.windowMinimumStreamWidth) {
      desiredSidebarWidth = this.windowMaximumSidebarWidth;
    }

    // if sidebar width is below the minimum limit, set it back to its minimum value
    if (desiredSidebarWidth < this.windowMinimumSidebarWidth) {
      desiredSidebarWidth = this.windowMinimumSidebarWidth;
    }

    this.inboxState.inboxSectionSizes.sidebarWidth = `${desiredSidebarWidth}px`;
    this.storeLayout(desiredSidebarWidth, this.streamAndSidebarContainerWidth);
  }

  private storeLayout(sidebarWidth: number, totalWidth: number) {
    storage.set(LOCALSTORAGE_SIDEBAR_WIDTH_KEY, this.percentageWidth(sidebarWidth, totalWidth));
  }

  private percentageWidth(width: number, totalWidth: number): string {
    return `${(width / totalWidth) * 100}%`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SidebarDragHandle': typeof SidebarDragHandle;
    'inbox2/sidebar-drag-handle': typeof SidebarDragHandle;
  }
}
