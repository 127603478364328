/* import __COLOCATED_TEMPLATE__ from './custom-time-sla-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import Component from '@glimmer/component';
import Delays from 'embercom/lib/workflows/helpers/delays';

export default class CustomTimeSlaModal extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked slaName = '';
  @tracked firstReplyTimeInSeconds = null;
  @tracked nextReplyTimeInSeconds = null;
  @tracked timeToResolveInSeconds = null;
  @tracked timeToCloseInSeconds = null;
  @tracked firstReplyDelayUnit = 'second';
  firstReplyDelayValue = 0;
  @tracked nextReplyDelayUnit = 'second';
  nextReplyDelayValue = 0;
  @tracked resolutionDelayUnit = 'day';
  resolutionDelayValue = 0;
  @tracked timeToCloseDelayUnit = 'day';
  timeToCloseDelayValue = 0;
  selectedScheduleId = this.defaultScheduleId;
  @tracked pauseOnWaitingOnCustomer = false;
  @tracked pauseOnSnooze = false;

  get delays() {
    return new Delays();
  }

  get officeHoursSchedules() {
    return this.args.officeHoursSchedules;
  }

  get conversationSlas() {
    return this.args.conversationSlas;
  }

  get hideSlaModal() {
    return this.args.hideSlaModal;
  }

  onSlaCreated(sla) {
    this.args.onSlaCreated(sla);
  }

  get trimmedSlaName() {
    let slaName = this.slaName || '';
    return slaName.trim();
  }

  get duplicatedName() {
    return this.conversationSlas.filterBy('isNew', false).isAny('name', this.trimmedSlaName);
  }

  get firstTimeValueNull() {
    return this.firstReplyTimeInSeconds === null;
  }

  get nextTimeValueNull() {
    return this.nextReplyTimeInSeconds === null;
  }

  get timeToResolveValueNull() {
    return this.timeToResolveInSeconds === null;
  }

  get timeToCloseValueNull() {
    return this.timeToCloseInSeconds === null;
  }

  get firstReplyTimeValid() {
    // we want this to be between 30 seconds and 100 days
    return (
      this.firstTimeValueNull ||
      (!this.firstTimeValueNull &&
        Number(this.firstReplyTimeInSeconds) >= 30 &&
        Number(this.firstReplyTimeInSeconds) <= 8640000)
    );
  }

  get nextReplyTimeValid() {
    // we want this to be between 30 seconds and 100 days
    return (
      this.nextTimeValueNull ||
      (!this.nextTimeValueNull &&
        Number(this.nextReplyTimeInSeconds) >= 30 &&
        Number(this.nextReplyTimeInSeconds) <= 8640000)
    );
  }

  get timeToResolveValid() {
    // we want this to be between 30 seconds and 100 days
    return (
      this.timeToResolveValueNull ||
      (!this.timeToResolveValueNull &&
        Number(this.timeToResolveInSeconds) >= 30 &&
        Number(this.timeToResolveInSeconds) <= 8640000)
    );
  }

  get timeToCloseValid() {
    // we want this to be between 30 seconds and 100 days
    return (
      this.timeToCloseValueNull ||
      (!this.timeToCloseValueNull &&
        Number(this.timeToCloseInSeconds) >= 30 &&
        Number(this.timeToCloseInSeconds) <= 8640000 &&
        Number(this.timeToCloseInSeconds) >= Number(this.timeToResolveInSeconds))
    );
  }

  get timeValidationError() {
    if (!this.firstReplyTimeValid || !this.nextReplyTimeValid) {
      return this.intl.t('inbox.sla.custom-time-sla-modal.invalid-response-times');
    }

    if (!this.timeToResolveValid || !this.timeToCloseValid) {
      return this.intl.t('inbox.sla.custom-time-sla-modal.invalid-completion-times');
    }
    return null;
  }

  get saveButtonDisabled() {
    let invalidName = this.trimmedSlaName.length === 0 || this.duplicatedName;
    return invalidName || !!this.timeValidationError;
  }

  get defaultScheduleId() {
    let schedule = this.officeHoursSchedules.findBy('isDefault', true);
    if (schedule) {
      return schedule.id;
    } else if (this.officeHoursSchedules.length > 0) {
      return this.officeHoursSchedules.firstObject.id;
    }
  }

  get appHasMoreThanOneOfficeHours() {
    return this.officeHoursSchedules.length > 1;
  }

  get dropdownSchedules() {
    return this.officeHoursSchedules.map((schedule) => {
      return {
        value: schedule.id,
        text: schedule.name,
        description: schedule.labelWithTimezone,
      };
    });
  }

  @task({ drop: true })
  *createNewSlaAndCloseModal() {
    let sla = yield this.store
      .createRecord('inbox/conversation-sla', {
        name: this.trimmedSlaName,
        firstReplyTimeInSeconds: this.firstReplyTimeInSeconds,
        nextReplyTimeInSeconds: this.nextReplyTimeInSeconds,
        resolutionTimeInSeconds: this.timeToResolveInSeconds,
        timeToCloseInSeconds: this.timeToCloseInSeconds,
        officeHoursScheduleId: this.selectedScheduleId,
        pauseOnWaitingOnCustomer: this.pauseOnWaitingOnCustomer,
        pauseOnSnooze: this.pauseOnSnooze,
      })
      .save();
    this.closeCustomTimeSlaModal();
    this.onSlaCreated(sla);
  }

  @action
  closeCustomTimeSlaModal() {
    this.slaName = null;
    this.firstReplyTimeInSeconds = null;
    this.nextReplyTimeInSeconds = null;
    this.hideSlaModal();
  }

  @action
  setFirstReplyDelayUnit(unit) {
    this.firstReplyDelayUnit = unit;
    if (Number(this.firstReplyDelayValue) === 0) {
      this.firstReplyTimeInSeconds = null;
    } else {
      this.firstReplyTimeInSeconds = this.delays.getDelayInSeconds(this.firstReplyDelayValue, unit);
    }
  }

  @action
  setFirstReplyDelayValue() {
    if (Number(this.firstReplyDelayValue) === 0) {
      this.firstReplyTimeInSeconds = null;
    } else {
      this.firstReplyTimeInSeconds = this.delays.getDelayInSeconds(
        this.firstReplyDelayValue,
        this.firstReplyDelayUnit,
      );
    }
  }

  @action
  setNextReplyDelayUnit(unit) {
    this.nextReplyDelayUnit = unit;
    if (Number(this.nextReplyDelayValue) === 0) {
      this.nextReplyTimeInSeconds = null;
    } else {
      this.nextReplyTimeInSeconds = this.delays.getDelayInSeconds(this.nextReplyDelayValue, unit);
    }
  }

  @action
  setNextReplyDelayValue() {
    if (Number(this.nextReplyDelayValue) === 0) {
      this.nextReplyTimeInSeconds = null;
    } else {
      this.nextReplyTimeInSeconds = this.delays.getDelayInSeconds(
        this.nextReplyDelayValue,
        this.nextReplyDelayUnit,
      );
    }
  }

  @action
  setResolutionDelayUnit(unit) {
    this.resolutionDelayUnit = unit;
    if (Number(this.resolutionDelayValue) === 0) {
      this.timeToResolveInSeconds = null;
    } else {
      this.timeToResolveInSeconds = this.delays.getDelayInSeconds(this.resolutionDelayValue, unit);
    }
  }

  @action
  setResolutionDelayValue() {
    if (Number(this.resolutionDelayValue) === 0) {
      this.timeToResolveInSeconds = null;
    } else {
      this.timeToResolveInSeconds = this.delays.getDelayInSeconds(
        this.resolutionDelayValue,
        this.resolutionDelayUnit,
      );
    }
  }

  @action
  setTimeToCloseDelayUnit(unit) {
    this.timeToCloseDelayUnit = unit;
    if (Number(this.timeToCloseDelayValue) === 0) {
      this.timeToCloseInSeconds = null;
    } else {
      this.timeToCloseInSeconds = this.delays.getDelayInSeconds(this.timeToCloseDelayValue, unit);
    }
  }

  @action
  setTimeToCloseDelayValue() {
    if (Number(this.timeToCloseDelayValue) === 0) {
      this.timeToCloseInSeconds = null;
    } else {
      this.timeToCloseInSeconds = this.delays.getDelayInSeconds(
        this.timeToCloseDelayValue,
        this.timeToCloseDelayUnit,
      );
    }
  }
}
