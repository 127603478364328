/* import __COLOCATED_TEMPLATE__ from './item-text.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  item: {
    text: string;
  };
}

interface Signature {
  Args: Arguments;
}

const ItemText = templateOnlyComponent<Signature>();
export default ItemText;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::EditSidebar::ItemText': typeof ItemText;
    'inbox2/left-nav/edit-sidebar/item-text': typeof ItemText;
  }
}
