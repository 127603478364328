/* import __COLOCATED_TEMPLATE__ from './teammate-roles.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { ROLE_FILTER_TYPE } from 'embercom/lib/settings/filters/constants';
import { inject as service } from '@ember/service';
import { partition } from 'underscore';

export default class TeamInboxes extends Component {
  @service intl;

  @tracked selections = this.args.filter.value || [];
  @tracked forceUpdate = undefined;

  get items() {
    let roleItems = this.args.roles.map((role) => ({
      text: role.name,
      value: role.id,
      isSelected: this.selections.includes(role.id),
    }));

    roleItems.unshift({
      text: this.intl.t('settings.teammate-workload.filters.roles.no-role'),
      value: -1,
      isSelected: this.selections.includes(-1),
    });

    let [selectedItems, unselectedItems] = partition(roleItems, ({ isSelected }) => isSelected);

    return [
      {
        heading: this.intl.t('settings.teammate-workload.filters.roles.selected'),
        items: selectedItems,
      },
      {
        heading: this.intl.t('settings.teammate-workload.filters.roles.unselected'),
        items: unselectedItems,
      },
    ];
  }

  get filterLabel() {
    if (this.selections.length > 0) {
      let firstRole;

      firstRole = this.args.roles.find((role) => {
        return this.selections.includes(role.id);
      });

      if (firstRole === undefined) {
        firstRole = {
          name: this.intl.t('settings.teammate-workload.filters.roles.no-role'),
        };
      }

      return this.intl.t('settings.teammate-workload.filters.roles.label', {
        count: this.selections.length - 1,
        name: firstRole.name,
      });
    }
    return this.intl.t('settings.teammate-workload.filters.roles.any');
  }

  @action
  onSelectionChange(selectedItems) {
    this.selections = selectedItems;
    this.args.updateFilter({
      type: ROLE_FILTER_TYPE,
      comparator: undefined,
      value: selectedItems.map((item) => parseInt(item, 10)),
    });
  }

  @action
  reset(element, [filter]) {
    if (filter.value.length === 0) {
      this.selections = [];
      this.forceUpdate = Math.random();
    }
  }
}
