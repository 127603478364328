/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { Resource } from 'ember-resources';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  SpamMessage,
  type SpamMessageWireFormat,
  type SpamMetaWireFormat,
} from 'embercom/objects/inbox/spam';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { request } from 'embercom/lib/inbox/requests';
import { post } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';

export class SpamResource extends Resource {
  @service session!: Session;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;

  @tracked spamMessages: Array<SpamMessage> = [];
  @tracked hasError = false;
  @tracked page = 1;
  @tracked canLoadMore = false;

  constructor(owner: any, args: any, previous: any) {
    super(owner, args);

    if (!previous) {
      taskFor(this.loadSpamMessages).perform();
    } else {
      this.page = previous.page;
      this.canLoadMore = previous.canLoadMore;
      this.spamMessages = previous.spamMessages;
    }
  }

  get isLoading() {
    return taskFor(this.loadSpamMessages).isRunning;
  }

  get isEmpty() {
    return this.spamMessages.length === 0;
  }

  get shouldDisplayErrorState() {
    return this.hasError && this.isEmpty;
  }

  @task
  *loadSpamMessages() {
    try {
      this.hasError = false;
      this.spamMessages.pushObjects(yield this.fetchSpamMessages());
    } catch (e) {
      this.hasError = true;
    }
  }

  @task
  *markAsNotSpam(messageId: number) {
    try {
      yield post(`/ember/spam_messages/process_email`, {
        id: messageId,
        app_id: this.session.workspace.id,
      });
      this.snackbar.notify(this.intl.t('inbox.spam.process-spam-success'));
      return true;
    } catch (e) {
      this.snackbar.notifyError(this.intl.t('inbox.spam.errors.process-spam-error'));
      return false;
    }
  }

  async fetchSpamMessages() {
    let response = await request(
      `/ember/spam_messages?app_id=${this.session.workspace.id}&page=${this.page}&per_page=20`,
    );
    let json = (await response.json()) as {
      spam_messages: SpamMessageWireFormat[];
      meta: SpamMetaWireFormat;
    };
    this.page = json.meta.page + 1;
    this.canLoadMore = json.meta.total_pages > json.meta.page;
    return json.spam_messages.map(SpamMessage.deserialize);
  }

  @action reloadSpamMessages() {
    taskFor(this.loadSpamMessages).perform();
  }

  @action loadMore() {
    if (this.canLoadMore) {
      taskFor(this.loadSpamMessages).perform();
    }
  }
}
