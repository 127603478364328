/* import __COLOCATED_TEMPLATE__ from './swift.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

const Swift = templateOnlyComponent<Signature>();
export default Swift;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::IdvSetup::Ios::CodeSnippets::Swift': typeof Swift;
    'installation-new/mobile/idv-setup/ios/code-snippets/swift': typeof Swift;
  }
}
