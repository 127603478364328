/* import __COLOCATED_TEMPLATE__ from './section-edit-visibility-button.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { SIDEBAR_EXPANDED_CONTENT_VISIBILITY } from 'embercom/lib/inbox/constants';

export default class InboxConversationSiderbarSectionBodyEditVisibilityButton extends Component {
  @service intercomEventService;

  @action
  toggleVisibility() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edit',
      object: `inbox_sidebar_${this.args.sectionName}`,
      context: this.args.editVisibilityMode ? 'exit_edit_mode' : 'enter_edit_mode',
    });

    this.args.updateEditVisibilityMode(!this.args.editVisibilityMode);

    if (this.args.editVisibilityMode) {
      this.args.updateExpandedContentVisibility(
        SIDEBAR_EXPANDED_CONTENT_VISIBILITY.expandedWithHiddenLabel,
      );
    } else {
      this.args.updateExpandedContentVisibility(SIDEBAR_EXPANDED_CONTENT_VISIBILITY.collapsed);

      if (this.args.afterDoneClicked) {
        this.args.afterDoneClicked();
      }
    }
  }
}
