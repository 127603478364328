/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';

interface Args {
  conversation: NewConversation;
}

interface Signature {
  Args: Args;
}

export default class UnnamedComponent extends Component<Signature> {
  get groupedParts() {
    return PartGroup.groupParts(
      this.args.conversation as any,
      this.args.conversation.renderableParts,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation::Placeholder': typeof UnnamedComponent;
    'inbox2/new-conversation/placeholder': typeof UnnamedComponent;
  }
}
