/* import __COLOCATED_TEMPLATE__ from './cvda-date-time-filter.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { type CvdaDateTimeItem } from '../search2';

interface Signature {
  Element: HTMLElement;
  Args: {
    onComparisonSelect: (comparison: CvdaDateTimeItem['comparison']) => void;
    onValueSelect: (value: string) => void;
    onUnitSelect: (unit: CvdaDateTimeItem['value']['unit']) => void;
    selected: CvdaDateTimeItem;
  };
}

export default class CvdaDateTimeFilter extends Component<Signature> {
  @service declare intl: IntlService;

  get radioButtons() {
    return [
      {
        value: 'lt',
        label: this.intl.t('inbox.search.filters.more-filters.more-than'),
      },
      {
        value: 'gt',
        label: this.intl.t('inbox.search.filters.more-filters.less-than'),
      },
    ];
  }

  get units() {
    return [
      {
        text: this.intl.t('inbox.search.filters.more-filters.minutes', {
          value: this.args.selected.value.value || 0,
        }),
        value: 'minutes',
      },
      {
        text: this.intl.t('inbox.search.filters.more-filters.hours', {
          value: this.args.selected.value.value || 0,
        }),
        value: 'hours',
      },
      {
        text: this.intl.t('inbox.search.filters.more-filters.days', {
          value: this.args.selected.value.value || 0,
        }),
        value: 'days',
      },
    ];
  }

  @action setSelectedRadioButtonValue(value: 'lt' | 'gt') {
    this.args.onComparisonSelect(value);
  }

  @action setSelectedValue(event: Event) {
    this.args.onValueSelect((event.target as HTMLInputElement).value);
  }

  @action setSelectedeUnit(value: CvdaDateTimeItem['value']['unit']) {
    this.args.onUnitSelect(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::CvdaDateTimeFilter': typeof CvdaDateTimeFilter;
  }
}
