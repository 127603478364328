/* import __COLOCATED_TEMPLATE__ from './achievements.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type Inbox2TeammateActivityService from 'embercom/services/inbox2-teammate-activity-service';
// @ts-ignore
import { dedupeTracked } from 'tracked-toolbox';
import { action } from '@ember/object';

interface Signature {
  Args: {
    goBack: () => void;
    closeSidebar: () => void;
  };
}

export default class UnnamedComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inbox2TeammateActivityService: Inbox2TeammateActivityService;
  @service notificationsService: any;

  @dedupeTracked performanceLoaded = false;
  @dedupeTracked ratingsLoaded = false;
  @dedupeTracked hasUnseenRatings = false;

  get isInitialLoadComplete() {
    return this.performanceLoaded && this.ratingsLoaded;
  }

  @action latestRatingsOnLoad(hasUnseenRatings: boolean) {
    this.ratingsLoaded = true;
    this.hasUnseenRatings = hasUnseenRatings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::YourAchievements::Achievements': typeof UnnamedComponent;
    'inbox2/your-achievements/achievements': typeof UnnamedComponent;
  }
}
