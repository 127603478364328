/* import __COLOCATED_TEMPLATE__ from './segment.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import templateOnlyComponent from '@ember/component/template-only';

const Segment = templateOnlyComponent();
export default Segment;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::Segment': typeof Segment;
  }
}
