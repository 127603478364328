/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Link = templateOnlyComponent<Signature>();
export default Link;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ConsistentNav::Link': typeof Link;
    'inbox2/user-menu/consistent-nav/link': typeof Link;
  }
}
