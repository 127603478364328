/* import __COLOCATED_TEMPLATE__ from './swift-package-manager.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

const SwiftPackageManager = templateOnlyComponent<Signature>();
export default SwiftPackageManager;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Ios::Steps::SwiftPackageManager': typeof SwiftPackageManager;
    'installation-new/mobile/initial-setup/ios/steps/swift-package-manager': typeof SwiftPackageManager;
  }
}
