/* import __COLOCATED_TEMPLATE__ from './team-avatar-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { filter, equal } from '@ember/object/computed';
import Component from '@ember/component';
import { slice, ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  classNames: ['inbox__admin-avatar__team-avatar-container'],
  classHash: {
    1: {
      1: '',
    },
    2: {
      0: 'o__first-of-two-admins',
      1: 'o__second-of-two-admins',
    },
    3: {
      0: 'o__first-of-three-admins',
      1: 'o__second-of-three-admins',
      2: 'o__third-of-three-admins',
    },
  },
  allTeamMembers: filter('app.admins', function (admin) {
    return this.get('admin.member_ids').includes(parseInt(admin.get('id'), 10));
  }),
  teamMembers: slice('allTeamMembers', 0, 3),
  onlyOneMember: equal('teamMembers.length', 1),
  adminsCountClass: ternary('onlyOneMember', 'o__l-team-avatar', 'o__s-team-avatar'),
  notDefaultAvatar(url) {
    return url.search('default-avatars') === -1;
  },
  buildClassNames(index) {
    let classNames = ['inbox__admin-avatar__team-avatar'];
    classNames.push(this.classHash[this.get('teamMembers.length')][index]);
    classNames.push(this.adminsCountClass);

    return classNames.join(' ');
  },
  avatarData: computed('teamMembers', function () {
    let data = [];
    this.teamMembers.forEach((member, index) => {
      let avatar = {};
      let url = member.get('avatar.image_urls.square_128');
      if (url && this.notDefaultAvatar(url)) {
        avatar.imagePresent = true;
        avatar.imageUrl = member.get('avatar.image_urls.square_128');
      } else {
        avatar.imagePresent = false;
        avatar.initial = member.get('first_name').charAt(0).toUpperCase();
      }

      avatar.classNames = this.buildClassNames(index);
      data.push(avatar);
    });
    return data;
  }),
});
