/* import __COLOCATED_TEMPLATE__ from './select-appearance.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Theme from 'embercom/objects/inbox/types/theme';

interface Args {
  onClose: () => unknown;
  onNext: () => unknown;
  setTheme: (theme: Theme) => unknown;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class Inbox2OnboardingSelectAppearance extends Component<Signature> {
  @service intercomEventService: any;
  lightTheme = Theme.Light;
  darkTheme = Theme.Dark;
  systemTheme = Theme.System;

  @action
  onSelectMode(mode: Theme) {
    this.args.setTheme(mode);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed',
      object: 'theme',
      place: 'onboarding',
      section: 'onboarding',
    });

    this.args.onNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::SelectAppearance': typeof Inbox2OnboardingSelectAppearance;
    'inbox2/onboarding/select-appearance': typeof Inbox2OnboardingSelectAppearance;
  }
}
