/* import __COLOCATED_TEMPLATE__ from './link-to-content.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';

export interface Args {
  content: KnowledgeBaseContent;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class KnowledgeBaseLinkToContent extends Component<Signature> {
  @service declare session: Session;

  get entityObjectName() {
    return objectNames[this.args.content.entityType];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::LinkToContent': typeof KnowledgeBaseLinkToContent;
    'inbox2/knowledge-base/link-to-content': typeof KnowledgeBaseLinkToContent;
  }
}
