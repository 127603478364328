/* import __COLOCATED_TEMPLATE__ from './tagging-modal.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TaggingModal extends Component {
  @service notificationsService;
  @service intl;

  @action
  afterTagSave() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('settings.teammate-workload.admin-tagging-modal.tags-applied'),
    );
    this.args.admin.reload();
    this.args.onClose();
  }
}
