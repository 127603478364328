/* import __COLOCATED_TEMPLATE__ from './sla-details-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

export default Component.extend({
  store: service(),

  tagName: '',

  init() {
    this._super(...arguments);
    this.loadConversationSla.perform();
  },

  formattedReplyToday: computed('conversationNextBreachTime', function () {
    let now = moment();
    let nextBreachTime = moment(this.conversationNextBreachTime);
    return now.isSame(nextBreachTime, 'day');
  }),

  formattedReply: computed('conversationNextBreachTime', function () {
    let now = moment();
    let nextBreachTime = moment(this.conversationNextBreachTime);
    let breached = nextBreachTime.isBefore(now);
    if (!breached) {
      return 'Reply by';
    }

    if (this.formattedReplyToday) {
      return 'Target missed at';
    } else {
      return 'Target missed on';
    }
  }),

  loadConversationSla: task(function* () {
    let conversationSla = yield this.store.findRecord(
      'inbox/conversation-sla',
      this.conversationSlaId,
      { backgroundReload: false },
    );
    this.set('conversationSla', conversationSla);
  }).restartable(),

  removeSla: action(function () {
    this.removeSlaTask.perform();
  }),
});
