/* import __COLOCATED_TEMPLATE__ from './practice-shortcuts.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Inbox2PracticeShortcuts extends Component {
  @service declare router: RouterService;

  @action goToInbox() {
    this.router.transitionTo('inbox.workspace.inbox');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PracticeShortcuts': typeof Inbox2PracticeShortcuts;
    'inbox2/practice-shortcuts': typeof Inbox2PracticeShortcuts;
  }
}
