/* import __COLOCATED_TEMPLATE__ from './macro-execution-error-notification-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type Notification } from 'embercom/services/snackbar';

interface Args {
  notification: Notification;
}

interface Signature {
  Args: Args;
}

const MacroExecutionErrorNotificationContent = templateOnlyComponent<Signature>();
export default MacroExecutionErrorNotificationContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::MacroExecutionErrorNotificationContent': typeof MacroExecutionErrorNotificationContent;
    'inbox2/macro-execution-error-notification-content': typeof MacroExecutionErrorNotificationContent;
  }
}
