/* import __COLOCATED_TEMPLATE__ from './team-select-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class TeamSelectItem extends Component {
  @service intl;

  distributionMethods = {
    load_balanced: this.intl.t('settings.teammate-workload.distribution-methods.load-balanced'),
    round_robin: this.intl.t('settings.teammate-workload.distribution-methods.round-robin'),
    manual: this.intl.t('settings.teammate-workload.distribution-methods.manual'),
  };

  get distributionMethodToString() {
    return this.distributionMethods[this.args.item.teamSettings.distributionMethod];
  }
}
