/* import __COLOCATED_TEMPLATE__ from './twitter-integration-disabled-banner.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const TwitterIntegrationDisabledBanner = templateOnlyComponent<Signature>();
export default TwitterIntegrationDisabledBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TwitterIntegrationDisabledBanner': typeof TwitterIntegrationDisabledBanner;
    'inbox2/twitter-integration-disabled-banner': typeof TwitterIntegrationDisabledBanner;
  }
}
