/* import __COLOCATED_TEMPLATE__ from './assignment-limit.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { post } from '../../../lib/ajax';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';

export default class AssignmentLimit extends Component {
  @service permissionsService;
  @service appService;
  @service intl;
  @service notificationsService;
  @service intercomConfirmService;
  @service intercomEventService;
  @service modelDataCacheService;

  @tracked assignmentLimit = this.args.admin?.workload_management_settings?.assignment_limit ?? '';
  @tracked isEditingAssignmentLimit = false;
  @tracked valueIsModified = false;

  @ref('assignment-limit-input') inputElement;

  get canChangeAssignmentLimit() {
    return this.permissionsService.currentAdminCan('can_manage_workload_management');
  }

  get assignmentLimitToInt() {
    return parseInt(this.assignmentLimit, 10);
  }

  get adminAssignmentLimit() {
    return this.args.admin?.workload_management_settings?.assignment_limit;
  }

  get assignmentLimitHasChanged() {
    return this.assignmentLimitToInt !== this.adminAssignmentLimit;
  }

  get assignmentLimitInvalid() {
    return (
      !this.assignmentLimit ||
      this.assignmentLimitToInt > 1_000_000 ||
      this.assignmentLimitToInt < 0
    );
  }

  get disableSubmitButton() {
    return this.assignmentLimitInvalid || !this.assignmentLimitHasChanged;
  }

  @action
  async changeAssignmentLimit() {
    let revertToWorkspace = this.assignmentLimit === this.args.workspaceSettings.capacityLimit;

    // prevent submitting invalid assignment limit
    if ((this.assignmentLimitInvalid || !this.assignmentLimitHasChanged) && !revertToWorkspace) {
      return;
    }

    // null reverts to workspace default
    let assignment_limit = !revertToWorkspace ? this.assignmentLimitToInt : null;

    try {
      await post(`/ember/admins/change_assignment_limit`, {
        assignment_limit,
        admin_ids: [this.args.admin.id],
        id: this.appService.app.currentAdmin.id,
        app_id: this.appService.app.id,
      });

      this.args.admin.workload_management_settings = {
        assignment_limit,
      };

      if (!revertToWorkspace) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.teammate-workload.assignment-limit.success', {
            teammate: this.args.admin?.name,
          }),
        );
      } else {
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.teammate-workload.assignment-limit.revert-to-workspace-success', {
            teammate: this.args.admin?.name,
            workspaceLimit: this.args.workspaceSettings.capacityLimit,
          }),
        );
      }
      this.resetState();
    } catch (error) {
      if (error.jqXHR) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
      }
    } finally {
      this.appService.app.updateLocalCache(this.modelDataCacheService);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'assignment_limit',
        place: 'teammate_workload',
        teammate_id: this.args.admin.id,
        assignment_limit,
      });
    }
  }

  @action unfocusInput(focusEvent) {
    if (focusEvent.relatedTarget?.type === 'submit') {
      return;
    }

    if (!this.assignmentLimitHasChanged) {
      this.resetState();
      return;
    }

    this.triggerConfirmModal();
  }

  @action async triggerConfirmModal() {
    let confirmed = await this.intercomConfirmService.confirm({
      title: this.intl.t('settings.teammate-workload.confirm-modal.title'),
      body: this.intl.t('settings.teammate-workload.confirm-modal.body', {
        name: this.args.admin.name,
      }),
      cancelButtonText: this.intl.t('settings.teammate-workload.confirm-modal.cancel-button'),
      confirmButtonText: this.intl.t('settings.teammate-workload.confirm-modal.confirm-button'),
    });

    if (confirmed) {
      this.resetState();
    } else {
      this.inputElement.focus();
    }
  }

  @action
  async revertToWorkspaceLimit() {
    this.assignmentLimit = this.args.workspaceSettings.capacityLimit;
    await this.changeAssignmentLimit();
  }

  resetState() {
    this.isEditingAssignmentLimit = false;
    this.assignmentLimit = this.args.admin?.workload_management_settings?.assignment_limit || '';
    this.valueIsModified = false;
  }
}
