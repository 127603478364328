/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

import { Resource, type Named } from 'ember-resources';

import { type later } from '@ember/runloop';
import { Channel } from 'embercom/models/data/inbox/channels';

type ResourceArgs = {
  lastUpdatedAt: number | undefined;
  conversation: Conversation | undefined;
};

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export class TimedRestrictedRepliesResource extends Resource<Named<ResourceArgs>> {
  @service declare session: Session;

  @tracked declare lastUpdatedAt: number | undefined;
  @tracked declare conversation: Conversation | undefined;
  _preventRepliesTaskId: ReturnType<typeof later> | undefined;

  constructor(owner: any, args: Named<ResourceArgs>, previous: TimedRestrictedRepliesResource) {
    super(owner, args, previous);
    this.lastUpdatedAt = this.args.named.lastUpdatedAt;
    this.conversation = this.args.named.conversation;
  }

  get whatsappReplyLimit() {
    if (this.session.workspace.isFeatureEnabled('whatsapp-test-message-templates')) {
      return 1000 * 60 * 3; // 3 minutes
    } else {
      return 1000 * 3600 * 24; // 24 hours
    }
  }

  get preventFacebookReplies() {
    if (this.conversation?.currentChannel !== Channel.Facebook) {
      return false;
    }
    return this.conversation?.lastUserPartOlderThan(7 * 24 * 3600 * 1000); // 7 days
  }

  get preventInstagramReplies() {
    if (this.conversation?.currentChannel !== Channel.Instagram) {
      return false;
    }
    return this.conversation?.lastUserPartOlderThan(7 * 24 * 3600 * 1000); // 7 days
  }

  get preventWhatsappReplies() {
    if (this.conversation?.currentChannel !== Channel.Whatsapp) {
      return false;
    }
    return this.conversation?.lastUserPartOlderThan(this.whatsappReplyLimit);
  }
}
