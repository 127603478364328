/* import __COLOCATED_TEMPLATE__ from './browse.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type KnowledgeBaseFolder } from 'embercom/objects/inbox/knowledge-base/folder';
import { groupBy } from 'underscore';
import { isBlank } from '@ember/utils';
import { type KnowledgeBaseSearchResult } from 'embercom/objects/inbox/knowledge-base/search-result';
import { type EntityType } from 'embercom/models/data/entity-types';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';

interface Args {
  folders: KnowledgeBaseFolder[];
  selectedFolder?: KnowledgeBaseFolder;
  isError: boolean;
  retry: () => void;
  isLoading: boolean;
  onSelectFolder: (folder: KnowledgeBaseFolder) => void;
  onSelectContent: (
    entityId: KnowledgeBaseContent['entityId'],
    entityType: EntityType,
    isSearchResult: boolean,
  ) => void;
}

interface Signature {
  Args: Args;
}

export default class KnowledgeBaseBrowse extends Component<Signature> {
  folderContentsCount = (folder: KnowledgeBaseFolder): number => {
    let count = folder.contents.length;
    this.subFoldersForFolder(folder).forEach((subFolder) => {
      count += this.folderContentsCount(subFolder);
    });
    return count;
  };

  subFoldersForFolder = (folder: KnowledgeBaseFolder): KnowledgeBaseFolder[] => {
    return this.foldersByParentId[`${folder.id}`] ?? [];
  };

  get folders() {
    return this.args.folders;
  }

  get rootFolders() {
    return this.orderFolders(this.folders.filter((folder) => folder.isRoot));
  }

  get selectedFolder() {
    return this.args.selectedFolder;
  }

  get foldersByParentId(): Record<string, KnowledgeBaseFolder[]> {
    return groupBy(this.folders, (folder) => (folder.isRoot ? 'root' : `${folder.parentFolderId}`));
  }

  get contentForCurrentFolder(): KnowledgeBaseSearchResult[] {
    return this.selectedFolder?.contents ?? [];
  }

  get subFoldersForCurrentFolder() {
    let unorderedFolders = this.isBrowsingTopLevel
      ? this.foldersByParentId.root
      : this.foldersByParentId[`${this.selectedFolder?.id}`];
    return this.orderFolders(unorderedFolders);
  }

  get isBrowsingTopLevel() {
    return isBlank(this.selectedFolder);
  }

  orderFolders(unorderedFolders: KnowledgeBaseFolder[] | undefined): KnowledgeBaseFolder[] {
    if (!unorderedFolders || unorderedFolders.length === 0) {
      return [];
    }

    return unorderedFolders.sort((a, b) => a.title.localeCompare(b.title));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::Browse': typeof KnowledgeBaseBrowse;
  }
}
