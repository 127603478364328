/* import __COLOCATED_TEMPLATE__ from './latest-rating-card.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: any;
}

const LatestRatingCard = templateOnlyComponent<Signature>();
export default LatestRatingCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::YourAchievements::LatestRatingCard': typeof LatestRatingCard;
    'inbox2/your-achievements/latest-rating-card': typeof LatestRatingCard;
  }
}
