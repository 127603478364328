/* import __COLOCATED_TEMPLATE__ from './installation-methods.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type { InstallationMethodSelection } from 'embercom/components/installation-new/constants';

interface Args {
  setInstallationMethod: (method: string) => void;
  selectedInstallationMethod: string;
  installationMethods: InstallationMethodSelection[];
}

interface Signature {
  Args: Args;
}

export default class InstallationMethods extends Component<Signature> {
  @service appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::InstallationMethods': typeof InstallationMethods;
    'installation-new/installation-methods': typeof InstallationMethods;
  }
}
