/* import __COLOCATED_TEMPLATE__ from './example-code-snippets.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';

interface Args {
  spa: boolean;
  forLoggedInUsers: boolean;
  selectedProgrammingLanguage: string;
  appIdCode: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class ExampleCodeSnippets extends Component<Signature> {
  constructor(owner: never, args: Args) {
    super(owner, args);
  }

  get codeExamples(): Record<string, Record<string, string>> {
    return {
      javascript: {
        userID: 'user.id',
        name: 'user.name',
        email: 'user.email',
        createdAt: 'user.createdAt',
      },
      rails: {
        userID: '"<%= current_user.id %>"',
        name: '"<%= current_user.name %>"',
        email: '"<%= current_user.email %>"',
        createdAt: '"<%= current_user.created_at.to_i %>"',
      },
      django: {
        userID: '"{{ request.user.id|escapejs }}"',
        name: '"{{ request.user.name|escapejs }}"',
        email: '"{{ request.user.email|escapejs }}"',
        createdAt: '"{{ request.user.date_joined|date:"U" }}"',
      },
      php: {
        userID: '<?php echo json_encode($current_user->id) ?>',
        name: '<?php echo json_encode($current_user->name) ?>',
        email: '<?php echo json_encode($current_user->email) ?>',
        createdAt: '"<?php echo strtotime($current_user->created_at) ?>"',
      },
      unknown: {
        userID: 'user.id',
        name: 'user.name',
        email: 'user.email',
        createdAt: 'user.createdAt',
      },
    };
  }

  get availableExampleKeys() {
    return Object.keys(this.codeExamples);
  }

  get selectedProgrammingLanguageExample() {
    return this.availableExampleKeys.includes(this.args.selectedProgrammingLanguage)
      ? this.codeExamples[this.args.selectedProgrammingLanguage]
      : this.codeExamples.unknown;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::CodeSnippets::ExampleCodeSnippets': typeof ExampleCodeSnippets;
    'installation-new/web/initial-setup/code-snippets/example-code-snippets': typeof ExampleCodeSnippets;
  }
}
