/* import __COLOCATED_TEMPLATE__ from './code-snippets.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { ANDROID_PROGRAMMING_LANGUAGES } from 'embercom/components/installation-new/constants';
import { inject as service } from '@ember/service';

const LANGUAGE_OPTIONS = [
  { text: 'Kotlin', value: ANDROID_PROGRAMMING_LANGUAGES.KOTLIN },
  { text: 'Java', value: ANDROID_PROGRAMMING_LANGUAGES.JAVA },
];

interface Args {
  androidApiKey: string;
}

export default class CodeSnippets extends Component<Args> {
  @service declare appService: any;

  programmingLanguages = LANGUAGE_OPTIONS;

  private get kotlinInitializationSnippet() {
    return `
    override fun onCreate() {
      super.onCreate()
      Intercom.initialize(this, "${this.args.androidApiKey}", "${this.appService.app.id}")
      Intercom.client().loginUnidentifiedUser() // If you have identifying information for your user you can use loginIdentifiedUser() instead
      Intercom.client().setLauncherVisibility(Intercom.Visibility.VISIBLE)
    }
    `;
  }

  private get javaInitializationSnippet() {
    return `
    @Override public void onCreate() {
      super.onCreate();
      Intercom.initialize(this, "${this.args.androidApiKey}", "${this.appService.app.id}");
      Intercom.client().loginUnidentifiedUser(); // If you have identifying information for your user you can use loginIdentifiedUser() instead
      Intercom.client().setLauncherVisibility(Intercom.Visibility.VISIBLE);
    }
    `;
  }

  @tracked selectedProgrammingLanguage: ANDROID_PROGRAMMING_LANGUAGES =
    ANDROID_PROGRAMMING_LANGUAGES.KOTLIN;

  @action onSelectProgrammingLanguage(language: ANDROID_PROGRAMMING_LANGUAGES) {
    this.selectedProgrammingLanguage = language;
  }

  get initializationSnippets(): Record<ANDROID_PROGRAMMING_LANGUAGES, string> {
    return {
      [ANDROID_PROGRAMMING_LANGUAGES.KOTLIN]: this.kotlinInitializationSnippet,
      [ANDROID_PROGRAMMING_LANGUAGES.JAVA]: this.javaInitializationSnippet,
    };
  }

  get selectedCodeSnippet() {
    return this.initializationSnippets[this.selectedProgrammingLanguage];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Android::CodeSnippets': typeof CodeSnippets;
    'installation-new/mobile/initial-setup/android/code-snippets': typeof CodeSnippets;
  }
}
