/* import __COLOCATED_TEMPLATE__ from './api-key-banner.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type SdkAppService from 'embercom/services/sdk-app-service';
import {
  INSTALLATION_PLATFORMS,
  type MobilePlatform,
} from 'embercom/components/installation-new/constants';

interface Args {
  selectedInstallationPlatform: MobilePlatform;
}
interface Signature {
  Args: Args;
  Element: any;
}

export default class ApiKeyBanner extends Component<Signature> {
  @tracked openSectionId: string | null = null;

  @service declare appService: any;
  @service declare sdkAppService: SdkAppService;

  get admin() {
    return this.app.currentAdmin;
  }

  get app() {
    return this.appService.app;
  }

  get isRegenerateSdkAppRunning() {
    return this.sdkAppService.isRegenerateSdkAppRunning;
  }

  get selectedPlatformApiKey() {
    switch (this.args.selectedInstallationPlatform) {
      case INSTALLATION_PLATFORMS.ANDROID:
        return this.sdkAppService.androidApiKey;
      default:
        return this.sdkAppService.iosApiKey;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::ReactNative::ApiKeyBanner': typeof ApiKeyBanner;
    'installation-new/mobile/initial-setup/react-native/app-released-warning': typeof ApiKeyBanner;
  }
}
