/* import __COLOCATED_TEMPLATE__ from './view-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type Router from '@ember/routing/router-service';
import { action } from '@ember/object';

export default class UnnamedComponent extends Component {
  @service declare intercomEventService: any;
  @service declare router: Router;

  @action toggleView(view: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'inbox_view_switcher',
      place: 'inbox_conversations_list',
      section: 'inbox',
      context: `switched-view-${view}`,
    });
    this.router.transitionTo({ queryParams: { view } });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ViewSwitcher': typeof UnnamedComponent;
    'inbox2/view-switcher': typeof UnnamedComponent;
  }
}
