/* import __COLOCATED_TEMPLATE__ from './jump-to.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const JumpTo = templateOnlyComponent<Signature>();
export default JumpTo;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::CommandK::JumpTo': typeof JumpTo;
    'inbox2/onboarding/command-k/jump-to': typeof JumpTo;
  }
}
