/* import __COLOCATED_TEMPLATE__ from './side-conversation-summary-part.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
// import { RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import SideConversationReply from 'embercom/objects/inbox/renderable/side-conversation-reply';
import type SideConversationStarted from 'embercom/objects/inbox/renderable/side-conversation-started';

interface SideConversationPart extends RenderablePart {
  renderableData: SideConversationStarted | SideConversationReply;
}

interface Arguments {
  summaryPart: SideConversationPart;
}

interface Signature {
  Args: Arguments;
}

export default class SideConversationSummaryPart extends Component<Signature> {
  RenderableType = RenderableType;

  get isSideConversationStartedPart() {
    return this.args.summaryPart.renderableType === RenderableType.SideConversationStarted;
  }

  get isSideConversationReplyPart() {
    return this.args.summaryPart.renderableType === RenderableType.SideConversationReply;
  }

  get participantsCount() {
    return this.args.summaryPart.renderableData.participantsCount;
  }

  get hasMultipleParticipants() {
    return this.participantsCount > 1;
  }

  get isSideConversationReplyPartWithMultipleParticipants() {
    return this.isSideConversationReplyPart && this.hasMultipleParticipants;
  }

  get isSideConversationReplyPartWithSingleParticipant() {
    return this.isSideConversationReplyPart && !this.hasMultipleParticipants;
  }

  get senderEmail() {
    if (this.args.summaryPart.renderableData instanceof SideConversationReply) {
      return this.args.summaryPart.renderableData.fromEmail;
    }
    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationCard::SideConversationSummaryPart': typeof SideConversationSummaryPart;
    'inbox2/left-nav/conversation-card/side-conversation-summary-part': typeof SideConversationSummaryPart;
  }
}
