/* import __COLOCATED_TEMPLATE__ from './predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Predicate from 'predicates/models/predicate';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';

export default class PredicatesEditor extends Component {
  @service appService;
  @service attributeService;
  @service intl;
  @tracked predicates;
  @tracked paywall;

  constructor() {
    super(...arguments);
    if (this.args.setSupportedAttributes) {
      this.args.setSupportedAttributes(this.supportedPredicateAttributes);
    }
  }

  get supportedPredicateAttributes() {
    return this.attributeGroupList
      .map((group) => group.attributes)
      .flat()
      .map((attribute) => attribute.identifier);
  }

  get attributeGroupList() {
    let groupList = [];

    let attributes = this.attributeService.inboxViewsAttributes.map((attribute) => {
      if (attribute.identifier === 'conversation.next_breach_time' && this.paywall?.isActive) {
        return {
          ...attribute,
          component: 'paywalls/select-item',
          componentShouldReplaceItem: false,
          paywallOpenUpgradeModal: this.paywall?.openUpgradeModal,
          paywallAnalyticsEventData: this.paywall?.analyticsEventData,
        };
      } else {
        return attribute;
      }
    });

    groupList.push(
      { attributes },
      this.conversationAttributes,
      ...this.attributeService.ticketAttributesByType,
    );

    return groupList;
  }

  get conversationAttributes() {
    let attributesUsed = [];
    for (let predicate of Predicate.walkPredicates(this.args.predicateGroup.predicates)) {
      predicate.attributeIdentifier && attributesUsed.push(predicate.attributeIdentifier);
    }

    let usedArchivedConversationCustomAttributes =
      this.attributeService.archivedConversationCustomAttributes.filter((attribute) =>
        attributesUsed.includes(attribute.identifier),
      );

    let allConversationDataAttributes = [
      ...this.attributeService.nonArchivedConversationCustomAttributes,
      ...usedArchivedConversationCustomAttributes,
    ].reject((attribute) => attribute.type === DataType.Files);

    return {
      heading: this.intl.t('inbox.inbox-views.predicates.conversation-attribute-heading'),
      attributes: allConversationDataAttributes,
    };
  }
}
