/* import __COLOCATED_TEMPLATE__ from './spam.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import { SpamResource } from 'embercom/components/inbox2/spam-resource';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type SpamMessage } from 'embercom/objects/inbox/spam';
import { taskFor } from 'ember-concurrency-ts';
import Spam from 'embercom/objects/inbox/inboxes/spam';

const ALL_COLUMNS = [
  {
    label: 'name',
    valuePath: 'name',
    width: '220px',
  },
  {
    label: 'email',
    valuePath: 'email',
    width: '270px',
  },
  {
    label: 'subject',
    valuePath: 'emailSubject',
    width: '350px',
  },
  {
    label: 'date',
    valuePath: 'createdAt',
    width: '150px',
  },
  {
    label: 'rejection_reason',
    valuePath: 'rejectionReasonLabel',
    width: '120px',
  },
];

interface Args {}

interface Signature {
  Args: Args;
}

export default class SpamComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare inbox2Counters: Inbox2Counters;

  @tracked messageToPreview: SpamMessage | undefined;
  @tracked processingMessage: SpamMessage | undefined;

  spamInbox = new Spam(0); // The count does not matter here as we are just using it to generate a key for the Inbox counters service

  columns = ALL_COLUMNS;

  readonly spamResource = useResource(this, SpamResource);

  @action openPreviewPanel(spamMessage: SpamMessage) {
    this.messageToPreview = spamMessage;
  }

  @action hidePreviewPanel() {
    this.messageToPreview = undefined;
  }

  @action async markAsNotSpam(spamMessage: SpamMessage, event: Event) {
    event.stopPropagation();
    if (spamMessage?.id) {
      this.processingMessage = spamMessage;
      let messageProcessed = await taskFor(this.spamResource.markAsNotSpam).perform(spamMessage.id);
      if (messageProcessed) {
        this.spamResource.spamMessages.removeObject(spamMessage);
        this.messageToPreview = undefined;
        this.inbox2Counters.decrementCount(this.spamInbox);
      }
      this.processingMessage = undefined;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Spam': typeof SpamComponent;
    'inbox2/spam': typeof SpamComponent;
  }
}
