/* import __COLOCATED_TEMPLATE__ from './folder-card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';
import { type KnowledgeBaseFolder } from 'embercom/objects/inbox/knowledge-base/folder';

interface Args {
  folder: KnowledgeBaseFolder;
  contentsCount?: number;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

const KnowledgeBaseFolderCardComponent = templateOnlyComponent<Signature>();
export default KnowledgeBaseFolderCardComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::Browse::FolderCard': typeof KnowledgeBaseFolderCardComponent;
  }
}
