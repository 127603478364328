/* import __COLOCATED_TEMPLATE__ from './item-create-custom-folder.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  item: {
    onClick: () => void;
  };
  hideDropdownOverlay: () => void;
}

interface Signature {
  Args: Arguments;
}

const ItemCreateCustomFolder = templateOnlyComponent<Signature>();
export default ItemCreateCustomFolder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::EditSidebar::ItemCreateCustomFolder': typeof ItemCreateCustomFolder;
    'inbox2/left-nav/edit-sidebar/item-create-custom-folder': typeof ItemCreateCustomFolder;
  }
}
