/* import __COLOCATED_TEMPLATE__ from './add-customer-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { task } from 'ember-concurrency-decorators';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  recipients?: UserSummary[];
  onRecipientChange: (user: UserSummary[]) => void;
  highlightEmptyRecipientsError: boolean;
}

interface Signature {
  Args: Args;
}

export default class AddCustomerDropdown extends Component<Signature> {
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare intl: IntlService;

  @tracked selectedCustomer: UserSummary | undefined = undefined;
  @tracked searchList: UserSummary[] = [];
  @tracked inputText = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadInitialSearchList();

    if (this.args.recipients && this.args.recipients.length > 0) {
      this.selectCustomer(this.args.recipients[0]);
    }
  }

  @action
  removeSelectedUser() {
    this.selectedCustomer = undefined;
    this.args.onRecipientChange([]);
  }

  @action
  selectCustomer(user: UserSummary) {
    this.selectedCustomer = user;
    this.args.onRecipientChange([user]);
  }

  get selectedCustomerLabel() {
    return this.selectedCustomer?.name || this.selectedCustomer?.email;
  }

  @action updateSearch(event: KeyboardEvent & { target: HTMLInputElement }) {
    let text = event.target.value;
    this.inputText = text;
    taskFor(this.searchUsers).perform(text);
  }

  get hasCustomerResults() {
    return this.searchList.length > 0;
  }

  get dropdownItems() {
    return this.searchList;
  }

  get isSearching() {
    return taskFor(this.searchUsers).isRunning;
  }

  loadInitialSearchList() {
    let defaultLetter = 'a';
    taskFor(this.searchUsers).perform(defaultLetter);
  }

  @task({ restartable: true })
  *searchUsers(query: string) {
    this.searchList = yield this.inboxSearchSuggestionsService.getUserSuggestions(query, 10);
  }

  get inputLooksLikeEmail() {
    return this.inputText?.match(/.+\@.+\..+/);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::AddCustomerDropdown': typeof AddCustomerDropdown;
    'inbox2/ticket-attribute/add-customer-dropdown': typeof AddCustomerDropdown;
  }
}
