/* import __COLOCATED_TEMPLATE__ from './left-nav.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const LeftNav = templateOnlyComponent<Signature>();
export default LeftNav;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav': typeof LeftNav;
    'inbox2/left-nav': typeof LeftNav;
  }
}
