/* import __COLOCATED_TEMPLATE__ from './progress.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Progress = templateOnlyComponent<Signature>();
export default Progress;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::Progress': typeof Progress;
    'inbox2/onboarding/progress': typeof Progress;
  }
}
