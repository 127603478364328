/* import __COLOCATED_TEMPLATE__ from './user-action-popover.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

const ANONYMOUS_ATTRIBUTE_IDENTIFIERS = ['created_at', 'last_request_at', 'last_contacted_at'];
const USER_ATTRIBUTE_IDENTIFIERS = ['remote_created_at', 'last_request_at', 'session_count'];

export default class UserActionPopover extends Component {
  @service store;
  @service attributeService;
  @service router;
  @service appService;

  @tracked user = null;

  get displayableAttributes() {
    return this.attributeService.displayableAttributes.map((a) => a.attribute);
  }

  get userCardDisplayedUserAttributeIdentifiers() {
    return this.user?.is_anonymous ? ANONYMOUS_ATTRIBUTE_IDENTIFIERS : USER_ATTRIBUTE_IDENTIFIERS;
  }

  get userCardDisplayedUserAttributes() {
    return this.userCardDisplayedUserAttributeIdentifiers.map((identifier) => {
      return this.displayableAttributes.find((a) => a.identifier === identifier);
    });
  }

  get cityNameAttribute() {
    return this.displayableAttributes.find(
      (attribute) => attribute.identifier === 'geoip_data.city_name',
    );
  }

  @task({ restartable: true })
  *fetchUser() {
    try {
      let user = yield this.store.findRecord('user', this.args.userId, {
        backgroundReload: false,
      });
      this.user = user;
    } catch (e) {
      console.warn('<Inbox::UserActionPopover>: User not loaded');
    }
  }

  @action
  linkToOutboundComposer() {
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', this.appService.app.id, {
      queryParams: { recipient: this.user.id, overwrite: null },
    });
  }

  @action
  linkToUserProfile() {
    this.router.transitionTo('apps.app.users.user', this.user.id);
  }
}
