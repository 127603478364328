/* import __COLOCATED_TEMPLATE__ from './automatic-away-mode.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { put } from 'embercom/lib/ajax';
import { ref } from 'ember-ref-bucket';

export default class AutomaticAwayMode extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intl;
  @service intercomEventService;
  DEFAULT_INITIAL_INACTIVITY_ENABLED = false;
  DEFAULT_INITIAL_INACTIVITY_THRESHOLD = 30;
  DEFAULT_FOLLOWING_INACTIVITY_ENABLED = false;
  DEFAULT_FOLLOWING_INACTIVITY_THRESHOLD = 60;

  @tracked autoAwaySetting = this.args.model.autoAwaySetting;

  @tracked initialInactivityEnabled = this.getInitialInactivityEnabledValue();
  @tracked initialInactivityThreshold = this.getInitialInactivityThresholdValue();
  @tracked followingInactivityEnabled = this.getFollowingInactivityEnabledValue();
  @tracked followingInactivityThreshold = this.getFollowingInactivityThresholdValue();

  @ref('following-inactivity-threshold-input') followingInactivityThresholdInput;
  @ref('initial-inactivity-threshold-input') initialInactivityThresholdInput;

  get saveButtonActive() {
    return (
      !this.isDefaultState &&
      (this.initialInactivityHasChanged || this.followingInactivityHasChanged)
    );
  }

  get initialInactivityHasChanged() {
    return (
      this.initialInactivityEnabled !== this.autoAwaySetting.initialInactivityThresholdActive ||
      parseInt(this.initialInactivityThreshold, 10) !==
        this.autoAwaySetting.initialInactivityThreshold
    );
  }

  get followingInactivityHasChanged() {
    return (
      this.followingInactivityEnabled !== this.autoAwaySetting.followingInactivityThresholdActive ||
      parseInt(this.followingInactivityThreshold, 10) !==
        this.autoAwaySetting.followingInactivityThreshold
    );
  }

  get isInitiallyDefaultSettings() {
    return (
      this.getInitialInactivityEnabledValue() === this.DEFAULT_INITIAL_INACTIVITY_ENABLED &&
      this.getInitialInactivityThresholdValue() === this.DEFAULT_INITIAL_INACTIVITY_THRESHOLD &&
      this.getFollowingInactivityEnabledValue() === this.DEFAULT_FOLLOWING_INACTIVITY_ENABLED &&
      this.getFollowingInactivityThresholdValue() === this.DEFAULT_FOLLOWING_INACTIVITY_THRESHOLD
    );
  }

  get isDefaultState() {
    return (
      this.isInitiallyDefaultSettings &&
      this.initialInactivityEnabled === this.DEFAULT_INITIAL_INACTIVITY_ENABLED &&
      this.followingInactivityEnabled === this.DEFAULT_FOLLOWING_INACTIVITY_ENABLED &&
      this.initialInactivityThreshold === this.DEFAULT_INITIAL_INACTIVITY_THRESHOLD &&
      this.followingInactivityThreshold === this.DEFAULT_FOLLOWING_INACTIVITY_THRESHOLD
    );
  }

  getInitialInactivityEnabledValue() {
    return (
      this.autoAwaySetting.initialInactivityThresholdActive ||
      this.DEFAULT_INITIAL_INACTIVITY_ENABLED
    );
  }

  getInitialInactivityThresholdValue() {
    return (
      this.autoAwaySetting.initialInactivityThreshold || this.DEFAULT_INITIAL_INACTIVITY_THRESHOLD
    );
  }

  getFollowingInactivityEnabledValue() {
    return (
      this.autoAwaySetting.followingInactivityThresholdActive ||
      this.DEFAULT_FOLLOWING_INACTIVITY_ENABLED
    );
  }

  getFollowingInactivityThresholdValue() {
    return (
      this.autoAwaySetting.followingInactivityThreshold ||
      this.DEFAULT_FOLLOWING_INACTIVITY_THRESHOLD
    );
  }

  @action
  toggleInitialInactivity() {
    this.initialInactivityEnabled = !this.initialInactivityEnabled;
    if (this.initialInactivityEnabled) {
      this.initialInactivityThresholdInput.disabled = false;
      this.initialInactivityThresholdInput.focus();
    }
  }

  @action
  toggleFollowingInactivity() {
    this.followingInactivityEnabled = !this.followingInactivityEnabled;
    if (this.followingInactivityEnabled) {
      this.followingInactivityThresholdInput.disabled = false;
      this.followingInactivityThresholdInput.focus();
    }
  }

  @action
  setInitialInactivityThreshold(event) {
    this.initialInactivityThreshold = event.target.value;
  }

  @action
  setFollowingInactivityThreshold(event) {
    this.followingInactivityThreshold = event.target.value;
  }

  @action
  async persistAutoAwaySetting() {
    if (
      this.initialInactivityThreshold < 10 ||
      this.followingInactivityThreshold < 10 ||
      this.initialInactivityThreshold > 20000 ||
      this.followingInactivityThreshold > 20000
    ) {
      this.notificationsService.notifyError(
        this.intl.t('settings.automatic-away-mode.value-error'),
      );
      return;
    }

    if (
      this.initialInactivityEnabled &&
      this.followingInactivityEnabled &&
      parseInt(this.initialInactivityThreshold, 10) + 5 >
        parseInt(this.followingInactivityThreshold, 10)
    ) {
      this.notificationsService.notifyError(
        this.intl.t('settings.automatic-away-mode.thresholds-too-close-error'),
      );
      return;
    }

    let data = {
      app_id: this.appService.app.id,
      initial_inactivity_threshold_active: this.initialInactivityEnabled,
      initial_inactivity_threshold: this.initialInactivityThreshold,
      following_inactivity_threshold_active: this.followingInactivityEnabled,
      following_inactivity_threshold: this.followingInactivityThreshold,
    };

    let response = await put(`/ember/auto_away_settings`, data);

    if (this.initialInactivityHasChanged) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated_auto_away_initial_threshold',
        object: 'auto_away_settings',
        enabled: this.initialInactivityEnabled,
        threshold: this.initialInactivityThreshold,
      });
    }

    if (this.followingInactivityHasChanged) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated_auto_away_following_threshold',
        object: 'auto_away_settings',
        enabled: this.followingInactivityEnabled,
        threshold: this.followingInactivityThreshold,
      });
    }

    this.autoAwaySetting.initialInactivityThreshold = response.initial_inactivity_threshold;
    this.autoAwaySetting.followingInactivityThreshold = response.following_inactivity_threshold;
    this.autoAwaySetting.followingInactivityThresholdActive =
      response.following_inactivity_threshold_active;
    this.autoAwaySetting.initialInactivityThresholdActive =
      response.initial_inactivity_threshold_active;

    this.notificationsService.notifyConfirmation(
      this.intl.t('settings.automatic-away-mode.settings-saved'),
    );
  }
}
