/* import __COLOCATED_TEMPLATE__ from './content-viewer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type AttachmentList } from '@intercom/interblocks.ts';
import { EntityType } from 'embercom/models/data/entity-types';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import { inject as service } from '@ember/service';
import type CustomIntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import type RegionService from 'embercom/services/region-service';
import type RouterService from '@ember/routing/router-service';
import type RouteInfo from '@ember/routing/route-info';
import type InboxApi from 'embercom/services/inbox-api';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { ResponseError } from 'embercom/lib/inbox/requests';

interface Args {
  content: KnowledgeBaseContent;
  goBack: () => void;
  visibleToUser?: boolean;
  onManualCopy: () => void;
  onOpenCrossLinkedContent: (content: KnowledgeBaseContent) => void;
}

interface Signature {
  Args: Args;
}

export const KNOWLEDGE_HUB_ROUTE = 'apps.app.knowledge-hub.view';

export const ROUTE_CONTENT_TYPES_TO_ENTITY_TYPES: Record<string, EntityType> = {
  'internal-article': EntityType.InternalArticle,
} as const;

export default class KnowledgeBaseContentViewer extends Component<Signature> {
  @service declare intl: CustomIntlService;
  @service declare session: Session;
  @service declare regionService: RegionService;
  @service declare router: RouterService;
  @service declare inboxApi: InboxApi;
  @service declare notificationsService: $TSFixMe;

  @tracked linkedContentStack: KnowledgeBaseContent[] = [];

  detectKnowledgeHubLinkClicks = modifier(
    (element: HTMLElement) => {
      let clickHandler = async (event: Event) => {
        let target: HTMLElement | null = event.target as HTMLElement;
        let href = target.getAttribute('href');

        if (target.tagName !== 'A' || !href) {
          return;
        }

        let url = new URL(href);
        let routeInfo: RouteInfo = this.router.recognize(url.pathname);

        if (
          this.regionService.safeBrowsingIntercomHosts.includes(url.host) &&
          routeInfo?.name === KNOWLEDGE_HUB_ROUTE &&
          routeInfo.params['content_type'] &&
          routeInfo.params['content_id']
        ) {
          let entityType = ROUTE_CONTENT_TYPES_TO_ENTITY_TYPES[routeInfo.params['content_type']];
          let entityId = Number(routeInfo.params['content_id']);
          try {
            event.preventDefault();
            await this.fetchAndOpenLinkedContent(entityType, entityId);
          } catch (error) {
            if (error instanceof ResponseError && error.response.status === 404) {
              this.notificationsService.notifyError(
                this.intl.t('inbox.knowledge-base.sidebar.content.broken-link-error'),
              );
            }
            console.error('Error opening linked knowledge content', error);
          }
        }
      };

      element.addEventListener('click', clickHandler);

      return function cleanupObserver() {
        element.removeEventListener('click', clickHandler);
      };
    },
    { eager: false },
  );

  get linkedContent(): KnowledgeBaseContent | undefined {
    return this.linkedContentStack.lastObject;
  }

  get authorId() {
    return this.args.content.author?.id.toString();
  }

  get attachments() {
    let blocks = this.args.content.blocks;
    let attachments = blocks.filter((block) => block.type === 'attachmentList');
    return attachments as AttachmentList[];
  }

  get isInternalArticle() {
    return this.args.content.entityType === EntityType.InternalArticle;
  }

  get shouldShowLockIcon() {
    return !this.isInternalArticle && !this.args.visibleToUser;
  }

  get publicEntityName() {
    switch (this.args.content.entityType) {
      case EntityType.ArticleContent:
        return this.intl.t('inbox.knowledge-base.sidebar.public-article');
      case EntityType.InternalArticle:
        return this.intl.t('inbox.knowledge-base.sidebar.internal-article');
      default:
        return this.args.content.entityName;
    }
  }

  @action async fetchAndOpenLinkedContent(
    entityType: KnowledgeBaseContent['entityType'],
    entityId: KnowledgeBaseContent['entityId'],
  ) {
    let content = await this.inboxApi.fetchKnowledgeBaseContent(entityId, entityType, '1');
    if (content) {
      this.args.onOpenCrossLinkedContent(content);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::ContentViewer': typeof KnowledgeBaseContentViewer;
    'inbox2/knowledge-base/content-viewer': typeof KnowledgeBaseContentViewer;
  }
}
