/* import __COLOCATED_TEMPLATE__ from './icon.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  percentage: number;
  size?: number;
}

interface Signature {
  Args: Args;
}

const PI = 3.14;

export default class Inbox2SlaIcon extends Component<Signature> {
  @service declare session: Session;

  readonly strokeWidth = 1.5;

  get size() {
    return this.args.size ?? 12;
  }

  get cx() {
    return this.size / 2;
  }

  get cy() {
    return this.size / 2;
  }

  get radius() {
    return this.size / 2 - this.strokeWidth;
  }

  get smallerCircleRadius() {
    return this.radius / 2;
  }

  get smallerCircleStrokeWidth() {
    return this.radius;
  }

  get smallerCircleCircumference() {
    return this.smallerCircleRadius * 2 * PI;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Sla::Icon': typeof Inbox2SlaIcon;
    'inbox2/sla/icon': typeof Inbox2SlaIcon;
  }
}
