/* import __COLOCATED_TEMPLATE__ from './segment.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  apiSecret: string;
  selectedProgrammingLanguage: string;
  userIdentifier: string;
  highlightLanguage: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class Segment extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  get segmentExamples() {
    let apiSecret = this.args.apiSecret;
    if (this.args.userIdentifier === 'id') {
      return {
        rails: {
          userId: '<%= current_user.id %>',
          userHash: `<%= OpenSSL::HMAC.hexdigest("sha256", "${apiSecret}", current_user.id.to_s) %>`,
        },
        django: {
          userId: '{{ request.user.id|escapejs }}',
          userHash: `{{ hmac.new(b'${apiSecret}', bytes(request.user.id, encoding='utf-8'), digestmod=hashlib.sha256).hexdigest() }}`,
        },
        php: {
          userId: '<?php echo json_encode($user->id); ?>',
          userHash: `<?php echo hash_hmac("sha256", $user->id, "${apiSecret}"); ?>`,
        },
        node: {
          userId: 'user.id',
          userHash: `crypto.createHmac('sha256', '${apiSecret}').update(user.id).digest('hex')`,
        },
      };
    } else {
      return {
        rails: {
          userId: '<%= current_user.email %>',
          userHash: `<%= OpenSSL::HMAC.hexdigest("sha256", "${apiSecret}", current_user.email.to_s) %>`,
        },
        django: {
          userId: '{{ request.user.email|escapejs }}',
          userHash: `{{ hmac.new(b'${apiSecret}', bytes(request.user.email, encoding='utf-8'), digestmod=hashlib.sha256).hexdigest() }}`,
        },
        php: {
          userId: '<?php echo json_encode($user->email); ?>',
          userHash: `<?php echo hash_hmac("sha256", $user->email, "${apiSecret}"); ?>`,
        },
        node: {
          userId: 'user.email',
          userHash: `crypto.createHmac('sha256', '${apiSecret}').update(user.email).digest('hex')`,
        },
      };
    }
  }

  get selectedExample() {
    return this.segmentExamples[
      this.args.selectedProgrammingLanguage as keyof typeof this.segmentExamples
    ];
  }

  @action
  onCopyCode() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'copy_code_snippet',
      metadata: {
        is_idv: true,
        programming_language: this.args.selectedProgrammingLanguage,
      },
      place: 'installation-new',
      section: 'settings',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::GenerateHmac::Segment': typeof Segment;
    'installation-new/generate-hmac/segment': typeof Segment;
  }
}
