/* import __COLOCATED_TEMPLATE__ from './volume-control-settings-section.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

interface Args {
  teamSettings: any[];
  updateVolumeControl: (id: number) => void;
}

export default class VolumeControlSettingsSection extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get teamLength() {
    return this.args.teamSettings.length - 1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::VolumeControlSettingsSection': typeof VolumeControlSettingsSection;
    'inbox/workload-management/volume-control-settings-section': typeof VolumeControlSettingsSection;
  }
}
