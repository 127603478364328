/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';
import { type KnowledgeBaseSearchResult } from 'embercom/objects/inbox/knowledge-base/search-result';

interface Args {
  content: KnowledgeBaseSearchResult;
  isSearchResult: boolean;
  onClick: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

const KnowledgeBaseBrowseListItemContent = templateOnlyComponent<Signature>();
export default KnowledgeBaseBrowseListItemContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::ListItems::Content': typeof KnowledgeBaseBrowseListItemContent;
    'inbox2/knowledge-base/list-items/content': typeof KnowledgeBaseBrowseListItemContent;
  }
}
