/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',

  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  openTaggingModalFromVisibilityComponent: action(function (_, user) {
    this.modalService.openModal('users/segments/segment/modals/tagging', user, {
      canCloseModal: true,
    });
  }),
});
