/* import __COLOCATED_TEMPLATE__ from './product-icon-nav.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { PRODUCTS, INBOX_ID } from 'embercom/lib/billing';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import { tracked } from '@glimmer/tracking';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';
import type Router from '@ember/routing/router-service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  product: {
    id?: string;
    svgId: string;
    productName?: string;
    route: string;
    activeRoute?: string;
    className?: string;
    analyticsEventObjectName?: string;
    active?: boolean;
  };
}

interface Signature {
  Args: Args;
}

export default class ProductIconNav extends Component<Signature> {
  @service declare router: Router;
  @service declare inbox2Counters: Inbox2Counters;
  @service declare inboxApi: InboxApi;
  @service declare session: Session;

  classNames = ['product-icon'];

  @tracked pinnedInboxes?: Inbox[];

  get isInbox() {
    return this.args.product.id === INBOX_ID;
  }

  get productKey() {
    let productId = this.args.product.id;

    return productId ? (PRODUCTS as any)[productId]?.key : productId;
  }

  get svgPath() {
    let { product } = this.args;

    return product.svgId as InterfaceIconName;
  }

  get inboxCount() {
    return (
      this.inbox2Counters.countForInboxWithIdAndCategory(
        this.session.teammate.id.toString(),
        InboxCategory.Admin,
      ) ?? 0
    );
  }

  get hasAnyUnread() {
    return this.inboxCount > 0;
  }

  get canUseFree() {
    return this.session.workspace.isFeatureEnabled('ember-purchase-free');
  }

  get canUseInbox() {
    return this.session.workspace.isFeatureEnabled('inbox');
  }

  get inboxIsActive() {
    return !!(this.canUseFree || this.canUseInbox);
  }

  get showInboxCount() {
    return this.isInbox && this.hasAnyUnread && this.inboxIsActive;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ProductIconNav': typeof ProductIconNav;
    'inbox2/product-icon-nav': typeof ProductIconNav;
  }
}
