/* import __COLOCATED_TEMPLATE__ from './recipients-confirmation-body.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';

interface Args {
  confirmContext: {
    changedRecipients: string[];
  };
}

export default class RecipientsConfirmationBody extends Component<Args> {
  get recipients() {
    return this.args.confirmContext.changedRecipients;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ManageParticipants::RecipientsConfirmationBody': typeof RecipientsConfirmationBody;
    'inbox2/manage-participants/recipients-confirmation-body': typeof RecipientsConfirmationBody;
  }
}
