/* import __COLOCATED_TEMPLATE__ from './conversations-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';

export default class ConversationsSideDrawer extends Component {
  @service store;
  @tracked hideBanner = false;
  @tracked conversationIds = [];
  @tracked conversations = [];
  @tracked currentFilterCount = 0;
  @tracked haveFetchedConversationContent = false;
  @tracked haveFetchedConversationIds = false;

  page = 1;
  pageSize = 10;
  maxResults = 100; // from max results on conversations/ids_by_filters endpoint

  get showBanner() {
    let idsDontMatchChartCount =
      (this.args.conversationsChartCount <= this.maxResults &&
        this.conversationIds.length !== this.args.conversationsChartCount) ||
      (this.args.conversationsChartCount > this.maxResults &&
        this.conversationIds.length < this.maxResults);

    return !this.hideBanner && this.haveFetchedConversationIds && idsDontMatchChartCount;
  }

  @action
  loadConversationsPage() {
    this.fetchConversationsData.perform();
  }

  @task({ enqueue: true })
  *fetchConversationsData() {
    if (this.conversationIds.length === 0) {
      let response = yield this.args.fetchConversationIds.perform(this.args.globalFilters);
      this.conversationIds = response;
      this.haveFetchedConversationIds = true;
    }

    if (this.conversationIds.length === 0 && this.haveFetchedConversationIds) {
      return;
    }

    if (this.page > 1) {
      this.args.triggerAnalyticsEvent({
        action: 'paginated',
        object: 'conversations_list',
        page: this.page,
      });
    }

    let start = (this.page - 1) * this.pageSize;
    let end = start + this.pageSize;
    if (start >= this.conversationIds.length) {
      return;
    }

    let response = yield post('/ember/reporting/conversations/content', {
      app_id: this.args.app.id,
      conversation_ids: this.conversationIds.slice(start, end),
    });

    this.store.pushPayload({
      conversations: response,
    });

    this.conversations.pushObjects(
      response.map((c) => this.store.peekRecord('conversation', c.id)),
    );
    this.page += 1;
    this.haveFetchedConversationContent = true;
  }

  @action
  onClose() {
    this.args.fetchConversationIds.cancelAll();
    this.fetchConversationsData.cancelAll();
    this.args.onClose();
    this.hideBanner = false;
    this.conversations = [];
    this.currentFilterCount = 0;
    this.conversationIds = [];
    this.haveFetchedConversationContent = false;
    this.haveFetchedConversationIds = false;
    this.page = 1;
  }
}
