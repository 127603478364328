/* import __COLOCATED_TEMPLATE__ from './set-away-reason.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type AwayReason } from 'embercom/services/admin-away-service';
import type AdminAwayService from 'embercom/services/admin-away-service';
import { AWAY_REASONS } from 'embercom/services/admin-away-service';

interface Args {
  item: { text: string; value: AwayReason; setAwayReason: (reason: AwayReason) => void };
}

interface Signature {
  Args: Args;
}

export default class SetAwayReasonPopover extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare adminAwayService: AdminAwayService;

  @tracked selectedReason: AwayReason = this.args.item.value;

  get reasons() {
    return AWAY_REASONS.map((reason) => {
      return {
        text: this.intl.t(
          `inbox.user-menu.reasons.${this.adminAwayService.getTranslationKeyForAwayReason(reason)}`,
        ),
        name: reason,
        selected: this.selectedReason === reason,
      };
    });
  }

  get openerContent() {
    return !this.selectedReason
      ? this.intl.t('inbox.user-menu.set-away-reason')
      : this.intl.t(
          `inbox.user-menu.reasons.${this.adminAwayService.getTranslationKeyForAwayReason(
            this.selectedReason,
          )}`,
        );
  }

  @action setAwayReason(reason: AwayReason) {
    if (this.selectedReason === reason) {
      this.selectedReason = null;
    } else {
      this.selectedReason = reason;
    }
    this.args.item.setAwayReason(this.selectedReason);
  }

  // This function is a Popper.js modifier (https://popper.js.org/docs/v2/modifiers/)
  // that keeps the dimensions of the app switcher popover the same as those of
  // the primary nav menu popover. This behavior is a design requirement from
  // the "early 2018" brand refresh.
  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::SetAwayReason': typeof SetAwayReasonPopover;
    'inbox2/user-menu/set-away-reason': typeof SetAwayReasonPopover;
  }
}
