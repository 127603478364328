/* import __COLOCATED_TEMPLATE__ from './ticket-summary-part.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

interface Args {
  conversation: ConversationSummary | Conversation;
  userDisplay: string;
  isTableView: boolean;
  hideTitle?: boolean;
  checkForTruncation?: boolean;
}

interface Signature {
  Args: Args;
}

export default class TicketSummaryPart extends Component<Signature> {
  @service declare session: Session;

  get workspaceID() {
    return this.session.workspace.id;
  }

  get showNoteLabel() {
    return (
      this.args.conversation.lastRenderableSummaryPart?.renderableType ===
        RenderableType.AdminNote ||
      this.args.conversation.lastRenderableSummaryPart?.renderableType === RenderableType.BotNote ||
      this.args.conversation.lastRenderableSummaryPart?.renderableType ===
        RenderableType.DuplicateConversationNote
    );
  }
  get summaryPart() {
    return (
      this.args.conversation.lastAdminMentionedPart ||
      this.args.conversation.lastRenderableSummaryPart
    );
  }

  get ticketType() {
    return this.args.conversation.ticketType;
  }

  get showTicketType() {
    let { conversation } = this.args;
    return conversation.ticketType && conversation.ticketTitle !== conversation.ticketType.name;
  }

  get ticketTypeNameAndId() {
    let { conversation } = this.args;
    let ticketDetails = conversation.ticketType?.name;
    if (conversation.ticketId) {
      let ticketId = `#${conversation.ticketId}`;
      let ticketType = `• ${ticketDetails}`;
      ticketDetails = `${ticketId} ${ticketDetails && ticketType}`;
    }
    return ticketDetails;
  }

  get ticketId() {
    let { conversation } = this.args;
    return (conversation.ticketId && `#${conversation.ticketId}`) || '';
  }

  get showTitle() {
    return !this.args.hideTitle;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationCard::TicketSummaryPart': typeof TicketSummaryPart;
    'inbox2/left-nav/conversation-card/ticket-summary-part': typeof TicketSummaryPart;
  }
}
