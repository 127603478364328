/* import __COLOCATED_TEMPLATE__ from './latest-ratings-carousel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { registerDestructor } from '@ember/destroyable';
import { type TaskGenerator } from 'ember-concurrency';
import { request } from 'embercom/lib/inbox/requests';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';
import { toLeft } from 'ember-animated/transitions/move-over';

export interface ConversationRatingWireFormat {
  conversation_id: number;
  rating_index: number;
  remark: string | null;
  user: { display_as: string };
  seen_in_inbox_of_love: boolean;
  id: string;
  updated_at: string;
}

interface Args {
  refreshRateMs?: number;
  onLoad?: (hasUnseenRatings: boolean) => void;
  isInitialLoad: boolean;
}

interface Signature {
  Args: Args;
}

export default class UnnamedComponent extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;

  @tracked ratingIndex = 0;
  @tracked latestRatings: ConversationRatingWireFormat[] = [];

  progressButtonAnimationOffset = -100;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadData();
    registerDestructor(this, () => {
      taskFor(this.fetchData).cancelAll();
      taskFor(this.markRatingAsSeen).cancelAll();
    });
  }

  get latestRating() {
    if (this.latestRatings.length) {
      return this.latestRatings[this.ratingIndex];
    }
    return undefined;
  }

  get hasNextRating() {
    return this.latestRatings.length > 1;
  }

  get isLoading(): boolean {
    return this.args.isInitialLoad;
  }

  get hasUnseenRatings(): boolean {
    return this.latestRatings.any((rating) => !rating.seen_in_inbox_of_love);
  }

  rules() {
    return toLeft;
  }

  @action nextRating() {
    if (this.ratingIndex === this.latestRatings.length - 1) {
      this.ratingIndex = 0;
    } else {
      this.ratingIndex += 1;
    }
  }

  @action onSeenRating(rating: ConversationRatingWireFormat) {
    if (!rating.seen_in_inbox_of_love) {
      taskFor(this.markRatingAsSeen).perform(rating);
    }
  }

  @task({})
  private *markRatingAsSeen(conversationRating: ConversationRatingWireFormat): TaskGenerator<void> {
    conversationRating.seen_in_inbox_of_love = true;
    yield this.inboxApi.markRatingAsSeen(conversationRating.id);
  }

  @action async loadData() {
    await taskFor(this.fetchData).perform();
    if (this.args.onLoad) {
      this.args.onLoad(this.hasUnseenRatings);
    }
  }

  @task({ restartable: true })
  private *fetchData(): TaskGenerator<void> {
    let response = yield request(
      `/ember/conversation_ratings/list_unseen_first.json?app_id=${this.session.workspace.id}&admin_id=${this.session.teammate.id}`,
    );
    let json = yield response.json();
    this.latestRatings = json;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::YourAchievements::LatestRatingsCarousel': typeof UnnamedComponent;
    'inbox2/your-achievements/latest-ratings-carousel': typeof UnnamedComponent;
  }
}
