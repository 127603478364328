/* import __COLOCATED_TEMPLATE__ from './edit-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { AVAILABLE_EMOJI } from 'embercom/lib/available-emoji';
import type IntlService from 'embercom/services/intl';
import ViewSummary from 'embercom/objects/inbox/view-summary';
import type Session from 'embercom/services/session';
import { put } from 'embercom/lib/ajax';
import { request } from 'embercom/lib/inbox/requests';

// @ts-ignore
import PredicateGroup from 'predicates/models/predicate-group';
// @ts-ignore
import Predicate from 'predicates/models/predicate';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import shuffle from '@intercom/pulse/lib/shuffle';
import { EmojiIcon } from 'embercom/objects/inbox/command-k/icons';
import type Store from '@ember-data/store';

interface Args {
  view: ViewSummary;
  onClose: (args: { success: boolean; view?: ViewSummary }) => void;
  onDelete?: (view: ViewSummary) => void;
}

interface Signature {
  Args: Args;
}

export default class EditModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  @service notificationsService: any;
  @service intercomEventService: any;

  // These imports are not supported in Inbox 2, but we need them in this
  // component.
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare store: Store;
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare appService: any;

  @tracked name: string;
  @tracked avatarEmoji: string;
  @tracked predicateGroup: PredicateGroup;
  @tracked supportedPredicateAttributes: Array<string> = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.name = this.args.view.name;

    if (this.args.view.icon instanceof EmojiIcon && this.args.view.icon.emoji) {
      this.avatarEmoji = this.args.view.icon.emoji;
    } else {
      this.avatarEmoji = this._randomEmoji();
    }

    this.predicateGroup = this.normalizePredicateGroup(
      this.store.createFragment('predicates/predicate-group', {
        predicates: PredicateGroup.convertRawPredicates(
          this.store,
          args.view.predicates.predicates,
        ),
      }),
    );

    taskFor(this.fetchAdminViewCount).perform();
  }

  get isValid() {
    let group = this.predicateGroup;
    return this.name.trim() !== '' && group.isValid && group.hasEditablePredicates;
  }

  get otherAdminVisibilityCount() {
    return taskFor(this.fetchAdminViewCount).lastSuccessful?.value?.other_admin_visibility_count;
  }

  get saveDisabled() {
    return !this.isValid || !this.predicatesAreSupported;
  }

  get predicatesAreSupported() {
    return this.predicateGroup.predicates.every((predicate: Predicate) => {
      return this.predicateIsSupported(predicate);
    });
  }

  predicateIsSupported(predicate: Predicate) {
    if (predicate.predicates) {
      return predicate.predicates.every((predicate: Predicate) => {
        return this.predicateIsSupported(predicate);
      });
    } else {
      return this.supportedPredicateAttributes.includes(predicate.attribute);
    }
  }

  @action setSupportedAttributes(attributes: Array<string>) {
    this.supportedPredicateAttributes = attributes;
  }

  @action predicatesChanged(predicates: any) {
    this.predicateGroup.set('predicates', predicates.copy());
  }

  @action cancel() {
    this.args.onClose({ success: false });
  }

  @action selectAvatarEmoji(emojiIdentifier: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.avatarEmoji = twemojiCompatibleEmoji;
  }

  @task *fetchAdminViewCount(): TaskGenerator<{ other_admin_visibility_count: number }> {
    let response = yield request(
      `/ember/inbox_views/${this.args.view.id}/stats?app_id=${this.session.workspace.id}`,
    );
    return yield response.json();
  }

  @task({ restartable: true })
  *saveView(): TaskGenerator<void> {
    try {
      let result = yield put(
        `/ember/inbox_views/${this.args.view.id}?app_id=${this.session.workspace.id}`,
        {
          name: this.name,
          avatar_emoji: this.avatarEmoji,
          predicate_group: this.predicateGroup.copy().serialize(),
        },
      );

      this.notificationsService.notifyConfirmation(
        this.intl.t('inbox.inbox-views.modals.edit.notifications.success', {
          name: this.name,
        }),
      );

      let view = new ViewSummary(
        result.id,
        result.name,
        result.avatar_emoji,
        undefined,
        result.predicate_group,
        result.system_type,
        result.has_dynamic_admin_assignee_predicates,
      );

      this.trackAnalyticsEvent(view);
      this.args.onClose({ success: true, view });
    } catch (error) {
      if (error.jqXHR.responseJSON.errors[0].message === 'name has already been taken') {
        this.notificationsService.notifyError(
          this.intl.t('inbox.inbox-views.modals.edit.notifications.duplicate-error'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('inbox.inbox-views.modals.edit.notifications.generic-error'),
        );
      }
    }
  }

  trackAnalyticsEvent(view: ViewSummary) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      object: 'inbox_view',
      id: view.id,
      has_dynamic_admin_assignee_predicate: view.hasDynamicAdminAssigneePredicates,
    });
  }

  _randomEmoji() {
    return shuffle(AVAILABLE_EMOJI)[0];
  }

  normalizePredicateGroup(predicateGroup: PredicateGroup) {
    predicateGroup = predicateGroup.copy();
    for (let predicate of Predicate.walkPredicates(predicateGroup.predicates)) {
      if (
        predicate.attribute === 'conversation.team_assignee_id' &&
        predicate.type === 'owner_id'
      ) {
        predicate.type = 'team_assignee_id';
      } else if (
        predicate.attribute === 'conversation.admin_assignee_id' &&
        predicate.type === 'owner_id'
      ) {
        predicate.type = 'admin_assignee_id';
      }
    }

    return predicateGroup;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Views::EditModal': typeof EditModal;
    'inbox2/views/edit-modal': typeof EditModal;
  }
}
