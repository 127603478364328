/* import __COLOCATED_TEMPLATE__ from './attribute-list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import FilteredObjectList from 'embercom/components/filtered-object-list';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

const ATTRIBUTE_HEIGHT = 30;

export default FilteredObjectList.extend({
  router: service(),
  objectHeight: ATTRIBUTE_HEIGHT,
  objectFilterKey: 'attribute.name',
  isInbox: computed('currentRouteName', function () {
    let currentRouteName = this.router.get('currentRouteName');
    return isPresent(currentRouteName) && currentRouteName.includes('apps.app.inbox.inbox');
  }),
});
