/* import __COLOCATED_TEMPLATE__ from './breadcrumbs.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type KnowledgeBaseFolder } from 'embercom/objects/inbox/knowledge-base/folder';
import { isPresent } from '@ember/utils';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';

interface Args {
  folders: KnowledgeBaseFolder[];
  selectedFolder?: KnowledgeBaseFolder;
  selectedContent?: KnowledgeBaseContent;
  onSelectFolder: (folder: KnowledgeBaseFolder) => void;
  goHome: () => void;
}

interface Signature {
  Args: Args;
}

export default class KnowledgeBaseBreadcrumbs extends Component<Signature> {
  get selectedFolder() {
    return this.args.selectedFolder;
  }

  get selectedContent() {
    return this.args.selectedContent;
  }

  get isViewingContent() {
    return isPresent(this.selectedContent);
  }

  get isViewingFolder() {
    return isPresent(this.selectedFolder);
  }

  get deepestFolder(): KnowledgeBaseFolder | undefined {
    if (this.selectedContent) {
      return this.args.folders.find((folder) => {
        return folder.containsContent(this.selectedContent as KnowledgeBaseContent);
      });
    } else {
      return this.selectedFolder;
    }
  }

  get folderPath(): KnowledgeBaseFolder[] {
    if (!this.deepestFolder) {
      return [];
    }
    let folderPath = [this.deepestFolder];
    let currentFolder: KnowledgeBaseFolder | undefined = this.deepestFolder;
    while (currentFolder?.parentFolderId) {
      currentFolder = this.args.folders.find(
        (folder) => folder.id === currentFolder!.parentFolderId,
      );
      if (currentFolder) {
        folderPath.unshift(currentFolder);
      }
    }
    return folderPath;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KnowledgeBase::Breadcrumbs': typeof KnowledgeBaseBreadcrumbs;
    'inbox2/knowledge-base/breadcrumbs': typeof KnowledgeBaseBreadcrumbs;
  }
}
