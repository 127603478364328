/* import __COLOCATED_TEMPLATE__ from './more-filters-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { tracked } from 'tracked-built-ins';
import {
  type BooleanComparison,
  type Comparison,
} from 'embercom/objects/inbox/search/predicate-group';
import type IntlService from 'ember-intl/services/intl';
import { dropTask } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { type CvdaDateTimeItem, type CvdaListItem, type MoreFiltersItem } from '../search2';
import {
  elementIsAttachedToTheDOM,
  elementIsChildOfAny,
  // @ts-ignore
} from '@intercom/pulse/lib/popover-utils';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Args {
  filterItem: MoreFiltersItem;
  onValueUpdated: (
    item: MoreFiltersItem,
    value: string | CvdaDateTimeItem['value'],
    comparison?: Comparison,
  ) => void;
  dropdownClass?: string;
  onRemove: (item: MoreFiltersItem) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class MoreFiltersItemComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;

  @tracked showDropDown = !this.args.filterItem.value;
  @tracked isHovered = false;
  @tracked textItemSelected: string = this.initialTextItemSelectedValue();
  @tracked booleanSelectedValue: BooleanComparison = this.initialBooleanSelectedValue();
  @tracked listItemSelected: CvdaListItem | undefined = this.initialListItemSelectedValue();
  @tracked dateTimeItemSelected: CvdaDateTimeItem = this.initalDateTimeItemSelectedValue();
  @tracked numericItemSelected?: string = this.initialNumericItemSelectedValue();
  @tracked createdByAdminSelected: AdminSummary | undefined =
    this.initialCreatedByAdminSelectedValue();
  @tracked documentClickRegistered = false;
  @tracked comparisonSelectedValue: Comparison = this.comparisonSelected();
  elementId = `more-filters-item-${this.args.filterItem.attribute.id}`;

  private decimalIntegerRegex = new RegExp(/^-?\d*\.?\d*$/);
  private wholeNumberRegex = new RegExp(/^-?\d*$/);

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.addDocumentClickHandler();
  }

  willDestroy() {
    super.willDestroy();
    this.showDropDown = false;
    this.removeDocumentClickHandler();
  }

  get icon() {
    return this.args.filterItem.attribute.icon || 'transfer';
  }

  get attributeId(): string {
    return `${this.args.filterItem.attribute.id}`;
  }

  get filterValue() {
    switch (this.args.filterItem.attribute.type) {
      case DataType.Boolean:
        return this.labelValue(this.booleanSelectedValue);
      case DataType.List:
        return this.labelValue(this.listItemSelected?.text);
      case DataType.Datetime:
        return this.dateTimeItemSelected.value.value
          ? `: ${this.dateTimeComparisonLabel} ${this.dateTimeItemSelected.value.value} ${this.dateTimeUnitLabel}`
          : this.labelValue();
      case DataType.Integer:
      case DataType.Decimal:
        return this.labelValue(this.numericItemSelected);
      default:
        return this.labelValue(this.textItemSelected);
    }
  }

  private labelValue(value?: string): string {
    return value ? `: ${value}` : '';
  }

  private initialTextItemSelectedValue(): string {
    if (this.showTextOptions && this.args.filterItem.value) {
      return this.args.filterItem.value as string;
    }
    return '';
  }

  private initialCreatedByAdminSelectedValue(): AdminSummary | undefined {
    if (this.showCreatedByAdminFilterOptions && this.args.filterItem.value) {
      return this.createdByTeammate || undefined;
    }
    return undefined;
  }

  private initialBooleanSelectedValue(): BooleanComparison {
    if (this.showBooleanOptions && this.args.filterItem.value) {
      return this.args.filterItem.value as BooleanComparison;
    }
    return 'true';
  }

  private initialListItemSelectedValue(): CvdaListItem | undefined {
    if (this.showListOptions && this.args.filterItem.value) {
      return this.listItems.find((item) => item.id === this.args.filterItem.value);
    }
    return this.listItems[0] || undefined;
  }

  private initalDateTimeItemSelectedValue(): CvdaDateTimeItem {
    if (this.showDateTimeOptions && this.args.filterItem.value) {
      return {
        value: this.args.filterItem.value as CvdaDateTimeItem['value'],
        comparison: this.args.filterItem.comparison || 'lt',
      };
    }
    return {
      value: {
        value: '',
        unit: 'minutes',
        direction: 'past',
      },
      comparison: 'lt',
    };
  }

  private initialNumericItemSelectedValue(): string | undefined {
    if (this.showNumericOptions && this.args.filterItem.value) {
      return this.args.filterItem.value as string;
    }
    return undefined;
  }
  private comparisonSelected(): Comparison {
    if (this.args.filterItem && this.args.filterItem.comparison) {
      return this.args.filterItem.comparison;
    }
    return 'eq';
  }

  get dateTimeComparisonLabel() {
    if (this.dateTimeItemSelected.comparison === 'lt') {
      return this.intl.t('inbox.search.filters.more-filters.more-than');
    }
    return this.intl.t('inbox.search.filters.more-filters.less-than');
  }

  get dateTimeUnitLabel() {
    let unit = this.dateTimeItemSelected.value.unit;
    let value = this.dateTimeItemSelected.value.value || 0;
    return this.intl.t(`inbox.search.filters.more-filters.${unit}`, { value });
  }

  get isDisabled(): boolean {
    switch (this.args.filterItem.attribute.type) {
      case DataType.Boolean:
        return false;
      case DataType.List:
        return false;
      case DataType.Datetime:
        return !this.isDateTimeItemValid;
      case DataType.Integer:
      case DataType.Decimal:
        return !this.isNumericItemValid;
      default:
        return !this.isTextItemValid;
    }
  }

  get createdByTeammate() {
    return this.inbox2AssigneeSearch.searchableAdmins.find(
      (admin) => admin.data.id.toString() === this.args.filterItem?.value,
    )?.data as AdminWithPermissions | undefined;
  }

  @action onTextItemSelect(value: string) {
    this.textItemSelected = value;
  }

  @action onCreatedByAdminSelected(admin: AdminSummary | undefined) {
    this.createdByAdminSelected = admin;
    this.args.onValueUpdated(this.args.filterItem, admin?.id.toString() || '');
  }

  @action toggleDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  @action onDoneClick() {
    this.showDropDown = false;

    switch (this.args.filterItem.attribute.type) {
      case DataType.Boolean:
        this.args.onValueUpdated(this.args.filterItem, this.booleanSelectedValue);
        break;
      case DataType.List:
        this.args.onValueUpdated(this.args.filterItem, this.listItemSelected!.id);
        break;
      case DataType.Datetime:
        if (this.isDateTimeItemValid) {
          this.args.onValueUpdated(
            this.args.filterItem,
            this.dateTimeItemSelected.value,
            this.dateTimeItemSelected.comparison,
          );
        }
        break;
      case DataType.Integer:
      case DataType.Decimal:
        if (this.isNumericItemValid) {
          this.args.onValueUpdated(
            this.args.filterItem,
            this.numericItemSelected!,
            this.args.filterItem.comparison,
          );
        }
        break;
      default:
        if (this.isTextItemValid) {
          this.args.onValueUpdated(this.args.filterItem, this.textItemSelected);
        }
    }
  }

  @action onRemove() {
    this.args.onRemove(this.args.filterItem);
  }

  @action onBooleanSelect(value: BooleanComparison) {
    this.booleanSelectedValue = value;
  }

  get showBooleanOptions() {
    return this.args.filterItem.attribute.type === DataType.Boolean;
  }

  get showListOptions() {
    return this.args.filterItem.attribute.type === DataType.List;
  }

  get showDateTimeOptions() {
    return this.args.filterItem.attribute.type === DataType.Datetime;
  }

  get showNumericOptions() {
    return (
      this.args.filterItem.attribute.type === DataType.Integer ||
      this.args.filterItem.attribute.type === DataType.Decimal
    );
  }

  get showCreatedByAdminFilterOptions() {
    return this.args.filterItem.attribute.type === DataType.AdminId;
  }

  get showTextOptions() {
    return (
      !this.showBooleanOptions &&
      !this.showListOptions &&
      !this.showDateTimeOptions &&
      !this.showNumericOptions
    );
  }

  get listItems() {
    return this.showListOptions
      ? this.args.filterItem.attribute.listOptions!.reduce((result: CvdaListItem[], item) => {
          if (!item.archived) {
            result.push({ text: item.label, id: item.id });
          }
          return result;
        }, [])
      : [];
  }

  @action onListItemSelect(item: CvdaListItem) {
    this.listItemSelected = { text: item.text, id: item.id };
  }

  @action onComparisonSelect(comparison: CvdaDateTimeItem['comparison']) {
    this.dateTimeItemSelected = {
      ...this.dateTimeItemSelected,
      comparison,
    };
  }
  @action onFiltersItemComparisonSelect(comparison: Comparison) {
    this.args.filterItem.comparison = comparison;
  }

  @action onValueSelect(value: string) {
    this.dateTimeItemSelected = {
      ...this.dateTimeItemSelected,
      value: {
        ...this.dateTimeItemSelected.value,
        value,
      },
    };
  }

  @action onUnitSelect(unit: CvdaDateTimeItem['value']['unit']) {
    this.dateTimeItemSelected = {
      ...this.dateTimeItemSelected,
      value: {
        ...this.dateTimeItemSelected.value,
        unit,
      },
    };
  }

  @action onNumericItemSelect(value: string) {
    this.numericItemSelected = value;
  }

  get isNumericItemValid(): boolean {
    if (!this.numericItemSelected) {
      return false;
    }

    if (this.args.filterItem.attribute.type === DataType.Integer) {
      return this.wholeNumberRegex.test(this.numericItemSelected);
    } else if (this.args.filterItem.attribute.type === DataType.Decimal) {
      return this.decimalIntegerRegex.test(this.numericItemSelected);
    }
    return true;
  }

  get isTextItemValid(): boolean {
    return !!this.textItemSelected.trim();
  }

  get isDateTimeItemValid(): boolean {
    if (
      !this.dateTimeItemSelected.value.value ||
      !this.wholeNumberRegex.test(this.dateTimeItemSelected.value.value)
    ) {
      return false;
    }
    return true;
  }

  @action async addDocumentClickHandler() {
    // We add this event listener after an arbitrarily long period of time
    // so that any clicks that cause ember router navigation have had time
    // to bubble correctly so that we don't listen to them immediately after
    // rendering the component with the dropdown open.
    if (!this.documentClickRegistered) {
      await taskFor(this.addDocumentClickHandlerAfterDelay).perform(10);
    }
  }

  @dropTask *addDocumentClickHandlerAfterDelay(delay: number) {
    yield timeout(delay);
    this.documentClickRegistered = true;
    document.addEventListener('click', this.handleDocumentClick);
  }

  @action handleDocumentClick(event: Event) {
    if (this.showDropDown) {
      // Only close the overlay if the click wasn't inside our component
      // and the target is still in the DOM
      if (
        event.target instanceof HTMLElement &&
        !elementIsChildOfAny(event.target, [`#${this.elementId}`]) &&
        elementIsAttachedToTheDOM(event.target)
      ) {
        this.onDoneClick();
      }
    }
  }

  @action async removeDocumentClickHandler() {
    if (this.documentClickRegistered) {
      taskFor(this.addDocumentClickHandlerAfterDelay).cancelAll();
      document.removeEventListener('click', this.handleDocumentClick);
      this.documentClickRegistered = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::MoreFiltersItem': typeof MoreFiltersItemComponent;
  }
}
