/* import __COLOCATED_TEMPLATE__ from './sender-label.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type UserComment from 'embercom/objects/inbox/renderable/user-comment';
import type AdminNote from 'embercom/objects/inbox/renderable/admin-note';

interface Arguments {
  renderableData: AdminComment | UserComment | AdminNote;
}

interface Signature {
  Args: Arguments;
}

export default class UnnamedComponent extends Component<Signature> {
  @service declare session: Session;
  entityType = EntityType;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationCard::SenderLabel': typeof UnnamedComponent;
    'inbox2/left-nav/conversation-card/sender-label': typeof UnnamedComponent;
  }
}
