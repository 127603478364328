/* import __COLOCATED_TEMPLATE__ from './team-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import { action } from '@ember/object';
import { useFunction } from 'ember-resources';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type InboxState from 'embercom/services/inbox-state';
import { request } from 'embercom/lib/inbox/requests';
import type IntlService from 'embercom/services/intl';
import cached from 'embercom/lib/cached-decorator';

interface Args {
  inbox: Inbox;
  // If the inbox is being rendered as part of a custom folder this argument will have the
  // id of the custom folder
  customFolderId?: number;
}

interface Signature {
  Args: Args;
}

const SELECTED_COUNT_TYPE_KEY = 'inbox2::team-details::selected-count-type';

export default class UnnamedComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;

  @tracked isLoadingTeam = false;
  @tracked hasError = false;
  @trackedInLocalStorage({ keyName: SELECTED_COUNT_TYPE_KEY, defaultValue: 'all' })
  selectedCountType = 'all';

  get canSeeSeparatedCounts(): boolean {
    return this.session.workspace.isFeatureEnabled('team-tickets-2-tickets-assignment-switch');
  }

  get team(): TeamSummary {
    return this.teamAndSettingsLoader.value?.team ?? {};
  }

  get allAdmins(): AdminSummary[] {
    if (!this.team.adminSummaries) {
      return [];
    }
    return this.team.adminSummaries;
  }

  get onlineAdmins(): AdminSummary[] {
    return this.allAdmins.filter((admin) => !admin.isAway).sort(this.adminSortOrder);
  }

  get offlineAdmins(): AdminSummary[] {
    return this.allAdmins.filter((admin) => admin.isAway).sort(this.adminSortOrder);
  }

  adminSortOrder(a: AdminSummary, b: AdminSummary) {
    // openCount desc, name asc
    return b.openCount! - a.openCount! || a.name.localeCompare(b.name);
  }

  get capacityLimit(): number {
    return this.teamAndSettingsLoader.value?.capacityLimit ?? {};
  }

  get lbaDescription() {
    let prefix = 'inbox.inbox-list';

    if (
      !this.session.workspace.isFeatureEnabled('team-tickets-2-tickets-assignment-switch') &&
      this.session.workspace.isFeatureEnabled('filter-tickets-balanced-assignment')
    ) {
      return `${prefix}.balanced-description-tickets-excluded`;
    }

    return `${prefix}.balanced-description`;
  }

  get ticketsAssignmentDisabled(): boolean {
    if (!this.canSeeSeparatedCounts) {
      return false;
    }
    return this.teamAndSettingsLoader.value?.ticketsAssignmentDisabled ?? false;
  }

  get isBlocking(): boolean {
    if (!this.adminsBlockable) {
      return false;
    }

    if (this.session.workspace.isFeatureEnabled('team-support-retain-role-level-capacity')) {
      return false;
    }

    return (
      (this.isEveryOnlineAdminAtCapacity && this.team.teamSettings?.volumeControlEnabled) || false
    );
  }

  get adminsBlockable(): boolean {
    if (!this.team.teamSettings) {
      return false;
    }
    return this.team.teamSettings.assignmentDistribution === 'load_balanced';
  }

  get adminIsMember(): boolean {
    if (!this.allAdmins) {
      return false;
    }

    return this.allAdmins.any((admin) => admin.id === this.session.teammate.id);
  }

  private get isEveryOnlineAdminAtCapacity(): boolean {
    return this.onlineAdmins.length === this.adminsAtCapacity.length;
  }

  get adminsAtCapacity(): AdminSummary[] {
    if (!this.adminsBlockable) {
      return [];
    }
    return this.onlineAdmins.filter((admin: AdminSummary) => {
      let adminAssignmentLimit = admin.workloadManagementSettings?.assignmentLimit;
      let assignmentLimit =
        adminAssignmentLimit !== undefined ? adminAssignmentLimit : this.capacityLimit;
      return admin.openCount! >= assignmentLimit;
    });
  }

  get selectedCounts(): number[] {
    return this.allAdmins.map((admin) => {
      switch (this.selectedCountType) {
        case 'tickets':
          return admin.countMetadata?.tickets || 0;
        case 'conversations':
          return admin.countMetadata?.conversations || 0;
        default:
          return admin.openCount || 0;
      }
    });
  }

  get totalSelectedCount(): number {
    return this.selectedCounts.reduce((acc, val) => acc + val, 0);
  }

  get shouldShowTeammatesAtCapacity(): boolean {
    if (this.adminsBlockable && (this.adminsAtCapacity.length > 0 || this.isBlocking)) {
      if (this.selectedCountType === 'all' && !this.ticketsAssignmentDisabled) {
        return true;
      } else if (this.selectedCountType === 'conversations' && this.ticketsAssignmentDisabled) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get teammatesAtCapacityText(): any {
    if (this.isBlocking) {
      return this.intl.t('inbox.inbox-list.inbox-blocking');
    } else if (this.adminsAtCapacity.length > 0) {
      return this.intl.t('inbox.inbox-list.blocked-teammates-count', {
        count: this.adminsAtCapacity.length,
      });
    }
  }

  @action
  onCountTypeChange(countType: string) {
    this.selectedCountType = countType;
  }

  @cached({ max: 1, ttl: 1000 * 60 })
  private async fetchCapacityLimits() {
    let response = await request(
      `/ember/app_conversation_capacity_limits/${this.session.workspace.id}?app_id=${this.session.workspace.id}`,
    );
    return await response.json();
  }

  @cached({ max: 100, ttl: 1000 * 60 })
  private async fetchTeamDetails(id: string) {
    let response = await request(`/ember/inbox/teams/${id}?app_id=${this.session.workspace.id}`);
    return await response.json();
  }

  private teamAndSettingsLoader = useFunction(
    this,
    async (previous: any, id: string): Promise<any> => {
      if (previous && id === previous.id) {
        return previous;
      }

      try {
        this.hasError = false;
        this.isLoadingTeam = true;
        let [{ team }, { capacity_limit, tickets_assignment_disabled }] = await Promise.all([
          this.fetchTeamDetails(id),
          this.fetchCapacityLimits(),
        ]);
        return {
          id,
          team: TeamSummary.deserialize(team),
          capacityLimit: capacity_limit,
          ticketsAssignmentDisabled: tickets_assignment_disabled,
        };
      } catch (err) {
        this.hasError = true;
      } finally {
        this.isLoadingTeam = false;
      }
    },
    () => [this.args.inbox.id],
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TeamDetails': typeof UnnamedComponent;
    'inbox2/team-details': typeof UnnamedComponent;
  }
}
