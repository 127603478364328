/* import __COLOCATED_TEMPLATE__ from './assignment-limit.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  ASSIGNMENT_LIMIT_FILTER_TYPE,
  ASSIGNMENT_LIMIT_COMPARATORS,
} from 'embercom/lib/settings/filters/constants';

export default class AssignmentLimit extends Component {
  @service intl;

  @tracked assignmentLimit = this.args.filter.value;
  @tracked selectedComparator = this.args.filter.comparator;
  @tracked popoverOpen = false;

  @action popoverClosed() {
    this.popoverOpen = false;
  }

  get comparisonOptions() {
    return Object.values(ASSIGNMENT_LIMIT_COMPARATORS).map((comparator) => ({
      comparator,
      disableInput: comparator === ASSIGNMENT_LIMIT_COMPARATORS.hasAnyValue,
    }));
  }

  get filterDetails() {
    return this.intl.t('settings.teammate-workload.filters.assignment-limit-details', {
      comparator: this.intl.t(
        `settings.teammate-workload.filters.label-comparators.${this.args.filter.comparator}`,
      ),
      assignmentLimit:
        this.args.filter.comparator !== ASSIGNMENT_LIMIT_COMPARATORS.hasAnyValue
          ? this.args.filter.value
          : '',
    });
  }

  @action revertState() {
    this.assignmentLimit = this.args.filter.value;
    this.selectedComparator = this.args.filter.comparator;
  }

  @action selectComparator(comparator) {
    this.selectedComparator = comparator;
  }

  @action updateFilter() {
    if (this.selectedComparator === ASSIGNMENT_LIMIT_COMPARATORS.hasAnyValue) {
      this.assignmentLimit = this.args.assignmentLimit;
    }

    this.args.updateFilter({
      type: ASSIGNMENT_LIMIT_FILTER_TYPE,
      comparator: this.selectedComparator,
      value:
        this.assignmentLimit === undefined
          ? this.assignmentLimit
          : parseInt(this.assignmentLimit, 10),
    });
  }
}
