/* import __COLOCATED_TEMPLATE__ from './date-value.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import { computed } from '@ember/object';

export default Component.extend({
  dateValue: computed('value', function () {
    return moment(this.value).format(dateAndTimeFormats.date);
  }),
});
