/* import __COLOCATED_TEMPLATE__ from './inbox-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { useFunction } from 'ember-resources';
import type Session from 'embercom/services/session';
import type InboxApi from 'embercom/services/inbox-api';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import Admin from 'embercom/objects/inbox/inboxes/admin';
import All from 'embercom/objects/inbox/inboxes/all';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';
import CreatedByYou from 'embercom/objects/inbox/inboxes/created-by-you';
import Team from 'embercom/objects/inbox/inboxes/team';
import Unassigned from 'embercom/objects/inbox/inboxes/unassigned';
import View from 'embercom/objects/inbox/inboxes/view';
import { type Awaited } from 'embercom/lib/inbox2/types';
import { action } from '@ember/object';

interface Args {}

interface Signature {
  Args: Args;
}

export default class OnboardingInboxList extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;

  get pinnedInboxes() {
    return this.pinnedInboxesLoader.value?.inboxes ?? [];
  }

  get inboxTotals() {
    return this.pinnedInboxesLoader.value?.totals ?? 0;
  }

  get yourInbox(): Inbox | undefined {
    return this.pinnedInboxes.find(
      (i) => i instanceof Admin && i.id === this.session.teammate.id.toString(),
    );
  }

  get mentionsInbox(): Inbox | undefined {
    return this.pinnedInboxes.find((i) => i instanceof Mentions);
  }

  get createdByYouInbox(): Inbox | undefined {
    return this.pinnedInboxes.find((i) => i instanceof CreatedByYou);
  }

  get allInbox(): Inbox | undefined {
    return this.pinnedInboxes.find((i) => i instanceof All);
  }

  get unassignedInbox(): Inbox | undefined {
    return this.pinnedInboxes.find((i) => i instanceof Unassigned);
  }

  get adminInboxes(): Inbox[] {
    return this.pinnedInboxes.filter(
      (i) => i instanceof Admin && i.id !== this.session.teammate.id.toString(),
    );
  }

  get teamInboxes(): Inbox[] {
    return this.pinnedInboxes.filter((i) => i instanceof Team);
  }

  get views(): Inbox[] {
    return this.pinnedInboxes.filter((i) => i instanceof View);
  }

  get inboxes(): Inbox[] {
    return this.pinnedInboxes.filter(
      (i) => i !== this.yourInbox && i !== this.mentionsInbox && !(i instanceof View),
    );
  }

  readonly pinnedInboxesLoader = useFunction(
    this,
    async (previous: Awaited<ReturnType<InboxApi['listInboxes']>>) => {
      if (previous) {
        return previous;
      }

      return this.inboxApi.listInboxes({ pinned: true });
    },
    () => [this.session.workspace.id],
  );

  @action onClick() {}
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::InboxList': typeof OnboardingInboxList;
    'inbox2/onboarding/inbox-list': typeof OnboardingInboxList;
  }
}
