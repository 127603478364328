/* import __COLOCATED_TEMPLATE__ from './onboarding-popover.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import storage from 'embercom/vendor/intercom/storage';

export function storageKey(workspace_id: string): string {
  return `${workspace_id}:seen-resume-onboarding-popover`;
}

interface Signature {
  Blocks: {
    default: [];
  };
}

export default class OnboardingPopover extends Component<Signature> {
  @service declare session: Session;

  get alreadySeen(): boolean {
    return storage.get(storageKey(this.session.workspace.id)) === 'true';
  }

  @action markAsSeen() {
    if (!this.alreadySeen) {
      storage.set(storageKey(this.session.workspace.id), 'true');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::OnboardingPopover': typeof OnboardingPopover;
    'inbox2/left-nav/onboarding-popover': typeof OnboardingPopover;
  }
}
