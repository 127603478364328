/* import __COLOCATED_TEMPLATE__ from './edit-menu-default-manage-menu.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type InboxCategory } from 'embercom/models/data/inbox/inbox-categories';

interface Arguments {
  inboxCategory: InboxCategory;
  hideMenu: () => void;
  onCreateCustomFolder: () => void;
  togglePin: () => void;
}

interface Signature {
  Args: Arguments;
}

const DefaultManageMenu = templateOnlyComponent<Signature>();
export default DefaultManageMenu;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Default::EditMenuDefaultManageMenu': typeof DefaultManageMenu;
    'inbox2/left-nav/folders/default/edit-menu-default-manage-menu': typeof DefaultManageMenu;
  }
}
