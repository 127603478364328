/* import __COLOCATED_TEMPLATE__ from './sla-target-performance.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import percent from 'embercom/lib/percentage-formatter';
import { computed } from '@ember/object';

let createHitRate = ({ targets = {}, misses = {} }) => ({
  value: percent(targets.value, targets.value - misses.value) || 0,
  previousValue: percent(targets.previousValue, targets.previousValue - misses.previousValue) || 0,
});

export default Component.extend({
  firstReplyHitRate: computed(
    'firstReplyMisses.{value,previousValue}',
    'firstReplyTargets.{value,previousValue}',
    function () {
      let { firstReplyTargets, firstReplyMisses } = this;
      return createHitRate({ targets: firstReplyTargets, misses: firstReplyMisses });
    },
  ),

  nextReplyHitRate: computed(
    'nextReplyMisses.{value,previousValue}',
    'nextReplyTargets.{value,previousValue}',
    function () {
      let { nextReplyTargets, nextReplyMisses } = this;
      return createHitRate({ targets: nextReplyTargets, misses: nextReplyMisses });
    },
  ),

  resolutionHitRate: computed(
    'resolutionMisses.{value,previousValue}',
    'resolutionTargets.{value,previousValue}',
    function () {
      let { resolutionTargets, resolutionMisses } = this;
      return createHitRate({ targets: resolutionTargets, misses: resolutionMisses });
    },
  ),

  timeToCloseHitRate: computed(
    'timeToCloseMisses.{value,previousValue}',
    'timeToCloseTargets.{value,previousValue}',
    function () {
      let { timeToCloseTargets, timeToCloseMisses } = this;
      return createHitRate({ targets: timeToCloseTargets, misses: timeToCloseMisses });
    },
  ),
});
