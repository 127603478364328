/* import __COLOCATED_TEMPLATE__ from './sidebar-sections.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
// @ts-ignore
import { copy } from 'ember-copy';
import { taskFor } from 'ember-concurrency-ts';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxVersion from 'embercom/services/inbox-version';
import Conversation, { NewConversation } from 'embercom/objects/inbox/conversation';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type Attribute from 'embercom/objects/inbox/attribute';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import { registerDestructor } from '@ember/destroyable';
import type User from 'embercom/objects/inbox/user';
import type IntlService from 'embercom/services/intl';
import { type SidebarSectionDetail } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import SidebarSection from 'embercom/objects/inbox/sidebar-section';
import { type RoleLabel } from 'embercom/objects/inbox/user-summary';
import type AttributesApi from 'embercom/services/attributes-api';
import type UserApi from 'embercom/services/user-api';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';

interface Args {
  conversation: Conversation | NewConversation;
  conversationTableEntry?: ConversationTableEntry;
  openSidebarSections?: Array<string | number>;
  onOpenSidebarSectionsChange?: Function;
  onAdminUpdate(assignee: AdminWithPermissions): void;
  onTeamUpdate(team: TeamSummary): void;
  sidebarSections?: SupportedSidebarSection[];
  onCollapse?: () => unknown;
  onConversationOrChecklistSelected?: () => unknown;
  setTicketTypeToAddToConversation: (ticketTypeId: number) => void;
  setTicketTypeToLinkToConversation?: (ticketTypeId: number) => void;
  onUserUpdated?: (user?: User) => unknown;
  userId: string;
  isPreviewingConversation?: boolean;
  commandKActionPriority?: number;
  hideTicketDescription?: boolean;
  previewOnly?: boolean;
}

interface Signature {
  Args: Args;
}

export default class SidebarSections extends Component<Signature> {
  supportedSidebarSections = SupportedSidebarSection;

  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare userApi: UserApi;
  @service declare attributesApi: AttributesApi;
  @service declare inboxVersion: InboxVersion;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare lbaMetricsService: LbaMetricsService;

  @tracked conversationAttributeDescriptors?: ConversationAttributeDescriptor[];
  @tracked userAttributes: Attribute[] = [];
  @tracked userCompanyAttributes?: Attribute[];
  @trackedReset('args.conversation') currentlyVisible = this.args.conversation.isReplyable;

  userResource = DeduplicatedAsyncData(
    this,
    () => [this.args.userId],
    async (userId) => {
      let user = await this.userApi.fetchUser(userId);
      if (this.args.onUserUpdated) {
        this.args.onUserUpdated(user);
      }
      return user;
    },
  );

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.loadUserAttributes).perform();
    taskFor(this.loadUserCompanyAttributes).perform();

    if (!(this.args.conversation instanceof NewConversation)) {
      taskFor(this.loadConversationAttributeDescriptors).perform();
    }

    registerDestructor(this, () => {
      taskFor(this.loadUserAttributes).cancelAll();
      taskFor(this.loadUserCompanyAttributes).cancelAll();
      taskFor(this.loadConversationAttributeDescriptors).cancelAll();
      taskFor(this.updatePinnedUserAttributes).cancelAll();
      taskFor(this.updatePinnedUserCompanyAttributes).cancelAll();
    });
  }

  get linkedTicketsSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: this.intl.t(`inbox.conversation-details-sidebar.section-headings.links`),
      intercomModuleIdentifier: 'linked-tickets',
    });
  }

  get ticketDescriptionSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: this.intl.t(`inbox.conversation-details-sidebar.section-headings.ticket-attributes`),
      intercomModuleIdentifier: 'ticket-description',
    });
  }

  get linkedConversationSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: this.intl.t('inbox.conversation-details-sidebar.section-headings.linked-conversation'),
      intercomModuleIdentifier: 'linked-conversation',
    });
  }

  get appCardUserId(): string {
    return this.args.userId;
  }

  get ticketDetailsSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: this.intl.t('inbox.conversation-details-sidebar.section-headings.ticket-attributes'),
      intercomModuleIdentifier: 'ticket-details',
    });
  }

  get hideConversationDetailsSection(): boolean {
    if (!(this.args.conversation instanceof Conversation)) {
      return false;
    }
    if (!this.args.conversation.isTicket) {
      return false;
    }

    return !this.args.conversation.isCustomerTicket;
  }

  get pinnedSidebarSections() {
    let sections = this.inboxSidebarService.pinnedSidebarSections(this.args.conversation);

    if (this.hideConversationDetailsSection) {
      return sections.filter(
        (section) => section.componentIdentifier !== SupportedSidebarSection.ConversationDetails,
      );
    }

    return sections;
  }

  get availableSidebarSections(): SidebarSection[] {
    let sections = this.inboxSidebarService.availableSidebarSections(this.args.conversation);

    if (this.hideConversationDetailsSection) {
      return sections.filter(
        (section) => section.componentIdentifier !== SupportedSidebarSection.ConversationDetails,
      );
    }

    return sections;
  }

  get sidebarSectionsToDisplay(): SidebarSection[] {
    if (this.session.showLightInbox) {
      let sections: string[] = [
        SupportedSidebarSection.ConversationDetails,
        SupportedSidebarSection.LinkedTickets,
      ].map(String);

      return this.pinnedSidebarSections.filter((section) =>
        sections.includes(section.componentIdentifier),
      );
    }

    if (!this.args.sidebarSections) {
      return this.pinnedSidebarSections;
    }

    let requestedSections = (this.args.sidebarSections ?? []).map(String);
    return this.pinnedSidebarSections.filter((section) =>
      requestedSections.includes(section.componentIdentifier),
    );
  }

  get sidebarSectionDetails(): {
    [key in SupportedSidebarSection]: SidebarSectionDetail;
  } {
    let roleLabel: RoleLabel | undefined = this.args.conversation.firstParticipant?.typeLabel;
    return this.inboxSidebarService.sidebarSectionDetails(roleLabel);
  }

  get conversationForConversationState() {
    return this.args.conversationTableEntry || this.args.conversation;
  }

  get showTicketDetailsSection() {
    return this.args.conversation.isTicket && this.currentlyVisible;
  }

  get shouldShowTicketDescription() {
    return (
      this.args.conversation.isTicket &&
      this.session.workspace.isFeatureEnabled('tickets-2-linked-tickets-open-preview')
    );
  }

  // The links app is shown when these conditions are met:
  // 1. The conversation is a ticket
  // 2. Or Conversation/ticket is replyable
  // 3. And the entity is an instance of Conversation
  get shouldShowLinksSection() {
    let isTicket = this.args.conversation.isTicket;

    let showLinksApp = isTicket || this.currentlyVisible;
    return showLinksApp && this.args.conversation instanceof Conversation;
  }

  @action onOpenSectionChange(sectionId: SupportedSidebarSection | number) {
    return this.inboxSidebarService.onOpenSectionChange(
      sectionId,
      this.args.conversation instanceof Conversation ? this.args.conversation?.id : undefined,
    );
  }

  @action onExpandedSectionChange(sectionId: SupportedSidebarSection | number | undefined) {
    this.inboxSidebarService.onExpandedSectionChange(
      sectionId,
      this.args.conversation instanceof Conversation ? this.args.conversation?.id : undefined,
    );

    if (this.args.onConversationOrChecklistSelected && sectionId !== undefined) {
      this.args.onConversationOrChecklistSelected();
    }
  }

  @task({ restartable: true })
  *loadUserAttributes() {
    this.userAttributes = yield this.attributesApi.listUserAttributes();
  }

  @task({ restartable: true })
  *loadUserCompanyAttributes() {
    this.userCompanyAttributes = yield this.attributesApi.listUserCompanyAttributes();
  }

  @task({ drop: true })
  *updatePinnedUserAttributes(pinnedAttributesKeys: string[]): Generator<Promise<any>> {
    yield this.session.updateTeammate({
      visible_attribute_ids: pinnedAttributesKeys,
    });
  }

  @action toggleUserDetailsPin(pinnedAttributesKeys: string[]) {
    let oldUserAttributes = copy(this.userAttributes);
    this.userAttributes = this.userAttributes?.map((attr) => {
      if (attr) {
        if (pinnedAttributesKeys.includes(attr.key)) {
          attr.adminVisibilitySetting.visible = true;
        } else {
          attr.adminVisibilitySetting.visible = false;
        }
      }
      return attr;
    });

    taskFor(this.updatePinnedUserAttributes)
      .perform(pinnedAttributesKeys)
      .catch((error) => {
        this.userAttributes = oldUserAttributes;
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('inbox.conversation-details.pin-user-attribute-error'),
        });
      });
  }

  @task({ drop: true })
  *updatePinnedUserCompanyAttributes(pinnedAttributesKeys: string[]): Generator<Promise<any>> {
    yield this.session.updateTeammate({
      visible_company_attribute_ids: pinnedAttributesKeys,
    });
  }

  @action toggleCompanyDetailsPin(pinnedAttributesKeys: string[]) {
    let oldUserCompanyAttributes = JSON.parse(JSON.stringify(this.userCompanyAttributes));
    this.userCompanyAttributes = this.userCompanyAttributes?.map((attr) => {
      if (attr) {
        if (pinnedAttributesKeys.includes(attr.key)) {
          attr.adminVisibilitySetting.visible = true;
        } else {
          attr.adminVisibilitySetting.visible = false;
        }
      }
      return attr;
    });

    taskFor(this.updatePinnedUserCompanyAttributes)
      .perform(pinnedAttributesKeys)
      .catch((error) => {
        this.userCompanyAttributes = oldUserCompanyAttributes;
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('inbox.conversation-details.pin-company-attribute-error'),
        });
      });
  }

  @task({ restartable: true })
  *loadConversationAttributeDescriptors() {
    this.conversationAttributeDescriptors =
      (yield this.session.workspace.fetchConversationAttributeDescriptors()) as ConversationAttributeDescriptor[];
  }

  @action async onUserAttributeUpdated(attribute: Attribute, value: any) {
    if (!this.user) {
      return;
    }

    let oldAttributeValue = this.user.getAttributeValue(attribute.key);
    let opts = attribute.isCompany ? { companyId: this.user.qualificationCompany?.id } : {};

    try {
      this.user.updateAttribute(attribute, value, opts);

      await this.inboxApi.updateUserAttribute(
        attribute,
        value,
        this.user,
        this.user.qualificationCompany,
      );
      if (this.args.onUserUpdated) {
        this.args.onUserUpdated(this.user);
      }
    } catch (error) {
      this.user.updateAttribute(attribute, oldAttributeValue, opts);

      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'inbox.conversation-details-sidebar.attributes.errors.updating-user-attribute',
          {
            attributeName: attribute.humanFriendlyName,
          },
        ),
      });
    }
  }

  @action onQualificationAttributeUpdated(attribute: Attribute, value: any) {
    if (!this.user) {
      return;
    }

    if (attribute.isCompany && !this.user.qualificationCompany) {
      this.user.addNewCompany();
    }

    return this.onUserAttributeUpdated(attribute, value);
  }

  get user(): User | undefined {
    return this.userResource.value || undefined;
  }

  get isLoadingUser() {
    return this.userResource.isLoading;
  }

  @action onTeamUpdate(team: TeamSummary) {
    if (this.args.conversationTableEntry) {
      this.args.onTeamUpdate(team);
    } else if (this.args.conversation instanceof NewConversation) {
      this.args.onTeamUpdate(team);
    } else {
      this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
        LbaTriggerEvent.ASSIGNMENT,
      );
      this.inboxState.assignConversationToTeam(this.args.conversation, team);
    }
  }

  @action onAdminUpdate(admin: AdminWithPermissions) {
    if (this.args.conversationTableEntry) {
      this.args.onAdminUpdate(admin);
    } else if (this.args.conversation instanceof NewConversation) {
      this.args.onAdminUpdate(admin);
    } else {
      this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
        LbaTriggerEvent.ASSIGNMENT,
      );
      this.inboxState.assignConversationToAdmin(this.args.conversation, admin);
    }
  }

  @action async onSelectTicketType(ticketTypeId: number) {
    if (this.args.conversation instanceof NewConversation) {
      return;
    }

    this.args.setTicketTypeToAddToConversation(ticketTypeId);
  }

  @action async setQualificationCompanyForUser(companyId: string) {
    if (!this.user) {
      throw new Error('User ID is required to add to company');
    }

    let attributes = await this.userApi.addToCompany(this.user.id, companyId);
    this.user.updateAttributes(attributes);
  }

  @action async removeQualificationCompanyForUser() {
    if (!this.user) {
      throw new Error('User ID is required to remove qualification company');
    }

    if (!this.user.qualificationCompany) {
      throw new Error('Qualification company ID is required to remove the company');
    }

    let attributes = await this.userApi.removeFromCompany(
      this.user.id,
      this.user.qualificationCompany.id,
    );

    this.user.updateAttributes(attributes);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SidebarSections': typeof SidebarSections;
    'inbox2/sidebar-sections': typeof SidebarSections;
  }
}
