/* import __COLOCATED_TEMPLATE__ from './state-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import {
  INBOX_STATE_ICON_MAPPING,
  type InboxStateOption,
  isTicketState,
} from 'embercom/models/data/inbox/inbox-filters';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';

interface Args {
  selectedState?: InboxStateOption | string | null;
  onSelect?: (state: InboxStateOption | null) => void;
  dropdownClass?: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SearchFiltersStateSelector extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  get label() {
    this.intl.primaryLocale; // We must access the locale to make this getter aware that it is dependent on it.

    if (this.args.selectedState === undefined) {
      return this.intl.t('inbox.search.filters.status.none');
    }

    return this.intl.t(`inbox.search.filters.status.${this.args.selectedState}`);
  }

  get items() {
    this.intl.primaryLocale; // We must access the locale to make this getter aware that it is dependent on it.

    let items = [];

    let StateOptions = {
      ...ConversationState,
      ...TicketSystemState,
    };

    let stateItems = Object.values(StateOptions)
      .filter(
        (state) =>
          state !== ConversationState.Snoozed && !this.isTicketStateWithoutTicketsEnabled(state),
      )
      .map((option) => {
        return {
          text: this.intl.t(`inbox.search.filters.status.${option}`),
          value: option,
          icon: INBOX_STATE_ICON_MAPPING[option],
          isSelected: option === this.args.selectedState,
          component: 'inbox2/left-nav/conversation-list/filters-item',
        };
      });

    items.push({
      items: stateItems,
    });

    let snoozeItem = [
      {
        text: this.intl.t(`inbox.search.filters.status.${ConversationState.Snoozed}`),
        value: ConversationState.Snoozed,
        icon: 'snooze',
        isSelected: ConversationState.Snoozed === this.args.selectedState,
        component: 'inbox2/left-nav/conversation-list/filters-item',
      },
    ];

    items.push({
      items: snoozeItem,
    });

    if (this.args.selectedState) {
      let clearItem = [
        {
          text: this.intl.t(`inbox.search.filters.status.clear`),
          value: null,
          icon: null,
        },
      ];

      items.push({
        items: clearItem,
      });
    }

    return items;
  }

  private isTicketStateWithoutTicketsEnabled(inboxState: InboxStateOption) {
    return !this.session.workspace.ticketStateFiltering && isTicketState(inboxState);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::StateSelector': typeof SearchFiltersStateSelector;
    'inbox2/search/state-selector': typeof SearchFiltersStateSelector;
  }
}
