/* import __COLOCATED_TEMPLATE__ from './linkified-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

interface Args {
  edit?: (event: Event) => void;
  isDescription?: boolean;
  isTitle?: boolean;
  hasError?: boolean;
  isRequired?: boolean;
}

interface Signature {
  Args: Args;
}

export default class LinkifiedAttribute extends Component<Signature> {
  @tracked clientHeight: number = document.body.clientHeight;
  @service declare session: Session;

  get isTitle() {
    return this.args.isTitle;
  }

  get inputPadding() {
    return this.isTitle ? '-mx-2 p-2' : 'py-px px-1';
  }

  get hasError() {
    return this.args.hasError;
  }

  get placeholderText() {
    if (this.args.isDescription) {
      return 'inbox.conversation-attributes.add-description-placeholder';
    }

    if (this.isTitle) {
      return 'inbox.conversation-attributes.add-title-placeholder';
    }

    return 'inbox.conversation-attributes.placeholder';
  }

  @action windowResized() {
    this.clientHeight = document.body.clientHeight;
  }

  @action editValue(event: Event) {
    this.args.edit && this.args.edit(event);
  }

  get heightStyle() {
    if (!this.args.isDescription) {
      return '';
    }

    return `max-height: ${this.clientHeight * 0.35}px;`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::LinkifiedAttribute': typeof LinkifiedAttribute;
    'inbox2/ticket-attribute/linkified-attribute': typeof LinkifiedAttribute;
  }
}
