/* import __COLOCATED_TEMPLATE__ from './conversation-state-badge.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';

interface Signature {
  Args: {
    conversation: ConversationRecord;
  };
  Element: HTMLDivElement;
}

const ConversationStateBadge = templateOnlyComponent<Signature>();
export default ConversationStateBadge;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationStateBadge': typeof ConversationStateBadge;
    'inbox2/onboarding/conversation-state-badge': typeof ConversationStateBadge;
  }
}
