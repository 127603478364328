/* import __COLOCATED_TEMPLATE__ from './user-details.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';

export default Component.extend({
  attributeService: service(),
  modelDataCacheService: service(),
  editVisibilityMode: false,

  lookupAdminByEmail() {
    let userEmail = this.get('user.email');
    safeWindowOpen(`https://intercomrades.intercom.com/admin/admins?admin_email=${userEmail}`);
  },

  afterVisibilitySettingsDoneClicked: action(function () {
    this.app.updateLocalCache(this.modelDataCacheService);
  }),

  updateEditVisibilityMode: action(function (newEditVisibilityModeState) {
    this.set('editVisibilityMode', newEditVisibilityModeState);
  }),

  actions: {
    lookupAdminEmail() {
      this.lookupAdminByEmail();
    },
  },
});
