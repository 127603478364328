/* import __COLOCATED_TEMPLATE__ from './custom-time-sla-modal-original.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import Component from '@glimmer/component';
import Delays from 'embercom/lib/workflows/helpers/delays';

export default class CustomTimeSlaModal extends Component {
  @service appService;
  @service store;
  @tracked slaName = '';
  @tracked firstReplyTimeInSeconds = null;
  @tracked nextReplyTimeInSeconds = null;
  firstReplyDelayUnit = 'second';
  firstReplyDelayValue = 0;
  nextReplyDelayUnit = 'second';
  nextReplyDelayValue = 0;
  selectedScheduleId = this.defaultScheduleId;

  get delays() {
    return new Delays();
  }

  get officeHoursSchedules() {
    return this.args.officeHoursSchedules;
  }

  get conversationSlas() {
    return this.args.conversationSlas;
  }

  get hideSlaModal() {
    return this.args.hideSlaModal;
  }

  onSlaCreated(sla) {
    this.args.onSlaCreated(sla);
  }

  get trimmedSlaName() {
    let slaName = this.slaName || '';
    return slaName.trim();
  }

  get duplicatedName() {
    return this.conversationSlas.filterBy('isNew', false).isAny('name', this.trimmedSlaName);
  }

  get firstTimeValueNull() {
    return this.firstReplyTimeInSeconds === null;
  }

  get nextTimeValueNull() {
    return this.nextReplyTimeInSeconds === null;
  }

  get firstReplyTimeValid() {
    // we want this to be between 30 seconds and 5 days
    return (
      this.firstTimeValueNull ||
      (!this.firstTimeValueNull &&
        Number(this.firstReplyTimeInSeconds) >= 30 &&
        Number(this.firstReplyTimeInSeconds) <= 8640000) // 100 days
    );
  }

  get nextReplyTimeValid() {
    // we want this to be between 30 seconds and 5 days
    return (
      this.nextTimeValueNull ||
      (!this.nextTimeValueNull &&
        Number(this.nextReplyTimeInSeconds) >= 30 &&
        Number(this.nextReplyTimeInSeconds) <= 8640000) // 100 days
    );
  }

  get validResponseTimes() {
    return this.firstReplyTimeValid && this.nextReplyTimeValid;
  }

  get saveButtonDisabled() {
    let invalidName = this.trimmedSlaName.length === 0 || this.duplicatedName;
    return invalidName || !this.validResponseTimes;
  }

  get defaultScheduleId() {
    let schedule = this.officeHoursSchedules.findBy('isDefault', true);
    if (schedule) {
      return schedule.id;
    } else if (this.officeHoursSchedules.length > 0) {
      return this.officeHoursSchedules.firstObject.id;
    }
  }

  get appHasMoreThanOneOfficeHours() {
    return this.officeHoursSchedules.length > 1;
  }

  get dropdownSchedules() {
    return this.officeHoursSchedules.map((schedule) => {
      return {
        value: schedule.id,
        text: schedule.name,
        description: schedule.labelWithTimezone,
      };
    });
  }

  @task
  *createNewSlaAndCloseModal() {
    let sla = yield this.store
      .createRecord('inbox/conversation-sla', {
        name: this.trimmedSlaName,
        firstReplyTimeInSeconds: this.firstReplyTimeInSeconds,
        nextReplyTimeInSeconds: this.nextReplyTimeInSeconds,
        officeHoursScheduleId: this.selectedScheduleId,
      })
      .save();
    this.closeCustomTimeSlaModal();
    this.onSlaCreated(sla);
  }

  @action
  closeCustomTimeSlaModal() {
    this.slaName = null;
    this.firstReplyTimeInSeconds = null;
    this.nextReplyTimeInSeconds = null;
    this.hideSlaModal();
  }

  @action
  setFirstReplyDelayUnit(unit) {
    this.firstReplyDelayUnit = unit;
    if (Number(this.firstReplyDelayValue) === 0) {
      this.firstReplyTimeInSeconds = null;
    } else {
      this.firstReplyTimeInSeconds = this.delays.getDelayInSeconds(this.firstReplyDelayValue, unit);
    }
  }

  @action
  setFirstReplyDelayValue() {
    if (Number(this.firstReplyDelayValue) === 0) {
      this.firstReplyTimeInSeconds = null;
    } else {
      this.firstReplyTimeInSeconds = this.delays.getDelayInSeconds(
        this.firstReplyDelayValue,
        this.firstReplyDelayUnit,
      );
    }
  }

  @action
  setNextReplyDelayUnit(unit) {
    this.nextReplyDelayUnit = unit;
    if (Number(this.nextReplyDelayValue) === 0) {
      this.nextReplyTimeInSeconds = null;
    } else {
      this.nextReplyTimeInSeconds = this.delays.getDelayInSeconds(this.nextReplyDelayValue, unit);
    }
  }

  @action
  setNextReplyDelayValue() {
    if (Number(this.nextReplyDelayValue) === 0) {
      this.nextReplyTimeInSeconds = null;
    } else {
      this.nextReplyTimeInSeconds = this.delays.getDelayInSeconds(
        this.nextReplyDelayValue,
        this.nextReplyDelayUnit,
      );
    }
  }
}
