/* import __COLOCATED_TEMPLATE__ from './company-tags.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  router: service(),
  intercomEventService: service(),

  openModalFromCompanyTags: action(function (controller, company) {
    this.modalService.openModal('companies/company/modals/tagging', company);
  }),

  actions: {
    navigateToVisibilitySettings() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'company_tags_visibility_button',
      });
      this.router.transitionTo('apps.app.account.visibility.tags');
    },
  },
});
