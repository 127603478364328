/* import __COLOCATED_TEMPLATE__ from './ai-assist-entry.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type AiAssistSettings from 'embercom/services/ai-assist-settings';
import type Session from 'embercom/services/session';

interface Args {}

interface Signature {
  Args: Args;
}

export default class AiAssistEntry extends Component<Signature> {
  @service declare aiAssistSettings: AiAssistSettings;
  @service declare session: Session;

  get aiAssistEnabled() {
    return this.aiAssistSettings.hasAnyAiAssistInInbox;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::AiAssistEntry': typeof AiAssistEntry;
    'inbox2/left-nav/ai-assist-entry': typeof AiAssistEntry;
  }
}
