/* import __COLOCATED_TEMPLATE__ from './performance.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { type TeammateActivityOverview } from 'embercom/services/inbox2-teammate-activity-service';
import type Inbox2TeammateActivityService from 'embercom/services/inbox2-teammate-activity-service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { aggregationText } from 'embercom/helpers/aggregation-text';
import ENV from 'embercom/config/environment';

interface Args {
  onLoad?: () => void;
  isInitialLoad: boolean;
}

interface Signature {
  Args: Args;
}

export default class YourAchievementsPerformance extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inbox2TeammateActivityService: Inbox2TeammateActivityService;
  @service notificationsService: any;

  @tracked data?: TeammateActivityOverview;

  private AGGREGATION_TYPE = 'median';
  private TIMEFRAME_IN_HOURS = 8;
  private DEFAULT_REFRESH_TIME_IN_SECONDS = 60;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchTeammateStats).perform();

    registerDestructor(this, () => {
      taskFor(this.fetchTeammateStats).cancelAll();
    });
  }

  @task({ restartable: true }) *fetchTeammateStats(): TaskGenerator<void> {
    while (true) {
      if (document.hidden) {
        yield timeout(3 * 1000);
      } else {
        try {
          let teammatesData: { overview: TeammateActivityOverview } =
            yield this.inbox2TeammateActivityService.getCurrentTeammateActivityOverview(
              {
                timeframeInHours: this.TIMEFRAME_IN_HOURS,
                aggregationType: this.AGGREGATION_TYPE,
                idleThreshold: 1800,
                excludeBotTime: false,
                excludedQueryTypes: [],
              },
              {
                cachedOverview: this.data ? [this.data] : [],
              },
            );
          this.data = teammatesData.overview;
        } catch {
          this.notificationsService.notifyError(
            this.intl.t('inbox.conversation-details-sidebar.achievements.error', {
              seconds: this.DEFAULT_REFRESH_TIME_IN_SECONDS,
            }),
          );
        }
        if (this.args.onLoad) {
          this.args.onLoad();
        }
        if (ENV.environment === 'test') {
          break;
        } else {
          yield timeout(this.DEFAULT_REFRESH_TIME_IN_SECONDS * 1000);
        }
      }
    }
  }

  get aggregationTypeText(): string {
    return aggregationText(this.AGGREGATION_TYPE);
  }

  get currentTimeframe(): string {
    return `${this.TIMEFRAME_IN_HOURS}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::YourAchievements::Performance': typeof YourAchievementsPerformance;
    'inbox2/your-achievements/performance': typeof YourAchievementsPerformance;
  }
}
