/* import __COLOCATED_TEMPLATE__ from './ios.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import {
  INSTALLATION_TYPE,
  INSTALLATION_METHOD,
} from 'embercom/components/installation-new/constants';
import { inject as service } from '@ember/service';
import type SdkAppService from 'embercom/services/sdk-app-service';

interface Args {}

export default class Ios extends Component<Args> {
  @service declare appService: any;
  @service declare sdkAppService: SdkAppService;

  installationType = INSTALLATION_TYPE.LOGGED_IN_USERS;
  installationPlatforms = INSTALLATION_METHOD;

  get admin() {
    return this.appService.app.currentAdmin;
  }

  get isRegenerateSdkAppRunning() {
    return this.sdkAppService.isRegenerateSdkAppRunning;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Ios': typeof Ios;
    'installation-new/mobile/initial-setup/ios': typeof Ios;
  }
}
