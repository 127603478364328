/* import __COLOCATED_TEMPLATE__ from './app-released-warning.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {}
interface Signature {
  Args: Args;
  Element: any;
}

export default class AppReleasedWarning extends Component<Signature> {
  @tracked openSectionId: string | null = null;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::IdvSetup::AppReleasedWarning': typeof AppReleasedWarning;
    'installation-new/mobile/idv-setup/app-released-warning': typeof AppReleasedWarning;
  }
}
