/* import __COLOCATED_TEMPLATE__ from './ticket-visibility-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: { onModalClose: () => void; onConfirm: () => void };
}

const TicketVisibilityModal = templateOnlyComponent<Signature>();
export default TicketVisibilityModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketVisibilityModal': typeof TicketVisibilityModal;
  }
}
