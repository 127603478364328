/* import __COLOCATED_TEMPLATE__ from './merged-conversation-event-group.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type MergedConversationReply from 'embercom/objects/inbox/renderable/merged-conversation-reply';
import { helper } from '@ember/component/helper';
import { type MergedConversationReplyPart } from 'embercom/components/inbox2/conversation-stream/event-parts/merged-conversation-reply';

interface MergedConversationPart extends RenderablePart {
  renderableData: MergedConversationReply;
}

interface MergedConversationEventGroupParts extends PartGroup {
  parts: Array<MergedConversationPart>;
}

interface Args {
  partGroup: MergedConversationEventGroupParts;
  isPreviewPanel: boolean;
}

interface Signature {
  Args: Args;
}

export default class MergedConversationEventGroup extends Component<Signature> {
  get renderableTypes() {
    return RenderableType;
  }

  convertToEventReplyType = helper(function ([part]: [any]): MergedConversationReplyPart {
    return part as unknown as MergedConversationReplyPart;
  });
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketStream::PartGroups::MergedConversationEventGroup': typeof MergedConversationEventGroup;
    'inbox2/ticket-stream/part-groups/merged-conversation-event-group': typeof MergedConversationEventGroup;
  }
}
