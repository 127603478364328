/* import __COLOCATED_TEMPLATE__ from './whatsapp-template-inserted.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type WhatsappTemplate from 'embercom/objects/inbox/whatsapp-template';

interface Signature {
  Args: {
    template: WhatsappTemplate;
    onClose: () => void;
  };
}

const WhatsappTemplateInserted = templateOnlyComponent<Signature>();
export default WhatsappTemplateInserted;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::WhatsappTemplateInserted': typeof WhatsappTemplateInserted;
    'inbox2/whatsapp-template-inserted': typeof WhatsappTemplateInserted;
  }
}
