/* import __COLOCATED_TEMPLATE__ from './get-started.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  canClose: boolean;
  onEnter: () => unknown;
  onClose: () => unknown;
  onNotNow: () => unknown;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class OnboardingGetStarted extends Component<Signature> {
  @tracked mode: 'dark' | 'light' = 'light';

  get keys() {
    return ['Enter'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::GetStarted': typeof OnboardingGetStarted;
    'inbox2/onboarding/get-started': typeof OnboardingGetStarted;
  }
}
