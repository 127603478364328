/* import __COLOCATED_TEMPLATE__ from './confirm-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ConfirmModal extends Component {
  @tracked consentReceived = false;

  @action
  subscribe() {
    this.args.closeModal();
    this.args.toggleSubscriptionTypePreference.perform(this.args.subscriptionType);
  }
}
