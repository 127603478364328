/* import __COLOCATED_TEMPLATE__ from './android-installation-snippet-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';

export default class AndroidInstallationSnippetSelectComponent extends Component {
  options = [
    { label: 'Kotlin', value: 'kotlin' },
    { label: 'Java', value: 'java' },
    { label: 'React Native', value: 'reactnative' },
  ];
}
