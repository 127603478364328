/* import __COLOCATED_TEMPLATE__ from './meta-data-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { typeOf } from '@ember/utils';

export default Component.extend({
  hasValue: notEmpty('value.value'),
  isUrl: notEmpty('value.url'),
  isPotentialUrl: computed('value', function () {
    if (typeOf(this.value) === 'string') {
      return this.value.match(/^\s*https?:\/\/\S*/);
    }
    return false;
  }),

  valueComponent: computed('value', 'key', function () {
    if ((this.hasValue && this.isUrl) || this.isPotentialUrl) {
      return 'inbox/meta-data-items/url-value';
    } else if (this.key.match(/_date$/)) {
      return 'inbox/meta-data-items/date-value';
    } else {
      return 'inbox/meta-data-items/default-value';
    }
  }),
});
