/* import __COLOCATED_TEMPLATE__ from './modify-product-link.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntercomradesProductsOverride from 'embercom/lib/intercomrades-products-override';
import type Session from 'embercom/services/session';

export default class ModifyProductLink extends Component {
  @service declare modalService: any;
  @service declare session: Session;

  get appID() {
    return this.session.workspace.id;
  }

  get modifiedProducts() {
    return IntercomradesProductsOverride.isEnabled(this.appID);
  }

  @action
  openModifyProductsModal() {
    this.modalService.openModal('intercomrades-package-editor-modal');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ModifyProductLink': typeof ModifyProductLink;
    'inbox2/user-menu/modify-product-link': typeof ModifyProductLink;
  }
}
