/* import __COLOCATED_TEMPLATE__ from './installation-step.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  stepNumber: number;
  stepTitle?: string;
  stepSubtitle?: string;
}

interface Signature {
  Blocks: {
    default: [];
  };
  Args: Args;
  Element: HTMLElement;
}

const InstallationStep = templateOnlyComponent<Signature>();
export default InstallationStep;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::InstallationStep': typeof InstallationStep;
    'installation-new/installation-step': typeof InstallationStep;
  }
}
