/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { isPresent } from '@ember/utils';
import {
  ASSIGNMENT_LIMIT_FILTER_TYPE,
  ASSIGNMENT_LIMIT_COMPARATORS,
  TEAM_FILTER_TYPE,
  ROLE_FILTER_TYPE,
} from 'embercom/lib/settings/filters/constants';
import TeammatesCsvExporter from 'embercom/lib/teammate-workload/teammates-csv-export';

const DEFAULT_FILTERS = {
  [ASSIGNMENT_LIMIT_FILTER_TYPE]: {
    type: ASSIGNMENT_LIMIT_FILTER_TYPE,
    comparator: ASSIGNMENT_LIMIT_COMPARATORS.hasAnyValue,
    value: undefined,
  },
  [TEAM_FILTER_TYPE]: {
    type: TEAM_FILTER_TYPE,
    comparator: undefined,
    value: [],
  },
  [ROLE_FILTER_TYPE]: {
    type: ROLE_FILTER_TYPE,
    comparator: undefined,
    value: [],
  },
};

const DEFAULT_PAGE_SIZE = 50;

export default class Table extends Component {
  @service intl;
  @service appService;
  @service store;
  @service csv;

  @tracked selectedTeammates = [];
  @tracked searchTerm = '';
  @tracked normalizedSearchTerm = '';
  @tracked selectedFilters = DEFAULT_FILTERS;

  @tracked currentPage = 0;
  @tracked pageSize = DEFAULT_PAGE_SIZE;

  @tracked tableMode = 'SHRINK';

  get columns() {
    let primaryLabel = 'settings.teammate-workload.columns.team-inboxes';
    let primaryTooltip = null;
    if (this.appService.app.canSeeTeammateWorkloadPage) {
      primaryLabel = 'settings.teammate-workload.columns.primary-inboxes';
      primaryTooltip = this.intl.t('settings.teammate-workload.primary-inboxes.tooltip');
    }
    return [
      {
        valuePath: 'isSelectable',
        type: 'checkbox',
        width: '1%',
      },
      {
        label: this.intl.t('settings.teammate-workload.columns.name'),
        valuePath: 'name',
        width: '9%',
      },
      {
        label: this.intl.t('settings.teammate-workload.columns.status'),
        valuePath: 'status',
        isVisible: false,
        width: '12%',
      },
      {
        label: this.intl.t('settings.teammate-workload.columns.assignment-limit'),
        valuePath: 'assignmentLimit',
        tooltip: this.intl.t(this.lbaDescription, {
          htmlSafe: true,
        }),
        width: '12%',
      },
      {
        label: this.intl.t(primaryLabel),
        valuePath: 'primaryInboxes',
        width: '22%',
        tooltip: primaryTooltip,
      },
      {
        label: this.intl.t('settings.teammate-workload.columns.secondary-inboxes'),
        valuePath: 'secondaryInboxes',
        isVisible: this.appService.app.canSeeTeammateWorkloadPage,
        tooltip: this.intl.t('settings.teammate-workload.secondary-inboxes.tooltip'),
        width: '22%',
      },
      {
        label: this.intl.t('settings.teammate-workload.columns.tags'),
        valuePath: 'tags',
        isVisible: this.appService.app.canUseLbaTagMatch,
        tooltip: this.intl.t('settings.teammate-workload.tags.tooltip'),
        width: '22%',
      },
    ];
  }

  get lbaDescription() {
    let prefix = 'settings.teammate-workload.assignment-limit';

    if (
      !this.appService.app.canUseTicketsAssignmentSwitch &&
      this.appService.app.excludeTicketsBalancedAssignment
    ) {
      return `${prefix}.balanced-description-tickets-excluded`;
    }

    return `${prefix}.balanced-description`;
  }

  get teammates() {
    return this.args.teammates;
  }

  get filteredTeammates() {
    let updatedTeammates = this.teammates;
    for (let [type, filter] of Object.entries(this.selectedFilters)) {
      updatedTeammates = this.processFilter(type, filter, updatedTeammates);
    }

    return isPresent(this.normalizedSearchTerm)
      ? this.searchTeammates(updatedTeammates)
      : updatedTeammates;
  }

  get renderableData() {
    return this.filteredTeammates.slice(0, this.pageSize * (this.currentPage + 1));
  }

  get hasActiveFilters() {
    return this.searchTerm.length > 0 || !_.isEqual(this.selectedFilters, DEFAULT_FILTERS);
  }

  get lastPageHit() {
    return this.filteredTeammates.length <= this.pageSize * (this.currentPage + 1);
  }

  get sortedTeamInboxes() {
    return this.args.teamInboxes.sortBy('name');
  }

  get balancedInboxes() {
    let teamSettings = this.store.peekAll('team-setting', {});

    return this.args.teamInboxes.filter((inbox) => {
      let teamSetting = teamSettings.find((teamSetting) => teamSetting.teamId === inbox.id);
      return teamSetting?.distributionMethod === 'load_balanced';
    });
  }

  get bulkUpdateAllowed() {
    return this.selectedTeammates.length <= this.args.bulkUpdateLimit;
  }

  get shouldShowEmptyState() {
    return this.renderableData.length === 0;
  }

  get tableModeIsExpanded() {
    return this.tableMode === 'EXPAND';
  }

  updateSearchTerm() {
    this.normalizedSearchTerm = this.searchTerm.toLowerCase();
  }

  @action
  onLoadMore() {
    this.currentPage++;
  }

  @action
  onChangeSearchTerm() {
    debounce(this, this.updateSearchTerm, ENV.APP._250MS);
  }

  @action
  onChangeTableMode() {
    if (this.tableMode === 'SHRINK') {
      this.tableMode = 'EXPAND';
    } else {
      this.tableMode = 'SHRINK';
    }
  }

  searchTeammates(teammates) {
    return teammates.filter((teammate) => {
      return (
        teammate.name.toLowerCase().includes(this.normalizedSearchTerm) ||
        teammate.email.toLowerCase().includes(this.normalizedSearchTerm)
      );
    });
  }

  processFilter(type, filter, updatedTeammates) {
    switch (type) {
      case ASSIGNMENT_LIMIT_FILTER_TYPE:
        return this.processAssignmentLimitFilter(filter, updatedTeammates);
      case TEAM_FILTER_TYPE:
        return this.processTeamFilter(filter, updatedTeammates);
      case ROLE_FILTER_TYPE:
        return this.processRoleFilter(filter, updatedTeammates);
    }
  }

  processAssignmentLimitFilter(filter, updatedTeammates) {
    switch (filter.comparator) {
      case ASSIGNMENT_LIMIT_COMPARATORS.greaterThan:
        return updatedTeammates.filter(
          (teammate) => this.assignmentLimitForTeammate(teammate) > filter.value,
        );
      case ASSIGNMENT_LIMIT_COMPARATORS.lessThan:
        return updatedTeammates.filter(
          (teammate) => this.assignmentLimitForTeammate(teammate) < filter.value,
        );
      case ASSIGNMENT_LIMIT_COMPARATORS.is:
        return updatedTeammates.filter(
          (teammate) => this.assignmentLimitForTeammate(teammate) === filter.value,
        );
      case ASSIGNMENT_LIMIT_COMPARATORS.isNot:
        return updatedTeammates.filter(
          (teammate) => this.assignmentLimitForTeammate(teammate) !== filter.value,
        );
      case ASSIGNMENT_LIMIT_COMPARATORS.hasAnyValue:
        return updatedTeammates;
    }
  }

  processTeamFilter(filter, updatedTeammates) {
    if (filter.value.length === 0) {
      return updatedTeammates;
    }

    return updatedTeammates.filter((teammate) => filter.value.includes(parseInt(teammate.id, 10)));
  }

  processRoleFilter(filter, updatedTeammates) {
    if (filter.value.length === 0) {
      return updatedTeammates;
    }

    return updatedTeammates.filter((teammate) => {
      // stub the role Id to -1 when no role is present. Allows filtering for no role.
      let roleId = parseInt(teammate.currentAppPermissions.role?.id, 10) || -1;
      return filter.value.includes(roleId);
    });
  }

  assignmentLimitForTeammate(teammate) {
    return (
      teammate.workload_management_settings?.assignment_limit ??
      this.args.workspaceSettings.capacityLimit
    );
  }

  @action
  handleSelect(rowId) {
    let teammates;
    if (this.selectedTeammates.includes(rowId)) {
      teammates = this.selectedTeammates.without(rowId);
    } else {
      teammates = [...this.selectedTeammates, rowId];
    }
    this.selectedTeammates = teammates;
    this.filteredTeammates.forEach((teammate, index) => {
      teammate.isSelected = this.selectedTeammates.includes(teammate.id);
    });
  }

  @action
  onBulkSelect() {
    let hasAllSelected = this.filteredTeammates.every((teammate) =>
      this.selectedTeammates.includes(teammate.id),
    );
    if (hasAllSelected) {
      this.resetBulkSelect();
    } else if (this.selectedTeammates.length >= 0) {
      this.selectedTeammates = this.filteredTeammates.map((teammate) => teammate.id);
      this.filteredTeammates.forEach((teammate, index) => {
        if (index < this.filteredTeammates.length) {
          teammate.isSelected = true;
        }
      });
    }
  }

  @action
  resetBulkSelect() {
    this.selectedTeammates = [];
    this.filteredTeammates.forEach((teammate, index) => {
      if (index < this.filteredTeammates.length) {
        teammate.isSelected = false;
      }
    });
  }

  @action
  onChangeFilter(filter) {
    this.selectedFilters = {
      ...this.selectedFilters,
      [filter.type]: filter,
    };
  }

  @action
  clearFilters() {
    this.pageSize = DEFAULT_PAGE_SIZE;
    this.currentPage = 0;
    this.selectedFilters = DEFAULT_FILTERS;
    this.searchTerm = '';
    this.normalizedSearchTerm = '';
  }

  @action
  exportTeammates() {
    let exporter = new TeammatesCsvExporter(this.appService.app, this.filteredTeammates, {
      workspaceAssignmentLimit: this.args.workspaceSettings.capacityLimit,
      teamInboxes: this.sortedTeamInboxes,
      teamSettings: this.store
        .peekAll('team-setting', {})
        .filter((teamSetting) => this.sortedTeamInboxes.mapBy('id').includes(teamSetting.teamId)),
      intl: this.intl,
    });

    this.csv.export(exporter.data, {
      fileName: exporter.filename,
      withSeparator: false,
    });
  }
}
