/* import __COLOCATED_TEMPLATE__ from './active-sla.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

interface Arguments {
  nextBreachAt: Date;
  isInteractive?: boolean;
}

interface Signature {
  Args: Arguments;
  Element: HTMLElement;
}

enum Status {
  Due,
  NearlyDue,
  Overdue,
}

const FIVE_MINS = 5 * 60 * 1000;

export default class ActiveSla extends Component<Signature> {
  get status(): Status | undefined {
    let now = new Date();

    if (this.args.nextBreachAt < now) {
      return Status.Overdue;
    }

    let isNearlyDue = now.getTime() + FIVE_MINS > this.args.nextBreachAt.getTime();
    if (isNearlyDue) {
      return Status.NearlyDue;
    }

    return Status.Due;
  }

  get percentageFilled(): number {
    return {
      [Status.Due]: 10,
      [Status.NearlyDue]: 60,
      [Status.Overdue]: 0,
    }[this.status ?? Status.Due];
  }

  get statusStyles() {
    switch (this.status) {
      case Status.Due:
        return 'text-gray bg-gray-light dark:text-dm-gray dark:bg-dm-gray-dark border-gray-light dark:border-dm-gray-dark';
      case Status.NearlyDue:
        return 'text-yellow-dark bg-yellow-light dark:text-dm-yellow-light dark:bg-dm-yellow-dark border-yellow-light dark:border-dm-yellow-dark';
      case Status.Overdue:
        return 'text-red bg-red-light dark:text-dm-red dark:bg-dm-red-dark border-red-light dark:border-dm-red-dark';
      default:
        return '';
    }
  }

  get interactiveStyles() {
    if (!this.args.isInteractive) {
      return '';
    }

    switch (this.status) {
      case Status.Due:
        return 'hover:border-gray focus:border-gray dark:hover:border-dm-gray dark:focus:border-dm-gray';
      case Status.Overdue:
        return 'hover:border-red focus:border-red dark:hover:border-dm-red dark:focus:border-dm-red';
      case Status.NearlyDue:
        return 'hover:border-yellow-dark focus:border-yellow-dark dark:hover:border-dm-yellow-light dark:hover:focus-dm-yellow-light';
      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Sla::ActiveSla': typeof ActiveSla;
    'inbox2/sla/active-sla': typeof ActiveSla;
  }
}
