/* import __COLOCATED_TEMPLATE__ from './secondary-inboxes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';

export default class SecondaryInboxes extends Component {
  @service intl;
  @service store;
  @service intercomEventService;
  @service permissionsService;
  @service notificationsService;
  @service appService;

  @tracked groupList = this.updatedGroupList;

  get lacksPermissionToEditInboxes() {
    return !this.permissionsService.currentAdminCan('can_manage_teams');
  }

  get expandedTableMode() {
    return this.args.expanded;
  }

  get balancedInboxes() {
    return this.args.teamInboxes.filter((inbox) => {
      return this.getTeamSettingsById(inbox.id)?.distributionMethod === 'load_balanced' || false;
    });
  }
  get primaryInboxes() {
    return this.balancedInboxes.filter((inbox) => inbox.primaryMembers.includes(this.adminId));
  }

  get secondaryInboxes() {
    return this.balancedInboxes.filter((inbox) => inbox.secondaryMembers.includes(this.adminId));
  }

  get secondaryInboxesToDisplayInCell() {
    if (this.args.expanded) {
      return this.secondaryInboxes;
    }
    return this.secondaryInboxes.slice(0, 4);
  }

  get otherTeamInboxes() {
    return this.balancedInboxes.filter((inbox) => !inbox.secondaryMembers.includes(this.adminId));
  }

  get adminId() {
    return parseInt(this.args.admin.id, 10);
  }

  get getMore() {
    return this.secondaryInboxes.length - this.secondaryInboxesToDisplayInCell.length;
  }

  get updatedGroupList() {
    return [
      {
        heading: this.intl.t('settings.teammate-workload.secondary-inboxes.added-team-inboxes'),
        items: this.secondaryInboxes.map((inbox) => ({
          text: inbox.name,
          value: inbox.id,
          isSelected: true,
          inbox,
          component: 'inbox/teammate-workload/team-inboxes/team-select-item',
          teamSettings: this.getTeamSettingsById(inbox.id),
        })),
      },
      {
        heading: this.intl.t('settings.teammate-workload.secondary-inboxes.all-team-inboxes'),
        items: this.otherTeamInboxes.map((inbox) => ({
          text: inbox.name,
          value: inbox.id,
          isSelected: false,
          inbox,
          component: 'inbox/teammate-workload/team-inboxes/team-select-item',
          teamSettings: this.getTeamSettingsById(inbox.id),
        })),
      },
    ];
  }

  @action async onSelectInbox(inboxId) {
    let team = this.store.peekRecord('team', parseInt(inboxId, 10));
    let currentMemberIds = team.secondaryMembers.slice(0);
    let primaryMemberIds = team.primaryMembers.slice(0);
    let newMemberIds = [];

    let teamId = parseInt(team.id, 10);

    let trackingAction;
    let params = {
      app_id: this.appService.app.id,
      admin_ids: [this.adminId],
      team_ids: {
        added: [],
        removed: [],
      },
    };

    if (currentMemberIds.includes(this.adminId)) {
      params['team_ids']['removed'].push({ id: teamId });
      newMemberIds = currentMemberIds.filter((id) => {
        return id !== this.adminId;
      });
      trackingAction = 'removed';
    } else {
      if (primaryMemberIds.includes(this.adminId)) {
        primaryMemberIds = primaryMemberIds.filter((id) => {
          return id !== this.adminId;
        });
      }
      params['team_ids']['added'].push({ id: teamId, wlm_inbox_priority_level: 2 });
      newMemberIds = currentMemberIds.slice(0);
      newMemberIds.unshift(this.adminId);
      trackingAction = 'added';
    }
    try {
      let updatedTeam = await post('/ember/admins/update_team_memberships', params);
      this.store.pushPayload({ teams: updatedTeam });
    } catch (error) {
      if (error?.jqXHR?.responseJSON?.errors) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
        this.revertSelection();
      } else {
        throw error;
      }
    } finally {
      this.intercomEventService.trackAnalyticsEvent({
        action: trackingAction,
        object: 'secondary_inboxes',
        place: 'teammate_workload',
        teammate_id: this.adminId,
        team_inbox_id: team.id,
      });
    }
  }

  revertSelection() {
    this.groupList = this.groupList.map((group) => {
      group.items = group.items.map((item) => {
        if (this.secondaryInboxes.map((inbox) => inbox.id).includes(item.value)) {
          item.isSelected = true;
          item.forceUpdate = Math.random();
        } else {
          item.isSelected = false;
        }
        return item;
      });
      return group;
    });
  }

  @action onOpen() {
    this.groupList = this.updatedGroupList;
  }

  @action triggerPermissionsModal() {
    if (this.lacksPermissionToEditInboxes) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teams');
    }
  }

  getTeamSettingsById(teamId) {
    let teamSetting = this.store
      .peekAll('team-setting', {})
      .filter((teamSetting) => teamSetting.teamId === teamId);

    if (teamSetting.length) {
      return teamSetting[0];
    }

    return undefined;
  }
}
