/* import __COLOCATED_TEMPLATE__ from './item-pin.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  item: {
    title: string;
    togglePin: () => void;
    pinned: boolean;
  };
}

interface Signature {
  Args: Arguments;
}

const ItemPin = templateOnlyComponent<Signature>();
export default ItemPin;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::EditSidebar::ItemPin': typeof ItemPin;
    'inbox2/left-nav/edit-sidebar/item-pin': typeof ItemPin;
  }
}
