/* import __COLOCATED_TEMPLATE__ from './side-conversation-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { action } from '@ember/object';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation } from 'embercom/services/inbox-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';
import type ConversationStreamSettings from 'embercom/services/conversation-stream-settings';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type Nexus from 'embercom/services/nexus';
import { type EmberKeyboardEvent, EscKeyPriority } from 'embercom/lib/inbox2/types';
import type EmojiService from 'embercom/services/emoji-service';
import type InboxApi from 'embercom/services/inbox-api';
import { SideConversationResource } from './side-conversation-resource';
import { registerDestructor } from '@ember/destroyable';

interface Args {
  sideConversationId: number;
  parentConversationId: number;
  shouldFocusComposer?: boolean;
  resetShouldFocusComposer?: () => void;
  isNotePaneActive: boolean;
  onBack?: () => void;
}

interface Signature {
  Args: Args;
}

type FocusFunction = () => void;
export default class SideConversationPanel extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare conversationStreamSettings: ConversationStreamSettings;
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare nexus: Nexus;
  @service declare emojiService: EmojiService;

  @tracked composerFocusFn?: FocusFunction;
  @tracked canShowSendAndCloseHint = false;

  @trackedReset({
    memo: 'args.shouldFocusComposer',
    update(component: SideConversationPanel, _: string, last: boolean) {
      if (last) {
        return last;
      }

      return component.args.shouldFocusComposer;
    },
  })
  isComposerVisible = this.args.shouldFocusComposer ?? false;

  @ref('preview-panel-composer-container') declare composerContainer: HTMLElement;
  @ref('preview-panel-composer-opener') declare composerOpener: HTMLElement;

  readonly replyPane = ComposerPaneType.Reply;
  readonly ComposerLocation = ComposerLocation;
  readonly keyboardPriority = 1;

  sideConversationResource = SideConversationResource.from(this, () => ({
    sideConversationId: this.args.sideConversationId,
    parentConversationId: this.args.parentConversationId,
  }));

  constructor(owner: SideConversationPanel, args: Args) {
    super(owner, args);
    this.setupMessengerVisibility();
    this.commandK.setActivationContext(DisplayContext.SideConversation);
  }

  willDestroy() {
    super.willDestroy();
    this.commandK.resetActivationContext();
  }

  get activePane(): ComposerPaneType {
    return this.replyPane;
  }

  get conversation(): Conversation {
    return this.sideConversationResource.sideConversation;
  }

  get escPriority() {
    return EscKeyPriority.conversationPanel;
  }

  @action onReady({ focus }: { focus: FocusFunction }) {
    this.composerFocusFn = focus;

    if (this.args.shouldFocusComposer) {
      next(() => focus());
      this.args.resetShouldFocusComposer?.();
    }
  }

  @action showComposer() {
    this.isComposerVisible = true;
    this.toggleMessengerVisibility({ hide: true });
    this.inboxState.setActiveComposer(ComposerLocation.ConversationPreviewPanel);
    next(() => this.composerFocusFn?.());
  }

  @action hideComposer(event?: Event, kev?: EmberKeyboardEvent) {
    event?.stopPropagation();
    kev?.stopPropagation();
    kev?.stopImmediatePropagation();

    this.isComposerVisible = false;
    this.toggleMessengerVisibility({ hide: false });
    this.inboxState.setActiveComposer(ComposerLocation.ConversationPage);
    setTimeout(() => this.composerOpener.focus(), 20);
  }

  @action updateActivationContext() {
    if (
      this.commandK.currentActivationContext !== DisplayContext.SideConversation &&
      this.commandK.currentActivationContext !== DisplayContext.SideConversationMessageActions
    ) {
      this.commandK.setActivationContext(DisplayContext.SideConversation);
    }
  }

  private setupMessengerVisibility() {
    this.toggleMessengerVisibility({ hide: true });

    registerDestructor(this, () => {
      this.toggleMessengerVisibility({ hide: false });
    });
  }

  private toggleMessengerVisibility({ hide }: { hide: boolean }) {
    if ('Intercom' in window) {
      window.Intercom('update', {
        hide_default_launcher: hide,
      });
    }
  }

  @action trackSidebarToggle(options = { keyboardShortcutUsed: false }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: this.inboxSidebarService.isSidebarCollapsed ? 'hidden' : 'shown',
      object: 'conversation_panel',
      section: options.keyboardShortcutUsed ? 'shortcuts' : undefined,
      shortcut_key: options.keyboardShortcutUsed,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SideConversationPanel': typeof SideConversationPanel;
    'inbox2/side-conversation-panel': typeof SideConversationPanel;
  }
}
