/* import __COLOCATED_TEMPLATE__ from './web.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  DEFAULT_GUIDE_LIBRARY,
  DEFAULT_GUIDE_LIBRARY_V2,
  TICKETS_INTERVENTION_GUIDE_LIBRARY,
} from 'embercom/lib/home/guides';

export default class WebComponent extends Component {
  @service onboardingHomeService;

  get inviteTechPersonStepKey() {
    if (this.onboardingHomeService.guide.identifier === DEFAULT_GUIDE_LIBRARY_V2) {
      return 'guide_library_foundational_steps_install_messenger_v2';
    } else if (this.onboardingHomeService.guide.identifier === DEFAULT_GUIDE_LIBRARY) {
      return 'guide_library_foundational_steps_install_messenger';
    } else if (this.onboardingHomeService.guide.identifier === TICKETS_INTERVENTION_GUIDE_LIBRARY) {
      return 'guide_library_foundational_steps_messenger_omnichannel';
    } else {
      return 'set_up_messenger_primary';
    }
  }
}
