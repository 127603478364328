/* import __COLOCATED_TEMPLATE__ from './notification-follow-up-on-snooze.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type Notification } from 'embercom/services/snackbar';
import type Snackbar from 'embercom/services/snackbar';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type Session from 'embercom/services/session';

interface Args {
  notification: Notification;
}

interface Signature {
  Args: Args;
}

export default class NotificationFollowUpOnSnooze extends Component<Signature> {
  @service declare snackbar: Snackbar;
  @service declare router: RouterService;
  @service declare session: Session;
  @service declare intercomEventService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  @action navigateToWorkflows() {
    if (this.snackbar.notifications?.length) {
      this.snackbar.notifications.pop();
      this.router.transitionTo('apps.app.automation.workflows-overview', this.session.workspace.id);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        context: 'notification-follow-up-on-snooze',
        object: 'set_up_a_workflow_button',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::NotificationFollowUpOnSnooze': typeof NotificationFollowUpOnSnooze;
    'inbox2/left-nav/notification-follow-up-on-snooze': typeof NotificationFollowUpOnSnooze;
  }
}
