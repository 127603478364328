/* import __COLOCATED_TEMPLATE__ from './sms-usage-limits-reached-banner.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const SmsUsageLimitsReachedBanner = templateOnlyComponent<Signature>();
export default SmsUsageLimitsReachedBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::SmsUsageLimitsReachedBanner': typeof SmsUsageLimitsReachedBanner;
    'inbox2/sms-usage-limits-reached-banner': typeof SmsUsageLimitsReachedBanner;
  }
}
