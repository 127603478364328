/* import __COLOCATED_TEMPLATE__ from './ticket-visibility.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    tooltip: string;
    icon: InterfaceIconName;
    label: string;
    dataLabel: string;
  };
}

const TicketVisibility = templateOnlyComponent<Signature>();
export default TicketVisibility;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::TicketVisibility': typeof TicketVisibility;
    'inbox2/ticket-attribute/ticket-visibility': typeof TicketVisibility;
  }
}
