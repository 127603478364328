/* import __COLOCATED_TEMPLATE__ from './share-linked-ticket-checkbox.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

interface Args {
  onUserVisibilityChanged: (visibleToUser: boolean) => boolean;
  isSharingDisabled: boolean;
  isInternalTicket: boolean;
  hasSharedLinkedTicket: boolean;
  isLinkedToRequest: boolean;
}

interface Signature {
  Args: Args;
}

export default class ShareLinkedTicketCheckbox extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  @tracked visibleToUser = false;

  @action
  toggleVisibleToUser() {
    if (this.args.isSharingDisabled) {
      return;
    }

    this.visibleToUser = !this.visibleToUser;
    if (this.args.onUserVisibilityChanged) {
      this.args.onUserVisibilityChanged(this.visibleToUser);
    }
  }

  get tooltipMessage() {
    if (this.args.isSharingDisabled) {
      if (this.args.isLinkedToRequest) {
        return this.intl.t('inbox.ticket-attributes.create-ticket.linked-to-request-ticket');
      }

      if (this.args.isInternalTicket) {
        return this.intl.t('inbox.ticket-attributes.create-ticket.disable-share-linked-ticket');
      } else {
        return this.intl.t('inbox.ticket-attributes.create-ticket.has-shared-linked-ticket');
      }
    }

    return this.intl.t('inbox.ticket-attributes.create-ticket.can-share-linked-ticket');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ShareLinkedTicketCheckbox': typeof ShareLinkedTicketCheckbox;
    'inbox2/share-linked-ticket-checkbox': typeof ShareLinkedTicketCheckbox;
  }
}
