/* import __COLOCATED_TEMPLATE__ from './shopify.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import templateOnlyComponent from '@ember/component/template-only';
import { type INSTALLATION_TYPE } from 'embercom/components/installation-new/constants';

interface Args {
  selectedInstallationType: INSTALLATION_TYPE;
  setSelectedInstallationType: (installationType: INSTALLATION_TYPE) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

const Shopify = templateOnlyComponent<Signature>();
export default Shopify;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::InitialSetup::Shopify': typeof Shopify;
  }
}
