/* import __COLOCATED_TEMPLATE__ from './teammate-presence.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import type AppSettings from 'embercom/objects/inbox/app-settings';

interface Args {
  appSettings: AppSettings;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class TeammatePresence extends Component<Signature> {
  @service declare session: Session;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @tracked savedShowTeammatesPresence = this.args.appSettings.showTeammatesPresence;
  @tracked currentShowTeammatesPresence = this.args.appSettings.showTeammatesPresence;

  get saveButtonDisabled() {
    return this.currentShowTeammatesPresence === this.savedShowTeammatesPresence;
  }

  @action
  toggleShowTeammatesPresence() {
    this.currentShowTeammatesPresence = !this.currentShowTeammatesPresence;
  }

  @action
  async saveSettings() {
    try {
      await post(`/ember/inbox/app_settings/update_show_teammates_presence`, {
        app_id: this.session.workspace.id,
        show_teammates_presence: this.currentShowTeammatesPresence,
      });
      this.savedShowTeammatesPresence = this.currentShowTeammatesPresence;
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammate-presence.settings-saved'),
      );
    } catch (error) {
      this.currentShowTeammatesPresence = this.savedShowTeammatesPresence;
      this.notificationsService.notifyError(
        this.intl.t('settings.teammate-presence.saving-settings-failed'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::TeammatePresence::TeammatePresence': typeof TeammatePresence;
  }
}
