/* import __COLOCATED_TEMPLATE__ from './permissions-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const PermissionsModal = templateOnlyComponent<Signature>();
export default PermissionsModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PermissionsModal': typeof PermissionsModal;
    'inbox2/permissions-modal': typeof PermissionsModal;
  }
}
