/* import __COLOCATED_TEMPLATE__ from './code-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable jquery-unsafe/no-html */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import $ from 'jquery';
import ClipboardJS from 'clipboard';
import { inject as service } from '@ember/service';
import { htmlUnescape } from 'embercom/lib/html-unescape';
import { captureException } from 'embercom/lib/sentry';

// https://github.com/sindresorhus/strip-indent/blob/master/index.js
const unindent = (str) => {
  let match = str.match(/^[ \t]*(?=\S)/gm);

  if (!match) {
    return str;
  }

  let indent = Math.min.apply(
    Math,
    match.map(function (el) {
      return el.length;
    }),
  );

  let re = new RegExp(`^[ \\t]{${indent}}`, 'gm');

  return indent > 0 ? str.replace(re, '') : str;
};

const stripHTMLComments = (str) => {
  return str.replace(/<!---->/g, '');
};

const trim = (str) => {
  return str.replace(/^\s+|\s+$/g, '');
};

const lineCount = (str) => {
  return str.split('\n').length;
};

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  classNames: ['code-block'],
  language: null,
  lineNumbers: null,
  onCopyCode: null,
  cdaList: null,
  clipboard: null,

  didInsertElement() {
    this._super(...arguments);
    let copyCodeButton = this.element.querySelector('.code-block__clipboard-button');
    this.clipboard = new ClipboardJS(copyCodeButton);
    this.clipboard.on('success', () => {
      this.notificationsService.notifyConfirmation('Code copied to clipboard');
    });
    this.clipboard.on('error', () => {
      this.notificationsService.notifyWarning(
        'Your browser does not support copying directly to the clipboard. Please use Ctrl - C to do this instead.',
      );
    });
  },

  didRender() {
    this._super(...arguments);
    let $currentView = $(this.element);
    let $hiddenView = $currentView.find('.code-block__hidden-output');
    let $hiddenViewSubview = $hiddenView.find('.ember-view');
    let $content;

    if ($hiddenViewSubview.length) {
      $content = $hiddenViewSubview;
    } else {
      $content = $hiddenView;
    }

    let code = htmlUnescape($content.html());
    code = unindent(code);
    code = stripHTMLComments(code);
    code = trim(code);

    let lineNumbers = [];
    for (let i = 1; i <= lineCount(code); i++) {
      lineNumbers.push(i);
    }
    $currentView.find('.code-block__line-numbers').html(lineNumbers.join('\n'));

    let highlightedCode;
    if (this.language) {
      highlightedCode = hljs.highlight(this.language, code).value;
    } else {
      highlightedCode = hljs.highlightAuto(code).value;
    }

    $currentView.find('.code-block__code__highlight').html(highlightedCode);

    let $copyToClipboardButton = $currentView.find('.code-block__clipboard-button');
    $copyToClipboardButton.attr('data-clipboard-text', code);
  },

  willDestroyElement() {
    try {
      this.clipboard.destroy();
    } catch (e) {
      captureException(e, {
        fingerprint: ['clipboard.destroy', 'code-component'],
      });
    }
    this._super(...arguments);
  },

  codeCopied: action(function () {
    this.intercomEventService.trackEvent('code-snippet-copy-button-clicked', {
      action: 'clicked',
      object: `${this.rerenderOnChange}_code`,
      place: this.cdaList ? 'cda_tool' : 'code_component',
      owner: 'growth',
    });

    if (typeof this.onCopyCode === 'function') {
      this.onCopyCode();
    }
  }),
});
