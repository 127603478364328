/* import __COLOCATED_TEMPLATE__ from './cvda-list-filter.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { type CvdaListItem } from '../search2';

interface Signature {
  Element: HTMLElement;
  Args: {
    onSelect: (value: CvdaListItem) => void;
    listItems: CvdaListItem[];
    selected?: CvdaListItem;
  };
}

export default class CvdaListFilter extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked selectedRadioButtonValue = 'eq';

  get radioButtons() {
    return [
      {
        value: 'eq',
        label: this.intl.t('inbox.search.filters.more-filters.is'),
      },
    ];
  }

  get groupList() {
    return [
      {
        items: this.args.listItems,
      },
    ];
  }

  get selectedLabel() {
    return this.args.selected?.text || '';
  }

  @action onSelectItem(item: CvdaListItem) {
    this.args.onSelect(item);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::CvdaListFilter': typeof CvdaListFilter;
  }
}
