/* import __COLOCATED_TEMPLATE__ from './set-status.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    item: {
      value: boolean;
      toggleAwayMode: () => void;
    };
  };
}

const SetStatus = templateOnlyComponent<Signature>();
export default SetStatus;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::SetStatus': typeof SetStatus;
    'inbox2/user-menu/set-status': typeof SetStatus;
  }
}
