/* import __COLOCATED_TEMPLATE__ from './date-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import moment from 'moment-timezone';
import { reject } from 'rsvp';

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
}

type RangeOption =
  | {
      id: 'today' | 'yesterday' | 'last-30-days' | 'last-90-days';
      text: string;
      range: DateRange;
    }
  | {
      id: 'custom';
      text: string;
      range: Partial<DateRange>;
    };

function findClosestRangeMatch(
  start: moment.Moment,
  end: moment.Moment,
): RangeOption['id'] | undefined {
  if (!start.isValid || !end.isValid) {
    return;
  }

  let today = moment();
  let yesterday = today.clone().subtract(1, 'days');

  if (start.isSame(yesterday, 'day') && end.isSame(today.add(1, 'day'), 'day')) {
    return 'today';
  }

  if (start.isSame(yesterday.subtract(1, 'day'), 'day') && end.isSame(today, 'day')) {
    return 'yesterday';
  }

  let diff = end.diff(start, 'days');
  if (end.isSame(today, 'day') && diff === 30) {
    return 'last-30-days';
  }

  if (end.isSame(today, 'day') && diff === 90) {
    return 'last-90-days';
  }

  return 'custom';
}

interface Signature {
  Element: HTMLElement;
  Args: {
    range: DateRange | undefined;
    onSelect: (start: moment.Moment, end: moment.Moment) => any;
  };
}

export default class DateSelector extends Component<Signature> {
  @service declare intl: IntlService;

  get selectedRange() {
    let { range } = this.args;
    if (!range) {
      return;
    }

    let closest = findClosestRangeMatch(range.start, range.end);
    return this.ranges.find((range) => range.id === closest);
  }

  get displayableStartDate() {
    return this.args.range?.start.add(1, 'day').startOf('day');
  }

  get displayableEndDate() {
    return this.args.range?.end.subtract(1, 'day').endOf('day');
  }

  get ranges(): RangeOption[] {
    let now = moment();
    let today = now.clone();
    let yesterday = now.clone().subtract(1, 'days');

    return [
      {
        id: 'today',
        text: this.intl.t('inbox.search.filters.date.today'),
        range: {
          start: today.clone().subtract(1, 'day').startOf('day'),
          end: today.clone().add(1, 'day').endOf('day'),
        },
      },
      {
        id: 'yesterday',
        text: this.intl.t('inbox.search.filters.date.yesterday'),
        range: {
          start: yesterday.clone().subtract(1, 'day').startOf('day'),
          end: yesterday.clone().add(1, 'day').endOf('day'),
        },
      },
      {
        id: 'last-30-days',
        text: this.intl.t('inbox.search.filters.date.last-30-days'),
        range: {
          start: now.clone().subtract(30, 'days').startOf('day'),
          end: today.clone().endOf('day'),
        },
      },
      {
        id: 'last-90-days',
        text: this.intl.t('inbox.search.filters.date.last-90-days'),
        range: {
          start: now.clone().subtract(90, 'days').startOf('day'),
          end: today.clone().endOf('day'),
        },
      },
      {
        id: 'custom',
        text: this.intl.t('inbox.search.filters.date.custom'),
        range: {
          start: this.args.range?.start,
          end: this.args.range?.end,
        },
      },
    ];
  }

  @action onSelect(option: RangeOption) {
    if (option.id === 'custom') {
      return reject();
    }

    return this.args.onSelect(option.range.start, option.range.end);
  }

  @action onSelectCustomRange(start: moment.Moment, end: moment.Moment) {
    return this.args.onSelect(
      start.subtract(1, 'day').startOf('day'),
      end.add(1, 'day').endOf('day'),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::DateSelector': typeof DateSelector;
  }
}
