/* import __COLOCATED_TEMPLATE__ from './assignment-limit-setting.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';

interface Args {
  capacity: any;
  assignmentLimitUpdate: { isChanged: boolean };
  updateAssignmentLimit?: (limit: number) => void;
  saveAssignmentLimit?: (limit: number) => void;
  changeTab?: (tab: string) => void;
}

export default class AssignmentLimitSetting extends Component<Args> {
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked tempCapacityLimit: any = this.args.capacity.capacityLimit;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get validCapacityLimit() {
    return this.tempCapacityLimit && this.tempCapacityLimit > 0;
  }

  get limitUpdated() {
    let isSame =
      !isNaN(this.tempCapacityLimit) &&
      parseInt(this.args.capacity.capacityLimit, 10) === parseInt(this.tempCapacityLimit, 10);
    return !isSame;
  }

  @action
  changeTabFn(tab: string) {
    if (this.args.changeTab) {
      this.args.changeTab(tab);
    }
  }

  @action
  updateTempCapacityLimit() {
    this.args.assignmentLimitUpdate.isChanged = this.limitUpdated;
    if (this.args.updateAssignmentLimit) {
      this.args.updateAssignmentLimit(this.tempCapacityLimit);
    }
    if (
      this.appService.app.canUseHelpdeskSetup &&
      this.args.saveAssignmentLimit &&
      this.limitUpdated
    ) {
      debounce(this, this.args.saveAssignmentLimit, this.tempCapacityLimit, 500);
    }
  }

  get showTicketsExcludedNote() {
    if (this.appService.app.canUseTicketsAssignmentSwitch) {
      return false;
    }

    return this.appService.app.excludeTicketsBalancedAssignment;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::AssignmentLimitSetting': typeof AssignmentLimitSetting;
    'inbox/workload-management/assignment-limit-setting': typeof AssignmentLimitSetting;
  }
}
