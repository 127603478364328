/* import __COLOCATED_TEMPLATE__ from './url-performance-table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ParseMultiSignal from 'embercom/lib/reporting/multi-signal-parsing';

const TOP_PERFORMERS_COUNT = 5;

export default Component.extend({
  intl: service(),
  appService: service(),
  normalized: [],
  selected: [],

  get columns() {
    return [
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.title'),
        propertyName: 'key',
        canExpandToFit: true,
        valueComponent: 'inbox/reporting/performance-table-fields/url',
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.conversations'),
        propertyName: 'conversations_created',
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.response-time'),
        propertyName: 'median_first_response_time',
        valueComponent: 'inbox/reporting/performance-table-fields/minutes',
        description: this.intl.t(
          'components.inbox.reporting.performance-tables.response-time-tooltip',
        ),
        maxWidth: 100,
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.opps-influenced'),
        propertyName: 'opps_influenced',
        maxWidth: 100,
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.pipeline'),
        propertyName: 'pipeline',
        valueComponent: 'inbox/reporting/performance-table-fields/currency',
      },
      {
        label: this.intl.t('components.inbox.reporting.performance-tables.closed-won'),
        propertyName: 'closed_won',
        valueComponent: 'inbox/reporting/performance-table-fields/closed-won',
      },
    ];
  },

  init() {
    this._super(...arguments);
    this.set('selected', []);
  },

  didReceiveAttrs() {
    this._super(...arguments);
    if (!this.signal) {
      return;
    }
    let current = this.get('signal.current.context');
    let previous = this.get('signal.previous.context');
    this.set('normalized', ParseMultiSignal(current, previous));
  },

  topPages: computed('normalized', function () {
    return this.normalized.slice(0, TOP_PERFORMERS_COUNT);
  }),

  hasAdditionalSelections: gt('additionalSelectionOptions.firstObject.items.length', 0),

  additionalSelectionOptions: computed('topPages', 'normalized', function () {
    let top = this.topPages;
    let fields = this.normalized.filter((row) => !top.includes(row));
    return [
      {
        items: fields.map((row) => {
          return {
            text: row.key,
            value: row,
          };
        }),
      },
    ];
  }),

  rows: computed('topPages', 'selected', function () {
    let top = this.topPages;
    let additionalRows = this.selected;
    return top.concat(additionalRows);
  }),

  customOfficeHoursTooltipText: computed(
    'appService',
    'intl.locale',
    'settings.reportingExcludeOooHours',
    function () {
      let defaultTooltip = this.intl.t(
        'apps.app.reports.lead-generation.sales.conversions-by-page-tooltip',
      );
      let officeHoursTooltip = this.intl.t(
        'components.reporting.flexible.chart-header-custom-tooltip.office-hours-tooltip.tooltip-description',
        { includeOfficeHours: this.settings.reportingExcludeOooHours },
      );
      return this.intl.t(
        'components.reporting.reports.effectiveness.office-hours.tooltip-with-office-hours',
        { defaultTooltip, officeHoursTooltip, htmlSafe: true },
      );
    },
  ),
});
