/* import __COLOCATED_TEMPLATE__ from './channel-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type AdminAwayService from 'embercom/services/admin-away-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  item: {
    componentAttrs: {
      defaultAvailableChannel: string;
    };
  };
}

interface Signature {
  Args: Args;
}

export default class ChannelSwitcherPopover extends Component<Signature> {
  @service declare session: Session;
  @service declare router: Router;
  @service declare adminAwayService: AdminAwayService;
  @service declare intl: IntlService;
  @tracked selectedChannel: string = this.args.item.componentAttrs.defaultAvailableChannel;

  get selectedChannelName() {
    let channel = this.channelList.find(
      (channel) => channel.value.toLowerCase() === this.selectedChannel,
    );
    return channel?.name;
  }

  get currentWorkspace() {
    return this.session.workspace;
  }

  get currentAdmin() {
    return this.session.teammate;
  }

  get channelList() {
    return [
      {
        value: 'both',
        name: this.intl.t('inbox.user-menu.all_channel'),
      },
      {
        value: 'conversations',
        name: this.intl.t('inbox.user-menu.conversations_channel'),
      },
      {
        value: 'phone',
        name: this.intl.t('inbox.user-menu.phone_channel'),
      },
    ];
  }

  @action async changeChannel(selectedValue: string) {
    this.selectedChannel = selectedValue;
    this.adminAwayService.toggleAvailableChannel(selectedValue);
  }

  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ChannelSwitcher': typeof ChannelSwitcherPopover;
    'inbox2/user-menu/channel-switcher': typeof ChannelSwitcherPopover;
  }
}
