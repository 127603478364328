/* import __COLOCATED_TEMPLATE__ from './hmac-snippet-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  language: 'rails',
  highlightLanguage: computed('language', function () {
    if (this.language === 'rails') {
      return 'ruby';
    } else if (this.language === 'django') {
      return 'python';
    } else if (this.language === 'node') {
      return 'javascript';
    } else {
      return this.language;
    }
  }),
  userIdentifier: 'email',
  isSegment: false,

  segmentExamples: computed('apiSecret', function () {
    let apiSecret = this.apiSecret;

    return {
      rails: {
        userId: '<%= current_user.id %>',
        userHash: `<%= OpenSSL::HMAC.hexdigest("sha256", "${apiSecret}", current_user.id.to_s) %>`,
      },
      django: {
        userId: '{{ request.user.id|escapejs }}',
        userHash: `{{ hmac.new(b'${apiSecret}', bytes(request.user.id, encoding='utf-8'), digestmod=hashlib.sha256).hexdigest() }}`,
      },
      php: {
        userId: '<?php echo json_encode($user->id); ?>',
        userHash: `<?php echo hash_hmac("sha256", $user->id, "${apiSecret}"); ?>`,
      },
      node: {
        userId: 'user.id',
        userHash: `crypto.createHmac('sha256', '${apiSecret}').update(user.id).digest('hex')`,
      },
    };
  }),

  selectedExample: computed('examples', 'language', function () {
    return this.segmentExamples[this.language];
  }),
});
