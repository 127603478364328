/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { type TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {
  ticketTypeResource: TicketTypeResource;
}

interface Signature {
  Args: Args;
}

export default class NewConversationHeader extends Component<Signature> {
  @service declare session: Session;

  get ticketCategoryHeading() {
    let { selectedTicketType } = this.args.ticketTypeResource;
    if (!selectedTicketType) {
      return 'inbox.new-conversation.heading';
    }
    let category = selectedTicketType.category;
    switch (category) {
      case TicketCategory.Request:
        return 'inbox.new-conversation.create-customer-ticket';
      case TicketCategory.Task:
        return 'inbox.new-conversation.create-back-office-ticket';
      case TicketCategory.Tracker:
        return 'inbox.new-conversation.create-tracker-ticket';
      default:
        return 'inbox.new-conversation.create-ticket';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation::Header': typeof NewConversationHeader;
    'inbox2/new-conversation/header': typeof NewConversationHeader;
  }
}
