/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type PermissionsModal from 'embercom/services/permissions-modal';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { post, put } from 'embercom/lib/ajax';
import { type TabItem } from 'embercom/components/new-settings/common/standard-base';

const WORKLOAD_MANAGEMENT_DEEP_DIVE_ARTICLE_ID = 6553774;
const BULK_UPDATE_LIMIT = 50;
interface Args {
  model: any;
  changeTab: (tab: string) => void;
  tab: string;
}

export default class Main extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare permissionsModal: PermissionsModal;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare appService: any;

  DEFAULT_INITIAL_INACTIVITY_ENABLED = false;
  DEFAULT_INITIAL_INACTIVITY_THRESHOLD = 30;
  DEFAULT_FOLLOWING_INACTIVITY_ENABLED = false;
  DEFAULT_FOLLOWING_INACTIVITY_THRESHOLD = 60;

  @tracked initialInactivityEnabled = this.initialInactivityEnabledValue;
  @tracked initialInactivityThreshold = this.initialInactivityThresholdValue;
  @tracked followingInactivityEnabled = this.followingInactivityEnabledValue;
  @tracked followingInactivityThreshold = this.followingInactivityThresholdValue;
  @tracked selfAssignedSelection: string = this.assignmentSetting;
  @tracked savedShowTeammatesPresence = this.args.model.appSettings.showTeammatesPresence;
  @tracked currentShowTeammatesPresence = this.args.model.appSettings.showTeammatesPresence;

  @tracked isInboxOrderDrawerOpen = false;
  @tracked settings: any = {
    limit: this.args.model.capacity.capacityLimit,
    volumeControlChangedTeams: new Set<number>(),
    pullConversationEnabled: undefined,
    ticketsAssignmentDisabled: this.args.model.capacity.ticketsAssignmentDisabled,
  };
  @tracked isSortingChanged = false;
  @tracked isLimitChanged = false;
  @tracked isPullConversationEnabledChanged = false;
  // remove those two when cleaning up predicted-assignment-volume-control-deprecated
  @tracked isVolumeControlChanged = false;
  @tracked volumeControlSettings: any[] = this.getVolumeControlSettings();
  @tracked bulkUpdateLimit: number = BULK_UPDATE_LIMIT;
  @tracked autoAwaySetting = this.args.model.autoAwaySettingModel;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.tab === 'teammates' && !this.appService.app.canSeeTeammateWorkloadPage) {
      this.changeTab('workspace');
    }
  }

  get isUpdated() {
    return (
      this.isSortingChanged ||
      this.isLimitChanged ||
      this.isVolumeControlChanged ||
      this.isPullConversationEnabledChanged ||
      this.ticketsAssignmentDisabledHasChanged
    );
  }

  private getVolumeControlSettings() {
    let allTeamSettings = this.args.model.teams.map((team: any) => {
      let setting = this.args.model.settings.find((s: any) => s.teamId === team.id);

      if (!setting || !team || setting.distributionMethod !== 'load_balanced') {
        return null;
      }

      return {
        team,
        setting,
      };
    });

    return allTeamSettings.filter((teamSetting: any) => teamSetting !== null);
  }

  @action
  async pullConversationEnabledUpdate(pullConversationEnabled: boolean, isChanged: boolean) {
    this.settings.pullConversationEnabled = pullConversationEnabled;
    this.isPullConversationEnabledChanged = isChanged;

    if (this.appService.app.canUseHelpdeskSetup) {
      try {
        this.args.model.capacity.pullConversationEnabled = pullConversationEnabled;

        await this.args.model.capacity.save();
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.pull-conversation.save-success',
          ),
        );
      } catch (_) {
        this.notificationsService.notifyError(
          this.intl.t(
            'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.pull-conversation.save-error',
          ),
        );
      }
    }
  }

  @action
  async ticketsAssignmentDisabledUpdate() {
    this.settings = {
      ...this.settings,
      ticketsAssignmentDisabled: !this.settings.ticketsAssignmentDisabled,
    };

    if (this.appService.app.canUseHelpdeskSetup) {
      try {
        this.args.model.capacity.ticketsAssignmentDisabled =
          this.settings.ticketsAssignmentDisabled;
        await this.args.model.capacity.save();
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.tickets-assignment.save-success',
          ),
        );
      } catch (_) {
        this.notificationsService.notifyError(
          this.intl.t(
            'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.tickets-assignment.save-error',
          ),
        );
      }
    }
  }

  get ticketsAssignmentDisabledHasChanged() {
    return (
      this.settings.ticketsAssignmentDisabled !== this.args.model.capacity.ticketsAssignmentDisabled
    );
  }

  get tabs(): Array<TabItem & { canAccess: boolean }> {
    if (this.appService.app.canUseHelpdeskSetup) {
      return [
        {
          label: 'settings.teammate-workload.general-tab.title',
          value: 'general',
          canAccess: true,
        },
        {
          label: 'settings.workload-management.workload-management-title',
          value: 'workspace',
          canAccess: this.args.model.canAccessWorkloadManagement,
        },
        {
          label: 'settings.teammate-workload.teammate-assignment-limit-tab.title',
          value: 'assignment-limits',
          canAccess: true,
        },
      ];
    }

    return [
      {
        label: 'settings.workload-management.workload-management-title',
        value: 'workspace',
        canAccess: this.args.model.canAccessWorkloadManagement,
      },
      {
        label: 'settings.teammate-workload.teammates-tab.title',
        value: 'teammates',
        canAccess:
          this.app.canSeeTeammateWorkloadPage && this.args.model.canAccessWorkloadManagement,
      },
      {
        label: 'settings.teammate-workload.other-tab.title',
        value: 'other',
        canAccess: true,
      },
    ];
  }

  get controlTabs(): TabItem[] {
    return this.tabs.filter((tab) => tab.canAccess);
  }

  get selectedTab() {
    if (this.controlTabs.find((tab) => tab.value === this.args.tab)) {
      return this.args.tab;
    } else {
      return this.controlTabs[0]?.value;
    }
  }

  @action
  openDeepDiveArticle() {
    window.Intercom('showArticle', WORKLOAD_MANAGEMENT_DEEP_DIVE_ARTICLE_ID);
  }

  @action
  async changeTab(newTab: string) {
    let tab = await this.args.model.changeTabs(this.selectedTab, newTab);
    this.args.changeTab(tab);
  }

  @action
  openInboxOrderDrawer() {
    this.isInboxOrderDrawerOpen = true;
  }

  @action
  updateAttributeSorting(sorting: string[], isChanged: boolean) {
    this.settings.sorting = sorting;
    this.isSortingChanged = isChanged;
  }

  @action
  updateAssignmentLimit(limit: number) {
    this.settings.limit = limit;
    this.isLimitChanged =
      this.settings.limit === '' ||
      (!isNaN(this.settings.limit) &&
        parseInt(this.args.model.capacity.capacityLimit, 10) !== parseInt(this.settings.limit, 10));
  }

  @action
  updateTrackedProperty(key: string, value: any) {
    switch (key) {
      case 'initialInactivityEnabled':
        this.initialInactivityEnabled = value;
        break;
      case 'initialInactivityThreshold':
        this.initialInactivityThreshold = value;
        break;
      case 'followingInactivityEnabled':
        this.followingInactivityEnabled = value;
        break;
      case 'followingInactivityThreshold':
        this.followingInactivityThreshold = value;
        break;
      case 'currentShowTeammatesPresence':
        this.currentShowTeammatesPresence = value;
        break;
    }
  }

  @action
  updateVolumeControl(teamId: number) {
    if (this.appService.app.canUseFeature('predicted-assignment-volume-control-deprecated')) {
      return;
    }
    this.volumeControlSettings.forEach((s) => {
      if (s.team.id === teamId) {
        if (this.settings.volumeControlChangedTeams.has(teamId)) {
          this.settings.volumeControlChangedTeams.delete(teamId);
        } else {
          this.settings.volumeControlChangedTeams.add(teamId);
        }
        s.setting.volumeControlEnabled = !s.setting.volumeControlEnabled;
      }
    });
    this.isVolumeControlChanged = this.settings.volumeControlChangedTeams.size > 0;
    if (this.appService.app.canUseHelpdeskSetup) {
      this.saveSettings();
    }
  }

  private clearTrackedChangeStatuses() {
    this.isSortingChanged = false;
    this.isLimitChanged = false;
    this.isVolumeControlChanged = false;
    this.isPullConversationEnabledChanged = false;
    this.settings.limit = this.args.model.capacity.capacityLimit;
    this.settings.volumeControlChangedTeams = new Set<number>();
    this.args.model.attributesUpdate.isChanged = false;
    this.args.model.assignmentLimitUpdate.isChanged = false;
  }

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get assignmentSetting() {
    return this.admin?.assign_conversations_on_reply?.toString();
  }

  @action
  async saveAttributeSorting(value: any) {
    this.args.model.capacity.sortingCriteria = value;

    try {
      await this.args.model.capacity.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'sorting_criteria',
        sortingCriteria: this.args.model.capacity.sortingCriteria,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.conversations-sorting.save-success',
        ),
      );
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.conversations-sorting.save-error',
        ),
      );
    }
  }

  @action
  async saveAssignmentLimit(limit: number) {
    this.args.model.capacity.capacityLimit = limit;

    try {
      await this.args.model.capacity.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'assignment_limit',
        assignmentLimit: `${this.settings.limit}`,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.assignment-limits.save-success',
        ),
      );
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.helpdesk.assignments.tabs.workload-management-helpdesk-setup.assignment-limits.save-error',
        ),
      );
    }
  }

  @action
  async saveSettings() {
    if (!this.settings.limit || this.settings.limit < 1) {
      this.notificationsService.notifyError(
        this.intl.t('settings.workload-management.update-invalid-limit-notification'),
      );
      return;
    }

    try {
      let volumeControlPromises: any[] = [];
      if (this.isSortingChanged) {
        this.args.model.capacity.sortingCriteria = this.settings.sorting;
      }
      if (this.isLimitChanged) {
        this.args.model.capacity.capacityLimit = this.settings.limit;
      }
      if (this.isPullConversationEnabledChanged) {
        this.args.model.capacity.pullConversationEnabled = this.settings.pullConversationEnabled;
      }
      if (this.ticketsAssignmentDisabledHasChanged) {
        this.args.model.capacity.ticketsAssignmentDisabled =
          this.settings.ticketsAssignmentDisabled;
      }
      if (this.isVolumeControlChanged) {
        this.volumeControlSettings.forEach((s) => {
          if (this.settings.volumeControlChangedTeams.has(s.team.id)) {
            volumeControlPromises.push(
              s.setting.save({ adapterOptions: { onlyUpdateVolumeControl: true } }),
            );
          }
        });
      }

      await Promise.all(volumeControlPromises);
      await this.args.model.capacity.save();

      if (this.isSortingChanged) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'updated',
          object: 'sorting_criteria',
          sortingCriteria: this.settings.sorting,
        });
      }
      if (this.isLimitChanged) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'updated',
          object: 'assignment_limit',
          assignmentLimit: `${this.settings.limit}`,
        });
      }
      if (this.isVolumeControlChanged) {
        this.volumeControlSettings.forEach((s) => {
          if (this.settings.volumeControlChangedTeams.has(s.team.id)) {
            this.intercomEventService.trackAnalyticsEvent({
              action: 'toggled',
              object: 'volume-control',
              volumeControlEnabled: s.setting.volumeControlEnabled,
            });
          }
        });
      }

      this.clearTrackedChangeStatuses();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.workload-management.update-success-notification'),
      );
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t('settings.workload-management.update-fail-notification'),
      );
    }
  }

  @action
  showWorkloadManagementArticle() {
    window.Intercom('showArticle', 6553774); // https://www.intercom.com/help/en/articles/6553774-balanced-assignment-deep-dive
  }

  @action
  async saveOtherSettings() {
    try {
      if (this.args.model.canAccessAutoAwaySettings) {
        await this.persistAutoAwaySetting();
      }
      await this.saveSelfAssignSetting();
      if (this.args.model.canAccessManageInboxTeammatePresence) {
        await this.saveTeammatePresenceSettings();
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.workload-management.update-success-notification'),
      );
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t('settings.workload-management.update-fail-notification'),
      );
    }
  }

  @action
  async saveSelfAssignSetting(value?: boolean) {
    if (value !== undefined && this.appService.app.canUseHelpdeskSetup) {
      this.admin.assign_conversations_on_reply = value;
    } else {
      this.admin.assign_conversations_on_reply = this.selfAssignedSelection === 'true';
    }
    try {
      await this.admin.save();
      if (this.appService.app.canUseHelpdeskSetup) {
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'new-settings.helpdesk.assignments.other.self-assign-section.helpdesk-setup-update.save-success',
          ),
        );
      }
    } catch (_) {
      if (this.appService.app.canUseHelpdeskSetup) {
        this.notificationsService.notifyError(
          this.intl.t(
            'new-settings.helpdesk.assignments.other.self-assign-section.helpdesk-setup-update.save-error',
          ),
        );
      }
    }
  }

  @action
  async persistAutoAwaySetting() {
    if (
      this.initialInactivityThreshold < 10 ||
      this.followingInactivityThreshold < 10 ||
      this.initialInactivityThreshold > 20000 ||
      this.followingInactivityThreshold > 20000
    ) {
      this.notificationsService.notifyError(
        this.intl.t('settings.automatic-away-mode.value-error'),
      );
      return;
    }

    if (
      this.initialInactivityEnabled &&
      this.followingInactivityEnabled &&
      parseInt(this.initialInactivityThreshold, 10) + 5 >
        parseInt(this.followingInactivityThreshold, 10)
    ) {
      this.notificationsService.notifyError(
        this.intl.t('settings.automatic-away-mode.thresholds-too-close-error'),
      );
      return;
    }

    let data = {
      app_id: this.appService.app.id,
      initial_inactivity_threshold_active: this.initialInactivityEnabled,
      initial_inactivity_threshold: this.initialInactivityThreshold,
      following_inactivity_threshold_active: this.followingInactivityEnabled,
      following_inactivity_threshold: this.followingInactivityThreshold,
    };

    try {
      let response = await put(`/ember/auto_away_settings`, data);

      if (this.initialInactivityHasChanged) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'updated_auto_away_initial_threshold',
          object: 'auto_away_settings',
          enabled: this.initialInactivityEnabled,
          threshold: this.initialInactivityThreshold,
        });
      }

      if (this.followingInactivityHasChanged) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'updated_auto_away_following_threshold',
          object: 'auto_away_settings',
          enabled: this.followingInactivityEnabled,
          threshold: this.followingInactivityThreshold,
        });
      }

      this.autoAwaySetting.initialInactivityThreshold = response.initial_inactivity_threshold;
      this.autoAwaySetting.followingInactivityThreshold = response.following_inactivity_threshold;
      this.autoAwaySetting.followingInactivityThresholdActive =
        response.following_inactivity_threshold_active;
      this.autoAwaySetting.initialInactivityThresholdActive =
        response.initial_inactivity_threshold_active;

      if (this.appService.app.canUseHelpdeskSetup) {
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'new-settings.helpdesk.assignments.other.away-section.helpdesk-setup-update.save-success',
          ),
        );
      }
    } catch (_) {
      if (this.appService.app.canUseHelpdeskSetup) {
        this.notificationsService.notifyError(
          this.intl.t(
            'new-settings.helpdesk.assignments.other.away-sectionsection.helpdesk-setup-update.save-error',
          ),
        );
      }
    }
  }

  @action
  async saveTeammatePresenceSettings() {
    try {
      await post(`/ember/inbox/app_settings/update_show_teammates_presence`, {
        app_id: this.app.id,
        show_teammates_presence: this.currentShowTeammatesPresence,
      });
      this.savedShowTeammatesPresence = this.currentShowTeammatesPresence;
      if (this.appService.app.canUseHelpdeskSetup) {
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'new-settings.helpdesk.assignments.other.teammate-presence-section.helpdesk-setup-update.save-success',
          ),
        );
      }
    } catch (error) {
      this.currentShowTeammatesPresence = this.savedShowTeammatesPresence;
      if (this.appService.app.canUseHelpdeskSetup) {
        this.notificationsService.notifyError(
          this.intl.t(
            'new-settings.helpdesk.assignments.other.teammate-presence-section.helpdesk-setup-update.save-error',
          ),
        );
      }
      throw error;
    }
  }

  get initialInactivityHasChanged() {
    return (
      this.initialInactivityEnabled !== this.autoAwaySetting.initialInactivityThresholdActive ||
      parseInt(this.initialInactivityThreshold, 10) !==
        this.autoAwaySetting.initialInactivityThreshold
    );
  }

  get followingInactivityHasChanged() {
    return (
      this.followingInactivityEnabled !== this.autoAwaySetting.followingInactivityThresholdActive ||
      parseInt(this.followingInactivityThreshold, 10) !==
        this.autoAwaySetting.followingInactivityThreshold
    );
  }

  get initialInactivityEnabledValue() {
    return (
      this.autoAwaySetting.initialInactivityThresholdActive ||
      this.DEFAULT_INITIAL_INACTIVITY_ENABLED
    );
  }

  get initialInactivityThresholdValue() {
    return (
      this.autoAwaySetting.initialInactivityThreshold || this.DEFAULT_INITIAL_INACTIVITY_THRESHOLD
    );
  }

  get followingInactivityEnabledValue() {
    return (
      this.autoAwaySetting.followingInactivityThresholdActive ||
      this.DEFAULT_FOLLOWING_INACTIVITY_ENABLED
    );
  }

  get followingInactivityThresholdValue() {
    return (
      this.autoAwaySetting.followingInactivityThreshold ||
      this.DEFAULT_FOLLOWING_INACTIVITY_THRESHOLD
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::Main': typeof Main;
    'inbox/workload-management/main': typeof Main;
  }
}
