/* import __COLOCATED_TEMPLATE__ from './conversation-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  conversation: Conversation;
  isActive: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationListItem extends Component<Signature> {
  get summaryPart() {
    let summaryTypes = [
      RenderableType.AdminComment,
      RenderableType.AdminNote,
      RenderableType.BotComment,
      RenderableType.BotNote,
      RenderableType.UserComment,
    ];

    return this.args.conversation.renderableParts.find((part) =>
      summaryTypes.includes(part.renderableType),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationListItem': typeof ConversationListItem;
    'inbox2/onboarding/conversation-list-item': typeof ConversationListItem;
  }
}
