/* import __COLOCATED_TEMPLATE__ from './control-key.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type OnboardingStep from 'embercom/lib/inbox2/onboarding/step';
import platform from 'embercom/lib/browser-platform';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';

interface Args {
  key: OnboardingStep['keys'][number];
  step: OnboardingStep;
  didPressWrongKeys: boolean;
}

interface Signature {
  Args: Args;
}

export default class OnboardingControlKey extends Component<Signature> {
  @service declare inboxHotkeys: InboxHotkeys;
  // If a user presses any key, we'll set the active key event,
  // and then we can derive from that if it's correct, partially
  // correct, or just completely wrong.
  @tracked activeKeyEvent?: KeyboardEvent;

  get isActive() {
    return !this.args.step.isComplete && this.args.step.activeKey === this.args.key;
  }

  get keyCodes() {
    let codes = this.args.key.code.split('+');
    // In German standard layout the key equivalent for code BracketRight is +
    // So we cannot split on +
    let displayTexts =
      this.args.key.display === '+' ? [this.args.key.display] : this.args.key.display.split('+');

    return codes.map((code, index) => {
      return {
        text: this.formatCode(displayTexts[index]),
        isPressed: this.isKeyPressed(code, this.activeKeyEvent),
      };
    });
  }

  @action handleKeys(event: KeyboardEvent) {
    this.activeKeyEvent = event;
  }

  @action handleKeyUp() {
    this.activeKeyEvent = undefined;
  }

  private formatCode(code?: string) {
    // If a key is a single letter, we want to display the uppercase variation
    // for legibility. For example, smallcase "l" may look like "i".
    return code?.length === 1 ? code.toLocaleUpperCase() : code;
  }

  private isKeyPressed(code: string, event?: KeyboardEvent) {
    if (!event) {
      return false;
    }

    if (code === 'cmd') {
      return platform.isMac ? event.metaKey : event.ctrlKey;
    } else if (code === 'shift') {
      return event.shiftKey;
    } else {
      return code.toLowerCase() === event.key.toLowerCase() || code === event.code;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ControlKey': typeof OnboardingControlKey;
    'inbox2/onboarding/control-key': typeof OnboardingControlKey;
  }
}
