/* import __COLOCATED_TEMPLATE__ from './installation-platform-items.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import { INSTALLATION_STATUS } from 'embercom/components/installation-new/constants';
import {
  type InstallationPlatformStatus,
  type InstallationPlatformSelectorOptions,
} from 'embercom/components/installation-new/installation-platform-selector';
export interface Args {
  webInstallStatus: INSTALLATION_STATUS;
  androidInstallStatus: INSTALLATION_STATUS;
  iosInstallStatus: INSTALLATION_STATUS;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class InstallationPlatformItems extends Component<Signature> {
  @service declare intl: IntlService;

  get installationPlatforms(): InstallationPlatformSelectorOptions[] {
    return [
      {
        id: 'web',
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-platform.web.title',
        ),
        icon: 'computer' as InterfaceIconName,
        installationStatuses: this.webInstallationStatuses,
      },
      {
        id: 'mobile',
        title: this.intl.t(
          'apps.app.settings.installation.installation.new.installation-platform.mobile.title',
        ),
        icon: 'mobile' as InterfaceIconName,
        installationStatuses: this.mobileInstallationStatuses,
      },
    ];
  }

  private get webInstallationStatuses(): InstallationPlatformStatus[] {
    let color;
    let text;
    let tooltipText;

    switch (this.args.webInstallStatus) {
      case INSTALLATION_STATUS.SECURELY_INSTALLED:
        color = 'green';
        text = this.intl.t('apps.app.settings.installation.pulse-status.installed');
        break;
      case INSTALLATION_STATUS.INSECURELY_INSTALLED:
        color = 'yellow';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.statuses.insecurely-installed',
        );
        tooltipText = this.intl.t(
          'apps.app.settings.installation.installation.new.web.statuses.insecurely-installed-tooltip',
        );
        break;
      case INSTALLATION_STATUS.INSTALLED_FOR_VISITORS_ONLY:
        color = 'yellow';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.web.statuses.installed-for-visitors-only',
        );
        tooltipText = this.intl.t(
          'apps.app.settings.installation.installation.new.web.statuses.installed-for-visitors-only-tooltip',
        );
        break;
      default:
        color = 'gray';
        text = this.intl.t('apps.app.settings.installation.pulse-status.not-installed');
    }

    return [
      {
        color,
        text,
        tooltipText,
      },
    ];
  }

  private get mobileInstallationStatuses(): InstallationPlatformStatus[] {
    // If both are fully installed, show a single green installed status
    if (
      this.args.iosInstallStatus === INSTALLATION_STATUS.SECURELY_INSTALLED &&
      this.args.androidInstallStatus === INSTALLATION_STATUS.SECURELY_INSTALLED
    ) {
      return [
        {
          color: 'green',
          text: this.intl.t('apps.app.settings.installation.pulse-status.installed'),
        },
      ];
    }

    // If neither are fully installed, show a single gray not installed status
    if (
      this.args.iosInstallStatus === INSTALLATION_STATUS.NOT_INSTALLED &&
      this.args.androidInstallStatus === INSTALLATION_STATUS.NOT_INSTALLED
    ) {
      return [
        {
          color: 'gray',
          text: this.intl.t('apps.app.settings.installation.pulse-status.not-installed'),
        },
      ];
    }

    // If one is fully installed and the other is not, show specific statuses for each
    return [this.iosStatus, this.androidStatus];
  }

  private get iosStatus(): InstallationPlatformStatus {
    let color;
    let text;
    let tooltipText;

    switch (this.args.iosInstallStatus) {
      case INSTALLATION_STATUS.SECURELY_INSTALLED:
        color = 'green';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.ios.statuses.installed',
        );
        break;
      case INSTALLATION_STATUS.INSECURELY_INSTALLED:
        color = 'yellow';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.ios.statuses.insecurely-installed',
        );
        tooltipText = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.ios.statuses.insecurely-installed-tooltip',
        );
        break;
      default:
        color = 'gray';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.ios.statuses.not-installed',
        );
    }

    return {
      color,
      text,
      tooltipText,
    };
  }

  private get androidStatus(): InstallationPlatformStatus {
    let color;
    let text;
    let tooltipText;

    switch (this.args.androidInstallStatus) {
      case INSTALLATION_STATUS.SECURELY_INSTALLED:
        color = 'green';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.android.statuses.installed',
        );
        break;
      case INSTALLATION_STATUS.INSECURELY_INSTALLED:
        color = 'yellow';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.android.statuses.insecurely-installed',
        );
        tooltipText = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.android.statuses.insecurely-installed-tooltip',
        );
        break;
      default:
        color = 'gray';
        text = this.intl.t(
          'apps.app.settings.installation.installation.new.mobile.android.statuses.not-installed',
        );
    }

    return {
      color,
      text,
      tooltipText,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::InstallationPlatformItems': typeof InstallationPlatformItems;
    'installation-new/installation-platform-items': typeof InstallationPlatformItems;
  }
}
