/* import __COLOCATED_TEMPLATE__ from './segments.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { and, not, alias } from '@ember/object/computed';

export default Component.extend({
  tagName: '',
  init() {
    this._super(...arguments);
    this.user.loadSegments.perform();
  },
  didUpdateAttrs() {
    this._super(...arguments);
    this.user.loadSegments.perform();
  },
  showLoadingIndicator: alias('user.loadSegments.isRunning'),
  isLoadingSegmentsDone: not('user.loadSegments.isRunning'),
  showErrorMessage: and('user.segmentsError', 'isLoadingSegmentsDone'),
});
