/* import __COLOCATED_TEMPLATE__ from './update-linked-report-state-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface Args {
  selectedTicketState: TicketSystemState | ConversationState | number;
  trackerTicketsCount?: number;
  linkedCustomerTicketCount: number;
  linkedCustomerReportCount: number;
  onModalClose: () => void;
  onContinueWithoutUpdating: () => void;
  onUpdateTicketStates: () => void;
}

interface Signature {
  Args: Args;
}

export default class UpdateLinkedReportStateModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;

  get translationKeySuffix() {
    if (this.args.selectedTicketState === ConversationState.Closed) {
      return this.args.trackerTicketsCount && this.args.trackerTicketsCount > 1
        ? 'close-multiple'
        : 'close';
    } else {
      return 'update';
    }
  }

  get translatedTicketState() {
    if (typeof this.args.selectedTicketState === 'number') {
      return this.ticketStateService.getTicketCustomStateById(this.args.selectedTicketState)
        .adminLabel;
    }

    if (
      Object.values(TicketSystemState).includes(this.args.selectedTicketState as TicketSystemState)
    ) {
      return this.intl.t(`inbox.ticket-state.${this.args.selectedTicketState}`);
    } else {
      return this.intl.t('inbox.conversation-states.close');
    }
  }

  get headerText() {
    return this.intl.t(
      `inbox.update-linked-tickets-states-modal.header.${this.translationKeySuffix}`,
    );
  }

  get bodyPrefixText() {
    return this.intl.t(
      `inbox.update-linked-tickets-states-modal.body-prefix.${this.translationKeySuffix}`,
      {
        linkedCustomerTicketCount: this.args.linkedCustomerTicketCount,
        linkedCustomerReportCount: this.args.linkedCustomerReportCount,
        trackerTicketsCount: this.args.trackerTicketsCount,
        state: this.translatedTicketState,
        htmlSafe: true,
      },
    );
  }

  get bodySuffixText() {
    return this.intl.t(
      `inbox.update-linked-tickets-states-modal.body-suffix.${this.translationKeySuffix}`,
      {
        linkedCustomerTicketCount: this.args.linkedCustomerTicketCount,
        linkedCustomerReportCount: this.args.linkedCustomerReportCount,
        trackerTicketsCount: this.args.trackerTicketsCount,
        state: this.translatedTicketState,
        htmlSafe: true,
      },
    );
  }

  get primaryButtonCta() {
    return this.intl.t(
      `inbox.update-linked-tickets-states-modal.primary-button-cta.${this.translationKeySuffix}`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UpdateLinkedReportStateModal': typeof UpdateLinkedReportStateModal;
    'inbox2/update-linked-report-state-modal': typeof UpdateLinkedReportStateModal;
  }
}
