/* import __COLOCATED_TEMPLATE__ from './animated-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

interface Args<T, I extends number> {
  item: T;
  index: I;
  itemHeights: number[];
}

interface Signature<T, I extends number> {
  Args: Args<T, I>;
  Blocks: {
    default: [
      {
        item: T;
        index: I;
      },
    ];
  };
}

export default class AnimatedListItem<T, I extends number> extends Component<Signature<T, I>> {
  // We calculate by how much the item should be offset from the top of the list container.
  // Items can be variable in height and have an itemHeight defined or be fixed in height externally.
  get offset() {
    return this.args.itemHeights.reduce((acc, itemHeight, index) => {
      if (index < this.args.index) {
        return acc + itemHeight;
      }
      return acc;
    }, 0);
  }

  get itemHeight(): number {
    return this.args.itemHeights[this.args.index];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::AnimatedListItem': typeof AnimatedListItem;
    'inbox2/left-nav/animated-list-item': typeof AnimatedListItem;
  }
}
