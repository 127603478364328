/* import __COLOCATED_TEMPLATE__ from './conversation-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: any;
}

const ConversationList = templateOnlyComponent<Signature>();
export default ConversationList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationList': typeof ConversationList;
    'inbox2/onboarding/conversation-list': typeof ConversationList;
  }
}
