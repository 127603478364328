/* import __COLOCATED_TEMPLATE__ from './section-body.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { SIDEBAR_EXPANDED_CONTENT_VISIBILITY } from 'embercom/lib/inbox/constants';

export default class InboxConversationSiderbarSectionBody extends Component {
  @tracked
  expandedContentVisibility = SIDEBAR_EXPANDED_CONTENT_VISIBILITY.collapsed;

  @action
  updateExpandedContentVisibility(newVisibilityState) {
    this.expandedContentVisibility = newVisibilityState;
  }
}
