/* import __COLOCATED_TEMPLATE__ from './link-existing-ticket-button.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type Snackbar from 'embercom/services/snackbar';
import type CommandKService from 'embercom/services/command-k';
import { tracked } from '@glimmer/tracking';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  conversation: Conversation;
  inSidebar?: boolean;
  pinnedSection?: boolean;
}

interface Signature {
  Args: Args;
}

export default class LinkExistingTicketButton extends Component<Signature> {
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare commandK: CommandKService;

  @tracked isShowingConfirmationModal = false;
  @tracked selectedTicket: ConversationTableEntry | null = null;

  @action
  async onClick() {
    this.commandK.findAndShow('search-ticket', () => {});
  }

  async linkTicket(ticketId: any) {
    try {
      let data = {
        ticket_id: ticketId,
        conversation_id: this.inboxState.activeConversationId,
      } as Record<string, unknown>;

      await this.inboxApi.linkExistingTicket(data);

      this.snackbar.notify(
        this.intl.t(
          'inbox.conversation-details-sidebar.linked-tickets.link-existing-ticket.success-message',
          {
            ticket_id: ticketId,
          },
        ),
      );
    } catch (e) {
      this.snackbar.notifyError(
        this.intl.t(
          'inbox.conversation-details-sidebar.linked-tickets.link-existing-ticket.error-message',
          {
            ticket_id: ticketId,
          },
        ),
      );
    }
  }

  @action
  async onSelectTicket(ticket: any) {
    this.isShowingConfirmationModal = true;
    this.selectedTicket = ticket;
  }

  @action
  async onConfirm() {
    this.isShowingConfirmationModal = false;
    if (this.selectedTicket) {
      await this.linkTicket(this.selectedTicket.id);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LinkExistingTicketButton': typeof LinkExistingTicketButton;
    'inbox2/link-existing-ticket-button': typeof LinkExistingTicketButton;
  }
}
