/* import __COLOCATED_TEMPLATE__ from './latest-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import templateOnlyComponent from '@ember/component/template-only';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Signature {
  Element: HTMLDivElement;
  Args: SidebarSectionComponentArgs<{
    conversation: Conversation;
  }>;
}

const LatestConversations = templateOnlyComponent<Signature>();
export default LatestConversations;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::ConversationDetailsSidebar::LatestConversations': typeof LatestConversations;
    'inbox2/onboarding/conversation-details-sidebar/latest-conversations': typeof LatestConversations;
  }
}
