/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

import { registerDestructor } from '@ember/destroyable';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';
import SmartReply from 'embercom/objects/inbox/smart-reply';
import type ApplicationInstance from '@ember/application/instance';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';

type Args = {
  conversation: Conversation;
};

interface ReplyResource {
  smartReply: SmartReply | null;
}

/* eslint-disable @intercom/intercom/no-component-inheritance */
export default class SmartReplyResource extends Resource<Named<Args>> implements ReplyResource {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;

  declare conversationId: number;
  declare lastPart: RenderablePart | null;
  @tracked declare smartReply: SmartReply | null;

  constructor(owner: ApplicationInstance) {
    super(owner);

    registerDestructor(this, () => {
      taskFor(this.fetchSmartReply).cancelAll();
    });
  }

  modify(_: unknown[], args: Args) {
    if (this.session.showLightInbox) {
      return;
    }

    if (!this.shouldFetchNewSmartReply(args)) {
      if (this.shouldClearSmartReply(args)) {
        this.smartReply = SmartReply.createPending();
        taskFor(this.fetchSmartReply).cancelAll();
      }
      return;
    }

    this.conversationId = args.conversation.id;
    this.lastPart = this.lastContentPart(args.conversation);

    this.smartReply = SmartReply.createPending();
    taskFor(this.fetchSmartReply).perform(this.conversationId);
  }

  // TODO - smart replies v1 doesn't need to re-fetch after each part
  // but the frontend isn't currently aware of the experiment we're in
  private shouldFetchNewSmartReply(current: Args) {
    if (current.conversation.isLoading) {
      return false;
    }

    if (current.conversation.id !== this.conversationId) {
      return true;
    }

    let currentLastContentPart = this.lastContentPart(current.conversation);
    if (currentLastContentPart?.pending) {
      return false;
    }

    if (currentLastContentPart?.uuid !== this.lastPart?.uuid) {
      return true;
    }

    return false;
  }

  private shouldClearSmartReply(current: Args) {
    if (current.conversation.isLoading) {
      return true;
    }

    let currentLastContentPart = this.lastContentPart(current.conversation);
    if (currentLastContentPart?.pending) {
      return true;
    }

    return false;
  }

  @task({ restartable: true })
  private *fetchSmartReply(id: number): TaskGenerator<void> {
    let controller = new AbortController();
    let smartReply;

    try {
      smartReply = yield this.inboxApi.fetchSmartReply(id, { signal: controller.signal });
    } finally {
      controller.abort();
    }

    // the task should be cancelled if the conversation has changed
    // but double check to be safe
    if (this.conversationId !== id) {
      return;
    }

    this.smartReply = smartReply;
  }

  private lastContentPart(conversation: Conversation) {
    let parts = conversation.renderableParts;
    let types = [RenderableType.Email, RenderableType.AdminComment, RenderableType.UserComment];

    for (let i = parts.length - 1; i >= 0; i--) {
      if (types.includes(parts[i].renderableType)) {
        return parts[i];
      }
    }
    return null;
  }
}
