/* import __COLOCATED_TEMPLATE__ from './team-details-row.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type Session from 'embercom/services/session';
import type TeamSummary from 'embercom/objects/inbox/team-summary';

const INBOX_PRIORITY_PRIMARY = 1;
const COUNT_CLASS_NAMES = {
  atCapacity: 'text-red dark:text-dm-red',
  notAtCapacity: 'text-gray dark:text-dm-gray',
};

interface Args {
  admin: AdminSummary;
  team: TeamSummary;
  capacityLimit: number;
  adminsBlockable: boolean;
  countType: string;
  ticketsAssignmentDisabled: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class UnnamedComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare session: Session;

  get adminAssignmentLimit(): number | undefined {
    return this.args.admin.workloadManagementSettings?.assignmentLimit;
  }

  get assignmentLimit(): number {
    return this.adminAssignmentLimit !== undefined
      ? this.adminAssignmentLimit
      : this.args.capacityLimit;
  }

  get aboveCapacity() {
    return this.adminInboxCount! >= this.assignmentLimit;
  }

  get isPrimaryInbox() {
    return this.args.admin.wlmInboxPriorityLevel === INBOX_PRIORITY_PRIMARY;
  }

  get adminInboxCount() {
    switch (this.args.countType) {
      case 'tickets':
        return this.args.admin.countMetadata?.tickets;
      case 'conversations':
        return this.args.admin.countMetadata?.conversations;
      default:
        return this.args.admin.openCount;
    }
  }

  get shouldShowAtCapacity() {
    if (this.args.adminsBlockable && this.aboveCapacity) {
      if (this.args.countType === 'all' && !this.args.ticketsAssignmentDisabled) {
        return true;
      } else if (this.args.countType === 'conversations' && this.args.ticketsAssignmentDisabled) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  get countClassNames() {
    if (this.shouldShowAtCapacity) {
      return COUNT_CLASS_NAMES.atCapacity;
    }

    return COUNT_CLASS_NAMES.notAtCapacity;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TeamDetailsRow': typeof UnnamedComponent;
    'inbox2/team-details-row': typeof UnnamedComponent;
  }
}
