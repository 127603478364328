/* import __COLOCATED_TEMPLATE__ from './view-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import type IntlService from 'embercom/services/intl';

// @ts-ignore
import PredicateGroup from 'predicates/models/predicate-group';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type InboxState from 'embercom/services/inbox-state';
import type Store from '@ember-data/store';

interface Args {
  view: ViewSummary;
  inbox: Inbox;
  // If the inbox is being rendered as part of a custom folder this argument will have the
  // id of the custom folder
  customFolderId?: number;
  onEditView: (view: ViewSummary) => void;
  removePin: () => void;
}

interface Signature {
  Args: Args;
}

export default class UnnamedComponent extends Component<Signature> {
  @service declare session: Session;
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare store: Store;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;

  @tracked predicateGroup: PredicateGroup;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.predicateGroup = this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, args.view.predicates.predicates),
    });
  }

  get viewName() {
    let view = this.args.view;
    return view.isSystemView
      ? this.intl.t(`inbox.inbox-list.system-view-names.${view.systemType}`)
      : view.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ViewDetails': typeof UnnamedComponent;
    'inbox2/view-details': typeof UnnamedComponent;
  }
}
