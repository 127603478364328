/* import __COLOCATED_TEMPLATE__ from './event-summary-list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import AttributeList from 'embercom/components/inbox/user-profile/attribute-list';

export default AttributeList.extend({
  objectFilterKey: 'humanFriendlyName',
});
