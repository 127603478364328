/* import __COLOCATED_TEMPLATE__ from './no-results.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  onClearClick?: () => void;
}

const SearchV2NoResults = templateOnlyComponent<Signature>();
export default SearchV2NoResults;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::NoResults': typeof SearchV2NoResults;
    'inbox2/search/no-results': typeof SearchV2NoResults;
  }
}
