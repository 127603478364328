/* import __COLOCATED_TEMPLATE__ from './video-call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type UserSummary from 'embercom/objects/inbox/user-summary';

interface Args {
  user: UserSummary | undefined;
  titleLine: string;
  isCameraAvailable: boolean;
  isCameraOn: boolean;
  isMicrophoneOn: boolean;
  isRecordingEnabled: boolean;
  isUserCameraOn: boolean;
  isScreenSharingOn: boolean;
  isExpandedView: boolean;
  isRecording: boolean;
  startTime?: number;
  endCall: () => void;
  toggleMicrophone: () => void;
  toggleCamera: () => void;
  toggleExpandedView: () => void;
  toggleRecording: () => void;
}

export default class VideoCallModal extends Component<Args> {
  get heightClasses() {
    return this.args.isScreenSharingOn && !this.args.isExpandedView ? 'h-128' : '';
  }

  get widthClasses() {
    let width = 'w-96';

    if (this.args.isScreenSharingOn) {
      width = !this.args.isExpandedView ? 'w-192' : '';
    }

    return `${width} `;
  }

  get screenSharingClasses() {
    if (!this.args.isScreenSharingOn) {
      return '';
    }

    return 'w-full h-full m-auto';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::VideoCallModal': typeof VideoCallModal;
    'inbox2/video-call-modal': typeof VideoCallModal;
  }
}
