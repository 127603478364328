/* import __COLOCATED_TEMPLATE__ from './click-callback.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const ClickCallback = templateOnlyComponent<Signature>();
export default ClickCallback;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ConsistentNav::ClickCallback': typeof ClickCallback;
    'inbox2/user-menu/consistent-nav/click-callback': typeof ClickCallback;
  }
}
