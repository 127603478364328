/* import __COLOCATED_TEMPLATE__ from './notification-sound-blocked-banner.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type BrowserAlerts from 'embercom/services/browser-alerts';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';

export default class NotificationSoundBlockedBannerComponent extends Component {
  @service declare browserAlerts: BrowserAlerts;

  clickAnywhereToClose = modifier((_element: Element, [_], { enabled }: { enabled: boolean }) => {
    if (enabled) {
      let onClickAnywhere = () => {
        this.closeBanner();
      };
      document.addEventListener('click', onClickAnywhere);
      return function () {
        document.removeEventListener('click', onClickAnywhere);
      };
    }
    return;
  });

  @action closeBanner() {
    this.browserAlerts.hideAudioNotificationsBlockedBanner = true;
  }

  get displayNoInteractionBanner(): boolean {
    return (
      this.browserAlerts.isBlockedByNoInteraction || this.browserAlerts.isBlockedByAudioCannotLoad
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NotificationSoundBlockedBanner': typeof NotificationSoundBlockedBannerComponent;
    'inbox2/notification-sound-blocked-banner': typeof NotificationSoundBlockedBannerComponent;
  }
}
