/* import __COLOCATED_TEMPLATE__ from './conversation-remarks-table.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { A } from '@ember/array';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { buildNestedFieldFilters } from 'embercom/lib/reporting/nested-field-filters';
import PredicateFilterMap from 'embercom/models/data/predicate-filter-map';
import type MutableArray from '@ember/array/mutable';
import type Store from '@ember-data/store';

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

interface Args {
  range: any;
  title?: string;
  filters?: any;
  filteringBy?: number[];
  pageSizeOverride?: number;
  titleTooltipText?: string;
  excludeOperator?: boolean;
  operatorOnly?: boolean;
  showFiltersNotAppliedWarning?: boolean;
}

interface Params {
  range_start: string;
  range_end: string;
  page: number;
  per_page: number;
  admin_ids?: string[];
  exclude_admin_ids?: string[];
  rating_index?: number;
  conversation_tag_ids?: string[];
  reporting_filters?: Record<string, any>;
  nested_filters?: { field_id: string; operator: any }[];
}

const DEFAULT_PAGE_SIZE = 50;
const SORTABLE_ATTRIBUTES = ['rating_index', 'admin.name'];

export default class ConversationRemarksTable extends Component<Signature> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare intl: any;
  @service declare store: Store;
  @service declare bootOptionsService: any;

  @tracked ratings: MutableArray<any>;
  @tracked currentPage = 1;
  @tracked pageSize: number;
  @tracked hasRenderedLastPage = false;
  @tracked linksEnabled: boolean;
  @tracked sortingBy: string | null = null;
  @tracked sortAscending = true;
  @tracked filteringByRating: number | null = null;

  declare args: Args;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.ratings = [];
    this.pageSize = args.pageSizeOverride || DEFAULT_PAGE_SIZE;
    this.linksEnabled = !this.intercomEventService.embeddedContent;
    this.filteringByRating = args.filteringBy?.length ? args.filteringBy[0] : null;
    taskFor(this.restartRatingsTask).perform();
  }

  get operator() {
    return this.appService.app.admins.findBy('is_operator', true);
  }

  get hasNoRatings() {
    return !this.ratings.length;
  }

  get ratingFilterItems() {
    return PredicateFilterMap.last_conversation_rating_given.data.reduce(
      (ratingItems: any, rating: { value: any; emoji: any }) => [
        ...ratingItems,
        {
          text: this.intl.t(`common.conversation-ratings.with-emoji.rating_index_${rating.value}`, {
            emoji: rating.emoji,
          }),
          value: rating.value,
        },
      ],
      [{ text: this.intl.t('reporting.inbox.conversation-remarks.all-ratings'), value: null }],
    );
  }

  get isRatingsTaskLoading() {
    return taskFor(this.loadRatingsTask).isRunning;
  }

  get isNextPageTaskLoading() {
    return taskFor(this.nextPageTask).isRunning;
  }

  @task({ restartable: true })
  *restartRatingsTask(): TaskGenerator<void> {
    this.ratings = A();
    this.currentPage = 1;
    this.hasRenderedLastPage = false;
    yield taskFor(this.loadRatingsTask).perform();
  }

  @task({ restartable: true })
  *loadRatingsTask(): TaskGenerator<void> {
    let params: Params = {
      range_start: this.args.range.start,
      range_end: this.args.range.end,
      page: this.currentPage,
      per_page: this.pageSize,
      exclude_admin_ids: this.args.excludeOperator ? [this.operator.id] : [],
    };

    if (this.filteringByRating) {
      params.rating_index = this.filteringByRating;
    }

    let teammateIds = this.args.filters?.teammate_id;
    if (isPresent(teammateIds)) {
      params.admin_ids = teammateIds;
    }

    if (this.args.operatorOnly) {
      params.admin_ids = [this.operator.id];
    }

    let conversationTagIds = this.args.filters?.conversation_tag_ids;
    if (isPresent(conversationTagIds)) {
      params.conversation_tag_ids = conversationTagIds;
    }

    //generic filters
    let { custom_attributes, ...reportingFilters } = this.args.filters || {};
    if (isPresent(reportingFilters)) {
      params.reporting_filters = reportingFilters;
    }

    if (isPresent(custom_attributes)) {
      let nestedFilters = buildNestedFieldFilters(custom_attributes);

      if (nestedFilters) {
        params.nested_filters = nestedFilters;
      }
    }

    let ratings = yield this.store.query('conversation-rating', params);
    this.ratings.addObjects(ratings);
    this.hasRenderedLastPage = ratings.length < this.pageSize;
  }

  @task({ drop: true })
  *nextPageTask(): TaskGenerator<void> {
    if (this.hasRenderedLastPage || taskFor(this.loadRatingsTask).isRunning) {
      return;
    }
    this.currentPage += 1;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'load_next_page',
      object: 'remark_report',
      context: `page_${this.currentPage}`,
    });
    yield taskFor(this.loadRatingsTask).perform();
  }

  @action
  sort(attribute: string) {
    if (!SORTABLE_ATTRIBUTES.includes(attribute)) {
      return;
    }
    if (attribute === this.sortingBy) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortingBy = attribute;
      this.sortAscending = true;
    }
    let result = this.ratings.sortBy(this.sortingBy);
    this.ratings = this.sortAscending ? result : result.reverse();
  }

  @action
  filterBy(rating: number) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_by_rating',
      object: 'remark_report',
      context: `by_${rating}`,
    });
    this.filteringByRating = rating;
    taskFor(this.restartRatingsTask).perform();
  }

  @action
  updateRating() {
    taskFor(this.restartRatingsTask).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::Reporting::ConversationRemarksTable': typeof ConversationRemarksTable;
  }
}
