/* import __COLOCATED_TEMPLATE__ from './swift-ui.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  iosApiKey: string;
  appId: string;
}

interface Signature {
  Args: Args;
  Element: any;
}

const SwiftUi = templateOnlyComponent<Signature>();
export default SwiftUi;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Mobile::InitialSetup::Ios::CodeSnippets::SwiftUi': typeof SwiftUi;
    'installation-new/mobile/initial-setup/ios/code-snippets/swift-ui': typeof SwiftUi;
  }
}
