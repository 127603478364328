/* import __COLOCATED_TEMPLATE__ from './teams.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

export default class Teams extends Component {
  get adminTeams() {
    return this.args.adminTeams.map((a) => a.toJSON({ includeId: true }));
  }

  get teamsForAdmin() {
    return this.adminTeams.filter((team) =>
      team.memberIds.includes(parseInt(this.args.admin.id, 10)),
    );
  }
}
